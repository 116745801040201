import React, { useContext, useState, MouseEvent } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Divider } from 'antd';
import './HeaderBanner.scss';

import { OrderServiceLink } from './OrderServiceLink';
import AuthContext from '../../../contexts/Auth/AuthContext';
import { GovUaToast } from '../../Toasts/GovUaToast';
import { AccessibilityPanelButton } from '../AccessibilityPanel/AccessibilityPanelButton';
import { MVSButton } from './MVSButton';
import { NavTabs } from './NavTabs';
import { ROUTES } from '../../../router/config';

const HeaderBanner = (): JSX.Element => {
  const authContext = useContext(AuthContext);
  const { pathname } = useLocation();
  const [isGovUaToastShown, setGovUaToastShown] = useState(false);

  const govUaToastHandler = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    if (isGovUaToastShown) {
      setGovUaToastShown(false);
      return;
    }
    setGovUaToastShown(true);
  };

  return (
    <div className="header-banner">
      <div className="container">
        <div className="header-banner__content">
          <div className="header-banner__links">
            <div className="header-banner__links-container">
              <NavLink to={ROUTES.HOME} className="header-banner__logo">
                <img
                  className="header-banner__logo-img"
                  src="/images/simple_logo.svg"
                  alt="logo"
                  width="72"
                  height="72"
                />
                <h1 className="header-banner__logo-title">Єдине вікно для громадян</h1>
              </NavLink>
              <div className="header-banner__links-container header-banner__links-container--external">
                <GovUaToast
                  govUaToastHandler={govUaToastHandler}
                  isGovUaToastShown={isGovUaToastShown}
                  variant="large"
                />
                <Divider type="vertical" className="header-banner__links-container__divider" />
                <MVSButton />
              </div>
            </div>

            <NavTabs />
          </div>
          <div className="header-banner__action-buttons">
            <div className="header-banner__action-buttons__auth">
              {!authContext.isLoggedIn && (
                <div className="media-title header-banner__media-title header-banner__media-title header-banner__media-title_default-hover">
                  <NavLink to={ROUTES.LOGIN} className="media-title header-banner__media-title">
                    <img
                      className="media-title__media"
                      src="/images/user_white.svg"
                      alt="user"
                      width="24"
                      height="24"
                    />
                    <span className="media-title__title">Увійти</span>
                  </NavLink>
                </div>
              )}
              {authContext.isLoggedIn && (
                <>
                  <div className="media-title__title ">
                    <Link
                      to={ROUTES.LOGIN}
                      state={{
                        nextPage: pathname,
                      }}
                      className="media-title header-banner__media-title"
                      onClick={(): void => authContext.logout()}
                    >
                      <img
                        className="media-title__media"
                        src="/images/exit_white.svg"
                        alt="exit"
                        width="24"
                        height="24"
                      />
                      <span className="media-title__title">Вийти</span>
                    </Link>
                  </div>
                  <div className="media-title__title">
                    <Link
                      to={ROUTES.USER.REQUESTS.ACTIVE_REQUESTS}
                      className="media-title header-banner__media-title"
                      style={{ display: 'flex' }}
                    >
                      <img
                        className="media-title__media"
                        src="/images/user_white.svg"
                        alt="user"
                        width="24"
                        height="24"
                      />
                      <span className="media-title__title">Особистий кабінет</span>
                    </Link>
                  </div>
                </>
              )}
              <AccessibilityPanelButton />
            </div>

            <OrderServiceLink />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBanner;
