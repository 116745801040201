import { DocumentTypeEntity } from '../../helpers/reformatDocumentTypeToEntity';
import { getERZDocumentTypeEntites } from '../../modules/erz/helpers';
import { api } from '../Api';
import { DocumentTypeCode, IntegrationSystem, ServiceCodes, UserService } from '../Api/types';
import { SelectOption } from './Select';

const documentTypeCodeByServiceCode: Partial<Record<ServiceCodes, DocumentTypeCode>> = {
  [ServiceCodes.COMMISSION_SALE]: DocumentTypeCode.COMMISSION_SALE,
  [ServiceCodes.PURCHASE]: DocumentTypeCode.PURCHASE,
  [ServiceCodes.TRANSPORT_ACROSS_BORDER]: DocumentTypeCode.TRANSPORT_ACROSS_BORDER,
  [ServiceCodes.COLD_STEEL_TRANSPORTATION]: DocumentTypeCode.COLD_STEEL_TRANSPORTATION,
  [ServiceCodes.STORAGE_CARRYING]: DocumentTypeCode.STORAGE_CARRYING,
  [ServiceCodes.COLD_STEEL_STORAGE_FOR_COLLECTION]: DocumentTypeCode.COLD_STEEL_STORAGE_FOR_COLLECTION,
  [ServiceCodes.RESIDENCE_RELOCATION]: DocumentTypeCode.RESIDENCE_RELOCATION,
  [ServiceCodes.TEMPORARY_RESIDENCE_RELOCATION]: DocumentTypeCode.TEMPORARY_RESIDENCE_RELOCATION,
  [ServiceCodes.VALIDITY_PERIOD_PROLONGATION]: DocumentTypeCode.VALIDITY_PERIOD_PROLONGATION,
  [ServiceCodes.MISSING_PERSON]: DocumentTypeCode.MISSING_PERSON,
};

interface GetServiceDataReturnType<T extends ServiceCodes> {
  service: {
    id: UserService['id'];
    name: UserService['name'];
    code: UserService['code'];
  };
  applicationAttachment: T extends ServiceCodes.CRIMINAL_RECORD_EXTRACT
    ? Record<never, never>
    : {
        id: DocumentTypeEntity['id'];
        fileName: DocumentTypeEntity['label'];
        typeDoc: DocumentTypeEntity['label'];
        code: DocumentTypeEntity<IntegrationSystem.ERZ>['code'];
        info: Record<string, never>;
        encoded_contents: [];
      };
}

export async function getServiceData<T extends ServiceCodes>(serviceCode: T): Promise<GetServiceDataReturnType<T>> {
  const [{ data: services }, [documentEntity]] = await Promise.all([
    api.services.getAll(),
    getERZDocumentTypeEntites([documentTypeCodeByServiceCode[serviceCode]]),
  ]);

  const currentService = services.find((service) => {
    return service.code === serviceCode;
  });

  return {
    service: {
      id: currentService.id,
      name: currentService.name,
      code: currentService.code,
    },
    // Some services does not have its application_:code document
    applicationAttachment: {
      id: documentEntity?.id || null,
      fileName: documentEntity?.label || null,
      typeDoc: documentEntity?.label || null,
      code: documentEntity?.code || null,
      info: {},
      encoded_contents: [],
    },
  };
}

export const checkIsCityWithSpecialStatus = (cityId: string): boolean => {
  const cityIdsWithSpecialStatus: string[] = [IdConstants.KYIV, IdConstants.SEVASTOPOL];

  return cityIdsWithSpecialStatus.includes(cityId);
};

export const maxFileSize = 1.6 * 1024 * 1024; // 1.6 MB
export const legalAgeDate = new Date();
legalAgeDate.setFullYear(new Date().getFullYear() - 18);

export type PayloadGroupMockup<T extends string = never> = Record<T, Record<string, boolean | null | []>>;
export interface Attachment {
  id: string;
  typeDoc: string;
  fileName: string;
  code: DocumentTypeCode;
  info: Record<string, unknown>;
  encoded_contents: string[] | [];
}

export enum FormConstants {
  INPUT_MAX_LENGTH = 120,
  TEXTAREA_MAX_LENGTH = 3000,
}

export enum IdConstants {
  UKRAINE = '7974a75f-85b9-4f2c-9aeb-fb403cdd0a53',
  KYIV = '4c32c29c-4b71-4b3b-b481-52bf1e38f0fb',
  SEVASTOPOL = 'd487add9-b7ea-4350-9efe-1c36c2f2c461',
}

export enum DocumentRegisteredDocDataFieldName {
  registeredDocType = 'registeredDocType',
  registeredLocalCommunityNumber = 'registeredLocalCommunityNumber',
  registeredLocalCommunityDate = 'registeredLocalCommunityDate',
  registeredLocalCommunityAuthDep = 'registeredLocalCommunityAuthDep',
  registeredDocumentLocalCommunityDoc = 'registeredDocumentLocalCommunityDoc',
}

export enum DocumentLeaseAgreementDataFieldName {
  LEASE_AGREEMENT = 'lease_agreement',
  contractNumberRent = 'contractNumberRent',
  indexNumberRent = 'indexNumberRent',
  dateSignRent = 'dateSignRent',
  expirationDateRent = 'expirationDateRent',
  declaredDocRent = 'declaredDocRent',
}

export enum DocumentOwnershipCertificateDataFieldName {
  OWNERSHIP_CERTIFICATE = 'ownership_certificate',
  serialNumberDoc = 'serialNumberDoc',
  indexNumber = 'indexNumber',
  certificateDate = 'certificateDate',
  authDep = 'authDep',
  PropertyDeclaredDoc = 'PropertyDeclaredDoc',
}

export enum DocumentExractPrprDataFieldName {
  EXRACT_PRPR = 'exract_prpr',
  ownershipRegNumber = 'ownershipRegNumber',
  ownershipindexNumber = 'ownershipindexNumber',
  ownershipFormedBy = 'ownershipFormedBy',
  ownershipDate = 'ownershipDate',
  OwnershipDeclaredDoc = 'OwnershipDeclaredDoc',
}

export enum DocumentCertMarriageDataFieldName {
  CERT_MARRIAGE = 'cert_marriage',
  marriageSeriaNumberDoc = 'marriageSeriaNumberDoc',
  marriageAuthDep = 'marriageAuthDep',
  marriageDate = 'marriageDate',
  marriageDeclaredDoc = 'marriageDeclaredDoc',
}

export enum DocumentVityagTeritorialnoyiGromadiDataFieldName {
  VITYAG_TERITORIALNOYI_GROMADI = 'address',
  localCommunityDate = 'localCommunityDate',
  localCommunityNumber = 'localCommunityNumber',
  localCommunityAuthDep = 'localCommunityAuthDep',
  localCommunityDeclaredDoc = 'localCommunityDeclaredDoc',
}

export enum DocumentCertIdpDataFieldName {
  CERT_IDP = 'cert_idp',
  numberVPO = 'numberVPO',
  dateIssueVPO = 'dateIssueVPO',
  VPODeclaredDoc = 'VPODeclaredDoc',
}

export enum DocumentLegalResidenceDataFieldName {
  LEGAL_RESIDENCE = 'legal_residence',
  numberExtract = 'numberExtract',
  authDepExtract = 'authDepExtract',
  addressDoc = 'addressDoc',
  ExtractDeclaredDoc = 'ExtractDeclaredDoc',
  dateExtract = 'dateExtract',
  dateExpired = 'dateExpired',
}

export enum PersonStatusFieldName {
  personAddDeclaredDoc = 'personAddDeclaredDoc',
  personStatusDocType = 'personStatusDocType',
  personStatusName = 'personStatusName',
  personStatusOrg = 'personStatusOrg',
  personStatusDocSerialNumber = 'personStatusDocSerialNumber',
  personStatusDocIssuingAuthority = 'personStatusDocIssuingAuthority',
  personStatusDocIssueDate = 'personStatusDocIssueDate',
  personStatusDocExpireDate = 'personStatusDocExpireDate',
  personStatusDocument = 'personStatusDocument',
}

export enum EmploymentDataFieldName {
  confirmEmploymentDocType = 'confirmEmploymentDocType',
  confirmEmploymentDocSerialNumber = 'confirmEmploymentDocSerialNumber',
  confirmEmploymentDocIssuingAuthority = 'confirmEmploymentDocIssuingAuthority',
  confirmEmploymentDocIssueDate = 'confirmEmploymentDocIssueDate',
  confirmEmploymentDocExpireDate = 'confirmEmploymentDocExpireDate',
  confirmEmploymentDocument = 'confirmEmploymentDocument',
}

export enum LearningDataFeildName {
  confirmLearningDoc = 'confirmLearningDoc',
  confirmLearningDocType = 'confirmLearningDocType',
  confirmLearningDocSerialNumber = 'confirmLearningDocSerialNumber',
  confirmLearningDocIssueDate = 'confirmLearningDocIssueDate',
  confirmLearningDocument = 'confirmLearningDocument',
}

export enum PaymentDataFieldName {
  paymentType = 'paymentType',
  paymentDocType = 'paymentDocType',
  paymentDocument = 'paymentDocument',
}

export enum AdditionalDocumentFieldName {
  additionalDoc = 'additionalDoc',
  additionalDocType = 'additionalDocType',
  additionalDocuments = 'additionalDocuments',
}

export enum HealthDataFieldName {
  healthDataDoc = 'healthDataDoc',
  healthDataDocType = 'healthDataDocType',
  healthDataDocSerialNumber = 'healthDataDocSerialNumber',
  healthDataDocIssuingAuthority = 'healthDataDocIssuingAuthority',
  healthDataDocIssueDate = 'healthDataDocIssueDate',
  healthDataDocExpireDate = 'healthDataDocExpireDate',
  healthDataDocument = 'healthDataDocument',
}

export enum InsuranceDataFieldName {
  confirmInsuranceDoc = 'confirmInsuranceDoc',
  confirmInsuranceDocType = 'confirmInsuranceDocType',
  confirmInsuranceDocSerialNumber = 'confirmInsuranceDocSerialNumber',
  confirmInsuranceDocIssueDate = 'confirmInsuranceDocIssueDate',
  confirmInsuranceDocExpireDate = 'confirmInsuranceDocExpireDate',
  confirmInsuranceDocument = 'confirmInsuranceDocument',
}

export enum TechnicalConditionDataFieldName {
  confirmTechnicalConditionDoc = 'confirmTechnicalConditionDoc',
  confirmTechnicalConditionDocType = 'confirmTechnicalConditionDocType',
  confirmTechnicalConditionDocSerialNumber = 'confirmTechnicalConditionDocSerialNumber',
  confirmTechnicalConditionDocIssuingAuthority = 'confirmTechnicalConditionDocIssuingAuthority',
  confirmTechnicalConditionDocIssueDate = 'confirmTechnicalConditionDocIssueDate',
  confirmTechnicalConditionDocument = 'confirmTechnicalConditionDocument',
}

export const attachmentMapping: {
  [key: string]: {
    info: {
      [key: string]: string;
    };
    encoded_contents?: string | unknown;
  };
} = {
  [DocumentRegisteredDocDataFieldName.registeredDocType]: {
    info: {
      serialNumberDoc: DocumentRegisteredDocDataFieldName.registeredLocalCommunityNumber,
      auth_dep: DocumentRegisteredDocDataFieldName.registeredLocalCommunityAuthDep,
      date: DocumentRegisteredDocDataFieldName.registeredLocalCommunityDate,
    },
    encoded_contents: DocumentRegisteredDocDataFieldName.registeredDocumentLocalCommunityDoc,
  },
  [DocumentLeaseAgreementDataFieldName.LEASE_AGREEMENT]: {
    info: {
      serialNumberDoc: DocumentLeaseAgreementDataFieldName.contractNumberRent,
      indexNumber: DocumentLeaseAgreementDataFieldName.indexNumberRent,
      date: DocumentLeaseAgreementDataFieldName.dateSignRent,
      expirationDate: DocumentLeaseAgreementDataFieldName.expirationDateRent,
    },
    encoded_contents: DocumentLeaseAgreementDataFieldName.declaredDocRent,
  },
  [DocumentOwnershipCertificateDataFieldName.OWNERSHIP_CERTIFICATE]: {
    info: {
      serialNumberDoc: DocumentOwnershipCertificateDataFieldName.serialNumberDoc,
      indexNumber: DocumentOwnershipCertificateDataFieldName.indexNumber,
      date: DocumentOwnershipCertificateDataFieldName.certificateDate,
      auth_dep: DocumentOwnershipCertificateDataFieldName.authDep,
    },
    encoded_contents: DocumentOwnershipCertificateDataFieldName.PropertyDeclaredDoc,
  },
  [DocumentExractPrprDataFieldName.EXRACT_PRPR]: {
    info: {
      serialNumberDoc: DocumentExractPrprDataFieldName.ownershipRegNumber,
      indexNumber: DocumentExractPrprDataFieldName.ownershipindexNumber,
      date: DocumentExractPrprDataFieldName.ownershipDate,
      auth_dep: DocumentExractPrprDataFieldName.ownershipFormedBy,
    },
    encoded_contents: DocumentExractPrprDataFieldName.OwnershipDeclaredDoc,
  },
  [DocumentCertMarriageDataFieldName.CERT_MARRIAGE]: {
    info: {
      serialNumberDoc: DocumentCertMarriageDataFieldName.marriageSeriaNumberDoc,
      date: DocumentCertMarriageDataFieldName.marriageDate,
      auth_dep: DocumentCertMarriageDataFieldName.marriageAuthDep,
    },
    encoded_contents: DocumentCertMarriageDataFieldName.marriageDeclaredDoc,
  },
  [DocumentVityagTeritorialnoyiGromadiDataFieldName.VITYAG_TERITORIALNOYI_GROMADI]: {
    info: {
      serialNumberDoc: DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityNumber,
      date: DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityDate,
      auth_dep: DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityAuthDep,
    },
    encoded_contents: DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityDeclaredDoc,
  },
  [DocumentCertIdpDataFieldName.CERT_IDP]: {
    info: {
      serialNumberDoc: DocumentCertIdpDataFieldName.numberVPO,
      date: DocumentCertIdpDataFieldName.dateIssueVPO,
    },
    encoded_contents: DocumentCertIdpDataFieldName.VPODeclaredDoc,
  },
  [DocumentLegalResidenceDataFieldName.LEGAL_RESIDENCE]: {
    info: {
      serialNumberDoc: DocumentLegalResidenceDataFieldName.numberExtract,
      date: DocumentLegalResidenceDataFieldName.dateExtract,
      expirationDate: DocumentLegalResidenceDataFieldName.dateExpired,
      auth_dep: DocumentLegalResidenceDataFieldName.authDepExtract,
    },
    encoded_contents: DocumentLegalResidenceDataFieldName.ExtractDeclaredDoc,
  },
  [TechnicalConditionDataFieldName.confirmTechnicalConditionDocType]: {
    info: {
      serialNumberDoc: TechnicalConditionDataFieldName.confirmTechnicalConditionDocSerialNumber,
      auth_dep: TechnicalConditionDataFieldName.confirmTechnicalConditionDocIssuingAuthority,
      date: TechnicalConditionDataFieldName.confirmTechnicalConditionDocIssueDate,
    },
    encoded_contents: TechnicalConditionDataFieldName.confirmTechnicalConditionDocument,
  },
  [PersonStatusFieldName.personStatusDocType]: {
    info: {
      org: PersonStatusFieldName.personStatusOrg,
      serialNumberDoc: PersonStatusFieldName.personStatusDocSerialNumber,
      auth_dep: PersonStatusFieldName.personStatusDocIssuingAuthority,
      date: PersonStatusFieldName.personStatusDocIssueDate,
    },
    encoded_contents: PersonStatusFieldName.personStatusDocument,
  },
  [LearningDataFeildName.confirmLearningDocType]: {
    info: {
      serialNumberDoc: LearningDataFeildName.confirmLearningDocSerialNumber,
      date: LearningDataFeildName.confirmLearningDocIssueDate,
    },
    encoded_contents: LearningDataFeildName.confirmLearningDocument,
  },
  [InsuranceDataFieldName.confirmInsuranceDocType]: {
    info: {
      serialNumberDoc: InsuranceDataFieldName.confirmInsuranceDocSerialNumber,
      date: InsuranceDataFieldName.confirmInsuranceDocIssueDate,
      expirationDate: InsuranceDataFieldName.confirmInsuranceDocExpireDate,
    },
    encoded_contents: InsuranceDataFieldName.confirmInsuranceDocument,
  },
  [HealthDataFieldName.healthDataDocType]: {
    info: {
      serialNumberDoc: HealthDataFieldName.healthDataDocSerialNumber,
      date: HealthDataFieldName.healthDataDocIssueDate,
      expirationDate: HealthDataFieldName.healthDataDocExpireDate,
      auth_dep: HealthDataFieldName.healthDataDocIssuingAuthority,
    },
    encoded_contents: HealthDataFieldName.healthDataDocument,
  },
  [EmploymentDataFieldName.confirmEmploymentDocType]: {
    info: {
      serialNumberDoc: EmploymentDataFieldName.confirmEmploymentDocSerialNumber,
      date: EmploymentDataFieldName.confirmEmploymentDocIssueDate,
      auth_dep: EmploymentDataFieldName.confirmEmploymentDocIssuingAuthority,
      expirationDate: EmploymentDataFieldName.confirmEmploymentDocExpireDate,
    },
    encoded_contents: EmploymentDataFieldName.confirmEmploymentDocument,
  },
  [PaymentDataFieldName.paymentDocType]: {
    info: {},
    encoded_contents: PaymentDataFieldName.paymentDocument,
  },
  [AdditionalDocumentFieldName.additionalDocType]: {
    info: {},
    encoded_contents: AdditionalDocumentFieldName.additionalDocuments,
  },
  personPassportDocType: {
    info: {},
    encoded_contents: 'personPassportDocuments',
  },
  rnokppDocType: {
    info: {},
    encoded_contents: 'rnokppDoc',
  },
  photoCardDocType: {
    info: {},
    encoded_contents: 'photoCard',
  },
  missingPersonPhotoDocType: {
    info: {},
    encoded_contents: 'missingPersonPhoto',
  },
  disappearanceMissingPersonDocType: {
    info: {},
    encoded_contents: 'disappearanceMissingPersonDocument',
  },
  extractErdrDocType: {
    info: {},
    encoded_contents: 'extractErdrDocument',
  },
  ancestralConnectionDocType: {
    info: {},
    encoded_contents: 'ancestralConnectionDocument',
  },
  leftPhotoDocType: {
    info: {},
    encoded_contents: 'weaponLeftPhoto',
  },
  rightPhotoDocType: {
    info: {},
    encoded_contents: 'weaponRightPhoto',
  },
  expertResearchDocType: {
    info: {
      auth_dep: 'expertOrganization',
      date: 'examinationDate',
    },
    encoded_contents: 'expertResearch',
  },
};

export const genders: SelectOption[] = [
  { id: 'U', label: 'Не визначена' },
  { id: 'M', label: 'Чоловіча' },
  { id: 'F', label: 'Жіноча' },
];

export const vehicleTypes: SelectOption[] = [
  { id: '1', label: 'Автомобільний транспорт' },
  { id: '2', label: 'Залізничний транспорт' },
  { id: '3', label: 'Авіаційний транспорт' },
];

export const allowedForSelectionDocumentTypesForDeclaredPlaceOfResidence = [
  DocumentLeaseAgreementDataFieldName.LEASE_AGREEMENT,
  DocumentOwnershipCertificateDataFieldName.OWNERSHIP_CERTIFICATE,
  DocumentVityagTeritorialnoyiGromadiDataFieldName.VITYAG_TERITORIALNOYI_GROMADI,
  DocumentLegalResidenceDataFieldName.LEGAL_RESIDENCE,
  DocumentCertIdpDataFieldName.CERT_IDP,
  DocumentCertMarriageDataFieldName.CERT_MARRIAGE,
  DocumentExractPrprDataFieldName.EXRACT_PRPR,
];
