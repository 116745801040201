import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { InvoiceStatus, ServiceCodes } from '../../Api/types';
import { ConfirmationOfApplicationFieldName } from './ConfirmationOfApplication';
import { ServiceNames, StepsNavigationName } from '../../../config/services';
import { ROUTES } from '../../../router/config';

interface Props {
  formData?: {
    [ConfirmationOfApplicationFieldName.orgNpu]?: string;
  };
  serviceCode: keyof typeof ServiceNames;
  invoiceStatus?: InvoiceStatus | null;
  willBePayedLater?: boolean;
}

const iconByStatus: Record<InvoiceStatus, string> = {
  [InvoiceStatus.SUCCESS]: 'approved_circle_filled_blue.svg',
  [InvoiceStatus.IN_PROGRESS]: 'watch_grey.svg',
  [InvoiceStatus.FAILED]: 'criss-cross_circle_filled_red.svg',
};

export const Result: React.FC<Props> = ({ formData, serviceCode, invoiceStatus, willBePayedLater = false }) => {
  const orgNpu = useMemo<string>(
    () => JSON.parse(formData?.[ConfirmationOfApplicationFieldName.orgNpu] || null)?.label.toLowerCase(),
    [formData],
  );
  const iconFileName = useMemo(() => {
    switch (true) {
      case willBePayedLater:
        return iconByStatus[InvoiceStatus.IN_PROGRESS];

      case !!invoiceStatus:
        return iconByStatus[invoiceStatus];

      default:
        return iconByStatus[InvoiceStatus.SUCCESS];
    }
  }, [invoiceStatus, willBePayedLater]);

  const title = useMemo(() => {
    switch (true) {
      case invoiceStatus === InvoiceStatus.SUCCESS:
        return 'Вашу заяву успішно оплачено та відправлено';

      case invoiceStatus === InvoiceStatus.IN_PROGRESS:
      case willBePayedLater:
        return 'Ваша заява очікує на оплату';

      case invoiceStatus === InvoiceStatus.FAILED:
        return 'Ваша заява не оплачена';

      case serviceCode === ServiceCodes.MISSING_PERSON:
        return 'Вашу анкету успішно відправлено';

      default:
        return 'Вашу заяву успішно відправлено';
    }
  }, [invoiceStatus, willBePayedLater, serviceCode]);

  // TODO: https://jira.infotech.guru/browse/MVSUSLUH-1313
  const message = useMemo<string>(() => {
    switch (true) {
      case invoiceStatus === InvoiceStatus.IN_PROGRESS:
        return 'Заяву буде відправлено для розгляду одразу після здійснення оплати.';

      case invoiceStatus === InvoiceStatus.FAILED:
      case willBePayedLater:
        return 'Заяву буде відправлено для розгляду одразу після здійснення оплати або додавання платіжного документу.';

      case invoiceStatus === InvoiceStatus.SUCCESS:
      default: {
        switch (serviceCode) {
          case ServiceCodes.COLD_STEEL_STORAGE_FOR_COLLECTION:
            return 'Вашу заяву на отримання холодної колекційної зброї відправлено на розгляд';
          case ServiceCodes.COLD_STEEL_TRANSPORTATION:
            return 'Вашу заяву про надання дозволу на перевезення територією України холодної колекційної зброї відправлено на розгляд';
          case ServiceCodes.COMMISSION_SALE:
            return 'Вашу заяву на комісійний продаж відправлено на розгляд';
          case ServiceCodes.VALIDITY_PERIOD_PROLONGATION:
            return 'Вашу заяву про продовження строку дії дозволу на придбання, перевезення, зберігання, носіння зброї відправлено на розгляд';
          case ServiceCodes.RESIDENCE_RELOCATION:
            return 'Вашу заяву про зміну місця проживання відправлено на розгляд';
          case ServiceCodes.TEMPORARY_RESIDENCE_RELOCATION:
            return 'Вашу заяву про тимчасову зміну місця проживання відправлено на розгляд';
          case ServiceCodes.CANCELLATION_TEMPORARY_RESIDENCE_RELOCATION:
            return 'Вашу заяву про дострокове завершення терміну дії дозволу на тимчасову зміну місця проживання відправлено для розгляду';
          case ServiceCodes.PURCHASE:
            return 'Вашу заяву про придбання вогнепальної мисливської зброї, основних частин зброї, пневматичної, холодної, охолощеної зброї, пристроїв відправлено на розгляд';
          case ServiceCodes.STORAGE_CARRYING:
            return 'Вашу заяву про надання дозволу на зберігання, носіння зброї, крім нагородної відправлено на розгляд';
          case ServiceCodes.TRANSPORT_ACROSS_BORDER:
            return 'Вашу заяву про надання дозволу на перевезення (вивезення-ввезення) через митний кордон України відправлено на розгляд';
          case ServiceCodes.MISSING_PERSON:
            return 'Вашу анкету про особу, зниклу безвісти за особливих обставин було відправлено до Уповноваженого з питань осіб, зниклих безвісти за особливих обставин.';
          case ServiceCodes.CRIMINAL_RECORD_EXTRACT:
            return 'Ваш запит перебуває в обробці';
          default:
            break;
        }
      }
    }
  }, [serviceCode, invoiceStatus, willBePayedLater]);

  const helperText = useMemo(() => {
    const userRequestsLink = (
      <Link to={ROUTES.USER.REQUESTS.ACTIVE_REQUESTS} rel="noopener noreferrer">
        &quot;Мої запити&quot;
      </Link>
    );
    let text = '';

    switch (true) {
      case invoiceStatus === InvoiceStatus.IN_PROGRESS:
        text = 'Перевірити статус заяви ви можете в меню';
        break;

      case invoiceStatus === InvoiceStatus.FAILED:
      case willBePayedLater:
        text = 'Оплатити послугу ви можете у заяві в меню';
        break;

      case invoiceStatus === InvoiceStatus.SUCCESS:
        text = 'Слідкувати за статусом заяви та завантажити квитанцію про оплату ви можете в меню';
        break;

      case serviceCode === ServiceCodes.MISSING_PERSON:
        text = 'Переглянути статус відправки анкети ви можете в меню';
        break;

      default:
        text = 'Слідкувати за статусом заяви ви можете в меню';
        break;
    }

    return (
      <>
        {text} {userRequestsLink}.
      </>
    );
  }, [invoiceStatus, willBePayedLater, serviceCode]);

  return (
    <>
      <div
        id="step-description"
        className="block-title block-title_lg block-title_padding-bottom-md-extra block-title_bg-light"
      >
        {StepsNavigationName.result}
      </div>

      <div className="form__content form__content_height-sm">
        <div className="result-page-icon">
          <img src={'/images/' + iconFileName} alt={invoiceStatus + ' icon'} />
        </div>

        <h2>{title}</h2>

        <p>
          {message}
          {orgNpu && <span data-review-value="orgNpu">до “{orgNpu}”.</span>}
        </p>

        <p>{helperText}</p>
      </div>
    </>
  );
};
