import { ServiceCodes } from '../../../components/Api/types';
import { decorateRMPRoute } from '../helpers/decorateRMPRoute';

export const RMP_ROUTES = {
  SERVICES: {
    MISSING_PERSON: '/services/missing-person',
  },
} as const;

export const RMP_ROUTES_BY_SERVICE_CODE = {
  [ServiceCodes.MISSING_PERSON]: decorateRMPRoute(RMP_ROUTES.SERVICES.MISSING_PERSON),
} as const;
