import React, { useMemo } from 'react';

import { DictionaryPermitType, ServiceCodes } from '../../../../../../components/Api/types';
import { parseFormDataValue } from '../../../../../../helpers/parseFormDataValue';
import { DeclaredDocumentsFormDataProps } from '../../../../../../components/Documents/DeclaredDocumentsCreate';
import { HealthFormDataProps, previewHealthData } from '../../../../../../components/Forms/Steps/HealthData';
import {
  TechnicalConditionFormDataProps,
  previewTechnicalCondition,
} from '../../../../../../components/Forms/Steps/TechnicalConditionData';
import {
  AdditionalDocumentFormDataProps,
  previewAdditionalDocument,
} from '../../../../../../components/Forms/Steps/AdditionalDocument';
import {
  PlaceOfResidenceFormDataProps,
  previewPlaceOfResidence,
} from '../../../../../../components/Forms/Steps/PlaceOfResidence';
import { PersonStatusFormDataProps, previewPersonStatus } from './Step5';
import { PermitDataFieldName, PermitFormDataProps, previewPermitData } from './Step2';
import {
  PersonalAndPassportFormDataProps,
  previewPersonalAndPassportData,
} from '../../../../../../components/Forms/Steps/PersonalAndPassportData';
import {
  ConfirmationOfApplication,
  ConfirmationOfApplicationFormDataProps,
} from '../../../../../../components/Forms/Steps/ConfirmationOfApplication';

interface Props {
  formData: PlaceOfResidenceFormDataProps &
    DeclaredDocumentsFormDataProps &
    AdditionalDocumentFormDataProps &
    TechnicalConditionFormDataProps &
    PersonStatusFormDataProps &
    HealthFormDataProps &
    PermitFormDataProps &
    PersonalAndPassportFormDataProps &
    ConfirmationOfApplicationFormDataProps;
  handleInputChange: (name: string, value: unknown) => void;
  permitTypesList: DictionaryPermitType[];
}

const permitCode = [ServiceCodes.PURCHASE, ServiceCodes.COLD_STEEL_TRANSPORTATION];

const Step10: React.FC<Props> = ({ formData, handleInputChange, permitTypesList }) => {
  const hiddenTechnicalCondition = useMemo(
    () => permitCode.toString().includes(parseFormDataValue(formData[PermitDataFieldName.permitType])?.permitType),
    [formData],
  );
  const permitTypeLabel = useMemo(
    () =>
      permitTypesList.find(
        (permitType) => permitType.label === parseFormDataValue(formData[PermitDataFieldName.permitType])?.permitType,
      ).name,
    [permitTypesList, formData],
  );

  return (
    <ConfirmationOfApplication
      formData={formData}
      handleInputChange={handleInputChange}
      accordionData={[
        previewPermitData({ formData, permitTypeLabel }),
        previewPersonalAndPassportData({ formData }),
        previewPlaceOfResidence({ formData }),
        previewPersonStatus({ formData }),
        previewHealthData({ formData }),
        previewTechnicalCondition({ formData, hiddenTechnicalCondition }),
        previewAdditionalDocument({ formData }),
      ]}
    />
  );
};

export default Step10;
