import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import './Button.scss';

const handleOnClick = (disabled: boolean, cb: React.DOMAttributes<HTMLButtonElement>['onClick']) => {
  return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();

    // Chrome version < 116 does not handle disabled attribute for bubbling
    if (event.currentTarget.disabled) return;
    // handle when in devTools user removes attribute "disabled"
    if (disabled) return;

    cb(event);
  };
};

type ButtonProps = Readonly<
  ButtonHTMLAttributes<HTMLButtonElement> &
    React.PropsWithChildren & {
      className?: string;
      outline?: boolean;
      withIcon?: boolean;
      withoutBorder?: boolean;
      size?: 'sm' | 'md' | 'lg' | 'fit';
      bgColor?: 'blue' | 'error' | 'accent';
    }
>;

export const Button: React.FC<ButtonProps> = ({
  onClick,
  className,
  type = 'button',
  outline = false,
  withIcon = false,
  withoutBorder = false,
  size = 'lg',
  bgColor = 'blue',
  children,
  disabled = false,
  ...rest
}) => {
  return (
    <button
      onClick={handleOnClick(disabled, onClick)}
      className={classNames(
        'custom-btn',
        {
          'custom-btn__outline': outline,
          [`custom-btn__${size}`]: size,
          [`custom-btn__${bgColor}`]: bgColor,
          [`custom-btn__without-border`]: withoutBorder,
          'custom-btn__with-icon': withIcon,
        },
        className,
      )}
      type={type}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};
