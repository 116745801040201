import dayjs from 'dayjs';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import TimePickerLocale from 'antd/es/time-picker/locale/uk_UA';
import CalendarLocale from 'antd/node_modules/rc-picker/es/locale/uk_UA';

import { ServiceCodes } from '../../../../../components/Api/types';
import { ServiceNames } from '../../../../../config/services';

export const currentServiceCode = ServiceCodes.CRIMINAL_RECORD_SEARCH_ALL;
export const serviceName = ServiceNames[currentServiceCode];
export const todaysDate = dayjs();

export const uaLocale: PickerLocale = {
  lang: {
    placeholder: 'Оберіть дату',
    yearPlaceholder: 'Оберіть рік',
    quarterPlaceholder: 'Оберіть квартал',
    monthPlaceholder: 'Оберіть місяць',
    weekPlaceholder: 'Оберіть тиждень',
    rangePlaceholder: ['Початкова дата', 'Кінцева дата'],
    rangeYearPlaceholder: ['Початковий рік', 'Кінцевий рік'],
    rangeMonthPlaceholder: ['Початковий місяць', 'Кінцевий місяць'],
    rangeWeekPlaceholder: ['Початковий тиждень', 'Кінцевий тиждень'],
    shortWeekDays: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    shortMonths: ['Січ', 'Лют', 'Бер', 'Кві', 'Тра', 'Чер', 'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Гру'],
    ...CalendarLocale,
  },
  timePickerLocale: {
    ...TimePickerLocale,
  },
};
