import React from 'react';

import { ERZServiceLockedReason } from '../../Api/types';
import { Reason } from '../../../contexts/ServiceAvailability/ServiceAvailabilityContext';

export const ReasonedMsg: React.FC<{ reason: ERZServiceLockedReason }> = ({ reason }) => (
  <>
    {reason === ERZServiceLockedReason.NO_LINK ? (
      <CommonMsg />
    ) : (
      <>
        <p>На жаль, послуга не доступна.</p>
        {reason === ERZServiceLockedReason.TIMEOUT_PERMIT && (
          <div>
            У Вас в наявності є зброя без дозволу на зберігання, носіння вогнепальної мисливської зброї, основних частин
            зброї, пневматичної, холодної, охолощеної зброї, пристроїв. Вам необхідно звернутися до відповідного органу
            НПУ за місцем зберігання Вашої зброї.
          </div>
        )}
        {reason === ERZServiceLockedReason.EXPIRED_PERMIT_TEMP_RELOCATION && (
          <div>
            Вами протермінований строк подання заяви, необхідної для повернення з тимчасової зміни місця зберігання
            зброї, основних частин зброї, пристроїв.
          </div>
        )}
        {reason === ERZServiceLockedReason.EXPIRED_PERMIT_STORAGE_AND_STORE && (
          <div>
            У Вас є в наявності протермінований дозвіл на зберігання, носіння вогнепальної мисливської зброї, основних
            частин зброї, пневматичної, холодної, охолощеної зброї, пристроїв. Вам необхідно звернутися до відповідного
            органу НПУ за місцем зберігання Вашої зброї.
          </div>
        )}
      </>
    )}
  </>
);

export const CommonMsg: React.FC = () => (
  <>
    <p>На жаль, в реєстрах недостатньо інформації для оформлення онлайн послуги.</p>
    <div>
      <p>Для отримання послуги Ви можете звернутися:</p>
      <ul>
        <li>особисто або уповноваженою особою (представником) до органу (підрозділу) поліції.</li>
        <li>
          особисто через суб’єкта господарювання, який має ліцензію на провадження господарської діяльності з торгівлі
          вогнепальною зброєю невійськового призначення та боєприпасами до неї, холодною зброєю, пневматичною зброєю
          калібру понад 4,5 міліметра і швидкістю польоту кулі понад 100 метрів на секунду, незалежно від місця
          проживання.
        </li>
      </ul>
    </div>
  </>
);

export const TRRMsg: React.FC<{ reason: Reason.DUPLICATED_APPLICATION | null }> = ({ reason }) =>
  reason ? (
    <>
      <p>На жаль, послуга не доступна.</p>
      <div>
        Ви вже подали заяву на тимчасову зміну місця зберігання зброї, основних частин зброї, пристроїв. Вам необхідно
        дочекатися опрацювання заяви працівниками органу Національної поліції України.
      </div>
    </>
  ) : (
    <>
      <p>Зверніть увагу!</p>
      <div>
        <p>Ви вже маєте дозвіл на тимчасову зміну місця зберігання зброї, основних частин зброї, пристроїв.</p>
        <p>Скасуйте його для отримання нового дозволу.</p>
      </div>
    </>
  );

export const TRRCancelationMsg: React.FC<{ reason: Reason.DUPLICATED_APPLICATION | null }> = ({ reason }) =>
  reason ? (
    <>
      <p>На жаль, послуга не доступна.</p>
      <div>
        Ви вже подали заяву на повернення після тимчасової зміни місця зберігання зброї, основних частин зброї,
        пристроїв. Вам необхідно дочекатися опрацювання заяви працівниками органу Національної поліції України.
      </div>
    </>
  ) : (
    <>
      <p>Зверніть увагу!</p>
      <p>Ви не маєте дозволу на тимчасову зміну місця зберігання зброї, основних частин зброї, пристроїв.</p>
    </>
  );
