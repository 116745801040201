import { Route, Routes } from 'react-router-dom';
import { lazy, useContext } from 'react';

import { SuspenseWithLoader } from '../../../components/SuspenseWithLoader';
import { PrivateRouteWrapper } from '../../../components/PrivateRouteWrapper';
import AuthContext from '../../../contexts/Auth/AuthContext';
import { RMP_ROUTES } from './config';
import ErrorPage from '../../../pages/ErrorPage/ErrorPage';

const MissingPerson = lazy(() => import('../pages/services/MissingPerson'));

export const RMPRouter = (): JSX.Element => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/services" element={<PrivateRouteWrapper isLoggedIn={isLoggedIn} />}>
        <Route
          path={RMP_ROUTES.SERVICES.MISSING_PERSON}
          element={
            <SuspenseWithLoader>
              <MissingPerson />
            </SuspenseWithLoader>
          }
        />
      </Route>

      <Route path="*" element={<ErrorPage errorType="404" />} />
    </Routes>
  );
};
