import { StatusColor } from '../../../components/Status';
import { IACUserRequestChannel, IACUserRequestStatus } from '../types';
import { IntegrationSystem, UserRequestStatus } from '../../../components/Api/types';
import { generateMappedStatuses } from '../../../helpers/generateMappedStatuses';

export const IACUserRequestChannels: Record<IACUserRequestChannel, string> = {
  [IACUserRequestChannel.CIC]: 'Єдине вікно для громадян',
  [IACUserRequestChannel.DII]: 'Портал Дія',
  [IACUserRequestChannel.SCE]: 'Сервісний центр або ЦНАП',
  [IACUserRequestChannel.MVS]: 'Сайт МВС',
};

export const IACUserRequestStatuses = generateMappedStatuses(IntegrationSystem.IAC, [
  {
    status: IACUserRequestStatus.done,
    label: 'Готово',
    color: StatusColor.GREEN,
  },
  {
    status: IACUserRequestStatus.processing,
    label: 'В роботі',
    color: StatusColor.YELLOW,
  },
  {
    status: IACUserRequestStatus.refusal,
    label: 'Відмова',
    color: StatusColor.RED,
  },
]);

export const IAC_statuses = generateMappedStatuses(IntegrationSystem.IAC, [
  {
    status: UserRequestStatus.accepted,
    label: 'Документ в системі',
    color: StatusColor.BLUE,
  },
  {
    status: UserRequestStatus.permit,
    label: 'Готово',
    color: StatusColor.GREEN,
  },
  {
    status: UserRequestStatus.ready_to_send,
    label: 'Готується до відправки',
    color: StatusColor.GREEN,
  },
  {
    status: UserRequestStatus.registered,
    label: 'В обробці',
    color: StatusColor.BLUE,
  },
  {
    status: UserRequestStatus.rejected,
    label: 'Відмовлено',
    color: StatusColor.RED,
  },
]);
