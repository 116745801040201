import { StepsNavigationName } from '../config/services';

export interface StepNavigation {
  readonly step: number;
  readonly name: StepsNavigationName;
  active: boolean;
  completed: boolean;
  hidden: boolean;
}

export const stepsNavigationDataFactory = (steps: StepsNavigationName[]): StepNavigation[] =>
  steps.map((stepName, index) => ({
    step: index + 1,
    name: stepName,
    active: index === 0,
    completed: false,
    hidden: false,
  }));
