import React from 'react';

import ServiceMenuItem from './ServiceMenuItem';
import Loader from '../../components/Loader';
import './ServiceMenuListMockup.scss';

interface Props {
  classType: string;
}

const ServiceMenuListMockup = ({ classType }: Props): JSX.Element => {
  const classes = `main-nav__content_active  ${classType}`;
  return (
    <div className={classes}>
      <div className="cards" style={{ position: 'relative' }}>
        <ServiceMenuItem>
          <Loader centered />
        </ServiceMenuItem>
        <ServiceMenuItem>
          <Loader centered />
        </ServiceMenuItem>
        <ServiceMenuItem>
          <Loader centered />
        </ServiceMenuItem>
        <ServiceMenuItem>
          <Loader centered />
        </ServiceMenuItem>
      </div>
    </div>
  );
};

export default ServiceMenuListMockup;
