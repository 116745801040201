import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import FileUpload from '../FileUpload';
import InitialSelect from '../Select';
import { DocumentTypeCode, IntegrationSystem } from '../../Api/types';
import { getERZDocumentsSelect } from '../../../modules/erz/helpers';
import { NullValueGuard } from '../../../guards/NullValueGuard';
import { PreviewFilesList } from '../PreviewFilesList';
import { AccordionData } from '../../Accordion/Accordion';
import { StepsNavigationName } from '../../../config/services';
import { AdditionalDocumentFieldName } from '../constants';
import { DocumentTypeEntity } from '../../../helpers/reformatDocumentTypeToEntity';
import deleteIcon from '../../../assets/images/delete-icon.svg';
import ModalContext from '../../../contexts/Modal/ModalContext';
import { ModalName } from '../../../contexts/Modal/modalTypes';
import { FormDocument } from '../DocumentsData';

export interface AdditionalDocumentFormDataProps {
  [AdditionalDocumentFieldName.additionalDoc]?: boolean;
  [AdditionalDocumentFieldName.additionalDocuments]?: FormDocument[];
}
interface Props {
  formData: AdditionalDocumentFormDataProps;
  handleInputChange: (name: string, value: unknown) => void;
  handleClearInput: (name: string) => void;
}
interface PreviewProps {
  formData: AdditionalDocumentFormDataProps;
}

const MAX_ADDITIONAL_DOCUEMNTS = 5;

export const AdditionalDocument: React.FC<Props> = React.memo(function AdditionalDocument({
  formData,
  handleInputChange,
  handleClearInput,
}) {
  const { renderSpecificModal } = useContext(ModalContext);
  const [optionsPersonDoc, setOptionsPersonDoc] = useState<Array<DocumentTypeEntity<IntegrationSystem.ERZ>>>([]);

  const additionalDoc = useMemo(() => formData.additionalDoc || false, [formData.additionalDoc]);
  const [additionalDocuments, setAdditionalDocuments] = useState<FormDocument[]>([]);

  const handleAddAdditionalDocument = useCallback((additionalDocument: FormDocument = null): void => {
    setAdditionalDocuments((previousInputs) => [
      ...previousInputs,
      {
        type: additionalDocument?.type || null,
        attachments: additionalDocument?.attachments || [],
      },
    ]);
  }, []);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    const booleanValue = value.toLowerCase() === 'true';

    if (!booleanValue) {
      setAdditionalDocuments([]);
      handleClearInput(AdditionalDocumentFieldName.additionalDocuments);
    } else {
      handleAddAdditionalDocument();
    }

    handleInputChange(name, booleanValue);
  };

  const checkForDuplicates = useCallback(
    (docType: FormDocument['type']): boolean => {
      const hasDuplicate = additionalDocuments.some(
        (additionalDocument) => additionalDocument.type?.code === docType.code,
      );

      hasDuplicate &&
        renderSpecificModal({
          modalName: ModalName.DocumentAddCheckAlert,
        });

      return hasDuplicate;
    },
    [additionalDocuments, renderSpecificModal],
  );

  useEffect(() => {
    const defaultValue = formData[AdditionalDocumentFieldName.additionalDocuments];

    if (additionalDoc) {
      if (defaultValue?.length) {
        defaultValue.forEach((documentData) => {
          handleAddAdditionalDocument(documentData);
        });
      } else {
        handleAddAdditionalDocument();
      }
    }
  }, []);

  useEffect(() => {
    handleInputChange(AdditionalDocumentFieldName.additionalDocuments, additionalDocuments);
  }, [additionalDocuments]);

  return (
    <>
      <div
        id="step-description"
        className="block-title block-title_lg block-title_padding-bottom-md-extra block-title_bg-light"
      >
        {StepsNavigationName.additionalDocument}
      </div>

      <div className="form__content">
        <div className="form__row">
          <div className="form-input form-input_margin-bottom-none">
            <div className="form-input__label form-input__label_margin-bottom-md">Ви хочете додати дані документа?</div>
            <fieldset id={AdditionalDocumentFieldName.additionalDoc} className="form-input__radiobuttons">
              <label className="radio" htmlFor={AdditionalDocumentFieldName.additionalDoc + '_yes'}>
                <input
                  className="radio__input"
                  type="radio"
                  id={AdditionalDocumentFieldName.additionalDoc + '_yes'}
                  name={AdditionalDocumentFieldName.additionalDoc}
                  value="true"
                  checked={additionalDoc}
                  onChange={handleRadioChange}
                />
                <span className="radio__checkmark"></span>
                <span className="radio__label">Так</span>
              </label>

              <label className="radio" htmlFor={AdditionalDocumentFieldName.additionalDoc + '_no'}>
                <input
                  className="radio__input"
                  type="radio"
                  id={AdditionalDocumentFieldName.additionalDoc + '_no'}
                  name={AdditionalDocumentFieldName.additionalDoc}
                  value="false"
                  checked={!additionalDoc}
                  onChange={handleRadioChange}
                />
                <span className="radio__checkmark"></span>
                <span className="radio__label">Ні</span>
              </label>
            </fieldset>
          </div>
        </div>

        {additionalDoc && (
          <>
            {additionalDocuments.map((additionalDocument, index) => {
              const docTypeFieldName = 'additionalDocument_docType_' + index;
              const fileFieldName = 'additionalDocument_file_' + index;

              return (
                <React.Fragment key={docTypeFieldName + fileFieldName}>
                  <div className="form-input form-input_margin-bottom-none required no-group">
                    <div className="form-input__label">Тип документа</div>
                    <div className="input-select js-input-select">
                      <InitialSelect
                        name={docTypeFieldName}
                        options={optionsPersonDoc}
                        placeholder="Не вибрано"
                        defaultValue={additionalDocument.type}
                        onChange={(newValue): void => {
                          const hasDuplicate = checkForDuplicates(newValue);

                          if (!hasDuplicate) {
                            handleInputChange(docTypeFieldName, 'testValue');

                            setAdditionalDocuments((previousInputs) => {
                              const newInputs = [...previousInputs];
                              newInputs[index] = {
                                ...newInputs[index],
                                type: newValue,
                              };

                              return newInputs;
                            });
                          }
                        }}
                        onMenuOpen={(): void => {
                          if (!optionsPersonDoc.length) {
                            getERZDocumentsSelect({
                              disallowedDocumentTypeKinds: ['A', 'B'],
                              setOptions: setOptionsPersonDoc,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-input form-input_margin-bottom-none required">
                    <div className="form-input__label">
                      {additionalDocument?.type?.code === DocumentTypeCode.PHOTO_CARD
                        ? 'Фото (3.5x4.5)'
                        : 'Додатковий документ'}
                    </div>
                    <FileUpload
                      key={additionalDocument?.type?.id}
                      inputName={fileFieldName}
                      inputTitle={
                        additionalDocument.type?.code === DocumentTypeCode.PHOTO_CARD
                          ? 'Додати фото'
                          : 'Додати сканкопію документа'
                      }
                      value={additionalDocument.attachments}
                      handleInputChange={(_, value): void => {
                        handleInputChange(fileFieldName, 'testValue');
                        setAdditionalDocuments((previousInputs) => {
                          const newInputs = [...previousInputs];
                          newInputs[index] = {
                            ...newInputs[index],
                            attachments: value,
                          };

                          return newInputs;
                        });
                      }}
                      handleClearInput={(): void => {
                        handleClearInput(fileFieldName);
                        setAdditionalDocuments((previousInputs) => {
                          const newInputs = [...previousInputs];
                          newInputs[index] = {
                            ...newInputs[index],
                            attachments: [],
                          };

                          return newInputs;
                        });
                      }}
                    />
                  </div>

                  {index > 0 && (
                    <div className="form-input form-input_margin-bottom-none">
                      <div className="form-button_remove-label">
                        <img src={deleteIcon} alt="delete" />
                        <input
                          className="form-button_remove"
                          type="button"
                          name="removeDoc"
                          value="Видалити документ"
                          onClick={(): void => {
                            setAdditionalDocuments((prevDocs) =>
                              prevDocs.filter((_, itemIndex) => itemIndex !== index),
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}

            {additionalDocuments.length < MAX_ADDITIONAL_DOCUEMNTS && (
              <div className="form__row">
                <div className="form-input form-input_margin-bottom-none">
                  <button
                    className="button button_border"
                    type="button"
                    onClick={(): void => handleAddAdditionalDocument()}
                  >
                    Додати ще один документ
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
});

export const previewAdditionalDocument = ({ formData }: PreviewProps): AccordionData => {
  return {
    title: StepsNavigationName.additionalDocument,
    content: (
      <>
        {formData[AdditionalDocumentFieldName.additionalDocuments]?.length ? (
          formData[AdditionalDocumentFieldName.additionalDocuments].map((additionalDocument) => {
            return (
              <div className="custom-columns custom-columns_grey-bg" key={additionalDocument.type.code}>
                <div className="custom-columns__row custom-columns__row_padding-tiny">
                  <div className="custom-columns__col">
                    <div className="custom-columns__title">Тип документа</div>
                    <div className="custom-columns__info">
                      <NullValueGuard>{additionalDocument.type.label}</NullValueGuard>
                    </div>
                  </div>

                  <div className="custom-columns__col">
                    <div className="custom-columns__title">Документ</div>
                    <div className="custom-columns__info">
                      <NullValueGuard>
                        <PreviewFilesList files={additionalDocument.attachments} />
                      </NullValueGuard>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="custom-columns custom-columns_grey-bg">
            <div className="custom-columns__row custom-columns__row_padding-tiny">
              <div className="custom-columns__col">
                <NullValueGuard>{null}</NullValueGuard>
              </div>
            </div>
          </div>
        )}
      </>
    ),
  };
};
