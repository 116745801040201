import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '../../router/config';

type PageInput = {
  nextPage: string;
};

const defaults: PageInput = {
  nextPage: ROUTES.USER.REQUESTS.ACTIVE_REQUESTS,
};

export const usePageAfterAuth = (input: PageInput = defaults): { pageToOpenAfterAuth: string } => {
  const { search, state } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const pageToOpenAfterAuth: string | null = useMemo(() => {
    return searchParams.get('next_page') || state?.nextPage || input?.nextPage;
  }, [input.nextPage, searchParams, state?.nextPage]);

  return {
    pageToOpenAfterAuth,
  };
};
