import React, { useCallback, useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

import { ValidateInputValueResult } from '../Validation';
import { removeErrorMarkFromInputField } from '../../../helpers/invalidFields';
import { Button } from '../../Button';

interface BaseProps {
  label: string;
  inputPlaceholder?: string;
  addInputButtonText: string;
  maxInputsQuantity?: number;
  defaultValues: string[];
  required?: boolean;
  validateUniqueness?: boolean;
  uniquenessErrorMessage?: string;
  formatValue?: (value: string) => string;
  onChange: (value: string[]) => void;
  validator?: (value: string) => ValidateInputValueResult;
}

interface PropsWithMask extends BaseProps {
  withMask: true;
  mask: string;
  inputMaskType: React.HTMLInputTypeAttribute;
}

interface PropsWithoutMask extends BaseProps {
  withMask?: false;
  mask?: never;
  inputMaskType?: never;
}

type Props = PropsWithMask | PropsWithoutMask;

export const MultipleInputField: React.FC<Props> = React.memo(function MultipleInputField({
  label,
  withMask = false,
  mask = '',
  inputMaskType = '',
  inputPlaceholder = 'Не вказано',
  addInputButtonText,
  maxInputsQuantity = 2,
  defaultValues = [],
  required = false,
  validateUniqueness = false,
  uniquenessErrorMessage = 'Дане значення вже введено',
  formatValue,
  onChange,
  validator: externalValidator,
}) {
  const [inputs, setInputs] = useState<Array<{ inputName: string; inputValue: string; validationError: string }>>([]);

  const internalValidator = useCallback(
    (value: string, otherValues: string[]): ValidateInputValueResult => {
      const isUniqueValue = validateUniqueness ? otherValues.every((otherValue) => otherValue !== value) : true;

      const isValid = isUniqueValue;
      const error = !isValid ? !isUniqueValue && uniquenessErrorMessage : '';

      return { isValid, error };
    },
    [validateUniqueness, uniquenessErrorMessage],
  );

  const handleValidateInput = useCallback(
    (inputIndex: number): void => {
      setInputs((previousInputs) => {
        removeErrorMarkFromInputField(previousInputs[inputIndex].inputName);

        const { inputValue: currentInputValue } = previousInputs[inputIndex];
        const otherInputsValues = previousInputs
          .filter((_, index) => index !== inputIndex)
          .map((previousInput) => previousInput.inputValue)
          .filter(Boolean);
        const shouldNotBeValidated = (!required && !currentInputValue) || !externalValidator;

        const { isValid, error } = shouldNotBeValidated
          ? internalValidator(currentInputValue, otherInputsValues)
          : ((): ValidateInputValueResult => {
              const externalValidationResult = externalValidator(currentInputValue);
              const internalValidationResult = internalValidator(currentInputValue, otherInputsValues);

              // eslint-disable-next-line @typescript-eslint/no-shadow
              const isValid = externalValidationResult.isValid && internalValidationResult.isValid;
              // eslint-disable-next-line @typescript-eslint/no-shadow
              const error = !isValid ? externalValidationResult.error || internalValidationResult.error : '';

              return { isValid, error };
            })();

        const newInputs = [...previousInputs];

        newInputs[inputIndex] = {
          ...newInputs[inputIndex],
          validationError: isValid ? '' : error,
        };

        return newInputs;
      });
    },
    [required, internalValidator, externalValidator],
  );

  const handleAddInput = useCallback((value = ''): void => {
    setInputs((previousInputs) => [
      ...previousInputs,
      {
        inputName: uniqueId('multipleInputField_'),
        inputValue: value,
        validationError: '',
      },
    ]);
  }, []);

  const handleDeleteInput = useCallback(
    (inputIndexToDelete: number): void => {
      setInputs((previousInputs) => {
        const newInputs = [...previousInputs];
        newInputs.splice(inputIndexToDelete, 1);

        return newInputs;
      });

      setInputs((inputsWithoutDeleted) => {
        inputsWithoutDeleted.forEach((_, index) => {
          handleValidateInput(index);
        });

        return inputsWithoutDeleted;
      });
    },
    [handleValidateInput],
  );

  const handleChangeInput = useCallback(
    (index: number, value: string) => {
      setInputs((previousInputs) => {
        const newInputs = [...previousInputs];
        newInputs[index] = {
          ...newInputs[index],
          inputValue: formatValue ? formatValue(value) : value,
        };

        return newInputs;
      });
    },
    [formatValue],
  );

  useEffect(() => {
    defaultValues.length ? defaultValues.forEach(handleAddInput) : handleAddInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleAddInput]);

  useEffect(() => {
    const newValues = inputs.map(({ inputValue }) => inputValue).filter(Boolean);

    onChange(newValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  return (
    <>
      {inputs.map(({ inputName, inputValue, validationError }, index) => {
        const isFirstInput = index === 0;

        return (
          <div
            key={inputName}
            className={classNames('form-input', 'form-input_margin-bottom-none', {
              'error-div': !!validationError,
              required: isFirstInput && required,
            })}
          >
            {isFirstInput && <div className="form-input__label">{label}</div>}

            <div className="form-input__row">
              {withMask ? (
                <InputMask
                  type={inputMaskType}
                  mask={mask}
                  placeholder={inputPlaceholder}
                  className="form-input__input form-control"
                  name={inputName}
                  value={inputValue}
                  onChange={(e): void => handleChangeInput(index, e.target.value)}
                  onBlur={(): void => handleValidateInput(index)}
                />
              ) : (
                <input
                  type="text"
                  placeholder={inputPlaceholder}
                  className="form-input__input form-control"
                  name={inputName}
                  value={inputValue}
                  onChange={(e): void => handleChangeInput(index, e.target.value)}
                  onBlur={(): void => handleValidateInput(index)}
                />
              )}
              {!isFirstInput && (
                <button className="close-button-default" type="button" onClick={(): void => handleDeleteInput(index)} />
              )}
            </div>

            {validationError && (
              <div key={inputName + 'error-msg'} className="error-msg">
                {validationError}
              </div>
            )}
          </div>
        );
      })}

      {inputs.length + 1 <= maxInputsQuantity && (
        <Button outline size="fit" type="button" onClick={(): void => handleAddInput()}>
          {addInputButtonText}
        </Button>
      )}
    </>
  );
});
