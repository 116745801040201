import React, { ReactNode } from 'react';

import MainTemplate from '../MainTemplate/MainTemplate';
import { VerticalNavTransform, NavigationItem } from '../../components/Layout/VerticalNavTransform';
import '../../containers/StyleSettings/themes/default/layout.scss';
import { ROUTES } from '../../router/config';
import { MSP_ROUTES } from '../../modules/msp/routes/config';
import { ERZ_ROUTES } from '../../modules/erz/routes/config';
import { decorateMSPRoute } from '../../modules/msp/helpers/decorateMSPRoute';
import { decorateERZRoute } from '../../modules/erz/helpers/decorateERZRoute';

type GenerateNavigationItemsKeysArg = Omit<NavigationItem, 'key' | 'children'> & {
  children?: GenerateNavigationItemsKeysArg[];
};

const generateNavigationItemsKeys = (
  navigationItems: GenerateNavigationItemsKeysArg[],
  keyCounter = 0,
): NavigationItem[] =>
  navigationItems.map<NavigationItem>((navigationItem) => {
    const key = String(keyCounter++);

    if (navigationItem.children) {
      const children = generateNavigationItemsKeys(navigationItem.children, keyCounter);

      keyCounter += navigationItem.children.length;

      return {
        ...navigationItem,
        key,
        children,
      };
    }

    return {
      ...navigationItem,
      key,
      children: null,
    };
  });

const CABINET_NAVIGATION: NavigationItem[] = generateNavigationItemsKeys([
  {
    label: 'Мої запити',
    slug: ROUTES.USER.REQUESTS.ACTIVE_REQUESTS,
  },
  {
    label: 'Профіль користувача',
    slug: ROUTES.USER.PROFILE,
  },
  {
    label: 'Соціальний захист',
    children: [
      {
        label: 'Потреби',
        slug: decorateMSPRoute(MSP_ROUTES.USER.SOCIAL_PROTECT_NEEDS),
      },
      {
        label: 'Супроводжуюча особа',
        slug: decorateMSPRoute(MSP_ROUTES.USER.SOCIAL_PROTECT_MENTOR),
      },
      {
        label: 'Проходження служби',
        slug: decorateMSPRoute(MSP_ROUTES.USER.SOCIAL_PROTECT_SERVICE),
      },
      {
        label: 'Лікування',
        slug: decorateMSPRoute(MSP_ROUTES.USER.SOCIAL_PROTECT_TREATMENT),
      },
      {
        label: 'Психологічний супровід',
        slug: decorateMSPRoute(MSP_ROUTES.USER.SOCIAL_PROTECT_SUPPORT),
      },
    ],
  },
  {
    label: 'Моя зброя',
    slug: decorateERZRoute(ERZ_ROUTES.USER.WEAPON_REGISTERED),
  },
]);

interface Props {
  children: ReactNode;
}

const CabinetTemplate = ({ children }: Props): JSX.Element => {
  return (
    <MainTemplate className="section-light">
      <section className="section-block-medium section-light">
        <div className="container container-with-aside">
          <aside className="container-with-aside__aside">
            <VerticalNavTransform navigationData={CABINET_NAVIGATION} />
          </aside>
          {children}
        </div>
      </section>
    </MainTemplate>
  );
};

export default CabinetTemplate;
