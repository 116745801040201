interface FactoryProps {
  fields: Array<Record<'localFieldName' | 'serverFieldName', string>>;
  groupName: string;
}

type FactoryReturnType = Record<string, Record<'name' | 'group', string>>;

export function fieldNamesForServerFactory({ fields, groupName }: FactoryProps): FactoryReturnType {
  return fields.reduce((accumulator, field) => {
    const { localFieldName, serverFieldName } = field;

    return {
      ...accumulator,
      [localFieldName]: {
        name: serverFieldName,
        group: groupName,
      },
    };
  }, {} as FactoryReturnType);
}
