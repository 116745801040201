import { ServiceCodes } from '../components/Api/types';
import { ROUTES, ROUTES_BY_SERVICE_CODE } from '../router/config';

export const headerTabs: string[] = [
  ROUTES.HOME,
  ROUTES.INTERNAL_SERVICES,
  ROUTES.USER.REQUESTS.ACTIVE_REQUESTS,
  ROUTES_BY_SERVICE_CODE[ServiceCodes.SOCIAL_PROTECT],
  ROUTES.EXTERNAL_SERVICES,
];
