import { DocumentType, IntegrationSystem } from '../components/Api/types';
import { SelectOption } from '../components/Forms/Select';

export interface DocumentTypeEntity<T extends IntegrationSystem = never> extends SelectOption {
  code: DocumentType<T>['code'] | 'null';
}

export function reformatDocumentTypeToEntity<T extends IntegrationSystem>(
  documentType: DocumentType<T>,
): DocumentTypeEntity<T> {
  return {
    id: documentType.id,
    label: documentType.name,
    code: documentType.code || 'null',
  };
}
