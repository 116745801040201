import React, { useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import idgovua from '../../assets/images/id-gov-ua-black.svg';
import AuthContext from '../../contexts/Auth/AuthContext';
import { SingInAfterHook } from './types';

type Props = SingInAfterHook & {
  expectedPath?: string;
};

export const IdGovProvider: React.FC<Props> = ({ handleSignIn, expectedPath }) => {
  const { loginWithTokens } = useContext(AuthContext);
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (!searchParams.has('access_token')) {
      return;
    }

    loginWithTokens({
      access_token: searchParams.get('access_token'),
      expires_in: Number(searchParams.get('expires_in')),
      refresh_token: searchParams.get('refresh_token'),
      token_type: 'Bearer',
    });

    handleSignIn();
  }, [loginWithTokens, handleSignIn, searchParams]);

  return (
    <a
      href={`${process.env.REACT_APP_IDGOV_REDIRECTURI}?${
        expectedPath
          ? new URLSearchParams({
              next_page: expectedPath,
            }).toString()
          : ''
      }`}
      className="idgov-auth"
    >
      <img className="" src={idgovua} alt="id gov ua" width="72" height="72" />
    </a>
  );
};
