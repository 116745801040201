import React, { useContext } from 'react';

import AuthContext from '../../contexts/Auth/AuthContext';
import { UserService } from '../Api/types';

type Props = {
  item: UserService;
};

const getStatus = (service: UserService, isLoggedIn: boolean): string | void => {
  if (service.withAuth && !isLoggedIn) return 'Необхідна авторизація';
};

export const LabelStatus: React.FC<Props> = ({ item }) => {
  const { isLoggedIn } = useContext(AuthContext);
  const status = getStatus(item, isLoggedIn);

  if (!status) return null;

  return <span className="label-status">{status}</span>;
};
