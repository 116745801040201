import React, { useContext, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import classNames from 'classnames';

import deleteIcon from '../../assets/images/delete-icon.svg';
import ModalContext from '../../contexts/Modal/ModalContext';
import { ModalName } from '../../contexts/Modal/modalTypes';
import {
  DocumentCertIdpDataFieldName,
  DocumentCertMarriageDataFieldName,
  DocumentExractPrprDataFieldName,
  DocumentLeaseAgreementDataFieldName,
  DocumentLegalResidenceDataFieldName,
  DocumentOwnershipCertificateDataFieldName,
  DocumentVityagTeritorialnoyiGromadiDataFieldName,
  FormConstants,
  allowedForSelectionDocumentTypesForDeclaredPlaceOfResidence,
} from '../Forms/constants';
import { useAsyncDataLoader } from '../../hooks/useAsyncDataLoader';
import { getERZDocumentTypeEntites } from '../../modules/erz/helpers';
import { DocumentTypeCode } from '../Api/types';
import { parseFormDataValue } from '../../helpers/parseFormDataValue';
import InitialSelect from '../Forms/Select';
import { getDateWithoutTime } from '../../helpers/dates';
import FileUpload from '../Forms/FileUpload';
import calendarIcon from '../../assets/images/calendar.svg';

export interface DocumentLeaseAgreementDataProps {
  [DocumentLeaseAgreementDataFieldName.LEASE_AGREEMENT]?: string;
  [DocumentLeaseAgreementDataFieldName.dateSignRent]?: string;
  [DocumentLeaseAgreementDataFieldName.expirationDateRent]?: string;
  [DocumentLeaseAgreementDataFieldName.contractNumberRent]?: string;
  [DocumentLeaseAgreementDataFieldName.indexNumberRent]?: string;
  [DocumentLeaseAgreementDataFieldName.declaredDocRent]?: File[];
}

export interface DocumentOwnershipCertificateDataProps {
  [DocumentOwnershipCertificateDataFieldName.OWNERSHIP_CERTIFICATE]?: string;
  [DocumentOwnershipCertificateDataFieldName.certificateDate]?: string;
  [DocumentOwnershipCertificateDataFieldName.serialNumberDoc]?: string;
  [DocumentOwnershipCertificateDataFieldName.indexNumber]?: string;
  [DocumentOwnershipCertificateDataFieldName.authDep]?: string;
  [DocumentOwnershipCertificateDataFieldName.PropertyDeclaredDoc]?: File[];
}

export interface DocumentExractPrprDataProps {
  [DocumentExractPrprDataFieldName.EXRACT_PRPR]?: string;
  [DocumentExractPrprDataFieldName.ownershipRegNumber]?: string;
  [DocumentExractPrprDataFieldName.ownershipindexNumber]?: string;
  [DocumentExractPrprDataFieldName.ownershipFormedBy]?: string;
  [DocumentExractPrprDataFieldName.ownershipDate]?: string;
  [DocumentExractPrprDataFieldName.OwnershipDeclaredDoc]?: File[];
}

export interface DocumentCertMarriageDataProps {
  [DocumentCertMarriageDataFieldName.CERT_MARRIAGE]?: string;
  [DocumentCertMarriageDataFieldName.marriageDate]?: string;
  [DocumentCertMarriageDataFieldName.marriageSeriaNumberDoc]?: string;
  [DocumentCertMarriageDataFieldName.marriageAuthDep]?: string;
  [DocumentCertMarriageDataFieldName.marriageDeclaredDoc]?: File[];
}

export interface DocumentLegalResidenceDataProps {
  [DocumentLegalResidenceDataFieldName.LEGAL_RESIDENCE]?: string;
  [DocumentLegalResidenceDataFieldName.dateExtract]?: string;
  [DocumentLegalResidenceDataFieldName.dateExpired]?: string;
  [DocumentLegalResidenceDataFieldName.numberExtract]?: string;
  [DocumentLegalResidenceDataFieldName.authDepExtract]?: string;
  [DocumentLegalResidenceDataFieldName.addressDoc]?: boolean;
  [DocumentLegalResidenceDataFieldName.ExtractDeclaredDoc]?: File[];
}

export interface DocumentVityagTeritorialnoyiGromadiDataProps {
  [DocumentVityagTeritorialnoyiGromadiDataFieldName.VITYAG_TERITORIALNOYI_GROMADI]?: string;
  [DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityDate]?: string;
  [DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityNumber]?: string;
  [DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityAuthDep]?: string;
  [DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityDeclaredDoc]?: File[];
}
export interface DocumentCertIdpDataFieldDataProps {
  [DocumentCertIdpDataFieldName.CERT_IDP]?: string;
  [DocumentCertIdpDataFieldName.numberVPO]?: string;
  [DocumentCertIdpDataFieldName.dateIssueVPO]?: string;
  [DocumentCertIdpDataFieldName.VPODeclaredDoc]?: File[];
}

export type DeclaredDocumentsFormDataProps = DocumentLeaseAgreementDataProps &
  DocumentOwnershipCertificateDataProps &
  DocumentExractPrprDataProps &
  DocumentCertMarriageDataProps &
  DocumentLegalResidenceDataProps &
  DocumentVityagTeritorialnoyiGromadiDataProps &
  DocumentCertIdpDataFieldDataProps;

interface Props {
  formData: DeclaredDocumentsFormDataProps;
  handleInputChange: (name: string, value: unknown) => void;
  handleClearInput: (name: string) => void;
  required?: boolean;
  showDocuments?: boolean;
}
interface DocumentType {
  id: string;
  label?: string;
  code?: string;
}

const todaysDate = getDateWithoutTime(new Date());

export const DeclaredDocumentsCreate: React.FC<Props> = ({
  formData,
  handleInputChange,
  handleClearInput,
  showDocuments = true,
  required = false,
}) => {
  const modalContext = useContext(ModalContext);

  const dateSignRent = useMemo(
    () => (formData.dateSignRent ? new Date(formData.dateSignRent) : null),
    [formData.dateSignRent],
  );

  const expirationDateRent = useMemo(
    () => (formData.expirationDateRent ? new Date(formData.expirationDateRent) : null),
    [formData.expirationDateRent],
  );

  const certificateDate = useMemo(
    () => (formData.certificateDate ? new Date(formData.certificateDate) : null),
    [formData.certificateDate],
  );

  const ownershipDate = useMemo(
    () => (formData.ownershipDate ? new Date(formData.ownershipDate) : null),
    [formData.ownershipDate],
  );

  const marriageDate = useMemo(
    () => (formData.marriageDate ? new Date(formData.marriageDate) : null),
    [formData.marriageDate],
  );

  const dateExpired = useMemo(
    () => (formData.dateExpired ? new Date(formData.dateExpired) : null),
    [formData.dateExpired],
  );

  const dateIssueVPO = useMemo(
    () => (formData.dateIssueVPO ? new Date(formData.dateIssueVPO) : null),
    [formData.dateIssueVPO],
  );

  const dateExtract = useMemo(
    () => (formData.dateExtract ? new Date(formData.dateExtract) : null),
    [formData.dateExtract],
  );

  const localCommunityDate = useMemo(
    () => (formData.localCommunityDate ? new Date(formData.localCommunityDate) : null),
    [formData.localCommunityDate],
  );

  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([{ id: null }]);

  const { data: documentTypeEntities = [], load } = useAsyncDataLoader(() =>
    getERZDocumentTypeEntites(
      allowedForSelectionDocumentTypesForDeclaredPlaceOfResidence as unknown as DocumentTypeCode[],
    ),
  );

  const handleRemoveDocument = (documentCode: string): void => {
    switch (documentCode) {
      case DocumentLeaseAgreementDataFieldName.LEASE_AGREEMENT:
        [
          DocumentLeaseAgreementDataFieldName.contractNumberRent,
          DocumentLeaseAgreementDataFieldName.indexNumberRent,
          DocumentLeaseAgreementDataFieldName.dateSignRent,
          DocumentLeaseAgreementDataFieldName.expirationDateRent,
          DocumentLeaseAgreementDataFieldName.declaredDocRent,
        ].forEach(handleClearInput);
        break;

      case DocumentOwnershipCertificateDataFieldName.OWNERSHIP_CERTIFICATE:
        [
          DocumentOwnershipCertificateDataFieldName.serialNumberDoc,
          DocumentOwnershipCertificateDataFieldName.indexNumber,
          DocumentOwnershipCertificateDataFieldName.certificateDate,
          DocumentOwnershipCertificateDataFieldName.authDep,
          DocumentOwnershipCertificateDataFieldName.PropertyDeclaredDoc,
        ].forEach(handleClearInput);
        break;

      case DocumentExractPrprDataFieldName.EXRACT_PRPR:
        [
          DocumentExractPrprDataFieldName.ownershipRegNumber,
          DocumentExractPrprDataFieldName.ownershipindexNumber,
          DocumentExractPrprDataFieldName.ownershipDate,
          DocumentExractPrprDataFieldName.ownershipFormedBy,
          DocumentExractPrprDataFieldName.OwnershipDeclaredDoc,
        ].forEach(handleClearInput);
        break;

      case DocumentCertMarriageDataFieldName.CERT_MARRIAGE:
        [
          DocumentCertMarriageDataFieldName.marriageSeriaNumberDoc,
          DocumentCertMarriageDataFieldName.marriageDate,
          DocumentCertMarriageDataFieldName.marriageAuthDep,
          DocumentCertMarriageDataFieldName.marriageDeclaredDoc,
        ].forEach(handleClearInput);
        break;

      case DocumentVityagTeritorialnoyiGromadiDataFieldName.VITYAG_TERITORIALNOYI_GROMADI:
        [
          DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityNumber,
          DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityDate,
          DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityAuthDep,
          DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityDeclaredDoc,
        ].forEach(handleClearInput);
        break;

      case DocumentLegalResidenceDataFieldName.LEGAL_RESIDENCE:
        [
          DocumentLegalResidenceDataFieldName.numberExtract,
          DocumentLegalResidenceDataFieldName.authDepExtract,
          DocumentLegalResidenceDataFieldName.dateExtract,
          DocumentLegalResidenceDataFieldName.dateExpired,
          DocumentLegalResidenceDataFieldName.ExtractDeclaredDoc,
        ].forEach(handleClearInput);
        break;

      case DocumentCertIdpDataFieldName.CERT_IDP:
        [
          DocumentCertIdpDataFieldName.numberVPO,
          DocumentCertIdpDataFieldName.dateIssueVPO,
          DocumentCertIdpDataFieldName.VPODeclaredDoc,
        ].forEach(handleClearInput);
        break;

      default:
        return;
    }

    const documentTypesWithoutDeleted = documentTypes.filter((document) => document.code !== documentCode);

    setDocumentTypes(documentTypesWithoutDeleted.length ? documentTypesWithoutDeleted : [{ id: null }]);
    handleClearInput(documentCode);
  };

  const removeAllDocuments = (): void => {
    allowedForSelectionDocumentTypesForDeclaredPlaceOfResidence.forEach((documentTypeCode) => {
      if (formData[documentTypeCode]) {
        handleRemoveDocument(documentTypeCode);
      }
    });
    setDocumentTypes([{ id: null }]);
  };

  const handleDocumentTypeChange = (value: DocumentType, index: number): void => {
    if (!value) {
      handleRemoveDocument(documentTypes[index].code);
      return;
    }

    if (documentTypes.some(({ id }) => id === value.id)) {
      modalContext.renderSpecificModal({
        modalName: ModalName.DocumentAddCheckAlert,
      });
    } else {
      setDocumentTypes((prevDocumentTypes) =>
        prevDocumentTypes.map((item, i) => {
          if (i === index) {
            handleRemoveDocument(item.code);
            return value;
          }
          return item;
        }),
      );
      handleInputChange(value.code, value);
    }
  };

  const createFieldsDocumentType = (NewValueCreate: DocumentType): JSX.Element => {
    switch (NewValueCreate.code) {
      case DocumentLeaseAgreementDataFieldName.LEASE_AGREEMENT:
        return (
          <React.Fragment key={NewValueCreate.code}>
            <div className="form__row form__container form__create-js" data-doc={NewValueCreate.code}>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Номер договору</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentLeaseAgreementDataFieldName.contractNumberRent}
                  value={formData.contractNumberRent || ''}
                  onChange={({ target: { name, value } }): void => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Індексний номер</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentLeaseAgreementDataFieldName.indexNumberRent}
                  value={formData.indexNumberRent || ''}
                  onChange={({ target: { name, value } }): void => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
            </div>

            <div className="form__row form__container form__create-js" data-doc={NewValueCreate.code}>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Дата підписання</div>
                <div className="form-input__date-box">
                  <DatePicker
                    locale="uk"
                    name={DocumentLeaseAgreementDataFieldName.dateSignRent}
                    id={DocumentLeaseAgreementDataFieldName.dateSignRent}
                    selected={dateSignRent}
                    placeholderText="дд.мм.рррр"
                    className="form-input__input  form-control"
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={todaysDate}
                    customInput={
                      <InputMask
                        mask="99.99.9999"
                        name={DocumentLeaseAgreementDataFieldName.dateSignRent}
                        value={dateSignRent ? dateSignRent.toLocaleDateString('uk-UA') : ''}
                      />
                    }
                    onChange={(date): void => {
                      handleInputChange(DocumentLeaseAgreementDataFieldName.dateSignRent, date);
                    }}
                  />
                  <label htmlFor={DocumentLeaseAgreementDataFieldName.dateSignRent}>
                    <img
                      className="has-datetimepicker form-input__date-icon js-date-picker"
                      src={calendarIcon}
                      alt="icon"
                    />
                  </label>
                </div>
              </div>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Строк оренди</div>
                <div className="form-input__date-box">
                  <DatePicker
                    locale="uk"
                    name={DocumentLeaseAgreementDataFieldName.expirationDateRent}
                    id={DocumentLeaseAgreementDataFieldName.expirationDateRent}
                    selected={expirationDateRent}
                    placeholderText="дд.мм.рррр"
                    className="form-input__input form-control"
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={todaysDate}
                    customInput={
                      <InputMask
                        mask="99.99.9999"
                        name={DocumentLeaseAgreementDataFieldName.expirationDateRent}
                        value={expirationDateRent ? expirationDateRent.toLocaleDateString('uk-UA') : ''}
                      />
                    }
                    onChange={(date): void => {
                      handleInputChange(DocumentLeaseAgreementDataFieldName.expirationDateRent, date);
                    }}
                  />
                  <label htmlFor={DocumentLeaseAgreementDataFieldName.expirationDateRent}>
                    <img
                      className="has-datetimepicker form-input__date-icon js-date-picker"
                      src={calendarIcon}
                      alt="icon"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div
              className="form-input form-input_margin-bottom-none form__create-js required"
              data-doc={NewValueCreate.code}
            >
              <div className="form-input__label">
                Документ, який підтверджує задеклароване місце проживання (зберігання зброї)
              </div>

              <FileUpload
                inputName={DocumentLeaseAgreementDataFieldName.declaredDocRent}
                inputTitle="Додати сканкопію документа"
                value={formData.declaredDocRent}
                handleInputChange={handleInputChange}
                handleClearInput={handleClearInput}
              />
            </div>
          </React.Fragment>
        );
      case DocumentOwnershipCertificateDataFieldName.OWNERSHIP_CERTIFICATE:
        return (
          <React.Fragment key={NewValueCreate.code}>
            <div className="form__row form__container form__create-js" data-doc={NewValueCreate.code}>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Серія та номер</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentOwnershipCertificateDataFieldName.serialNumberDoc}
                  value={formData.serialNumberDoc || ''}
                  onChange={({ target: { name, value } }): void => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Індексний номер</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentOwnershipCertificateDataFieldName.indexNumber}
                  value={formData.indexNumber || ''}
                  onChange={({ target: { name, value } }): void => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
            </div>

            <div className="form__row form__container form__create-js" data-doc={NewValueCreate.code}>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Дата видачі</div>
                <div className="form-input__date-box">
                  <DatePicker
                    locale="uk"
                    name={DocumentOwnershipCertificateDataFieldName.certificateDate}
                    id={DocumentOwnershipCertificateDataFieldName.certificateDate}
                    selected={certificateDate}
                    placeholderText="дд.мм.рррр"
                    className="form-input__input  form-control"
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={todaysDate}
                    customInput={
                      <InputMask
                        mask="99.99.9999"
                        name={DocumentOwnershipCertificateDataFieldName.certificateDate}
                        value={certificateDate ? certificateDate.toLocaleDateString('uk-UA') : ''}
                      />
                    }
                    onChange={(date): void => {
                      handleInputChange(DocumentOwnershipCertificateDataFieldName.certificateDate, date);
                    }}
                  />
                  <label htmlFor={DocumentOwnershipCertificateDataFieldName.certificateDate}>
                    <img
                      className="has-datetimepicker form-input__date-icon js-date-picker"
                      src={calendarIcon}
                      alt="icon"
                    />
                  </label>
                </div>
              </div>

              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Орган видачі</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentOwnershipCertificateDataFieldName.authDep}
                  value={formData.authDep || ''}
                  onChange={({ target: { name, value } }): void => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
            </div>

            <div
              className="form-input form-input_margin-bottom-none form__create-js required"
              data-doc={NewValueCreate.code}
            >
              <div className="form-input__label">
                Документ, який підтверджує задеклароване місце проживання (зберігання зброї)
              </div>

              <FileUpload
                inputName={DocumentOwnershipCertificateDataFieldName.PropertyDeclaredDoc}
                inputTitle="Додати сканкопію документа"
                value={formData.PropertyDeclaredDoc}
                handleInputChange={handleInputChange}
                handleClearInput={handleClearInput}
              />
            </div>
          </React.Fragment>
        );
      case DocumentExractPrprDataFieldName.EXRACT_PRPR:
        return (
          <React.Fragment key={NewValueCreate.code}>
            <div className="form__row form__container form__create-js" data-doc={NewValueCreate.code}>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Номер запису про право власності</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentExractPrprDataFieldName.ownershipRegNumber}
                  value={formData.ownershipRegNumber || ''}
                  onChange={({ target: { name, value } }): void => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Індексний номер</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentExractPrprDataFieldName.ownershipindexNumber}
                  value={formData.ownershipindexNumber || ''}
                  onChange={({ target: { name, value } }): void => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
            </div>

            <div className="form__row form__container form__create-js" data-doc={NewValueCreate.code}>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Дата видачі</div>
                <div className="form-input__date-box">
                  <DatePicker
                    locale="uk"
                    name={DocumentExractPrprDataFieldName.ownershipDate}
                    selected={ownershipDate}
                    id={DocumentExractPrprDataFieldName.ownershipDate}
                    placeholderText="дд.мм.рррр"
                    className="form-input__input  form-control"
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={todaysDate}
                    customInput={
                      <InputMask
                        mask="99.99.9999"
                        name={DocumentExractPrprDataFieldName.ownershipDate}
                        value={ownershipDate ? ownershipDate.toLocaleDateString('uk-UA') : ''}
                      />
                    }
                    onChange={(date): void => {
                      handleInputChange(DocumentExractPrprDataFieldName.ownershipDate, date);
                    }}
                  />
                  <label htmlFor={DocumentExractPrprDataFieldName.ownershipDate}>
                    <img
                      className="has-datetimepicker form-input__date-icon js-date-picker"
                      src={calendarIcon}
                      alt="icon"
                    />
                  </label>
                </div>
              </div>

              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Ким сформовано</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentExractPrprDataFieldName.ownershipFormedBy}
                  value={formData.ownershipFormedBy || ''}
                  onChange={({ target: { name, value } }): void => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
            </div>

            <div
              className="form-input form-input_margin-bottom-none form__create-js required"
              data-doc={NewValueCreate.code}
            >
              <div className="form-input__label">
                Документ, який підтверджує задеклароване місце проживання (зберігання зброї)
              </div>

              <FileUpload
                inputName={DocumentExractPrprDataFieldName.OwnershipDeclaredDoc}
                inputTitle="Додати сканкопію документа"
                value={formData.OwnershipDeclaredDoc}
                handleInputChange={handleInputChange}
                handleClearInput={handleClearInput}
              />
            </div>
          </React.Fragment>
        );
      case DocumentCertMarriageDataFieldName.CERT_MARRIAGE:
        return (
          <React.Fragment key={NewValueCreate.code}>
            <div className="form__row form__container form__create-js" data-doc={NewValueCreate.code}>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Серія та номер</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentCertMarriageDataFieldName.marriageSeriaNumberDoc}
                  value={formData.marriageSeriaNumberDoc || ''}
                  onChange={({ target: { name, value } }): void => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Дата видачі</div>
                <div className="form-input__date-box">
                  <DatePicker
                    locale="uk"
                    name={DocumentCertMarriageDataFieldName.marriageDate}
                    id={DocumentCertMarriageDataFieldName.marriageDate}
                    selected={marriageDate}
                    placeholderText="дд.мм.рррр"
                    className="form-input__input  form-control"
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={todaysDate}
                    customInput={
                      <InputMask
                        mask="99.99.9999"
                        name={DocumentCertMarriageDataFieldName.marriageDate}
                        value={marriageDate ? marriageDate.toLocaleDateString('uk-UA') : ''}
                      />
                    }
                    onChange={(date): void => {
                      handleInputChange(DocumentCertMarriageDataFieldName.marriageDate, date);
                    }}
                  />
                  <label htmlFor={DocumentCertMarriageDataFieldName.marriageDate}>
                    <img
                      className="has-datetimepicker form-input__date-icon js-date-picker"
                      src={calendarIcon}
                      alt="icon"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="form__row form__container form__create-js" data-doc={NewValueCreate.code}>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Орган видачі</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentCertMarriageDataFieldName.marriageAuthDep}
                  value={formData.marriageAuthDep || ''}
                  onChange={({ target: { name, value } }): void => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
            </div>

            <div
              className="form-input form-input_margin-bottom-none form__create-js required"
              data-doc={NewValueCreate.code}
            >
              <div className="form-input__label">
                Документ, який підтверджує задеклароване місце проживання (зберігання зброї)
              </div>

              <FileUpload
                inputName={DocumentCertMarriageDataFieldName.marriageDeclaredDoc}
                inputTitle="Додати сканкопію документа"
                value={formData.marriageDeclaredDoc}
                handleInputChange={handleInputChange}
                handleClearInput={handleClearInput}
              />
            </div>
          </React.Fragment>
        );
      case DocumentVityagTeritorialnoyiGromadiDataFieldName.VITYAG_TERITORIALNOYI_GROMADI:
        return (
          <React.Fragment key={NewValueCreate.code}>
            <div className="form__row form__container form__create-js" data-doc={NewValueCreate.code}>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Номер</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityNumber}
                  value={formData.localCommunityNumber || ''}
                  onChange={({ target: { name, value } }): void => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Дата формування</div>
                <div className="form-input__date-box">
                  <DatePicker
                    locale="uk"
                    name={DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityDate}
                    id={DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityDate}
                    selected={localCommunityDate}
                    placeholderText="дд.мм.рррр"
                    className="form-input__input  form-control"
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={todaysDate}
                    customInput={
                      <InputMask
                        mask="99.99.9999"
                        name={DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityDate}
                        value={localCommunityDate ? localCommunityDate.toLocaleDateString('uk-UA') : ''}
                      />
                    }
                    onChange={(date): void => {
                      handleInputChange(DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityDate, date);
                    }}
                  />
                  <label htmlFor={DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityDate}>
                    <img
                      className="has-datetimepicker form-input__date-icon js-date-picker"
                      src={calendarIcon}
                      alt="icon"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="form__row form__container form__create-js" data-doc={NewValueCreate.code}>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Орган видачі</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityAuthDep}
                  value={formData.localCommunityAuthDep || ''}
                  onChange={({ target: { name, value } }): void => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
            </div>

            <div
              className="form-input form-input_margin-bottom-none form__create-js required"
              data-doc={NewValueCreate.code}
            >
              <div className="form-input__label">
                Документ, який підтверджує задеклароване місце проживання (зберігання зброї)
              </div>

              <FileUpload
                inputName={DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityDeclaredDoc}
                inputTitle="Додати сканкопію документа"
                value={formData.localCommunityDeclaredDoc}
                handleInputChange={handleInputChange}
                handleClearInput={handleClearInput}
              />
            </div>
          </React.Fragment>
        );
      case DocumentLegalResidenceDataFieldName.LEGAL_RESIDENCE:
        return (
          <React.Fragment key={NewValueCreate.code}>
            <div className="form__row form__container form__create-js" data-doc={NewValueCreate.code}>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Номер</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentLegalResidenceDataFieldName.numberExtract}
                  value={formData.numberExtract || ''}
                  onChange={({ target: { name, value } }): void => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Орган видачі</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentLegalResidenceDataFieldName.authDepExtract}
                  value={formData.authDepExtract || ''}
                  onChange={({ target: { name, value } }): void => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
            </div>

            <div className="form__row form__container form__create-js" data-doc={NewValueCreate.code}>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Дата формування</div>
                <div className="form-input__date-box">
                  <DatePicker
                    locale="uk"
                    name={DocumentLegalResidenceDataFieldName.dateExtract}
                    id={DocumentLegalResidenceDataFieldName.dateExtract}
                    selected={dateExtract}
                    placeholderText="дд.мм.рррр"
                    className="form-input__input  form-control"
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={todaysDate}
                    customInput={
                      <InputMask
                        mask="99.99.9999"
                        name={DocumentLegalResidenceDataFieldName.dateExtract}
                        value={dateExtract ? dateExtract.toLocaleDateString('uk-UA') : ''}
                      />
                    }
                    onChange={(date): void => {
                      handleInputChange(DocumentLegalResidenceDataFieldName.dateExtract, date);
                    }}
                  />
                  <label htmlFor={DocumentLegalResidenceDataFieldName.dateExtract}>
                    <img
                      className="has-datetimepicker form-input__date-icon js-date-picker"
                      src={calendarIcon}
                      alt="icon"
                    />
                  </label>
                </div>
              </div>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Термін дії</div>
                <div className="form-input__date-box">
                  <DatePicker
                    locale="uk"
                    name={DocumentLegalResidenceDataFieldName.dateExpired}
                    id={DocumentLegalResidenceDataFieldName.dateExpired}
                    placeholderText="дд.мм.рррр"
                    selected={dateExpired}
                    className="form-input__input  form-control"
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={todaysDate}
                    customInput={
                      <InputMask
                        mask="99.99.9999"
                        name={DocumentLegalResidenceDataFieldName.dateExpired}
                        value={dateExpired ? dateExpired.toLocaleDateString('uk-UA') : ''}
                      />
                    }
                    onChange={(date): void => {
                      handleInputChange(DocumentLegalResidenceDataFieldName.dateExpired, date);
                    }}
                  />
                  <label htmlFor={DocumentLegalResidenceDataFieldName.dateExpired}>
                    <img
                      className="has-datetimepicker form-input__date-icon js-date-picker"
                      src={calendarIcon}
                      alt="icon"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div
              className="form-input form-input_margin-bottom-none form__create-js required"
              data-doc={NewValueCreate.code}
            >
              <div className="form-input__label">
                Документ, який підтверджує задеклароване місце проживання (зберігання зброї)
              </div>
              <FileUpload
                inputName={DocumentLegalResidenceDataFieldName.ExtractDeclaredDoc}
                inputTitle="Додати сканкопію документа"
                value={formData.ExtractDeclaredDoc}
                handleInputChange={handleInputChange}
                handleClearInput={handleClearInput}
              />
            </div>
          </React.Fragment>
        );
      case DocumentCertIdpDataFieldName.CERT_IDP:
        return (
          <React.Fragment key={NewValueCreate.code}>
            <div className="form__row form__container form__create-js" data-doc={NewValueCreate.code}>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Номер довідки ВПО</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentCertIdpDataFieldName.numberVPO}
                  value={formData.numberVPO || ''}
                  onChange={({ target: { name, value } }): void => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>

              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Дата видачі</div>
                <div className="form-input__date-box">
                  <DatePicker
                    locale="uk"
                    name={DocumentCertIdpDataFieldName.dateIssueVPO}
                    id={DocumentCertIdpDataFieldName.dateIssueVPO}
                    selected={dateIssueVPO}
                    placeholderText="дд.мм.рррр"
                    className="form-input__input  form-control"
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={todaysDate}
                    customInput={
                      <InputMask
                        mask="99.99.9999"
                        name={DocumentCertIdpDataFieldName.dateIssueVPO}
                        value={dateIssueVPO ? dateIssueVPO.toLocaleDateString('uk-UA') : ''}
                      />
                    }
                    onChange={(date): void => {
                      handleInputChange(DocumentCertIdpDataFieldName.dateIssueVPO, date);
                    }}
                  />
                  <label htmlFor={DocumentCertIdpDataFieldName.dateIssueVPO}>
                    <img
                      className="has-datetimepicker form-input__date-icon js-date-picker"
                      src={calendarIcon}
                      alt="icon"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div
              className="form-input form-input_margin-bottom-none form__create-js required"
              data-doc={NewValueCreate.code}
            >
              <div className="form-input__label">
                Документ, який підтверджує задеклароване місце проживання (зберігання зброї)
              </div>
              <FileUpload
                inputName={DocumentCertIdpDataFieldName.VPODeclaredDoc}
                inputTitle="Додати сканкопію документа"
                value={formData.VPODeclaredDoc}
                handleInputChange={handleInputChange}
                handleClearInput={handleClearInput}
              />
            </div>
          </React.Fragment>
        );
      default:
        return <React.Fragment />;
    }
  };

  useEffect(() => {
    const initialData = async (): Promise<void> => {
      setDocumentTypes((prevDocumentTypes) => {
        const updatedDocumentTypes = [...prevDocumentTypes];
        allowedForSelectionDocumentTypesForDeclaredPlaceOfResidence.forEach((documentTypeCode) => {
          if (formData[documentTypeCode]) {
            const newValue = parseFormDataValue(formData[documentTypeCode].toString());
            const newDocument = {
              id: newValue.id,
              label: newValue.label,
              code: newValue.code,
            };

            !updatedDocumentTypes[0].id
              ? (updatedDocumentTypes[0] = newDocument)
              : updatedDocumentTypes.push(newDocument);
          }
        });

        return updatedDocumentTypes;
      });
    };

    initialData();
    load();
  }, []);

  useEffect(() => {
    !showDocuments && removeAllDocuments();
  }, [showDocuments]);

  return (
    <>
      {showDocuments &&
        documentTypes.map((doc, index) => (
          <>
            <div key={doc?.id} className="form__row form__create-js">
              <div className={classNames('form-input form-input_margin-bottom-none no-group', { required })}>
                <div className="form-input__label">
                  Тип документу, який підтверджує задеклароване місце проживання (зберігання зброї)
                </div>
                <div className="input-select js-input-select create-js-html">
                  <InitialSelect
                    name={doc?.code || 'documentName'}
                    allowClear
                    options={documentTypeEntities}
                    placeholder="Не вибрано"
                    defaultValue={documentTypeEntities?.find(({ id }) => id === doc.id)}
                    onChange={(newValue): void => handleDocumentTypeChange(newValue, index)}
                  />
                </div>
              </div>
            </div>

            {createFieldsDocumentType(doc)}

            {index > 0 && (
              <div className="form-input form-input_margin-bottom-none" data-doc={doc.code}>
                <div className="form-button_remove-label">
                  <img src={deleteIcon} alt="icon" />
                  <input
                    className="form-button_remove"
                    type="button"
                    name="removeDoc"
                    value="Видалити документ"
                    onClick={(): void => {
                      if (doc.code) {
                        handleRemoveDocument(doc.code);
                      } else {
                        setDocumentTypes(documentTypes.filter((_, i) => i !== index));
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </>
        ))}

      {showDocuments && documentTypes.length < documentTypeEntities?.length && (
        <div className="form__row">
          <div className="form-input form-input_margin-bottom-none">
            <button
              className="button button_border"
              type="button"
              onClick={(): void => {
                setDocumentTypes([
                  ...documentTypes,
                  {
                    id: null,
                  },
                ]);
              }}
            >
              Додати ще один документ
            </button>
          </div>
        </div>
      )}
    </>
  );
};
