import { useCallback, useEffect, useMemo, useState } from 'react';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import './CriminalRecordSearchAll.scss';

import { SearchFormTemplate } from '../../../../../components/Layout/SearchForm/SearchFormTemplate';
import { uaLocale, serviceName, todaysDate } from './constants';
import { Result } from './components/Result';
import { useAsyncDataLoader } from '../../../../../hooks/useAsyncDataLoader';
import { api } from '../../../../../components/Api';
import { IACUserRequest } from '../../../types';
import { formatDateStringToISO } from '../../../../../helpers/dates';
import { AntSelectOption } from '../../../../../components/Inputs/Select';

export const CriminalRecordSearchAll: React.FC = () => {
  const [isSearchHappened, setIsSearchHappened] = useState<boolean>(false);
  const [filteredUserRequests, setFilteredUserRequests] = useState<IACUserRequest[]>([]);
  const [formData, setFormData] = useState<{
    purpose: AntSelectOption['value'];
    period: [start: string, end: string];
  }>({
    purpose: null,
    period: ['', ''],
  });

  const {
    isLoading: isPurposeEntitiesLoading,
    data: purposeEntities,
    load: loadPurposeEntities,
  } = useAsyncDataLoader<AntSelectOption[]>(
    async () => (await api.dictionary.getProposal()).data.map((d) => ({ label: d.name, value: d.id })),
    {
      defaultDataValue: [],
    },
  );
  const {
    isLoading: isUserRequestsLoading,
    data: userRequests = [],
    load: loadUserRequests,
  } = useAsyncDataLoader<IACUserRequest[], [purpose_id?: string]>(
    async (purpose_id = '') => (await api.iac.getUserRequests(purpose_id)).data,
    {
      defaultDataValue: [],
    },
  );

  const filterUserRequests = useCallback(
    (items: IACUserRequest[]) => {
      const isPeriodFilterUsed = formData.period.every(Boolean);
      const isPurposeFilterUsed = !!formData.purpose;

      if (!isPeriodFilterUsed && !isPurposeFilterUsed) return items;

      const [dateFrom, dateTo] = formData.period.map(formatDateStringToISO).map(dayjs);

      return items.filter((userRequest) => {
        const createdAtDate = dayjs(formatDateStringToISO(userRequest.created_at));
        const isPurposeFilterMatch = isPurposeFilterUsed ? formData.purpose === userRequest.purpose_id : true;
        const isPeriodFilterMatch = isPeriodFilterUsed
          ? // dateFrom <= createdAt <= dateTo
            createdAtDate.diff(dateFrom, 'days') >= 0 && dateTo.diff(createdAtDate, 'days') >= 0
          : true;

        return isPeriodFilterMatch && isPurposeFilterMatch;
      });
    },
    [formData.period, formData.purpose],
  );

  const sortedUserRequest = useMemo<IACUserRequest[]>(() => {
    return [...filteredUserRequests].sort(
      (a, b) =>
        new Date(formatDateStringToISO(b.created_at)).valueOf() -
        new Date(formatDateStringToISO(a.created_at)).valueOf(),
    );
  }, [filteredUserRequests]);

  useEffect(() => {
    loadPurposeEntities();
    loadUserRequests();
    setIsSearchHappened(true);
  }, []);

  useEffect(() => {
    if (isSearchHappened) {
      setFilteredUserRequests(filterUserRequests(userRequests));
    }
  }, [filterUserRequests, formData.period, isSearchHappened, userRequests]);

  const handleFilterChange = (name: keyof typeof formData, value: (typeof formData)[keyof typeof formData]): void => {
    setFormData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    setIsSearchHappened(false);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setIsSearchHappened(true);
    setFilteredUserRequests(filterUserRequests(userRequests));
  };

  return (
    <SearchFormTemplate
      isLoading={isUserRequestsLoading}
      result={sortedUserRequest}
      title={serviceName}
      canSubmit={!isSearchHappened}
      onSubmit={onSubmit}
      renderResult={(items): React.ReactNode => <Result items={items} />}
    >
      <div className="input-with-label">
        <div>Мета запиту</div>
        <Select
          disabled={isUserRequestsLoading}
          loading={isPurposeEntitiesLoading}
          options={purposeEntities}
          allowClear
          size="large"
          placeholder="Не вибрано"
          value={formData.purpose}
          onChange={(value): void => handleFilterChange('purpose', value)}
        />
      </div>

      <div className="input-with-label">
        <div>Період запиту</div>
        <DatePicker.RangePicker
          format="DD.MM.YYYY"
          showNow
          size="large"
          locale={uaLocale}
          minDate={todaysDate.subtract(1, 'year')}
          maxDate={todaysDate}
          disabled={isUserRequestsLoading}
          onChange={(_, dateStrings): void => handleFilterChange('period', dateStrings)}
        />
      </div>
    </SearchFormTemplate>
  );
};
