import React, { useEffect, useState } from 'react';

import InfoBlock from '../../../components/Home/InfoBlock/InfoBlock';
import MainTemplate from '../../../templates/MainTemplate/MainTemplate';
import ModalBox from '../../../components/Modals/modalBox';
import ServiceMenuListMockup from '../../../components/Home/ServiceMenuListMockup';
import { api } from '../../../components/Api';
import { useAsyncDataLoader } from '../../../hooks/useAsyncDataLoader';
import { ServicesHomePageList } from '../../../components/Home/ServicesHomePageList';
import { ROUTES } from '../../../router/config';
import './HomePage.scss';
import { ServiceType } from '../../../components/Api/types';
import { Statistics } from '../../../components/Home/Statistics';

const HomePage: React.FC = () => {
  const {
    isLoading: isServicesLoading,
    load: loadServices,
    data: services = [],
  } = useAsyncDataLoader(async () => (await api.services.getAll()).data);
  const {
    isLoading: isExternalServicesLoading,
    load: loadExternalServices,
    data: externalServices = [],
  } = useAsyncDataLoader(async () => (await api.services.getExternal()).data);

  const [isModalOpen, setIsPanelOpen] = useState(false);

  const toggleModalAddService = (): void => {
    if (isModalOpen) {
      setIsPanelOpen(false);
      return;
    }
    setIsPanelOpen(true);
  };

  useEffect(() => {
    loadServices();
    loadExternalServices();
  }, []);

  return (
    <MainTemplate>
      <InfoBlock />
      <Statistics />
      <section id="home" className="section-light homepage">
        <div className="homepage__container">
          {(isServicesLoading || isExternalServicesLoading) && <ServiceMenuListMockup classType="loading" />}

          {!(isServicesLoading || isExternalServicesLoading) && (
            <>
              <div className="item">
                <ServicesHomePageList
                  header="Послуги"
                  servicesType={ServiceType.INTERNAL}
                  services={services || []}
                  redirectLink={ROUTES.INTERNAL_SERVICES}
                />
              </div>
              <div className="item item--blue">
                <ServicesHomePageList
                  header="Інші державні послуги"
                  servicesType={ServiceType.EXTERNAL}
                  services={externalServices || []}
                  redirectLink={ROUTES.EXTERNAL_SERVICES}
                />
              </div>
            </>
          )}
        </div>
      </section>
      <ModalBox isModalOpen={isModalOpen} clickOnAddServiceButton={{ toggleModalAddService }} />
    </MainTemplate>
  );
};

export default HomePage;
