import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';

import { headerTabs } from '../../../config/headerTabsContent';
import './NavTabs.scss';
import { NavTabsMapping } from './uk.l10n';

export const NavTabs: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Tabs
      animated={false}
      items={headerTabs.map((headerTab, index) => ({ key: `${index}`, label: NavTabsMapping[headerTab] }))}
      className="nav-tabs"
      onChange={(key): void => {
        navigate(headerTabs[+key]);
      }}
      activeKey={`${headerTabs.findIndex((headerTab) => headerTab === pathname)}` || null}
    />
  );
};
