import React, { PropsWithChildren, createContext, useContext, useMemo, useState, useEffect, useCallback } from 'react';

import { FeatureName, IntegrationSystem, ServiceCodes, UserService } from '../../components/Api/types';
import { FeatureContext } from '../Features/FeatureContext';
import { ServiceUnAvailable } from '../../components/Forms/ServiceUnAvailable';
import { ServiceNames } from '../../config/services';
import AuthContext from '../Auth/AuthContext';
import { useAsyncDataLoader } from '../../hooks/useAsyncDataLoader';
import { api } from '../../components/Api';
import Loader from '../../components/Loader';

export enum Reason {
  NOT_ENOUGH_DATA = 'NO_DATA',
  DUPLICATED_APPLICATION = 'DUPLICATED_APPLICATION',
}

export interface ServiceAvailabilityContextInterface {
  isAvailable: boolean;
  disableCause: (props: ChangeAvailabilityProps) => void;
}

const ServiceAvailabilityContext = createContext<ServiceAvailabilityContextInterface>({
  isAvailable: true,
  disableCause: () => null,
});

interface Props extends PropsWithChildren {
  serviceCode: keyof typeof ServiceNames;
  skipAvailabilityValidation?: boolean;
}

type IsServiceAvailableInterface = boolean;
type FeatureAvailabiltyByFeatureName = Record<FeatureName, boolean>;

interface ChangeAvailabilityPropsWithCustomMessage {
  reason: Reason;
  message: JSX.Element;
}
interface ChangeAvailabilityPropsWithoutCustomMessage {
  reason: Reason.NOT_ENOUGH_DATA;
  message?: JSX.Element;
}
type ChangeAvailabilityProps = ChangeAvailabilityPropsWithCustomMessage | ChangeAvailabilityPropsWithoutCustomMessage;

const requiredFeaturesForServices: Partial<Record<FeatureName, ServiceCodes[]>> = {
  [FeatureName.erzServices]: [
    ServiceCodes.PURCHASE,
    ServiceCodes.STORAGE_CARRYING,
    ServiceCodes.VALIDITY_PERIOD_PROLONGATION,
    ServiceCodes.TRANSPORT_ACROSS_BORDER,
    ServiceCodes.COLD_STEEL_TRANSPORTATION,
    ServiceCodes.COMMISSION_SALE,
    ServiceCodes.RESIDENCE_RELOCATION,
    ServiceCodes.TEMPORARY_RESIDENCE_RELOCATION,
    ServiceCodes.COLD_STEEL_STORAGE_FOR_COLLECTION,
    ServiceCodes.CANCELLATION_TEMPORARY_RESIDENCE_RELOCATION,
  ],
  [FeatureName.temporaryResidenceRelocation]: [ServiceCodes.TEMPORARY_RESIDENCE_RELOCATION],
  [FeatureName.cancellationTemporaryResidenceRelocation]: [ServiceCodes.CANCELLATION_TEMPORARY_RESIDENCE_RELOCATION],
};

const featuresByCode = (code: ServiceCodes): FeatureName[] => {
  const features = Object.entries(requiredFeaturesForServices)
    .filter(([, serviceCodes]) => serviceCodes.includes(code))
    .map(([feature]) => feature as FeatureName);

  return features;
};

export const ServiceAvailabilityProvider: React.FC<Props> = ({
  children,
  serviceCode,
  skipAvailabilityValidation = false,
}) => {
  const { isFeatureAvailable, getSourceSystemByFeature } = useContext(FeatureContext);
  const { systems } = useContext(AuthContext);

  const [customIsServiceAvailable, setCustomIsServiceAvailable] = useState<IsServiceAvailableInterface>(true);
  const [customUnAvailabilityReason, setCustomUnAvailabilityReason] = useState<Reason | null>(null);
  const [customUnAvailabilityMessage, setCustomUnAvailabilityMessage] = useState<JSX.Element>(null);

  const { data: services, isLoading, load } = useAsyncDataLoader(async () => (await api.services.getAll()).data);

  useEffect(() => {
    load();
  }, []);

  const currentService = useMemo<UserService>(
    () => services?.find(({ code }: UserService) => code === serviceCode),
    [serviceCode, services],
  );
  const serviceFeatures = useMemo<FeatureName[]>(() => featuresByCode(serviceCode), [serviceCode]);
  const sourceSystems = useMemo<IntegrationSystem[]>(
    () => serviceFeatures.map(getSourceSystemByFeature),
    [serviceFeatures],
  );

  const isOnboarding =
    currentService?.isOnboarding &&
    !systems.some((name) => sourceSystems.some((sourceSystem) => sourceSystem === name));

  const featureAvailabiltyByFeatureName = useMemo<FeatureAvailabiltyByFeatureName>(
    () =>
      serviceFeatures.reduce(
        (acc, featureName) => ({
          ...acc,
          [featureName]: isFeatureAvailable(featureName),
        }),
        {} as FeatureAvailabiltyByFeatureName,
      ),
    [isFeatureAvailable, serviceFeatures],
  );

  const unavailableFeatureName = useMemo<FeatureName | null>(() => {
    for (const [featureName, isAvailable] of Object.entries(featureAvailabiltyByFeatureName)) {
      if (Object.prototype.hasOwnProperty.call(featureAvailabiltyByFeatureName, featureName)) {
        if (!isAvailable) return featureName as FeatureName;
      }
    }

    return null;
  }, [featureAvailabiltyByFeatureName]);

  const isServiceAvailable: boolean =
    skipAvailabilityValidation || (customIsServiceAvailable && !unavailableFeatureName) || isOnboarding;

  const changeAvailability = useCallback(
    ({ reason, message }: ChangeAvailabilityProps): void => {
      setCustomIsServiceAvailable(false);
      setCustomUnAvailabilityReason(reason);
      message && setCustomUnAvailabilityMessage(message);
    },
    [setCustomIsServiceAvailable, setCustomUnAvailabilityReason],
  );

  if (isLoading) {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '300px',
        }}
      >
        <Loader centered />
      </div>
    );
  }

  return (
    <ServiceAvailabilityContext.Provider
      value={{
        isAvailable: isServiceAvailable,
        disableCause: changeAvailability,
      }}
    >
      {isServiceAvailable ? (
        children
      ) : (
        <ServiceUnAvailable
          causedBy={customUnAvailabilityReason || unavailableFeatureName}
          customMsg={customUnAvailabilityMessage}
        />
      )}
    </ServiceAvailabilityContext.Provider>
  );
};

export default ServiceAvailabilityContext;
