import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { lazy, useContext } from 'react';

import { SuspenseWithLoader } from '../../../components/SuspenseWithLoader';
import { PrivateRouteWrapper } from '../../../components/PrivateRouteWrapper';
import AuthContext from '../../../contexts/Auth/AuthContext';
import { IAC_ROUTES } from './config';
import { ROUTES_BY_SERVICE_CODE } from '../../../router/config';
import { ServiceCodes } from '../../../components/Api/types';
import ErrorPage from '../../../pages/ErrorPage/ErrorPage';
import { CriminalRecordSearchAll } from '../pages/services/CriminalRecordSearchAll';

const CriminalRecordExtract = lazy(() => import('../pages/services/CriminalRecordExtract'));
const IACCheckForm = lazy(() => import('../pages/services/ExtractionCheck'));

export const IACRouter: React.FC = () => {
  const { search } = useLocation();
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/services">
        <Route path={IAC_ROUTES.SERVICES.CRIMINAL_RECORD_SEARCH}>
          <Route
            index
            element={
              <SuspenseWithLoader>
                <IACCheckForm />
              </SuspenseWithLoader>
            }
          />
          <Route
            path={IAC_ROUTES.SERVICES.CRIMINAL_RECORD_SEARCH_QR}
            element={<Navigate to={ROUTES_BY_SERVICE_CODE[ServiceCodes.CRIMINAL_RECORD_SEARCH]} state={{ search }} />}
          />
          <Route
            path={IAC_ROUTES.SERVICES.CRIMINAL_RECORD_SEARCH_QR_LEGACY}
            element={<Navigate to={ROUTES_BY_SERVICE_CODE[ServiceCodes.CRIMINAL_RECORD_SEARCH]} state={{ search }} />}
          />
        </Route>

        <Route path="/services" element={<PrivateRouteWrapper isLoggedIn={isLoggedIn} />}>
          <Route
            path={IAC_ROUTES.SERVICES.CRIMINAL_RECORD_EXTRACT}
            element={
              <SuspenseWithLoader>
                <CriminalRecordExtract />
              </SuspenseWithLoader>
            }
          />
        </Route>

        <Route
          path={IAC_ROUTES.SERVICES.CRIMINAL_RECORD_SEARCH_ALL}
          element={
            <SuspenseWithLoader>
              <CriminalRecordSearchAll />
            </SuspenseWithLoader>
          }
        />
      </Route>

      <Route path="*" element={<ErrorPage errorType="404" />} />
    </Routes>
  );
};
