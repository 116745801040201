import { useContext } from 'react';
import classNames from 'classnames';

import MVSLogo from '../../../assets/images/admin-mvs-logo.svg';
import AuthContext from '../../../contexts/Auth/AuthContext';
import './MVSButton.scss';

interface Props {
  color?: 'white' | 'black';
}

export const MVSButton: React.FC<Props> = ({ color = 'white' }) => {
  const { storedLinks } = useContext(AuthContext);

  return (
    <div className="media-title__title">
      <a
        href={storedLinks.externalLink?.mvsWebsite}
        className="media-title header-banner__media-title"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="media-title__media"
          src={color === 'white' ? MVSLogo : '/images/admin-mvs-logo_blue.svg'}
          alt="eye"
          width="24"
          height="24"
        />
        <span
          className={classNames('media-title__title', {
            'mvs-button--black': color === 'black',
          })}
        >
          Міністерство внутрішніх справ України
        </span>
      </a>
    </div>
  );
};
