import React, { MouseEvent, useContext } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Drawer as AntdDrawer, Divider, DrawerProps } from 'antd';
import './Drawer.scss';

import AuthContext from '../../../contexts/Auth/AuthContext';
import { GovUaToastButton } from '../../Toasts/GovUaToast';
import { AccessibilityPanelButton } from '../AccessibilityPanel/AccessibilityPanelButton';
import { MVSButton } from '../Header/MVSButton';
import { ROUTES } from '../../../router/config';
import { headerTabs } from '../../../config/headerTabsContent';
import { NavTabsMapping } from '../Header/uk.l10n';
import { OrderServiceLink } from '../Header/OrderServiceLink';

interface Props {
  isDrawerOpen: DrawerProps['open'];
  toggleDrawer: DrawerProps['onClose'];
  govUaToastOnClick: (e: MouseEvent<HTMLElement>) => void;
  logoutHandler: () => void;
  placement?: DrawerProps['placement'];
}

export const Drawer: React.FC<Props> = ({
  isDrawerOpen,
  toggleDrawer,
  govUaToastOnClick,
  logoutHandler,
  placement = 'right',
}) => {
  const authContext = useContext(AuthContext);
  const { pathname } = useLocation();

  return (
    <AntdDrawer open={isDrawerOpen} onClose={toggleDrawer} placement={placement} closable={false}>
      <div className="drawer">
        <div className="drawer__header">
          <span className="drawer__header--main">Меню</span>
          <button className="drawer__header--close" onClick={toggleDrawer}>
            <img src="/images/close_black.svg" alt="close" />
          </button>
        </div>

        <Divider className="drawer__divider" />

        <div className="drawer__content">
          {headerTabs.map((headerTab) => (
            <Link to={headerTab} className="drawer__content__item" key={headerTab}>
              {NavTabsMapping[headerTab]}
            </Link>
          ))}
        </div>

        <Divider className="drawer__divider" />

        <div className="drawer__content">
          <div className="drawer__content__item">
            <AccessibilityPanelButton onClick={(e): void => toggleDrawer(e)} color="black" />
          </div>

          {!authContext.isLoggedIn && (
            <NavLink to={ROUTES.LOGIN} className="drawer__content__item">
              <img src="/images/user_blue.svg" alt="user" width="24" height="24" />
              <span>Увійти</span>
            </NavLink>
          )}

          {authContext.isLoggedIn && (
            <>
              <Link to={ROUTES.USER.REQUESTS.ACTIVE_REQUESTS} className="drawer__content__item">
                <img src="/images/user_blue.svg" alt="user" width="24" height="24" />
                <span>Особистий кабінет</span>
              </Link>

              <Link
                to={ROUTES.LOGIN}
                state={{
                  nextPage: pathname,
                }}
                className="drawer__content__item"
                onClick={logoutHandler}
              >
                <img src="/images/exit_blue.svg" alt="user" width="24" height="24" />
                <span>Вийти</span>
              </Link>
            </>
          )}
        </div>

        <div className="drawer__footer">
          <div className="drawer__footer__content">
            <div className="drawer__footer__content__item">
              <OrderServiceLink className="drawer-order-button" />
            </div>

            <Divider className="drawer__divider" />

            <div className="drawer__footer__content__item">
              <div className="drawer__footer__content__item--link">
                <GovUaToastButton govUaToastHandler={govUaToastOnClick} color="black" />
              </div>

              <Divider className="drawer__divider" type="vertical" />

              <div className="drawer__footer__content__item--link">
                <MVSButton color="black" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AntdDrawer>
  );
};
