import React, { useContext } from 'react';
import classNames from 'classnames';
import './ServiceTile.scss';

import someIcon from '../../assets/images/icons-set.svg';
import { UserServiceExternal } from '../Api/types';
import { Button, LinkAsButton } from '../Button';
import InformerIcon from '../../assets/images/attention_blue.svg';
import ModalContext from '../../contexts/Modal/ModalContext';
import { ModalName } from '../../contexts/Modal/modalTypes';

type Props = {
  service: UserServiceExternal;
  className?: string;
};

export const ExternalServiceTile = React.memo<Props>(({ service, className = '' }) => {
  const { renderSpecificModal } = useContext(ModalContext);

  return (
    <div className={classNames('service', { [className]: className })} key={service.name}>
      <div>
        <div className="service__content">
          <div className="service_icon">
            <img src={service.icon || someIcon} alt="service" />
          </div>
        </div>
        <h4 className="service__title">{service.name}</h4>
      </div>

      <div className="service__link-container">
        {!!service.short_description && (
          <Button
            withIcon
            outline
            withoutBorder
            onClick={(): void =>
              renderSpecificModal({
                modalName: ModalName.ExternalServiceDetailsAlert,
                modalProps: {
                  service,
                },
              })
            }
          >
            <img src={InformerIcon} alt="details" />
            Детальніше
          </Button>
        )}
        <LinkAsButton to={service.link} size="lg" outline withIcon>
          <span>Перейти</span>
          <img src="/images/reply_blue.svg" alt="redirect" />
        </LinkAsButton>
      </div>
    </div>
  );
});
