import React, { useState, ReactNode, MouseEvent } from 'react';

import Header from '../../components/Layout/Header/Header';
import AccessibilityContext from '../../contexts/AccessibilityContext';
import DesktopMainMenu from '../../components/Layout/Navigations/DesktopMainMenu';
// import LayoutAlertMessage from '../../components/Layout/Alerts/LayoutAlertMessage'
import Footer from '../../components/Layout/Footer/Footer';
import '../../containers/StyleSettings/themes/default/layout.scss';
import { FloatActionButtons } from '../../components/FloatActionButtons';

interface Props {
  className?: string;
  children: ReactNode;
}

const MainTemplate = ({ className, children }: Props): JSX.Element => {
  const [isPanelShown, setPanelViewState] = useState(false);

  const toggleAccessibilityPanel = () => {
    if (isPanelShown) {
      setPanelViewState(false);
      return;
    }
    setPanelViewState(true);
  };

  const turnOnAccessibilityPanel = (event: MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    toggleAccessibilityPanel();
  };

  return (
    <AccessibilityContext.Provider value={{ turnOnAccessibilityPanel }}>
      <Header isPanelShown={isPanelShown} />
      <DesktopMainMenu />
      {/* <LayoutAlertMessage /> */}
      <main className={`main ${className}`} id="main">
        {children}
      </main>
      <Footer />
      <FloatActionButtons />
    </AccessibilityContext.Provider>
  );
};

export default MainTemplate;
