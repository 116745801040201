import { useState } from 'react';

import { ArtifactType } from '../components/Api/types';
import { saveFileFromBlob } from '../helpers/fetch/saveFileFromBlob';
import { ArtifactTypeNameMapping } from '../pages/UserArea/UserRequests/uk.l10n';
import { FileFormat } from '../helpers/fileHelper';

export const ArtifactTypeFileFormatMapping: Record<ArtifactType, FileFormat> = {
  [ArtifactType.EXTRACT]: FileFormat.PDF,
  [ArtifactType.RECEIPT]: FileFormat.PDF,
  [ArtifactType.MESSAGE]: FileFormat.PDF,
  [ArtifactType.APPLICATION]: FileFormat.PDF,
  [ArtifactType.SIGN]: FileFormat.SIGNATURE,
};

interface HookReturn {
  isArtifactLoading: boolean;
  currentArtifactTypeLoading: ArtifactType | null;
  downloadArtifact: (type: ArtifactType) => Promise<void>;
}

type UseArtifactLoader = (load: (artifactType: ArtifactType) => Promise<Blob>) => HookReturn;

export const useArtifactLoader: UseArtifactLoader = (load) => {
  const [isArtifactLoading, setIsArtifactLoading] = useState<HookReturn['isArtifactLoading']>(false);
  const [currentArtifactTypeLoading, setCurrentArtifactTypeLoading] =
    useState<HookReturn['currentArtifactTypeLoading']>(null);

  const downloadArtifact: HookReturn['downloadArtifact'] = async (artifactType) => {
    setIsArtifactLoading(true);
    setCurrentArtifactTypeLoading(artifactType);

    const fileName = ArtifactTypeNameMapping[artifactType] + ArtifactTypeFileFormatMapping[artifactType];

    await load(artifactType)
      .then((blob) => saveFileFromBlob(fileName, blob))
      .catch(console.error)
      .finally(() => {
        setIsArtifactLoading(false);
        setCurrentArtifactTypeLoading(null);
      });
  };

  return {
    isArtifactLoading,
    currentArtifactTypeLoading,
    downloadArtifact,
  };
};
