import { Route, Routes } from 'react-router-dom';
import { lazy, useContext } from 'react';

import { ServiceCodes } from '../../../components/Api/types';
import { SuspenseWithLoader } from '../../../components/SuspenseWithLoader';
import AuthContext from '../../../contexts/Auth/AuthContext';
import { PrivateRouteWrapper } from '../../../components/PrivateRouteWrapper';
import { ERZ_ROUTES } from './config';
import PermissionRenewal from '../pages/services/PermissionRenewal';
import ErrorPage from '../../../pages/ErrorPage/ErrorPage';

const WeaponRegistered = lazy(() => import('../pages/WeaponRegistered'));
const ERZSearch = lazy(() => import('../pages/services/ErzSearch'));

const WeaponTransportation = lazy(() => import('../pages/services/WeaponTransportation'));
const ResidenceRelocation = lazy(() => import('../pages/services/ResidenceRelocation'));
const CommissionSales = lazy(() => import('../pages/services/CommissionSales'));
// TODO: check why lazy import causes "using variable before initilization" only for the row below
// const PermissionRenewal = lazy(() => import('../pages/services/PermissionRenewal'));
const CollectableWeaponsTransportation = lazy(() => import('../pages/services/CollectableWeaponsTransportation'));
const WeaponPurchasePermit = lazy(() => import('../pages/services/WeaponPurchasePermit'));
const WeaponStorageCarrying = lazy(() => import('../pages/services/WeaponStorageCarrying'));
const ErzExtractPage = lazy(() => import('../pages/services/ErzExtract'));
const ColdSteelStorageForCollection = lazy(() => import('../pages/services/ColdSteelStorageForCollection'));
const CancellationTemporaryResidenceRelocation = lazy(
  () => import('../pages/services/CancellationTemporaryResidenceRelocation'),
);

export const ERZRouter: React.FC = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/user" element={<PrivateRouteWrapper isLoggedIn={isLoggedIn} />}>
        <Route
          path={ERZ_ROUTES.USER.WEAPON_REGISTERED}
          element={
            <SuspenseWithLoader>
              <WeaponRegistered />
            </SuspenseWithLoader>
          }
        />
      </Route>

      <Route path="/services">
        <Route
          path={ERZ_ROUTES.SERVICES.ERZ_SEARCH}
          element={
            <SuspenseWithLoader>
              <ERZSearch />
            </SuspenseWithLoader>
          }
        />

        <Route path="/services" element={<PrivateRouteWrapper isLoggedIn={isLoggedIn} />}>
          <Route
            path={ERZ_ROUTES.SERVICES.COMMISSION_SALE}
            element={
              <SuspenseWithLoader>
                <CommissionSales />
              </SuspenseWithLoader>
            }
          />
          <Route
            path={ERZ_ROUTES.SERVICES.ERZ_EXTRACT}
            element={
              <SuspenseWithLoader>
                <ErzExtractPage />
              </SuspenseWithLoader>
            }
          />
          <Route
            path={ERZ_ROUTES.SERVICES.WEAPON_PURCHASE_PERMIT}
            element={
              <SuspenseWithLoader>
                <WeaponPurchasePermit />
              </SuspenseWithLoader>
            }
          />
          <Route
            path={ERZ_ROUTES.SERVICES.WEAPON_STORAGE_CARRYING}
            element={
              <SuspenseWithLoader>
                <WeaponStorageCarrying />
              </SuspenseWithLoader>
            }
          />
          <Route
            path={ERZ_ROUTES.SERVICES.WEAPON_TRANSPORTATION}
            element={
              <SuspenseWithLoader>
                <WeaponTransportation />
              </SuspenseWithLoader>
            }
          />
          <Route
            path={ERZ_ROUTES.SERVICES.PERMISSION_RENEWAL}
            element={
              <SuspenseWithLoader>
                <PermissionRenewal />
              </SuspenseWithLoader>
            }
          />
          <Route
            path={ERZ_ROUTES.SERVICES.COLD_STEEL_STORAGE_FOR_COLLECTION}
            element={
              <SuspenseWithLoader>
                <ColdSteelStorageForCollection />
              </SuspenseWithLoader>
            }
          />
          <Route
            path={ERZ_ROUTES.SERVICES.RESIDENCE_RELOCATION}
            element={
              <SuspenseWithLoader>
                <ResidenceRelocation serviceCode={ServiceCodes.RESIDENCE_RELOCATION} />
              </SuspenseWithLoader>
            }
          />
          <Route
            path={ERZ_ROUTES.SERVICES.TEMPORARY_RESIDENCE_RELOCATION}
            element={
              <SuspenseWithLoader>
                <ResidenceRelocation serviceCode={ServiceCodes.TEMPORARY_RESIDENCE_RELOCATION} />
              </SuspenseWithLoader>
            }
          />
          <Route
            path={ERZ_ROUTES.SERVICES.CANCELLATION_TEMPORARY_RESIDENCE_RELOCATION}
            element={
              <SuspenseWithLoader>
                <CancellationTemporaryResidenceRelocation />
              </SuspenseWithLoader>
            }
          />
          <Route
            path={ERZ_ROUTES.SERVICES.COLLECTABLE_WEAPONS_TRANSPORTATION}
            element={
              <SuspenseWithLoader>
                <CollectableWeaponsTransportation />
              </SuspenseWithLoader>
            }
          />
        </Route>
      </Route>

      <Route path="*" element={<ErrorPage errorType="404" />} />
    </Routes>
  );
};
