import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './UserRequests.scss';
import '../../../components/Home/ServiceMenuList.scss';
import classNames from 'classnames';
import { Select } from 'antd';

import CabinetTemplate from '../../../templates/CabinetTemplate/CabinetTemplate';
import UserSearchIcon from '../../../assets/images/user-search_grey.svg';
import Loader from '../../../components/Loader';
import AuthContext from '../../../contexts/Auth/AuthContext';
import { useAsyncDataLoader } from '../../../hooks/useAsyncDataLoader';
import { api } from '../../../components/Api';
import { DraftListItem, ListItem } from './ListItem';
import { ALL_statuses, defaultSelectOption } from './uk.l10n';
import UserWithWatchIcon from '../../../assets/images/user-with-watch.svg';
import AttentionBlueIcon from '../../../assets/images/attention_blue.svg';
import { UserRequest, UserRequestStatus } from '../../../components/Api/types';
import { VideoInstructionLink } from '../../../components/VideoInstructionLink';
import { ServiceNames } from '../../../config/services';
import { ROUTES } from '../../../router/config';
import { StatusMapping } from '../../../components/Status';

const ordersTabs: Array<{ route: string; name: string }> = [
  {
    route: ROUTES.USER.REQUESTS.ACTIVE_REQUESTS,
    name: 'Активні запити',
  },
  {
    route: ROUTES.USER.REQUESTS.ARCHIVED_REQUESTS,
    name: 'Архів запитів',
  },
  {
    route: ROUTES.USER.REQUESTS.DRAFT_REQUESTS,
    name: 'Чернетки',
  },
];

interface Props {
  tab: 'active' | 'archived' | 'draft';
}

const UserRequests: React.FC<Props> = ({ tab }): JSX.Element => {
  const { fullName, storedLinks } = useContext(AuthContext);
  const [selectedStatus, setSelectedStatus] = useState<StatusMapping<UserRequestStatus | 'all'>>(defaultSelectOption);
  const [cachedDeletedDrafts, setCachedDeletedDrafts] = useState<UserRequest[]>([]);
  const [cachedDeletedOrders, setCachedDeletedOrders] = useState<UserRequest[]>([]);

  const {
    data: orders = [],
    load: loadOrders,
    isLoading: isOrdersLoading,
  } = useAsyncDataLoader(async () =>
    (await api.getUserOrders())?.data?.map((order) => ({
      ...order,
      title: ServiceNames[order.service_code],
    })),
  );
  const {
    data: draftOrders = [],
    load: loadDraftorders,
    isLoading: isDraftOrdersLoading,
  } = useAsyncDataLoader(async () =>
    (await api.services.draft.getAll())?.data?.map((order) => ({
      ...order,
      title: ServiceNames[order.service_code],
    })),
  );
  const {
    isLoading: areServicesLoading,
    load: loadServices,
    data: services,
  } = useAsyncDataLoader(async () => (await api.services.getAll()).data);

  const isLoading = useMemo(
    () => isDraftOrdersLoading || isOrdersLoading || areServicesLoading,
    [isDraftOrdersLoading, isOrdersLoading, areServicesLoading],
  );

  const videoInstructionLink = useMemo<string>(() => {
    const { active, archive, draft } = storedLinks?.videoInstruction?.requests || {};

    switch (tab) {
      case 'active':
        return active;

      case 'archived':
        return archive;

      case 'draft':
        return draft;

      default:
        return '';
    }
  }, [tab, storedLinks]);

  const ordersOnCurrentTab = useMemo(
    () =>
      tab === 'draft'
        ? (draftOrders || []).filter((draftOrder) => cachedDeletedDrafts.every((i) => i.id !== draftOrder.id))
        : (orders || []).filter(
            (order) =>
              !!order.is_archived === (tab === 'archived') && cachedDeletedOrders.every((i) => i.id !== order.id),
          ),
    [tab, draftOrders, orders, cachedDeletedDrafts, cachedDeletedOrders],
  );

  const noOrdersInfoMeesage = useMemo<JSX.Element>(() => {
    let infoMessage = null;

    switch (tab) {
      case 'active':
        infoMessage = (
          <>
            <div>
              <img src={UserSearchIcon} alt="no data icon" />
            </div>
            <div className="information__body">
              <div className="block-title_lg">Нічого не знайдено</div>
              <p>Наразі за вашим профілем не знайдено активних запитів</p>
              <div>
                <Link className="button" to={ROUTES.HOME}>
                  Замовити послугу
                </Link>
              </div>
            </div>
          </>
        );
        break;

      case 'archived':
        infoMessage = (
          <>
            <div>
              <img src={UserWithWatchIcon} alt="no data icon" />
            </div>
            <div className="information__body">
              <div className="block-title_lg">Нічого не знайдено</div>
              <p>Через деякий час тут будуть відображатись Ваші послуги, які були опрацьовані</p>
            </div>
          </>
        );
        break;

      case 'draft':
        infoMessage = (
          <>
            <div>
              <img src={UserWithWatchIcon} alt="no data icon" />
            </div>
            <div className="information__body">
              <div className="block-title_lg">Нічого не знайдено</div>
              <p>Тут будуть зберігатися Ваші чернетки</p>
            </div>
          </>
        );

      default:
        break;
    }

    return <div className="information">{infoMessage}</div>;
  }, [tab]);

  const filteredOrders = useMemo(() => {
    let filtered;

    if (tab === 'draft') {
      // Only real drafts have meta property not with null value
      // This filter has to be deleted when all services will be using draft feature
      filtered = ordersOnCurrentTab.filter((order) => !!order.meta);
    } else {
      filtered =
        selectedStatus.status === defaultSelectOption.status
          ? ordersOnCurrentTab
          : ordersOnCurrentTab.filter(
              (order) =>
                order.status === selectedStatus.status &&
                selectedStatus.integrationSystem === services.find(({ code }) => order.service_code === code)?.source,
            );
    }

    return filtered.sort((a, b) => b.id - a.id);
  }, [tab, selectedStatus, ordersOnCurrentTab]);

  useEffect(() => {
    if (tab === 'draft') {
      loadDraftorders();
    } else {
      loadOrders();
      loadServices();
    }
  }, [tab]);

  if (isLoading) {
    return (
      <CabinetTemplate>
        <Loader centered />
      </CabinetTemplate>
    );
  }

  return (
    <CabinetTemplate>
      <section className="user-requests container-with-aside__section container-with-aside__section_margin-top">
        <div className="block-title block-title_margin-bottom-md">Кабінет громадянина</div>
        <div className="block-title block-title_md block-title_supportive block-title_margin-bottom-md">
          Вітаємо, {fullName}
        </div>
        <div className="block-with-result">
          {tab === 'active' && (
            <div className="text__hint">
              <img src={AttentionBlueIcon} alt="attention icon" width={24} height={24} />
              <p>
                Для відображення даних по заявам, що були зареєстровані в системі Єдиного реєстру зброї, необхідно
                заповнити всі дані в Профілі користувача.
              </p>
            </div>
          )}

          <div className="main-nav">
            <div className="main-nav__links">
              {ordersTabs.map((ordersTab) => (
                <NavLink
                  key={ordersTab.route}
                  to={ordersTab.route}
                  className={({ isActive }): string =>
                    classNames('main-nav__tab', {
                      'main-nav__tab_active': isActive,
                    })
                  }
                >
                  {ordersTab.name}
                </NavLink>
              ))}
            </div>
          </div>
          {!ordersOnCurrentTab?.length ? (
            <>
              {videoInstructionLink && (
                <div className="user-requests__tools user-requests__tools--one-item">
                  <VideoInstructionLink link={videoInstructionLink} asButton />
                </div>
              )}

              {noOrdersInfoMeesage}
            </>
          ) : (
            <>
              {(tab !== 'draft' || videoInstructionLink) && (
                <div
                  className={classNames('user-requests__tools', {
                    'user-requests__tools--one-item': tab === 'draft',
                  })}
                >
                  {tab !== 'draft' && (
                    <FilterSelect
                      label="Статус"
                      options={ALL_statuses}
                      selected={selectedStatus}
                      onSelect={setSelectedStatus}
                    />
                  )}

                  <VideoInstructionLink link={videoInstructionLink} asButton />
                </div>
              )}

              <div className="user-requests__container">
                {filteredOrders.length ? (
                  tab === 'draft' ? (
                    <section>
                      <ul className="desktop-list desktop-list--draft">
                        {filteredOrders.map((item) => (
                          <li key={item.id} className="list__item list__item--draft">
                            <DraftListItem
                              item={item}
                              storeDeletedDraft={(draft: UserRequest): void =>
                                setCachedDeletedDrafts([...cachedDeletedDrafts, draft])
                              }
                            />
                          </li>
                        ))}
                      </ul>
                    </section>
                  ) : (
                    <>
                      <section>
                        <ul className="desktop-list">
                          {filteredOrders.map((item) => (
                            <ListItem
                              key={item.id}
                              item={item}
                              services={services}
                              storeDeletedRequest={(order): void => {
                                setCachedDeletedOrders([...cachedDeletedOrders, order]);
                              }}
                            />
                          ))}
                        </ul>
                      </section>
                    </>
                  )
                ) : (
                  <div className="nothing-found">
                    <div className="nothing-found__img-container">
                      <img src={UserSearchIcon} alt="Nothing found icon" />
                    </div>
                    <div className="block-title_lg">Нічого не знайдено</div>
                    <p>Наразі за вашим профілем не знайдено активних запитів із обраним статусом</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </section>
    </CabinetTemplate>
  );
};

interface FilterSelectProps<T> {
  label: string;
  selected: T;
  options: T[];
  onSelect: (props: T) => void;
}

function FilterSelect<T>({ label, options, selected, onSelect }: FilterSelectProps<T>): JSX.Element {
  return (
    <form className="services-filter-form">
      <div className="demo-select">
        <label htmlFor="demo-select">{label}</label>
        <Select
          id="demo-select"
          value={selected}
          onSelect={(_, selectedOption): void => onSelect(selectedOption)}
          size="large"
          listHeight={400}
          options={options}
        />
      </div>
    </form>
  );
}

export default UserRequests;
