import { useState } from 'react';

type Props<T> = {
  fetchFunction: () => Promise<T>;
  isResponseAppropriate: (data: T) => boolean;
  delayTime: number;
  totalAwaitTime: number;
};

export type HookReturn = {
  isShortPollingSuccessed: boolean;
  isShortPollingInProgress: boolean;
  isShortPollingFailed: boolean;
  startShortPolling: () => Promise<void>;
};

export enum PollingStatus {
  inProgress = 'inProgress',
  success = 'success',
  failed = 'failed',
}

export const useShortPolling = <T>({
  isResponseAppropriate,
  fetchFunction,
  delayTime,
  totalAwaitTime,
}: Props<T>): HookReturn => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);

  const startShortPolling = async (index = 0): Promise<void> => {
    setInProgress(true);
    setIsSuccess(false);
    setIsFailed(false);

    if (index * delayTime > totalAwaitTime) {
      setIsFailed(true);
      setInProgress(false);
      return;
    }
    const data = await fetchFunction();

    if (isResponseAppropriate(data)) {
      setInProgress(false);
      setIsSuccess(true);
    } else {
      setTimeout(() => startShortPolling(index + 1), delayTime);
    }
  };

  return {
    isShortPollingSuccessed: isSuccess,
    isShortPollingInProgress: inProgress,
    isShortPollingFailed: isFailed,
    startShortPolling,
  };
};
