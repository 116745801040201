import React, { useEffect, useMemo, useRef, useState } from 'react';
import './PermissionRenewal.scss';
import '../../../../../containers/StyleSettings/themes/default/layout.scss';

import StepsNavigation from '../../../../../components/Layout/StepsNavigation/StepsNavigation';
import MainTemplate from '../../../../../templates/MainTemplate/MainTemplate';
import FormSteps from './Steps/StepInitial';
import { ServiceInformer } from '../../../../../components/Forms/ServiceInformer';
import { ServiceCodes } from '../../../../../components/Api/types';
import { ServiceInformerType } from '../../../../../components/Forms/ServiceInformer/types';
import { ServiceNames, StepsNavigationName } from '../../../../../config/services';
import { getDateWithoutTime } from '../../../../../helpers/dates';
import { HeaderWithHomeLinkButton } from '../../../../../components/Forms/HeaderWithHomeLinkButton';
import { stepsNavigationDataFactory } from '../../../../../helpers/stepsNavigationDataFactory';

const StepsNavigationData = stepsNavigationDataFactory([
  StepsNavigationName.serviceDescription,
  StepsNavigationName.permissionData,
  StepsNavigationName.personalAndPassport,
  StepsNavigationName.currentPlaceOfResidence,
  StepsNavigationName.personStatus,
  StepsNavigationName.health,
  StepsNavigationName.insurance,
  StepsNavigationName.technicalCondition,
  StepsNavigationName.additionalDocument,
  StepsNavigationName.confirmationOfApplication,
  StepsNavigationName.signUp,
  StepsNavigationName.payment,
  StepsNavigationName.result,
]);

export const currentServiceCode = ServiceCodes.VALIDITY_PERIOD_PROLONGATION;
export const serviceName = ServiceNames[currentServiceCode];
export const totalSteps = StepsNavigationData.length;
export const stepNumberToPay = StepsNavigationData.length - 1;
export const stepNumberToSubmitForm = StepsNavigationData.length - 2;
export const stepNumberToDownloadPdf = StepsNavigationData.length - 3;
export const todaysDate = getDateWithoutTime(new Date());

export const PermissionRenewal: React.FC = () => {
  const htmlRef = useRef(null);
  const [myObjects, setMyObjects] = useState(StepsNavigationData);
  const currentStep = useMemo(() => myObjects.find((step) => step.active), [myObjects]);
  const isPaymentDetailsInformerActive = useMemo(
    () => currentStep.step === StepsNavigationData[0].step || currentStep.step === stepNumberToPay,
    [currentStep],
  );
  const isClueInformerActive = useMemo(
    () => currentStep.step === StepsNavigationData[0].step || currentStep.step === StepsNavigationData.at(-1).step,
    [currentStep],
  );
  const isValidityPeriodViolationInformerActive = useMemo(() => currentStep.step === 2, [currentStep]);
  const isPlaceOfResidenceInformerActive = useMemo(() => currentStep.step === 4, [currentStep]);
  const isPersonsStatusInformerActive = useMemo(() => currentStep.step === 5, [currentStep]);
  const isInsuranceDataInformerActive = useMemo(() => currentStep.step === 7, [currentStep]);
  const isTechnicalConditionDetailsInformerActive = useMemo(() => currentStep.step === 8, [currentStep]);
  const isTechnicalConditionDocumentDataInformerActive = useMemo(() => currentStep.step === 8, [currentStep]);

  const handleHiddenStep = (code: string) => {
    const permitCode = [ServiceCodes.PURCHASE, ServiceCodes.COLD_STEEL_TRANSPORTATION];
    const isCodeInPermitCode = permitCode.toString().includes(code);
    StepsNavigationData[6].hidden = isCodeInPermitCode;
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const stepToActivate = Number(htmlRef.current.querySelector('div[data-step]').getAttribute('data-step'));

      const newData = JSON.parse(JSON.stringify(myObjects))
        .filter((x: { hidden: boolean }) => {
          return !x.hidden;
        })
        .map((step: (typeof StepsNavigationData)[0]) => {
          if (step.step === stepToActivate) {
            step.active = true;
            step.completed = false;
          } else {
            if (step.step < stepToActivate) {
              step.completed = true;
            }
            if (step.active) {
              step.active = false;
            }
          }

          return step;
        });

      setMyObjects(newData);
    });
    observer.observe(htmlRef.current, { childList: true, subtree: true });
    return () => observer.disconnect();
  }, []);

  return (
    <MainTemplate className="section-light">
      <section className="section-block-medium section-light">
        <div className="container container-with-aside_margin-bottom-sm">
          <HeaderWithHomeLinkButton header={serviceName} />
        </div>
        <div className="wpp-container container container-with-two-asides container-with-aside_gap-md">
          <aside className="container-with-aside__aside">
            <StepsNavigation navigationData={myObjects} />
          </aside>
          <section
            className="container-with-aside__section container-with-aside__section_width-md form_max-width-md"
            ref={htmlRef}
          >
            <FormSteps handleHiddenStep={handleHiddenStep} steps={StepsNavigationData} />
          </section>
          <div className="informers-container">
            {isPaymentDetailsInformerActive && (
              <ServiceInformer type={ServiceInformerType.PAYMENT_DETAILS} serviceCode={currentServiceCode} />
            )}
            {isClueInformerActive && <ServiceInformer type={ServiceInformerType.CLUE} />}
            {isValidityPeriodViolationInformerActive && (
              <ServiceInformer type={ServiceInformerType.VALIDITY_PERIOD_VIOLATION} />
            )}
            {isPlaceOfResidenceInformerActive && <ServiceInformer type={ServiceInformerType.PLACE_OF_RESIDENCE} />}
            {isPersonsStatusInformerActive && <ServiceInformer type={ServiceInformerType.PERSONS_STATUS} />}
            {isTechnicalConditionDetailsInformerActive && (
              <ServiceInformer type={ServiceInformerType.TECHNICAL_CONDITION_DETAILS} />
            )}
            {isTechnicalConditionDocumentDataInformerActive && (
              <ServiceInformer type={ServiceInformerType.TECHNICAL_CONDITION_DOCUMENT_DATA} />
            )}
            {isInsuranceDataInformerActive && <ServiceInformer type={ServiceInformerType.INSURANCE_DATA} />}
            <ServiceInformer type={ServiceInformerType.CONTACT_CENTER} />
          </div>
        </div>
      </section>
    </MainTemplate>
  );
};
