import React from 'react';

import {
  DocumentCertIdpDataFieldName,
  DocumentCertMarriageDataFieldName,
  DocumentExractPrprDataFieldName,
  DocumentLeaseAgreementDataFieldName,
  DocumentLegalResidenceDataFieldName,
  DocumentOwnershipCertificateDataFieldName,
  DocumentVityagTeritorialnoyiGromadiDataFieldName,
  allowedForSelectionDocumentTypesForDeclaredPlaceOfResidence,
} from '../Forms/constants';
import { parseFormDataValue } from '../../helpers/parseFormDataValue';
import { formatDate } from '../../helpers/dates';
import { NullValueGuard } from '../../guards/NullValueGuard';
import { PreviewFilesList } from '../Forms/PreviewFilesList';
import { DeclaredDocumentsFormDataProps } from './DeclaredDocumentsCreate';

interface Props {
  formData: DeclaredDocumentsFormDataProps;
}

export const DeclaredDocumentsPreview: React.FC<Props> = ({ formData }) => {
  return (
    <>
      {allowedForSelectionDocumentTypesForDeclaredPlaceOfResidence
        .map((code) => formData[code])
        .filter(Boolean)
        .map((nonParsedItem) => {
          const item = parseFormDataValue(nonParsedItem.toString())?.code;

          switch (item) {
            case DocumentLeaseAgreementDataFieldName.LEASE_AGREEMENT:
              return (
                <div key={item} className="custom-columns custom-columns_grey-bg">
                  <div className="custom-columns__row custom-columns__row_padding-tiny">
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">
                        Тип документа, який підтверджує задеклароване місце проживання (перебування)
                      </div>
                      <div className="custom-columns__info">Договір оренди</div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Номер договору</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formData[DocumentLeaseAgreementDataFieldName.contractNumberRent]}
                        </NullValueGuard>
                      </div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Індексний номер</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>{formData[DocumentLeaseAgreementDataFieldName.indexNumberRent]}</NullValueGuard>
                      </div>
                    </div>
                  </div>
                  <div className="custom-columns__row custom-columns__row_padding-tiny">
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Дата підписання</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formatDate(formData[DocumentLeaseAgreementDataFieldName.dateSignRent])}
                        </NullValueGuard>
                      </div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Строк оренди</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formatDate(formData[DocumentLeaseAgreementDataFieldName.expirationDateRent])}
                        </NullValueGuard>
                      </div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">
                        Документ, який підтверджує задеклароване місце проживання (перебування)
                      </div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formData[DocumentLeaseAgreementDataFieldName.declaredDocRent] ? (
                            <PreviewFilesList files={formData[DocumentLeaseAgreementDataFieldName.declaredDocRent]} />
                          ) : null}
                        </NullValueGuard>
                      </div>
                    </div>
                  </div>
                </div>
              );

            case DocumentOwnershipCertificateDataFieldName.OWNERSHIP_CERTIFICATE:
              return (
                <div key={item} className="custom-columns custom-columns_grey-bg">
                  <div className="custom-columns__row custom-columns__row_padding-tiny">
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">
                        Тип документа, який підтверджує задеклароване місце проживання (перебування)
                      </div>
                      <div className="custom-columns__info">Свідоцтво про право власності</div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Серія та номер</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formData[DocumentOwnershipCertificateDataFieldName.serialNumberDoc]}
                        </NullValueGuard>
                      </div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Індексний номер</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formData[DocumentOwnershipCertificateDataFieldName.indexNumber]}
                        </NullValueGuard>
                      </div>
                    </div>
                  </div>
                  <div className="custom-columns__row custom-columns__row_padding-tiny">
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Дата видачі</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formatDate(formData[DocumentOwnershipCertificateDataFieldName.certificateDate])}
                        </NullValueGuard>
                      </div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Орган видачі</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formatDate(formData[DocumentOwnershipCertificateDataFieldName.authDep])}
                        </NullValueGuard>
                      </div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">
                        Документ, який підтверджує задеклароване місце проживання (перебування)
                      </div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formData[DocumentOwnershipCertificateDataFieldName.PropertyDeclaredDoc] ? (
                            <PreviewFilesList
                              files={formData[DocumentOwnershipCertificateDataFieldName.PropertyDeclaredDoc]}
                            />
                          ) : null}
                        </NullValueGuard>
                      </div>
                    </div>
                  </div>
                </div>
              );

            case DocumentExractPrprDataFieldName.EXRACT_PRPR:
              return (
                <div key={item} className="custom-columns custom-columns_grey-bg">
                  <div className="custom-columns__row custom-columns__row_padding-tiny">
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">
                        Тип документа, який підтверджує задеклароване місце проживання (перебування)
                      </div>
                      <div className="custom-columns__info">
                        Витяг з державного реєстру речових прав на нерухоме майно про реєстрацію права власності
                      </div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Номер запису про право власності</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>{formData[DocumentExractPrprDataFieldName.ownershipRegNumber]}</NullValueGuard>
                      </div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Індексний номер</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formData[DocumentExractPrprDataFieldName.ownershipindexNumber]}
                        </NullValueGuard>
                      </div>
                    </div>
                  </div>
                  <div className="custom-columns__row custom-columns__row_padding-tiny">
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Дата видачі</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formatDate(formData[DocumentExractPrprDataFieldName.ownershipDate])}
                        </NullValueGuard>
                      </div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Ким сформовано</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formatDate(formData[DocumentExractPrprDataFieldName.ownershipFormedBy])}
                        </NullValueGuard>
                      </div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">
                        Документ, який підтверджує задеклароване місце проживання (перебування)
                      </div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formData[DocumentExractPrprDataFieldName.OwnershipDeclaredDoc] ? (
                            <PreviewFilesList files={formData[DocumentExractPrprDataFieldName.OwnershipDeclaredDoc]} />
                          ) : null}
                        </NullValueGuard>
                      </div>
                    </div>
                  </div>
                </div>
              );

            case DocumentExractPrprDataFieldName.EXRACT_PRPR:
              return (
                <div key={item} className="custom-columns custom-columns_grey-bg">
                  <div className="custom-columns__row custom-columns__row_padding-tiny">
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">
                        Тип документа, який підтверджує задеклароване місце проживання (перебування)
                      </div>
                      <div className="custom-columns__info">Свідоцтво про шлюб</div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Серія та номер</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formData[DocumentCertMarriageDataFieldName.marriageSeriaNumberDoc]}
                        </NullValueGuard>
                      </div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Орган видачі</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>{formData[DocumentCertMarriageDataFieldName.marriageAuthDep]}</NullValueGuard>
                      </div>
                    </div>
                  </div>
                  <div className="custom-columns__row custom-columns__row_padding-tiny">
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Дата видачі</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formatDate(formData[DocumentCertMarriageDataFieldName.marriageDate])}
                        </NullValueGuard>
                      </div>
                    </div>

                    <div className="custom-columns__col">
                      <div className="custom-columns__title">
                        Документ, який підтверджує задеклароване місце проживання (перебування)
                      </div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formData[DocumentCertMarriageDataFieldName.marriageDeclaredDoc] ? (
                            <PreviewFilesList files={formData[DocumentCertMarriageDataFieldName.marriageDeclaredDoc]} />
                          ) : null}
                        </NullValueGuard>
                      </div>
                    </div>
                  </div>
                </div>
              );

            case DocumentVityagTeritorialnoyiGromadiDataFieldName.VITYAG_TERITORIALNOYI_GROMADI:
              return (
                <div key={item} className="custom-columns custom-columns_grey-bg">
                  <div className="custom-columns__row custom-columns__row_padding-tiny">
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">
                        Тип документа, який підтверджує задеклароване місце проживання (перебування)
                      </div>
                      <div className="custom-columns__info">Витяг з реєстру територіальної громади</div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Номер</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formData[DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityNumber]}
                        </NullValueGuard>
                      </div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Орган видачі</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formData[DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityAuthDep]}
                        </NullValueGuard>
                      </div>
                    </div>
                  </div>
                  <div className="custom-columns__row custom-columns__row_padding-tiny">
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Дата формування</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formatDate(formData[DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityDate])}
                        </NullValueGuard>
                      </div>
                    </div>

                    <div className="custom-columns__col">
                      <div className="custom-columns__title">
                        Документ, який підтверджує задеклароване місце проживання (перебування)
                      </div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formData[DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityDeclaredDoc] ? (
                            <PreviewFilesList
                              files={
                                formData[DocumentVityagTeritorialnoyiGromadiDataFieldName.localCommunityDeclaredDoc]
                              }
                            />
                          ) : null}
                        </NullValueGuard>
                      </div>
                    </div>
                  </div>
                </div>
              );

            case DocumentLegalResidenceDataFieldName.LEGAL_RESIDENCE:
              return (
                <div key={item} className="custom-columns custom-columns_grey-bg">
                  <div className="custom-columns__row custom-columns__row_padding-tiny">
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">
                        Тип документа, який підтверджує задеклароване місце проживання (перебування)
                      </div>
                      <div className="custom-columns__info">Довідка про реєстрацію місця проживання</div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Номер</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>{formData[DocumentLegalResidenceDataFieldName.numberExtract]}</NullValueGuard>
                      </div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Орган видачі</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>{formData[DocumentLegalResidenceDataFieldName.authDepExtract]}</NullValueGuard>
                      </div>
                    </div>
                  </div>
                  <div className="custom-columns__row custom-columns__row_padding-tiny">
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Дата формування</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formatDate(formData[DocumentLegalResidenceDataFieldName.dateExtract])}
                        </NullValueGuard>
                      </div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Термін дії</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formatDate(formData[DocumentLegalResidenceDataFieldName.dateExpired])}
                        </NullValueGuard>
                      </div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">
                        Документ, який підтверджує задеклароване місце проживання (перебування)
                      </div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formData[DocumentLegalResidenceDataFieldName.ExtractDeclaredDoc] ? (
                            <PreviewFilesList
                              files={formData[DocumentLegalResidenceDataFieldName.ExtractDeclaredDoc]}
                            />
                          ) : null}
                        </NullValueGuard>
                      </div>
                    </div>
                  </div>
                </div>
              );

            case DocumentCertIdpDataFieldName.CERT_IDP:
              return (
                <div key={item} className="custom-columns custom-columns_grey-bg">
                  <div className="custom-columns__row custom-columns__row_padding-tiny">
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">
                        Тип документа, який підтверджує задеклароване місце проживання (перебування)
                      </div>
                      <div className="custom-columns__info">Довідка внутрішньо переміщеної особи</div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Номер</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>{formData[DocumentCertIdpDataFieldName.numberVPO]}</NullValueGuard>
                      </div>
                    </div>
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">Дата видачі</div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formatDate(formData[DocumentCertIdpDataFieldName.dateIssueVPO])}
                        </NullValueGuard>
                      </div>
                    </div>
                  </div>
                  <div className="custom-columns__row custom-columns__row_padding-tiny">
                    <div className="custom-columns__col">
                      <div className="custom-columns__title">
                        Документ, який підтверджує задеклароване місце проживання (перебування)
                      </div>
                      <div className="custom-columns__info">
                        <NullValueGuard>
                          {formData[DocumentCertIdpDataFieldName.VPODeclaredDoc] ? (
                            <PreviewFilesList files={formData[DocumentCertIdpDataFieldName.VPODeclaredDoc]} />
                          ) : null}
                        </NullValueGuard>
                      </div>
                    </div>
                  </div>
                </div>
              );

            default:
              return;
          }
        })}
    </>
  );
};
