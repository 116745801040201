import {
  DataResponse,
  DictionaryAuthority,
  DictionaryCity,
  DictionaryColdSteelFactory,
  DictionaryColdSteelType,
  DictionaryCountry,
  DictionaryDistrict,
  DictionaryIndividualStatus,
  DictionaryRegion,
  DictionaryStreet,
  DictionaryWeaponCategory,
  DocumentType,
  ERZPermit,
  DictionaryPermitType,
  PayloadCreateDraft,
  PayloadUpdateDraft,
  PayloadVerifySubmitAvailability,
  PurchaseItem,
  ResponseCreateDraft,
  ResponseFeatures,
  ResponseUserData,
  ResponseUserWeapons,
  ResponseVerifySubmitAvailability,
  ServiceCategory,
  ServiceCodes,
  UserRequest,
  UserRequestDraft,
  UserService,
  ServiceSupportedCustomWeaponList,
  ResponseAccordionText,
  DictionaryProposal,
  IntegrationSystem,
  RMPSearchQueryParams,
  ErzSearchQueryParams,
  ResponseExtract,
  ResponsePaymentDetails,
  ArtifactType,
  SocialProtectAppeal,
  ResponseSocialProtectDetails,
  SocialProtectNeedsList,
  SocialProtectCreateChatProps,
  SocialProtectAddMessageProps,
  SocialProtectSendFeedbackProps,
  StatisticsData,
  UserServiceExternal,
} from './types';
import { request } from './client';
import { cacheMiddleware } from '../../guards/auth/CacheMiddleware';
import { formatMiddleware } from '../../guards/auth/FormatMiddleware';
import { antiFloodMiddleware } from '../../guards/auth/AntiFloodMiddleware';
import { authMiddleware } from '../../guards/auth';
import { updateDataMiddleware } from '../../guards/UpdateDataMiddleware';
import { createArtifactRequestHeaders } from '../../helpers/createArtifactRequestHeaders';
import { IACUserRequest } from '../../modules/iac/types';

interface RequestOptions {
  cache?: boolean;
  headers?: Headers;
}

type ClientFactory = (
  guard: (cb: (headers?: Headers) => ReturnType<typeof request>, headers?: Headers) => Promise<Response>,
) => {
  get: <T>(url: string, options?: RequestOptions) => Promise<T>;
  post: <T>(url: string, data?: unknown, headers?: Headers) => Promise<T>;
  patch: <T>(url: string, data?: unknown, headers?: Headers) => Promise<T>;
  put: <T>(url: string, data?: unknown, headers?: Headers) => Promise<T>;
  delete: (url: string) => Promise<void>;
};

export const clientFactory: ClientFactory = (guard) => ({
  get: async (
    url,
    options = {
      cache: false,
      headers: new Headers(),
    },
  ) =>
    cacheMiddleware(url, options.cache, async () =>
      formatMiddleware(
        await antiFloodMiddleware(
          await guard((resultHeaders) => request(url, 'GET', null, resultHeaders), options.headers),
        ),
        options.headers,
      ),
    ),
  post: async (url, data = null, headers = new Headers()) =>
    formatMiddleware(
      await updateDataMiddleware(
        await antiFloodMiddleware(await guard((resultHeaders) => request(url, 'POST', data, resultHeaders), headers)),
      ),
      headers,
    ),
  patch: async (url, data = null, headers = new Headers()) =>
    formatMiddleware(
      await updateDataMiddleware(
        await antiFloodMiddleware(await guard((resultHeaders) => request(url, 'PATCH', data, resultHeaders), headers)),
      ),
      headers,
    ),
  put: async (url, data = null, headers = new Headers()) =>
    formatMiddleware(
      await updateDataMiddleware(
        await antiFloodMiddleware(await guard((resultHeaders) => request(url, 'PUT', data, resultHeaders), headers)),
      ),
      headers,
    ),
  delete: async (url) =>
    formatMiddleware(
      await updateDataMiddleware(
        await antiFloodMiddleware(await guard((resultHeaders) => request(url, 'DELETE', null, resultHeaders))),
      ),
    ),
});

export const client = clientFactory(authMiddleware);
export const clientPublic = clientFactory((cb) => cb());

const api = {
  getFeatures: (force = false): Promise<ResponseFeatures> => {
    return client.get('/features' + (force ? '?force=true' : ''));
  },
  getUserOrders: (): Promise<DataResponse<UserRequest[] | null>> => {
    return client.get('/user-requests');
  },
  downloadUserRequestArtifact: (requestId: number, artifactType: ArtifactType): Promise<Blob> => {
    return client.get(`/user-requests/${requestId}/artifacts/${artifactType}`, {
      headers: createArtifactRequestHeaders(artifactType),
    });
  },
  downloadUserRequestPartArtifact: (partId: string, artifactType: ArtifactType): Promise<Blob> => {
    return client.get(`/user-requests/parts/${partId}/artifacts/${artifactType}`, {
      headers: createArtifactRequestHeaders(artifactType),
    });
  },
  getUserData: (): Promise<ResponseUserData> => {
    return client.get('/users');
  },
  updateUserProfile: (data: Record<string, string>): Promise<ResponseUserData> => {
    return client.patch('/users', data);
  },
  getWeaponsData: (): Promise<DataResponse<ResponseUserWeapons>> => {
    return client.get(`/weapons`);
  },
  getAccordionsText: (): Promise<DataResponse<ResponseAccordionText>> => {
    // TODO: come up with a server url
    return clientPublic.get('/accordionsText');
  },
  proxyQRSearchRequest: (qrUrl: string): Promise<DataResponse<any>> => {
    return clientPublic.get(`/criminal-record-search${qrUrl}`);
  },
  getStatistics: (): Promise<DataResponse<StatisticsData>> => {
    return clientPublic.get('/statistics');
  },

  services: {
    getAll: (): Promise<DataResponse<UserService[]>> => {
      return client.get('/services', { cache: true });
    },
    getExternal: (): Promise<DataResponse<UserServiceExternal[]>> => {
      return clientPublic.get('/external-services', { cache: true });
    },
    categories: (): Promise<DataResponse<ServiceCategory[]>> => {
      return clientPublic.get('/service-categories', {
        cache: true,
      });
    },
    generatePdf: <In>(code: ServiceCodes, payload: In): Promise<string> => {
      return client.post(`/user-requests/${code}/generatePdf`, payload);
    },
    submit: <In>(code: ServiceCodes, payload: In): Promise<{ status: 'success'; id?: string }> => {
      return client.post(`/user-requests/${code}/submitForm`, payload);
    },
    attachReceipt: async (id: number, receipt: string): Promise<void> => {
      return client.put(`/user-requests/${id}/attachments/receipt`, { file: receipt });
    },
    serviceWeapons: (code: ServiceSupportedCustomWeaponList): Promise<DataResponse<ResponseUserWeapons>> => {
      return client.get(`/user-requests/${code}/weapons`);
    },
    servicePermits: (code: ServiceCodes.VALIDITY_PERIOD_PROLONGATION): Promise<DataResponse<ERZPermit[]>> => {
      return client.get(`/user-requests/${code}/permits`);
    },
    getUserRequest: (id: number): Promise<DataResponse<Array<{ artifacts: string[] }>>> => {
      return client.get(`/user-requests?id=${id}`);
    },
    verifySubmitAvailability: (
      missing_person: PayloadVerifySubmitAvailability,
    ): Promise<DataResponse<ResponseVerifySubmitAvailability>> => {
      return client.post('/user-requests/missingPerson/canSubmit', {
        missing_person,
      });
    },
    hasActiveRequest: (
      serviceCode: ServiceCodes.CRIMINAL_RECORD_EXTRACT,
    ): Promise<DataResponse<Record<'answer', boolean>>> => client.get(`/user-requests/${serviceCode}/hasActiveRequest`),
    // TODO: change request according to back
    getPaymentDetails: <T = DataResponse<ResponsePaymentDetails>>(id: number, asFile = false): Promise<T> => {
      const headers = new Headers();
      if (asFile) {
        headers.set('Accept', 'application/pdf');
        headers.set('Content-Type', 'application/pdf');
      } else {
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'application/json');
      }
      return client.get(`/user-requests/${id}/payments/info`, { headers });
    },

    socialProtect: {
      getAll: (): Promise<DataResponse<SocialProtectAppeal[]>> => {
        return client.get(`/social-protect/appeals`);
      },
      getAllNeeds: (): Promise<DataResponse<SocialProtectNeedsList>> => {
        return client.get('/social-protect/needs');
      },
      getDetails: (): Promise<DataResponse<ResponseSocialProtectDetails>> => {
        return client.get(`/social-protect/details`);
      },
      createAppeal: (
        props: Required<Pick<SocialProtectAppeal, 'message'>> &
          Partial<Pick<SocialProtectAppeal, 'contact_kind' | 'contact_tag'>>,
      ): Promise<{
        message: string;
        success: boolean;
      }> => {
        return client.post(`/social-protect/add-topic`, props);
      },
      createChat: (
        props: SocialProtectCreateChatProps,
      ): Promise<{
        message: string;
        success: boolean;
      }> => {
        return client.post('/social-protect/add-order', props);
      },
      addMessage: (
        props: SocialProtectAddMessageProps,
      ): Promise<{
        message: string;
        success: boolean;
      }> => {
        return client.post('/social-protect/add-message', props);
      },
      sendFeedback: (
        props: SocialProtectSendFeedbackProps,
      ): Promise<{
        message: string;
        success: boolean;
      }> => {
        return client.post('/social-protect/send-feedback', props);
      },
    },

    draft: {
      getAll: (): Promise<DataResponse<UserRequest[] | null>> => {
        return client.get(`/user-requests/drafts`);
      },
      get: (serviceCode: ServiceCodes): Promise<DataResponse<[UserRequest]>> => {
        return client.get(`/user-requests/drafts?code=${serviceCode}`);
      },
      getDetails: (draftId: number): Promise<DataResponse<UserRequestDraft>> => {
        return client.get(`/user-requests/${draftId}`);
      },
      createInvoice: (draftId: number): Promise<Record<'redirect_uri', string>> => {
        // Hint: returns redirect URL to the GERC (payment system)
        return client.post(`/user-requests/${draftId}/payments/invoice`);
      },
      create: (
        serviceDetails: PayloadCreateDraft,
        defaultMetaData: Pick<UserRequestDraft, 'meta'>,
      ): Promise<ResponseCreateDraft> => {
        return client.post('/user-requests', { service: serviceDetails, ...defaultMetaData });
      },
      update: ({ draftId, serviceDraft }: PayloadUpdateDraft): Promise<UserRequestDraft> => {
        return client.post(`/user-requests/${draftId}`, serviceDraft);
      },
      delete: (draftId: number): Promise<void> => {
        return client.delete(`/user-requests/${draftId}`);
      },
    },
  },

  permits: {
    getAll: (
      queryParams: {
        permitType?: string;
        numb?: string;
      } = {},
    ): Promise<DataResponse<ERZPermit[]>> => {
      return client.get(`/permits?${new URLSearchParams(queryParams).toString()}`);
    },
    downloadExtract: (extractId?: string): Promise<Blob> => {
      const headers = new Headers();
      headers.set('Accept', 'application/pdf');
      headers.set('Content-Type', 'application/octet-stream');

      return client.get(`/permits/extraction/${extractId ?? 'all'}`, { headers });
    },
    searchExtract: (queryParams: ErzSearchQueryParams): Promise<ResponseExtract<IntegrationSystem.ERZ>> => {
      return client.get(`/weapons/extraction?${new URLSearchParams(queryParams).toString()}`);
    },
  },

  iac: {
    search: (queryParams: RMPSearchQueryParams): Promise<ResponseExtract<IntegrationSystem.IAC> | string> => {
      return client.get(
        `/user-requests/${ServiceCodes.CRIMINAL_RECORD_SEARCH}/search?${new URLSearchParams(queryParams).toString()}`,
      );
    },
    getUserRequests: (purpose_id = ''): Promise<DataResponse<IACUserRequest[]>> => {
      return client.get(
        `/user-requests/external/${IntegrationSystem.IAC}?${new URLSearchParams(
          purpose_id ? { purpose_id } : '',
        ).toString()}`,
      );
    },
    downloadUserRequestArtifact: (requestId: string, artifactType: ArtifactType): Promise<Blob> => {
      return client.get(`/artifacts/${IntegrationSystem.IAC}/${artifactType}/${requestId}`, {
        headers: createArtifactRequestHeaders(artifactType),
      });
    },
  },

  dictionary: {
    getColdSteelType: (): Promise<DataResponse<DictionaryColdSteelType[]>> => {
      return client.get(`/referenceBook/erz/findColdSteelType`, { cache: true });
    },
    getColdSteelFactory: (): Promise<DataResponse<DictionaryColdSteelFactory[]>> => {
      return client.get(`/referenceBook/erz/findFactory`, { cache: true });
    },
    getCountry: (): Promise<DataResponse<DictionaryCountry[]>> => {
      return client.get(`/referenceBook/address/country`, { cache: true });
    },
    getDistrict: (regionId: string): Promise<DataResponse<DictionaryDistrict[]>> => {
      return client.get(`/referenceBook/address/district?regionId=${regionId}`, {
        cache: true,
      });
    },
    getRegion: (): Promise<DataResponse<DictionaryRegion[]>> => {
      return client.get(`/referenceBook/address/region`, { cache: true });
    },
    getCity: (districtId: string): Promise<DataResponse<DictionaryCity[]>> => {
      return client.get(`/referenceBook/address/settlement?districtId=${districtId}`, {
        cache: true,
      });
    },
    getStreet: (settlementId: string): Promise<DataResponse<DictionaryStreet[]>> => {
      return client.get(`/referenceBook/address/street?settlementId=${settlementId}`);
    },
    getAuthorities: (): Promise<DataResponse<DictionaryAuthority[]>> => {
      return client.get('/referenceBook/erz/findAuthority', { cache: true });
    },
    getIndividualStatus: (): Promise<DataResponse<DictionaryIndividualStatus[]>> => {
      return client.get('/referenceBook/erz/findIndividualStatus', {
        cache: true,
      });
    },
    getPurchaseItems: (): Promise<DataResponse<PurchaseItem[]>> => {
      return client.get(`/referenceBook/erz/findPurchaseItemIndividual`, {
        cache: true,
      });
    },
    getWeaponCategory: (): Promise<DataResponse<DictionaryWeaponCategory[]>> => {
      return client.get('/referenceBook/erz/findFirearmCategory');
    },
    getProposal: (): Promise<DataResponse<DictionaryProposal[]>> => {
      return client.get('/referenceBook/iac/getProposal', { cache: true });
    },
    getDocumentTypes: {
      erz: (): Promise<DataResponse<Array<DocumentType<IntegrationSystem.ERZ>>>> => {
        return client.get(`/referenceBook/dictionary?name=document-types`, {
          cache: true,
        });
      },
      iac: (): Promise<DataResponse<Array<DocumentType<IntegrationSystem.IAC>>>> => {
        return client.get(`/referenceBook/iac/getPersonalDocuments`, {
          cache: true,
        });
      },
    },
    getPermitTypes: (): Promise<DataResponse<DictionaryPermitType[]>> => {
      return client.get(`/referenceBook/erz/getPermitsTypes`);
    },
  },
};

export default api;
