import React, { useContext, useEffect, useMemo, useState } from 'react';

import { api } from '../../../../../../components/Api';
import Loader from '../../../../../../components/Loader';
import ServiceAvailabilityContext, {
  Reason,
} from '../../../../../../contexts/ServiceAvailability/ServiceAvailabilityContext';
import { currentServiceCode } from '../PermissionRenewal';
import { formatDate } from '../../../../../../helpers/dates';
import { FormStepButtonId } from '../../../../../../components/Forms/Buttons';
import { NullValueGuard } from '../../../../../../guards/NullValueGuard';
import { parseFormDataValue } from '../../../../../../helpers/parseFormDataValue';
import { AccordionData } from '../../../../../../components/Accordion/Accordion';
import { StepsNavigationName } from '../../../../../../config/services';
import { PayloadGroupMockup } from '../../../../../../components/Forms/constants';
import { fieldNamesForServerFactory } from '../../../../../../helpers/fieldNamesForServerFactory';
import Select, { SelectOption } from '../../../../../../components/Forms/Select';
import { DictionaryPermitType, ERZPermit, Weapon } from '../../../../../../components/Api/types';
import { Informer } from '../../../WeaponRegistered/Informer';
import SadSmileIcon from '../../../../../../assets/images/sad_smile.svg';
import { PermitListWithWeapons } from '../../../../components/PermitListWithWeapons/PermitListWithWeapons';

export enum PermitDataFieldName {
  permitType = 'permitType',
}
export interface PermitFormDataProps {
  [PermitDataFieldName.permitType]?: string;
}

const payloadGroupName = 'permit';

export const permitDataPayloadGroupMockup: PayloadGroupMockup<typeof payloadGroupName> = {
  [payloadGroupName]: {
    id: null,
    type: null,
  },
};

export const permitDataFieldNamesForServer = fieldNamesForServerFactory({
  groupName: payloadGroupName,
  fields: [
    {
      localFieldName: PermitDataFieldName.permitType,
      serverFieldName: 'permit',
    },
  ],
});
interface Props {
  formData: PermitFormDataProps;
  handleInputChange: (name: string, value: unknown) => void;
  permitTypesList: DictionaryPermitType[];
}

interface PreviewProps {
  formData: PermitFormDataProps;
  permitTypeLabel: string;
}

const defaultSelectedExtractType: SelectOption = {
  id: '',
  label: 'Всі дозволи',
};

const Step2: React.FC<Props> = ({ formData, handleInputChange, permitTypesList }) => {
  const { disableCause } = useContext(ServiceAvailabilityContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [extractTypeEntities, setExtractTypeEntities] = useState<SelectOption[]>([]);
  const [selectedExtractTypeEntity, setSelectedExtractTypeEntity] = useState<SelectOption>(defaultSelectedExtractType);
  const [permitList, setPermitList] = useState<ERZPermit[]>([]);
  const [selectedPermitId, setSelectedPermitId] = useState<string | null>(
    formData.permitType ? JSON.parse(formData.permitType).id : null,
  );
  const [userWeapons, setUserWeapons] = useState<Weapon[]>([]);

  const filteredPermitList = useMemo<ERZPermit[]>(() => {
    return selectedExtractTypeEntity.id
      ? permitList.filter((permit) => permit.permitType === selectedExtractTypeEntity.id)
      : permitList;
  }, [selectedExtractTypeEntity, permitList]);

  const handleWeaponSelect = (id: string): void => {
    setSelectedPermitId(id);
    handleInputChange(
      'permitType',
      permitList.find((x) => {
        return x.id === id;
      }),
    );
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([api.services.servicePermits(currentServiceCode), api.getWeaponsData()])
      .then(([{ data: userPermits = [] }, { data: responseWeapons }]) => {
        const availablePermits = userPermits;

        if (!availablePermits.length) {
          disableCause({ reason: Reason.NOT_ENOUGH_DATA });
        } else {
          setExtractTypeEntities([
            defaultSelectedExtractType,
            ...permitTypesList
              .filter((permitType) => availablePermits.some((permit) => permit.permitType === permitType.label))
              .map((permitType) => ({
                id: permitType.label,
                label: permitType.name,
              })),
          ]);
          setPermitList(availablePermits);
          setUserWeapons([...responseWeapons.criminalItems, ...responseWeapons.ipsItems]);
        }
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableCause]);

  useEffect(() => {
    const nextBtn = document.querySelector(`button#${FormStepButtonId.next}`) as HTMLButtonElement;
    if (!selectedPermitId) {
      nextBtn.disabled = true;
    } else {
      nextBtn.disabled = false;
    }
  }, [selectedPermitId]);

  return (
    <>
      <div
        id="step-description"
        className="block-title block-title_lg block-title_padding-bottom-md-extra block-title_bg-light"
      >
        {StepsNavigationName.permissionData}
        <div className="input-select-top">
          <div className="input-select-top__label">Фільтр</div>
          <div className="input-select-top__input-container">
            <Select
              name="filterPermitType"
              className="value-container-padding-0"
              options={extractTypeEntities}
              defaultValue={selectedExtractTypeEntity}
              placeholder="Не вибрано"
              onChange={setSelectedExtractTypeEntity}
            />
          </div>
        </div>
      </div>

      <div className="permit-container">
        {!isLoading &&
          !!filteredPermitList.length &&
          filteredPermitList.map((permit) => (
            <div key={permit.id} className="permit-list">
              <div className="permit-item_input">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="radio" htmlFor={permit.id.toString()}>
                  <input
                    type="radio"
                    className="radio__input"
                    id={permit.id.toString()}
                    name="permitType"
                    checked={selectedPermitId === permit.id}
                    onChange={(): void => handleWeaponSelect(permit.id)}
                  />
                  <span className="radio__checkmark"></span>
                  <span className="radio__label"></span>
                </label>
              </div>

              <PermitListWithWeapons
                erzExtracts={[permit]}
                userWeapons={userWeapons}
                getExtractTypeName={(): string =>
                  extractTypeEntities.find((extractTypeEntity) => {
                    return extractTypeEntity.id === permit.permitType;
                  })?.label || null
                }
                fieldNames={{
                  permitType: 'Тип дозволу',
                  num: 'Номер дозволу',
                  issuedAt: 'Дата видачі',
                  expiredAt: 'Термін дії',
                }}
              />
            </div>
          ))}
        {!filteredPermitList.length && !isLoading && <Informer icon={SadSmileIcon} text="Немає доступних дозволів" />}
        {isLoading && (
          <div style={{ position: 'relative', width: '100%', height: '300px' }}>
            <Loader centered />
          </div>
        )}
      </div>
    </>
  );
};

export const previewPermitData = ({ formData, permitTypeLabel }: PreviewProps): AccordionData => {
  return {
    title: StepsNavigationName.permissionData,
    content: (
      <div className="custom-columns custom-columns_grey-bg custom-columns_default-gap">
        <div className="custom-columns__row custom-columns__row_padding-tiny custom-columns__row_one-col">
          <div className="custom-columns__col">
            <div className="custom-columns__title">Тип дозволу</div>
            <div className="custom-columns__info">{permitTypeLabel}</div>
          </div>
        </div>
        <div className="custom-columns__row custom-columns__row_padding-tiny">
          <div className="custom-columns__col">
            <div className="custom-columns__title">Номер дозволу</div>
            <div className="custom-columns__info">
              <NullValueGuard>{parseFormDataValue(formData[PermitDataFieldName.permitType])?.num}</NullValueGuard>
            </div>
          </div>
          <div className="custom-columns__col">
            <div className="custom-columns__title">Дата видачі</div>
            <div className="custom-columns__info">
              <NullValueGuard>
                {formatDate(parseFormDataValue(formData[PermitDataFieldName.permitType])?.issuedAt)}
              </NullValueGuard>
            </div>
          </div>
          <div className="custom-columns__col">
            <div className="custom-columns__title">Термін дії</div>
            <div className="custom-columns__info">
              <NullValueGuard>
                {formatDate(parseFormDataValue(formData[PermitDataFieldName.permitType])?.expiredAt)}
              </NullValueGuard>
            </div>
          </div>
        </div>
      </div>
    ),
  };
};
export default Step2;
