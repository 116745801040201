import { ServiceCodes } from '../components/Api/types';
import { ROUTES_BY_SERVICE_CODE } from '../router/config';

export type Slide = {
  type: 'external' | 'service';
  title: string;
  link: string;
  img: string;
};

export const slides: Slide[] = [
  {
    title: 'Чат-бот Єдиного реєстру зброї: поширені запитання',
    link: 'https://t.me/arms_mvs_bot',
    type: 'external',
    img: '/slider/bullets.png',
  },
  {
    title: 'Отримати дозвіл на придбання зброї',
    link: ROUTES_BY_SERVICE_CODE[ServiceCodes.PURCHASE],
    type: 'service',
    img: '/slider/phone_in_hands.png',
  },
  {
    title: 'Перевірка витягу з Єдиного реєстру осіб, зниклих безвісті за особливих обставин',
    link: 'https://wanted.mvs.gov.ua/rmpsearch/',
    type: 'external',
    img: '/slider/shoes_outside.png',
  },
  {
    title: 'Отримати витяг про несудимість',
    link: 'https://vytiah.mvs.gov.ua/app/landing',
    type: 'external',
    img: '/slider/judge.png',
  },
];
