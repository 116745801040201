import React, { MouseEvent } from 'react';
import classNames from 'classnames';

import { govUaToastConfig } from '../../config/govUaToast';

interface GovUaToastButtonProps {
  govUaToastHandler: (e: MouseEvent<HTMLElement>) => void;
  color?: 'black' | 'white';
}

interface GovUaToastProps extends GovUaToastButtonProps {
  variant: 'small' | 'large';
  isGovUaToastShown: boolean;
  withToastButton?: boolean;
}

export const GovUaToastButton = React.memo<GovUaToastButtonProps>(({ govUaToastHandler, color = 'white' }) => (
  <div>
    <button className="toast__btn gov-ua-logo header-banner_gov-ua-logo" onClick={govUaToastHandler}>
      <span
        className={classNames('gov-ua-logo__title', {
          'gov-ua-logo__title--black': color === 'black',
        })}
      >
        GOV.UA
      </span>
      <span
        className={classNames('gov-ua-logo__subtitle', {
          'gov-ua-logo__subtitle--black': color === 'black',
        })}
      >
        Державні сайти України
      </span>
    </button>
  </div>
));

export const GovUaToastContent = React.memo<GovUaToastButtonProps>(({ govUaToastHandler }) => (
  <>
    <div className="toast__header">
      <button aria-label="Close" className="toast__close-btn close-button" onClick={govUaToastHandler}>
        <div className="close-button__span" />
        <div className="close-button__span close-button__span_rotate" />
      </button>
      <div className="block-title block-title_margin-bottom-lg">Державні сайти України</div>
    </div>
    <div className="default-links">
      {govUaToastConfig.map((configValue) => (
        <a
          className="default-links__item"
          href={configValue.ref}
          target="_blank"
          rel="noreferrer nofollow noopener"
          key={configValue.title}
        >
          {configValue.title}
        </a>
      ))}
    </div>
  </>
));

export const GovUaToast = React.memo<GovUaToastProps>(
  ({ isGovUaToastShown, govUaToastHandler, variant, withToastButton = true }) => (
    <div className="toast">
      {withToastButton && <GovUaToastButton govUaToastHandler={govUaToastHandler} />}
      <div className={classNames('toast__window', `toast__window_${variant}`, { opened: isGovUaToastShown })}>
        <GovUaToastContent govUaToastHandler={govUaToastHandler} />
      </div>
    </div>
  ),
);
