import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import './ServiceMenuItem.scss';

interface Props {
  id?: number;
  classesItem?: string;
  linkPart?: string;
  slug?: string;
  title?: string;
  description?: string;
  children?: ReactNode;
}

const ServiceMenuItem = ({ id, classesItem, linkPart, slug, title, description, children }: Props): JSX.Element => {
  const className = 'cards__item ' + classesItem;
  return (
    <NavLink to={`/services${linkPart ? '/' + linkPart : ''}/${slug}`} state={{ id }} className={className}>
      <div className="cards__title-arrow">
        <div className="block-title card__title_hover">{title}</div>
      </div>
      <div className="block-title block-title_md block-title_supportive block-title_content">
        {description}
        {children}
      </div>
    </NavLink>
  );
};

export default ServiceMenuItem;
