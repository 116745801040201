import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';

import InitialSelect from '../../Select';
import FileUpload from '../../FileUpload';
import { DocumentTypeEntity } from '../../../../helpers/reformatDocumentTypeToEntity';
import { getERZDocumentsSelect } from '../../../../modules/erz/helpers';
import { DocumentTypeCode, ResponsePaymentDetails, UserServicePaymentType } from '../../../Api/types';
import { StepsNavigationName } from '../../../../config/services';
import { PaymentDataFieldName } from '../../constants';
import Loader from '../../../Loader';
import { Button } from '../../../Button';
import ModalContext from '../../../../contexts/Modal/ModalContext';
import { api } from '../../../Api';
import { ModalName } from '../../../../contexts/Modal/modalTypes';
import AttentionIcon from '../../../../assets/images/attention_grey.svg';
import { useAsyncDataLoader } from '../../../../hooks/useAsyncDataLoader';
import AttentionRead from '../../../../assets/images/attention_red.svg';
import './Payment.scss';

export enum PaymentType {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export interface PaymentFormDataProps {
  [PaymentDataFieldName.paymentType]?: PaymentType;
  [PaymentDataFieldName.paymentDocType]?: string;
  [PaymentDataFieldName.paymentDocument]?: File[];
}

interface Props {
  formData: PaymentFormDataProps;
  isPaymentInProgress: boolean;
  handleInputChange: (name: string, value: unknown) => void;
  handleClearInput: (name: string) => void;
  currentRequestId: number;
  allowedPaymentType: UserServicePaymentType;
}

const OnlinePaymentDisabledInformer: React.FC = () => (
  <div className="payment-informer">
    <img src={AttentionRead} className="payment-informer-info-icon" alt="info-icon" />
    <div className="payment-informer-text-block">
      <div className="payment-informer-header">На жаль, послуга онлайн оплати тимчасово недоступна.</div>
      <div className="payment-informer-body">
        Наразі доступне тільки завантаження платіжного документу. Вибачте за незручності.
      </div>
    </div>
  </div>
);

interface RadioButtonsProps {
  handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface OnlineRadioButtonProps extends RadioButtonsProps {
  isPaymentTypeOnline: boolean;
  isOnlinePaymentTypeDisabled: boolean;
}

interface OfflineRadioButtonProps extends RadioButtonsProps {
  isPaymentTypeOffline: boolean;
  isOfflinePaymentTypeDisabled: boolean;
}

const OnlinePaymentRadioButton: React.FC<OnlineRadioButtonProps> = ({
  handleRadioChange,
  isOnlinePaymentTypeDisabled,
  isPaymentTypeOnline,
}) => (
  <label className="radio" htmlFor={PaymentDataFieldName.paymentType + PaymentType.ONLINE}>
    <input
      className="radio__input"
      type="radio"
      id={PaymentDataFieldName.paymentType + PaymentType.ONLINE}
      name={PaymentDataFieldName.paymentType}
      value={PaymentType.ONLINE}
      checked={isPaymentTypeOnline}
      onChange={handleRadioChange}
      disabled={isOnlinePaymentTypeDisabled}
    />
    <span className="radio__checkmark"></span>
    <span
      className={classNames('radio__label', {
        'radio-disabled': isOnlinePaymentTypeDisabled,
      })}
    >
      Здійснити оплату онлайн
    </span>
  </label>
);

const OfflinePaymentRadioButton: React.FC<OfflineRadioButtonProps> = ({
  handleRadioChange,
  isOfflinePaymentTypeDisabled,
  isPaymentTypeOffline,
}) => (
  <label className="radio" htmlFor={PaymentDataFieldName.paymentType + PaymentType.OFFLINE}>
    <input
      className="radio__input"
      type="radio"
      id={PaymentDataFieldName.paymentType + PaymentType.OFFLINE}
      name={PaymentDataFieldName.paymentType}
      value={PaymentType.OFFLINE}
      checked={isPaymentTypeOffline}
      onChange={handleRadioChange}
      disabled={isOfflinePaymentTypeDisabled}
    />
    <span className="radio__checkmark"></span>
    <span
      className={classNames('radio__label', {
        'radio-disabled': isOfflinePaymentTypeDisabled,
      })}
    >
      Я вже оплатив. Завантажити копію платіжного документу
    </span>
  </label>
);

export const Payment: React.FC<Props> = ({
  formData,
  isPaymentInProgress,
  handleInputChange,
  handleClearInput,
  currentRequestId,
  allowedPaymentType,
}) => {
  const [optionsPersonDoc, setOptionsPersonDoc] = useState<DocumentTypeEntity[]>([]);

  const paymentDocType = useMemo(() => JSON.parse(formData.paymentDocType || null), [formData.paymentDocType]);
  const isPaymentTypeOnline = useMemo<boolean>(
    () => formData[PaymentDataFieldName.paymentType] === PaymentType.ONLINE,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.paymentType],
  );
  const isPaymentTypeOffline = useMemo<boolean>(
    () => formData[PaymentDataFieldName.paymentType] === PaymentType.OFFLINE,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.paymentType],
  );

  const isOnlinePaymentTypeDisabled = useMemo<boolean>(
    () => allowedPaymentType === UserServicePaymentType.OFFLINE,
    [allowedPaymentType],
  );
  const isOfflinePaymentTypeDisabled = useMemo<boolean>(
    () => allowedPaymentType === UserServicePaymentType.ONLINE,
    [allowedPaymentType],
  );

  const {
    isLoading,
    data: { fee, ...paymentDetails },
    load: loadPaymentDetails,
  } = useAsyncDataLoader(
    async () => (await api.services.getPaymentDetails(currentRequestId)).data || ({} as ResponsePaymentDetails),
    {
      defaultDataValue: {} as ResponsePaymentDetails,
    },
  );
  const { renderSpecificModal } = useContext(ModalContext);

  useEffect(() => {
    !isPaymentInProgress && loadPaymentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentInProgress]);

  useEffect(() => {
    let currentPaymentType: PaymentType;
    switch (true) {
      case isOfflinePaymentTypeDisabled:
        currentPaymentType = PaymentType.ONLINE;
        break;
      case isOnlinePaymentTypeDisabled:
        currentPaymentType = PaymentType.OFFLINE;
        break;
      default:
        currentPaymentType = PaymentType.ONLINE;
        break;
    }
    handleInputChange(PaymentDataFieldName.paymentType, currentPaymentType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleBFCache = (event: PageTransitionEvent): void => {
      if (event.persisted) {
        console.log('This page was restored from the bfcache.');
        location.reload();
      } else {
        console.log('This page was loaded normally.');
      }
    };

    window.addEventListener('pageshow', handleBFCache);
  }, []);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (!formData.paymentDocType) {
        const [document] = await getERZDocumentsSelect({
          allowedDocumentTypeCodes: [DocumentTypeCode.PAYMENT_REFERENCE],
          setOptions: setOptionsPersonDoc,
        });
        handleInputChange(PaymentDataFieldName.paymentDocType, document);
      }
    };

    isPaymentTypeOffline && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentTypeOffline, formData.paymentDocType]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    handleInputChange(name, value);

    [PaymentDataFieldName.paymentDocType, PaymentDataFieldName.paymentDocument].forEach(handleClearInput);
  };

  if (isLoading) {
    return <Loader centered />;
  }

  return (
    <>
      <div
        id="step-description"
        className="block-title block-title_lg block-title_padding-bottom-md-extra block-title_bg-light"
      >
        {StepsNavigationName.payment}
      </div>

      {isPaymentInProgress ? (
        <div className="form__content form__content_height-sm">
          <div className="payment-in-progress">
            <Loader />
            <h2>
              Зачекайте, будь ласка, ваша заява в процесі оплати.
              <br />
              Час очікування: до 2 хв.
            </h2>
          </div>
        </div>
      ) : (
        <div className="form__content">
          <div className="form__row-box">
            <div className="form-input form-input_margin-bottom-none">
              <fieldset
                id={PaymentDataFieldName.paymentType}
                className="form-input__radiobuttons form-input__radiobuttons--col-direction"
              >
                {isOnlinePaymentTypeDisabled ? (
                  <>
                    <OfflinePaymentRadioButton
                      handleRadioChange={handleRadioChange}
                      isOfflinePaymentTypeDisabled={isOfflinePaymentTypeDisabled}
                      isPaymentTypeOffline={isPaymentTypeOffline}
                    />
                    <OnlinePaymentRadioButton
                      handleRadioChange={handleRadioChange}
                      isOnlinePaymentTypeDisabled={isOnlinePaymentTypeDisabled}
                      isPaymentTypeOnline={isPaymentTypeOnline}
                    />
                  </>
                ) : (
                  <>
                    <OnlinePaymentRadioButton
                      handleRadioChange={handleRadioChange}
                      isOnlinePaymentTypeDisabled={isOnlinePaymentTypeDisabled}
                      isPaymentTypeOnline={isPaymentTypeOnline}
                    />
                    <OfflinePaymentRadioButton
                      handleRadioChange={handleRadioChange}
                      isOfflinePaymentTypeDisabled={isOfflinePaymentTypeDisabled}
                      isPaymentTypeOffline={isPaymentTypeOffline}
                    />
                  </>
                )}
              </fieldset>
            </div>
            {isOnlinePaymentTypeDisabled && <OnlinePaymentDisabledInformer />}
          </div>

          <div
            className={classNames('form__row-box', {
              'form__row-box__no-border': isPaymentTypeOnline || !formData[PaymentDataFieldName.paymentType],
            })}
          >
            <div className="form-input form-input_margin-bottom-none">
              <div className="form-input__label">Загальна вартість послуги</div>
              <div className="form-input__label--large">
                {isPaymentTypeOffline ? paymentDetails?.price : +fee + +paymentDetails?.price} грн.
                <Tooltip
                  title={
                    <span>
                      Вартість послуги - {paymentDetails?.price} грн.
                      <br />
                      {isPaymentTypeOffline
                        ? 'Комісія залежить від обраного банку'
                        : `Комісія платіжної системи - ${fee} грн.`}
                    </span>
                  }
                  placement="right"
                  color="white"
                  overlayInnerStyle={{
                    color: 'black',
                  }}
                >
                  <div className="image-container">
                    <img src={AttentionIcon} alt="icon" />
                  </div>
                </Tooltip>
              </div>
            </div>

            {Object.values(paymentDetails).every((paymentDetail) => !!paymentDetail) && (
              <Button
                outline
                onClick={(): void => {
                  renderSpecificModal({
                    modalName: ModalName.PaymentDetailsAlert,
                    modalProps: {
                      currentRequestId,
                      detailsList: paymentDetails,
                    },
                  });
                }}
                className="payment-button"
              >
                Переглянути реквізити
              </Button>
            )}
          </div>

          {isPaymentTypeOffline && (
            <>
              <div className="form__row-box">
                <div className="text-with-icon">
                  <div className="text-with-icon__title text-with-icon__title_md text-with-icon__title_attention-supportive">
                    Платіжний документ (платіжне доручення, квитанція) з відміткою банку, відділення поштового зв’язку
                    або коду проведеної операції про внесення коштів за надання відповідної платної послуги
                  </div>
                </div>
              </div>

              <div className="form-input form-input_margin-bottom-none required no-group">
                <div className="form-input__label">Тип документа</div>
                <div className="input-select js-input-select">
                  <InitialSelect
                    name={PaymentDataFieldName.paymentDocType}
                    options={optionsPersonDoc}
                    placeholder="Не вибрано"
                    defaultValue={paymentDocType}
                    onChange={(newValue, name): void => handleInputChange(name, newValue)}
                    onMenuOpen={(): void => {
                      if (!optionsPersonDoc.length) {
                        getERZDocumentsSelect({
                          allowedDocumentTypeCodes: [DocumentTypeCode.PAYMENT_REFERENCE],
                          setOptions: setOptionsPersonDoc,
                        });
                      }
                    }}
                  />
                </div>
              </div>

              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Платіжний документ</div>
                <FileUpload
                  quantityFiles={1}
                  inputName="paymentDocument"
                  inputTitle="Додати сканкопію документа"
                  value={formData[PaymentDataFieldName.paymentDocument]}
                  handleInputChange={handleInputChange}
                  handleClearInput={handleClearInput}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
