import './ChannelTag.scss';

type Props<T extends string> = {
  channel: T;
  channels: Record<T, string>;
};

export function ChannelTag<T extends string>({ channel, channels }: Props<T>): JSX.Element {
  return <div className="channel-tag">{channels[channel]}</div>;
}
