import { Tooltip } from 'antd';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import './DeafSupport.scss';

import colors from '../../containers/StyleSettings/themes/default/settings/app.colors.style.scss';
import variables from '../../containers/StyleSettings/settings/app.settings.style.scss';
import AuthContext from '../../contexts/Auth/AuthContext';

interface Props {
  className?: string;
}

export const DeafSupport = React.memo<Props>(function ({ className }) {
  const frameRef = useRef(null);
  const buttonRef = useRef(null);
  const { storedLinks } = useContext(AuthContext);
  const [isVideoOpen, setIsVedioOpen] = useState<boolean>(false);

  const toggleVideo = useCallback(() => {
    const [screenSmMin] = variables['screen-sm-min'].split('px');

    if (window.innerWidth >= Number(screenSmMin || '0')) {
      setIsVedioOpen((prevValue) => !prevValue);
    } else {
      window.location.href = storedLinks.videoInstruction.deafSupport;
    }
  }, [storedLinks.videoInstruction.deafSupport]);

  useEffect(() => {
    function onClickOutside(e: MouseEvent): void {
      if (e.target === buttonRef.current || e.target === buttonRef.current.children[0]) return;
      if (isVideoOpen && e.target !== frameRef.current) setIsVedioOpen(false);
    }

    document.addEventListener('click', onClickOutside, { capture: false });

    return (): void => document.removeEventListener('click', onClickOutside);
  }, [isVideoOpen]);

  if (!storedLinks.videoInstruction.deafSupport) {
    return <React.Fragment />;
  }

  return (
    <>
      <Tooltip
        overlayClassName="deaf-support-tooltip"
        overlayInnerStyle={{
          color: '#000',
          backgroundColor: colors.accent,
        }}
        placement={isVideoOpen ? 'leftBottom' : 'topLeft'}
        title={
          <div className="deaf-support-tooltip__content">
            <div>Міністерство внутрішніх справ України</div>
            <div>Інструкція жестовою мовою</div>
          </div>
        }
      >
        <button
          ref={buttonRef}
          className={classNames('deaf-support', { [className]: className })}
          onClick={toggleVideo}
        >
          <img className="deaf-support__icon" src="/images/deaf_support.svg" alt="deaf support" />
        </button>
      </Tooltip>

      {isVideoOpen && (
        <iframe
          ref={frameRef}
          className="deaf-support__iframe"
          src={storedLinks.videoInstruction.deafSupport}
          title="Як замовити онлайн-послугу на сайті МВС? [жестова мова]"
          allow="autoplay"
          allowFullScreen
        />
      )}
    </>
  );
});
