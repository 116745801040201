import { useCallback, useMemo, useState } from 'react';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { api } from '../components/Api';
import { FormDataType, PayloadCreateDraft, ServiceCodes, UserRequest, UserRequestDraft } from '../components/Api/types';
import { base64ToFile, fileToBase64 } from '../helpers/fileHelper';
import { AdditionalDocumentFieldName, attachmentMapping } from '../components/Forms/constants';
import { config } from '../config';
import { ROUTES } from '../router/config';
import { FormDocument } from '../components/Forms/DocumentsData';

export type UseFormDraftHookReturn = {
  createDraft: (props: CreateDraftProps) => Promise<number>;
  saveDraft: (props: SaveDraftProps) => Promise<void>;
  loadDraft: (serviceCode: ServiceCodes) => Promise<UserRequest>;
  deleteDraft: (draftId: number) => Promise<void>;
  prefillFormData: (props: PrefillFormDataProps) => Promise<{
    parsedStateForm: FormDataType;
    meta: UserRequestDraft['meta'];
  }>;
};

interface CreateDraftProps extends PayloadCreateDraft {
  totalSteps: number;
}

interface SaveDraftProps {
  draftId: number;
  draftCurrentStep: UserRequestDraft['meta']['current_step'];
  meta: Pick<UserRequestDraft['meta'], 'current_step' | 'total_steps'>;
  stateForm: FormDataType;
}

interface PrefillFormDataProps {
  draftId: number;
  handleInputChange: (name: string, value: unknown) => void;
}

export const useFormDraft = (): UseFormDraftHookReturn => {
  const [draftStateForm, setDraftStateForm] = useState<FormDataType>(null);
  const navigate = useNavigate();

  const attachmentFieldNames = useMemo(() => {
    return Object.values(attachmentMapping).map((i) => i.encoded_contents);
  }, []);
  const attachmentGroupNames = useMemo(() => {
    return Object.keys(attachmentMapping);
  }, []);

  const calculateLastFilledStep = useCallback(
    (draftCurrentStep: number, currentStep: number): number =>
      currentStep + 1 > draftCurrentStep ? currentStep + 1 : draftCurrentStep,
    [],
  );

  const createDraft = useCallback<UseFormDraftHookReturn['createDraft']>(async ({ totalSteps, ...serviceDetails }) => {
    const defaultMetaData = {
      meta: {
        current_step: 2,
        last_step_filled: 2,
        total_steps: totalSteps,
        data: {
          version: config.VERSION,
          info: '',
        },
      },
    };

    const { requestId } = await api.services.draft.create(serviceDetails, defaultMetaData);

    return requestId;
  }, []);

  const saveDraft = useCallback(
    async ({ draftId, draftCurrentStep, meta, stateForm }: SaveDraftProps) => {
      const stateFormCopy = cloneDeep(stateForm);
      const lastStepFilled = calculateLastFilledStep(draftCurrentStep, meta.current_step);

      for (const fieldName in stateFormCopy) {
        if (
          fieldName === 'declaredAddressNewDocuments' ||
          fieldName === AdditionalDocumentFieldName.additionalDocuments
        ) {
          await Promise.all(
            (stateFormCopy[fieldName] as FormDocument[]).map<Promise<FormDocument>>(async (document: FormDocument) => ({
              ...document,
              attachments: (await fileToBase64(document.attachments || [])) as unknown as File[],
            })),
          ).then((documents) => {
            stateFormCopy[fieldName] = documents;
          });
        } else {
          if (attachmentFieldNames.includes(fieldName)) {
            stateFormCopy[fieldName] = await fileToBase64((stateFormCopy[fieldName] || []) as File[]);
          }
        }
      }

      await api.services.draft.update({
        draftId,
        serviceDraft: {
          stateForm: stateFormCopy,
          meta: {
            current_step: isEqual(stateForm, draftStateForm) ? lastStepFilled : meta.current_step + 1,
            last_step_filled: lastStepFilled,
            total_steps: meta.total_steps,
            data: {
              version: config.VERSION,
              info: '',
            },
          },
        },
      });
    },
    [attachmentFieldNames, draftStateForm, calculateLastFilledStep],
  );
  const loadDraft = useCallback(async (serviceCode: ServiceCodes) => {
    const { data } = await api.services.draft.get(serviceCode);
    const [draft] = data ?? [];

    return draft;
  }, []);

  const deleteDraft = useCallback(async (draftId: number) => {
    return api.services.draft.delete(draftId);
  }, []);

  const checkValidDraftResponseData = useCallback((serviceDraftDetails: UserRequestDraft) => {
    // serviceDraftDetails is empty if request sent not to current user`s draft or something went wrong
    if (!serviceDraftDetails || isEmpty(serviceDraftDetails)) {
      console.warn('can not get the draft');
      navigate(ROUTES.USER.REQUESTS.DRAFT_REQUESTS);
    }
  }, []);

  const prefillFormData = useCallback(
    async ({ draftId, handleInputChange }: PrefillFormDataProps) => {
      return api.services.draft.getDetails(draftId).then(async ({ data: serviceDraftDetails }) => {
        checkValidDraftResponseData(serviceDraftDetails);

        const parsedStateForm = JSON.parse((serviceDraftDetails.stateForm as string) || '{}') as FormDataType;

        for (const fieldName in parsedStateForm) {
          if (
            fieldName === 'declaredAddressNewDocuments' ||
            fieldName === AdditionalDocumentFieldName.additionalDocuments
          ) {
            await Promise.all(
              (parsedStateForm[fieldName] as unknown as FormDocument[]).map<Promise<FormDocument>>(
                async (documentData) => ({
                  ...documentData,
                  attachments: await Promise.all(
                    documentData.attachments.map((base64) => base64ToFile(base64 as unknown as string)),
                  ),
                }),
              ),
            ).then((documents) => {
              parsedStateForm[fieldName] = documents;
            });
          } else if (attachmentFieldNames.includes(fieldName)) {
            await Promise.all(
              (parsedStateForm[fieldName] as string[]).map((file) => {
                return base64ToFile(file);
              }),
            ).then((files) => {
              parsedStateForm[fieldName] = files;
            });
          }
        }

        // Needed to fill "noGroupInput" array in StepInitial
        // which is used for filling "attachments" array in the request payload
        attachmentGroupNames.forEach((attachmnetGroupName) => {
          handleInputChange(attachmnetGroupName, parsedStateForm[attachmnetGroupName]);
        });

        setDraftStateForm(parsedStateForm);

        return {
          parsedStateForm,
          meta: serviceDraftDetails.meta,
        };
      });
    },
    [attachmentFieldNames, attachmentGroupNames, checkValidDraftResponseData],
  );

  return {
    createDraft,
    saveDraft,
    loadDraft,
    deleteDraft,
    prefillFormData,
  };
};
