import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './VerticalNavTransform.scss';
import { Menu, MenuProps } from 'antd';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import classnames from 'classnames';

import { Button } from '../../Button';
import AuthContext, { AuthContextInterface } from '../../../contexts/Auth/AuthContext';

export type NavigationItem = {
  key: string;
  label: string;
  slug?: string;
  children?: NavigationItem[];
  isShown?: (profile: AuthContextInterface) => boolean;
};

export interface VerticalNavTransformProps {
  navigationData: NavigationItem[];
}

const createMenuItems = (
  navigationData: NavigationItem[],
  navigate: NavigateFunction,
  auth: AuthContextInterface,
): MenuProps['items'] =>
  navigationData
    .filter((item) => !item.isShown || item.isShown(auth))
    .map((item) => ({
      key: item.key,
      label: item.label,
      children: item.children && createMenuItems(item.children, navigate, auth),
      onClick: item.slug && ((): void => navigate(item.slug)),
    }));

// TODO: refactor?
const findSelectedKey = (
  navigationData: NavigationItem[],
  pathName: string,
  parentKeys: string[] = [],
  initialValue: [string[], string[]] = [[], []],
): [string[], string[]] =>
  navigationData.reduce((result, navigationItem) => {
    if (navigationItem.children) {
      return findSelectedKey(navigationItem.children, pathName, [...parentKeys, navigationItem.key], result);
    }
    return pathName.includes(navigationItem.slug) ? [[navigationItem.key], parentKeys] : result;
  }, initialValue);

export const VerticalNavTransform = React.memo<VerticalNavTransformProps>(({ navigationData }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const menuButtonToggler = useRef(null);
  const auth = useContext(AuthContext);

  const menuItems = createMenuItems(navigationData, navigate, auth);
  const [selectedKey, openedDropdowns] = findSelectedKey(navigationData, pathname);

  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);

  const handleClickMenu = useCallback(
    (value = !isMenuActive) => {
      setIsMenuActive(value);
    },
    [isMenuActive],
  );

  useEffect(() => {
    function handleClickOutside(event: Event): void {
      if (menuButtonToggler.current && !menuButtonToggler.current.contains(event.target)) {
        handleClickMenu(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuButtonToggler, handleClickMenu]);

  return (
    <div>
      <div className="nav-container" ref={menuButtonToggler}>
        <Button
          className={classnames('nav-button', { 'nav-button__active': isMenuActive })}
          onClick={(): void => handleClickMenu()}
        >
          Меню
          <img
            className={classnames('nav-button-arrow', {
              'nav-button-arrow__transform': isMenuActive,
            })}
            alt="arrow"
          />
        </Button>

        <Menu
          defaultOpenKeys={openedDropdowns}
          selectedKeys={selectedKey}
          mode="inline"
          items={menuItems}
          className={classnames('nav', {
            nav__overlay: isMenuActive,
          })}
          onClick={(): void => handleClickMenu()}
        />
      </div>
      {isMenuActive && <div className="overlay" />}
    </div>
  );
});
