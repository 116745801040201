import React, { FormEvent, useContext, useReducer } from 'react';

import { ModalName } from '../../contexts/Modal/modalTypes';
import { handleAlertMessage } from '../../components/Layout/Alerts/LayoutAlertMessage';
import AuthContext from '../../contexts/Auth/AuthContext';
import ModalContext from '../../contexts/Modal/ModalContext';
import { SingInAfterHook } from './types';
import { useTipUserList } from './useTipUserList';

enum LOGIN {
  USER_INPUT = 'USER_INPUT',
  INPUT_BLUR = 'INPUT_BLUR',
}

interface InputState {
  value: string;
  isValid: boolean;
}

interface Action {
  type: LOGIN;
  payload?: string;
}

const emailReducer = (state: InputState, action: Action): InputState => {
  if (action.type === LOGIN.USER_INPUT) {
    return {
      value: action.payload,
      isValid: action.payload.includes('@'),
    };
  }
  if (action.type === LOGIN.INPUT_BLUR) {
    return {
      value: state.value,
      isValid: state.value.includes('@'),
    };
  }
  return { value: '', isValid: false };
};

const passwordReducer = (state: InputState, action: Action): InputState => {
  if (action.type === LOGIN.USER_INPUT) {
    return {
      value: action.payload,
      isValid: action.payload.trim().length > 6,
    };
  }
  if (action.type === LOGIN.INPUT_BLUR) {
    return {
      value: state.value,
      isValid: state.value.trim().length > 6,
    };
  }
  return { value: '', isValid: false };
};

type Props = SingInAfterHook;

export const BasicProvider: React.FC<Props> = ({ handleSignIn }) => {
  const authContext = useContext(AuthContext);
  const modalContext = useContext(ModalContext);
  const users = useTipUserList();

  const [emailState, dispatchEmail] = useReducer(emailReducer, {
    value: '',
    isValid: null,
  });
  const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
    value: process.env.REACT_APP_DEFAULT_PASSWORD || '',
    isValid: null,
  });

  const emailChangeHandler = (event: FormEvent<HTMLInputElement>): void => {
    dispatchEmail({
      type: LOGIN.USER_INPUT,
      payload: event.currentTarget.value,
    });
  };

  const passwordChangeHandler = (event: FormEvent<HTMLInputElement>): void => {
    dispatchPassword({
      type: LOGIN.USER_INPUT,
      payload: event.currentTarget.value,
    });
  };

  const validateEmailHandler = (): void => {
    dispatchEmail({ type: LOGIN.INPUT_BLUR });
  };

  const validatePasswordHandler = (): void => {
    dispatchPassword({ type: LOGIN.INPUT_BLUR });
  };

  const submitHandler = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    await authContext.loginWithPassword(passwordState.value, emailState.value);

    if (authContext.userData !== null && !authContext.userData.date_of_birth) {
      modalContext.renderSpecificModal({ modalName: ModalName.BirthdayAlert });
    }

    handleAlertMessage('show');
    handleSignIn();
  };

  return (
    <form onSubmit={submitHandler} id="login-form" className="form">
      <input type="hidden" name="_token" value="" />
      <div className={`form-input ${emailState.isValid === false ? 'error' : ''}`}>
        <label className="form-input__label" htmlFor="login">
          E-Mail або ІНН
        </label>
        <input
          id="login"
          className="form-input__input"
          name="login"
          required
          type="email"
          value={emailState.value}
          onChange={emailChangeHandler}
          onBlur={validateEmailHandler}
          list="users"
        />
        <datalist id="users">{users?.map((email) => <option value={email} key={email} />)}</datalist>
      </div>
      <div className={`form-input ${passwordState.isValid === false ? 'error' : ''}`}>
        <label className="form-input__label" htmlFor="password">
          Пароль
        </label>
        <input
          id="password"
          type="password"
          className="form-input__input"
          name="password"
          required
          value={passwordState.value}
          onChange={passwordChangeHandler}
          onBlur={validatePasswordHandler}
        />
      </div>
      <div className="form-input-checkbox">
        <input className="form-input-checkbox__input" type="checkbox" id="checkbox" name="remember" />
        <label className="form-input-checkbox__label" htmlFor="checkbox">
          Зберегти
        </label>
      </div>
      <button type="submit" className="button button_margin-auto button_margin-top-md">
        Вхід
      </button>
    </form>
  );
};
