import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ServiceType, UserServiceExternal, UserService } from '../Api/types';
import { InternalServiceTile } from './InternalServiceTile';
import { Button } from '../Button';
import './ServicesHomePageList.scss';
import { ExternalServiceTile } from './ExternalServiceTile';

interface BaseProps {
  header: string;
  maxVisibleServices?: 1 | 2 | 3 | 4;
  redirectLink: string;
}

interface ServiceProps extends BaseProps {
  servicesType: ServiceType.INTERNAL;
  services: UserService[];
}

interface ExternalServiceProps extends BaseProps {
  servicesType: ServiceType.EXTERNAL;
  services: UserServiceExternal[];
}

type Props = ServiceProps | ExternalServiceProps;

export const ServicesHomePageList: React.FC<Props> = ({
  header,
  maxVisibleServices = 4,
  services,
  redirectLink,
  servicesType,
}) => {
  const navigate = useNavigate();
  const renderServices = useCallback(() => {
    const servicesToView = services.slice(0, maxVisibleServices);

    return servicesType === ServiceType.EXTERNAL
      ? servicesToView.map((service) => (
          <ExternalServiceTile
            key={service.name}
            className="home-services-list__item"
            service={service as UserServiceExternal}
          />
        ))
      : servicesToView.map((service) => (
          <InternalServiceTile
            key={service.name}
            withAuthBanner
            className="home-services-list__item"
            service={service as UserService}
          />
        ));
  }, [maxVisibleServices, services, servicesType]);

  return (
    <div className="container">
      <div className="home-services-list">
        <div className="home-services-list__header">{header}</div>
        <div className="home-services-list__container">
          {services && renderServices()}
          <div className="row">
            <Button onClick={(): void => navigate(redirectLink)} className="all-services-button">
              Всі послуги
              <img src="/images/arrow_forward_white.svg" alt="arrow" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
