import React, { createContext, useState, ReactNode, useMemo, useCallback } from 'react';

import {
  CreateSocialProtectAppealAlertRender,
  DeleteServiceDraftAlertRender,
  DocumentMissingAlertRender,
  ExternalServiceDetailsAlertRender,
  LeavingWhileInProgressAlertRender,
  ModalName,
  PayOrLeaveAlertRender,
  PaymentDetailsAlertRender,
  RenderModalProps,
  ServiceDraftExistingAlertRender,
} from './modalTypes';
import { BirthdayUpdateAlert } from '../../components/Modals/BirthdayUpdateAlert/BirthdayUpdateAler';
import { AddresCheckAlert } from '../../components/Modals/AddressCheckAlert/AddresCheckAlert';
import { DocumentDataMissingAlert } from '../../components/Modals/DocumentDataMissingAlert';
import { DocumentAddCheckAlert } from '../../components/Modals/DocumentAddCheckAlert/DocumentAddCheckAlert';
import { ServiceDraftExistingAlert } from '../../components/Modals/ServiceDraftExistingAlert';
import { DeleteServiceDraftAlert } from '../../components/Modals/DeleteServiceDraftAlert';
import { ServerErrorAlert } from '../../components/Modals/ServerErrorAlert/ServerErrorAlert';
import { PayOrLeaveAlert } from '../../components/Modals/PayOrLeaveAlert';
import { PaymentDetailsAlert } from '../../components/Modals/PaymentDetailsAlert';
import { LeavingWhileInProgressAlert } from '../../components/Modals/LeavingWhileInProgressAlert.tsx';
import { AppealAcceptedAlert } from '../../components/Modals/AppealAcceptedAlert';
import { CreateSocialProtectAppealAlert } from '../../components/Modals/CreateSocialProtectAppealAlert';
import { ExternalServiceDetailsAlert } from '../../components/Modals/ExternalServiceDetailsAlert';

export interface ModalContextInterface {
  isModalOpened: boolean;
  toggleModal: (modalStatus?: boolean) => void;
  renderSpecificModal: (props: RenderModalProps) => void;
}

const ModalContext = createContext<ModalContextInterface>({
  isModalOpened: false,
  toggleModal: () => null,
  renderSpecificModal: () => null,
});

interface Props {
  children: ReactNode;
}

export const ModalContextProvider = ({ children }: Props): JSX.Element => {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [modalNameToRender, setModalNameToRender] = useState<RenderModalProps['modalName']>(null);
  const [modalPropsToRender, setModalPropsToRender] = useState<RenderModalProps['modalProps']>(null);

  const modalToRender = useMemo(() => {
    if (!isModalOpened) return <React.Fragment />;

    switch (modalNameToRender) {
      case ModalName.BirthdayAlert:
        return <BirthdayUpdateAlert />;

      case ModalName.AddressCheckAlert:
        return <AddresCheckAlert />;

      case ModalName.DocumentMissingAlert:
        return <DocumentDataMissingAlert {...(modalPropsToRender as DocumentMissingAlertRender['modalProps'])} />;

      case ModalName.DocumentAddCheckAlert:
        return <DocumentAddCheckAlert />;

      case ModalName.ServiceDraftExistingAlert:
        return <ServiceDraftExistingAlert {...(modalPropsToRender as ServiceDraftExistingAlertRender['modalProps'])} />;

      case ModalName.DeleteServiceDraftAlert:
        return <DeleteServiceDraftAlert {...(modalPropsToRender as DeleteServiceDraftAlertRender['modalProps'])} />;

      case ModalName.ServerErrorAlert:
        return <ServerErrorAlert />;

      case ModalName.PayOrLeaveAlert:
        return <PayOrLeaveAlert {...(modalPropsToRender as PayOrLeaveAlertRender['modalProps'])} />;

      case ModalName.PaymentDetailsAlert:
        return <PaymentDetailsAlert {...(modalPropsToRender as PaymentDetailsAlertRender['modalProps'])} />;

      case ModalName.LeavingWhileInProgressAlert:
        return (
          <LeavingWhileInProgressAlert {...(modalPropsToRender as LeavingWhileInProgressAlertRender['modalProps'])} />
        );

      case ModalName.AppealAcceptedAlert:
        return <AppealAcceptedAlert />;

      case ModalName.CreateSocialProtectAppealAlert:
        return (
          <CreateSocialProtectAppealAlert
            {...(modalPropsToRender as CreateSocialProtectAppealAlertRender['modalProps'])}
          />
        );

      case ModalName.ExternalServiceDetailsAlert:
        return (
          <ExternalServiceDetailsAlert {...(modalPropsToRender as ExternalServiceDetailsAlertRender['modalProps'])} />
        );

      default:
        return <React.Fragment />;
    }
  }, [modalNameToRender, modalPropsToRender, isModalOpened]);

  const toggleModal = useCallback((value = !isModalOpened) => setIsModalOpened(value), [isModalOpened]);

  const renderSpecificModal = useCallback(({ modalName, modalProps }: RenderModalProps) => {
    setModalNameToRender(modalName);
    setModalPropsToRender(modalProps);
    setIsModalOpened(true);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        isModalOpened,
        toggleModal,
        renderSpecificModal,
      }}
    >
      {modalToRender}
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
