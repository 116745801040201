import { isDateTimeISO } from '../components/Forms/Validation';

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export const getDateWithoutTime = (date: Date | string): Date => {
  const newDate = typeof date === 'string' ? new Date(date) : date;
  newDate.setHours(0, 0, 0, 0);

  return newDate;
};

export const formatCacheDate = (date: Date): string => formatDate(date, '-', true);

export const formatDate = (
  date: Date | string | null,
  separator: '.' | '/' | '-' = '.',
  format = false,
  withTime = false,
): string | null => {
  if (!date) return null;

  const originalValue = date;

  if (!(date instanceof Date)) {
    if (isDateTimeISO(date)) {
      date = new Date(date || '');
    } else {
      return originalValue !== null ? String(originalValue) : null;
    }
  }

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const time = [hours, minutes].map((value) => (value >= 10 ? value : '0' + value)).join(':');
  const dateString = format
    ? [year, padTo2Digits(month), padTo2Digits(day)].join(separator)
    : [padTo2Digits(day), padTo2Digits(month), year].join(separator);

  return withTime ? [dateString, time].join(' ') : dateString;
};

export const formatDateStringToISO = (dateString: string): string => {
  const parts = dateString.split('.');
  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

  return formattedDate;
};

export const areDatesDifferent = (dates: Array<Date | null>): boolean => {
  const uniqueDates = new Set(dates.filter(Boolean).map((date) => date.getTime()));

  return uniqueDates.size === dates.length;
};
