import React, { useCallback, useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import { NullValueGuard } from '../../../../guards/NullValueGuard';
import { formatDate } from '../../../../helpers/dates';
import { ERZPermit, FeatureName, ServiceCodes, Weapon } from '../../../../components/Api/types';
import { LinkAsButton } from '../../../../components/Button';
import { DownloadButton } from '../../../../components/Forms/Buttons';
import ModalContext from '../../../../contexts/Modal/ModalContext';
import { FeatureContext } from '../../../../contexts/Features/FeatureContext';
import { api } from '../../../../components/Api';
import { saveFileFromBlob } from '../../../../helpers/fetch/saveFileFromBlob';
import { ModalName } from '../../../../contexts/Modal/modalTypes';
import Accordion, { AccordionData } from '../../../../components/Accordion/Accordion';
import './PermitListWithWeapons.scss';
import { ROUTES_BY_SERVICE_CODE } from '../../../../router/config';

interface Props {
  erzExtracts: ERZPermit[];
  userWeapons: Weapon[];
  getExtractTypeName: (extractLabel: ServiceCodes) => string;
  withActionButtons?: boolean;
  fieldNames?: {
    permitType: string;
    num: string;
    issuedAt: string;
    expiredAt: string;
  };
}

export const PermitListWithWeapons: React.FC<Props> = ({
  erzExtracts,
  getExtractTypeName,
  userWeapons,
  withActionButtons = false,
  fieldNames = {
    permitType: 'Витяг ЄРЗ',
    num: 'Номер дозволу',
    issuedAt: 'Дата видачі',
    expiredAt: 'Термін дії',
  },
}) => {
  const [extractIdLoading, setExtractIdLoading] = useState<string>('');
  const { renderSpecificModal } = useContext(ModalContext);
  const { isFeatureAvailable } = useContext(FeatureContext);

  const checkIsExtractable = useCallback(
    (extract: ERZPermit): boolean =>
      extract.canExtract && (!extract.expiredAt || new Date(extract.expiredAt) > new Date()),
    [],
  );

  const downloadExtract = (extract: ERZPermit): void => {
    if (!!extractIdLoading) return;

    setExtractIdLoading(extract.id);

    api.permits
      .downloadExtract(extract.id)
      .then((blob) => saveFileFromBlob('витяг_ЄРЗ.pdf', blob))
      .catch(() => {
        renderSpecificModal({
          modalName: ModalName.ServerErrorAlert,
        });
        return;
      })
      .finally(() => setExtractIdLoading(''));
  };

  const getPermitWeapons = (permit: ERZPermit): Weapon[] =>
    userWeapons.filter((weapon) => permit.objects.find((object) => object.id === weapon.id));

  return (
    <div className="permit-list-with-weapons">
      <Accordion
        bordered
        accordion={false}
        withMargin={false}
        accordionData={erzExtracts.map<AccordionData>((extract) => ({
          title: (
            <div
              key={extract.id}
              className={classNames('permit-list-with-weapons__item', {
                'padding-right': !extract.objects,
              })}
            >
              <div className="permit-list-with-weapons__item__info-block">
                <div className="permit-list-with-weapons__item__info-block__item-full-width">
                  <div className="permit-list-with-weapons__item__info-block__container">
                    <span className="permit-list-with-weapons__item__info-block__name">{fieldNames.permitType}</span>
                    <span className="permit-list-with-weapons__item__info-block__value">
                      {getExtractTypeName(extract.permitType)}
                    </span>
                  </div>
                </div>

                <div className="permit-list-with-weapons__item__info-block__item1">
                  <div className="permit-list-with-weapons__item__info-block__container">
                    <span className="permit-list-with-weapons__item__info-block__name">{fieldNames.num}</span>
                    <span className="permit-list-with-weapons__item__info-block__value">{extract.num}</span>
                  </div>
                </div>

                <div className="permit-list-with-weapons__item__info-block__item2">
                  <div className="permit-list-with-weapons__item__info-block__container">
                    <span className="permit-list-with-weapons__item__info-block__name">{fieldNames.issuedAt}</span>
                    <span className="permit-list-with-weapons__item__info-block__value">
                      <NullValueGuard>{formatDate(extract.issuedAt)}</NullValueGuard>
                    </span>
                  </div>
                </div>

                <div className="permit-list-with-weapons__item__info-block__item3">
                  <div className="permit-list-with-weapons__item__info-block__container">
                    <span className="permit-list-with-weapons__item__info-block__name">{fieldNames.expiredAt}</span>
                    <span className="permit-list-with-weapons__item__info-block__value">
                      <NullValueGuard>{formatDate(extract.expiredAt)}</NullValueGuard>
                    </span>
                  </div>
                </div>
              </div>
              {withActionButtons && (
                <div className="permit-list-with-weapons__item__documents">
                  {checkIsExtractable(extract) && (
                    <DownloadButton
                      text="Витяг"
                      onClick={(e): void => {
                        e.stopPropagation();
                        downloadExtract(extract);
                      }}
                      disabled={!!extractIdLoading}
                      downloading={extract.id === extractIdLoading}
                      className="permit-list-with-weapons__item__documents__item download-file download-file__erz-extract"
                    />
                  )}
                  {extract.permitType === ServiceCodes.TEMPORARY_RESIDENCE_RELOCATION &&
                    isFeatureAvailable(FeatureName.erzServices) &&
                    isFeatureAvailable(FeatureName.cancellationTemporaryResidenceRelocation) && (
                      <LinkAsButton
                        outline
                        bgColor="error"
                        className="permit-list-with-weapons__item__documents__item cancellation-link"
                        to={ROUTES_BY_SERVICE_CODE[ServiceCodes.CANCELLATION_TEMPORARY_RESIDENCE_RELOCATION]}
                      >
                        Завершити
                      </LinkAsButton>
                    )}
                </div>
              )}
            </div>
          ),
          content: getPermitWeapons(extract).map((weapon) => (
            <div key={weapon.id} className="permit-list-with-weapons__weapon">
              <div className="permit-list-with-weapons__weapon--item1">
                <div className="permit-list-with-weapons__item__info-block__container">
                  <span className="permit-list-with-weapons__item__info-block__name">Тип зброї</span>
                  <span className="permit-list-with-weapons__item__info-block__value">
                    <NullValueGuard>{weapon.ORVD}</NullValueGuard>
                  </span>
                </div>
              </div>
              <div className="permit-list-with-weapons__weapon--item2">
                <div className="permit-list-with-weapons__item__info-block__container">
                  <span className="permit-list-with-weapons__item__info-block__name">Модель</span>
                  <span className="permit-list-with-weapons__item__info-block__value">
                    <NullValueGuard>{weapon.ORMD}</NullValueGuard>
                  </span>
                </div>
              </div>
              <div className="permit-list-with-weapons__weapon--item3">
                <div className="permit-list-with-weapons__item__info-block__container">
                  <span className="permit-list-with-weapons__item__info-block__name">Серія та номер</span>
                  <span className="permit-list-with-weapons__item__info-block__value">
                    <NullValueGuard>{weapon.ORSR}</NullValueGuard>
                  </span>
                </div>
              </div>
              <div className="permit-list-with-weapons__weapon--item4">
                <div className="permit-list-with-weapons__item__info-block__container">
                  <span className="permit-list-with-weapons__item__info-block__name">Калібр</span>
                  <span className="permit-list-with-weapons__item__info-block__value">
                    <NullValueGuard>{weapon.ORKL}</NullValueGuard>
                  </span>
                </div>
              </div>
              {!isEmpty(weapon.parts) && (
                <>
                  <div className="permit-list-with-weapons__weapon--item1">Основні частини:</div>
                  {weapon.parts.map((part) => (
                    <>
                      <div className="permit-list-with-weapons__weapon--item1">
                        <div className="permit-list-with-weapons__item__info-block__container">
                          <span className="permit-list-with-weapons__item__info-block__name">Тип</span>
                          <span className="permit-list-with-weapons__item__info-block__value">
                            <NullValueGuard>{part.type}</NullValueGuard>
                          </span>
                        </div>
                      </div>
                      <div className="permit-list-with-weapons__weapon--item2">
                        <div className="permit-list-with-weapons__item__info-block__container">
                          <span className="permit-list-with-weapons__item__info-block__name">Модель</span>
                          <span className="permit-list-with-weapons__item__info-block__value">
                            <NullValueGuard>{part.model}</NullValueGuard>
                          </span>
                        </div>
                      </div>
                      <div className="permit-list-with-weapons__weapon--item3">
                        <div className="permit-list-with-weapons__item__info-block__container">
                          <span className="permit-list-with-weapons__item__info-block__name">Серія та номер</span>
                          <span className="permit-list-with-weapons__item__info-block__value">
                            <NullValueGuard>{part.serialNum}</NullValueGuard>
                          </span>
                        </div>
                      </div>
                      <div className="permit-list-with-weapons__weapon--item4">
                        <div className="permit-list-with-weapons__item__info-block__container">
                          <span className="permit-list-with-weapons__item__info-block__name">Калібр</span>
                          <span className="permit-list-with-weapons__item__info-block__value">
                            <NullValueGuard>{part.caliber}</NullValueGuard>
                          </span>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              )}
            </div>
          )),
          collapsible: !!extract.objects.length ? 'header' : 'disabled',
          showArrow: !!extract.objects.length,
        }))}
      />
    </div>
  );
};
