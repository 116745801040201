import { ROUTES, ROUTES_BY_SERVICE_CODE } from '../../../router/config';
import { ServiceCodes } from '../../Api/types';

export const NavTabsMapping = {
  [ROUTES.HOME]: 'Головна',
  [ROUTES.INTERNAL_SERVICES]: 'Послуги',
  [ROUTES.USER.REQUESTS.ACTIVE_REQUESTS]: 'Мої запити',
  [ROUTES_BY_SERVICE_CODE[ServiceCodes.SOCIAL_PROTECT]]: 'Соціальний захист',
  [ROUTES.EXTERNAL_SERVICES]: 'Інші державні послуги',
};
