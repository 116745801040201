/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isLettersAndDigits, isRussianLetters, isNumber } from '../components/Forms/Validation';

export const validateInputCharacterHelper = (event: { target: any; key: any; preventDefault: () => void }) => {
  const key = event.key;
  const isDigit = event.target.dataset.number === 'true' ? isNumber(key) : false;

  if ((!isLettersAndDigits(key) && !isDigit) || isRussianLetters(key)) {
    event.preventDefault();
  }
};
