import { IntegrationSystem, UserRequestStatus } from '../../../components/Api/types';
import { StatusColor } from '../../../components/Status';
import { generateMappedStatuses } from '../../../helpers/generateMappedStatuses';

export const ERZ_statuses = generateMappedStatuses(IntegrationSystem.ERZ, [
  {
    status: UserRequestStatus.finish,
    label: 'Обробка закінчена',
    color: StatusColor.YELLOW,
  },
  {
    status: UserRequestStatus.accepted,
    label: 'Зареєстровано в системі',
    color: StatusColor.BLUE,
  },
  {
    status: UserRequestStatus.registered,
    label: 'Документ зареєстровано',
    color: StatusColor.YELLOW,
  },
  {
    status: UserRequestStatus.rejected,
    label: 'Документ відхилено',
    color: StatusColor.RED,
  },
  {
    status: UserRequestStatus.denied,
    label: 'Відхилено',
    color: StatusColor.RED,
  },
  {
    status: UserRequestStatus.forwarded,
    label: 'Документ перенаправлено в інший орган НПУ ',
    color: StatusColor.YELLOW,
  },
  {
    status: UserRequestStatus.rejection,
    label: 'Рішення про відмову у наданні дозволу',
    color: StatusColor.RED,
  },
  {
    status: UserRequestStatus.permit,
    label: 'Рішення про надання дозволу',
    color: StatusColor.GREEN,
  },
  {
    status: UserRequestStatus.payment,
    label: 'Очікує на оплату',
    color: StatusColor.YELLOW,
  },
  {
    status: UserRequestStatus.retained,
    label: 'Рішення про залишення без руху',
    color: StatusColor.GREY,
  },
  {
    status: UserRequestStatus.ready_to_send,
    label: 'Готово до відправки',
    color: StatusColor.GREEN,
  },
  {
    status: UserRequestStatus.fubar,
    label: 'Прийнято рішення про неможливість надання дозволу',
    color: StatusColor.RED,
  },
]);
