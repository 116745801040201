import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { ROUTES } from '../router/config';

interface Props {
  isLoggedIn: boolean;
}

export const PrivateRouteWrapper: React.FC<Props> = ({ isLoggedIn }) => {
  const { pathname } = useLocation();

  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate
      to={ROUTES.LOGIN}
      state={{
        nextPage: pathname,
      }}
    />
  );
};
