import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';

import './ServiceInformer.scss';
import { config } from '../../../config';
import { ServiceCodes, ResponseStoredLinks } from '../../Api/types';
import AttentionIconBlack from '../../../assets/images/attention_black.svg';
import ArrowToTheRight from '../../../assets/images/arrow.svg';
import { Props, ServiceInformerBorderColor, ServiceInformerType } from './types';
import { getStoredLinks } from '../../Api/apiService';
import { ExternalLink } from '../ExternalLink/ExternalLink';
import { useAsyncEffect } from '../../../hooks/useAsyncEffect';

export const ServiceInformer = React.memo<Props>(function Informer({ type, serviceCode }) {
  const [links, setLinks] = useState<ResponseStoredLinks['serviceInformer']>(null);
  const [isContentSizeFull, setIsContentSizeFull] = useState<boolean>(true);
  const toggleContentSize = useCallback(
    (value = !isContentSizeFull) => {
      setIsContentSizeFull(value);
    },
    [isContentSizeFull],
  );

  useAsyncEffect(async () => {
    const data = await getStoredLinks();
    setLinks(data?.serviceInformer);
  }, []);

  const borderColor = useMemo<ServiceInformerBorderColor>(() => {
    switch (type) {
      case ServiceInformerType.CLUE:
      case ServiceInformerType.PERSONS_STATUS:
      case ServiceInformerType.WEAPON_DATA:
      case ServiceInformerType.INSURANCE_DATA:
      case ServiceInformerType.TECHNICAL_CONDITION_DOCUMENT_DATA:
      case ServiceInformerType.VALIDITY_PERIOD_VIOLATION:
      case ServiceInformerType.PLACE_OF_RESIDENCE:
        return ServiceInformerBorderColor.YELLOW;

      case ServiceInformerType.CONTACT_CENTER:
      case ServiceInformerType.TECHNICAL_CONDITION_DETAILS:
      case ServiceInformerType.MISSING_PERSON_DETAILS:
        return ServiceInformerBorderColor.BLUE;

      case ServiceInformerType.PAYMENT_DETAILS:
        return ServiceInformerBorderColor.RED;

      // WARN: You have to list all possible ServiceInformerType
      // Below is protection in case of forgetting the listed ServiceInformerType
      default:
        return ServiceInformerBorderColor.YELLOW;
    }
  }, [type]);

  const title = useMemo<string>(() => {
    switch (type) {
      case ServiceInformerType.CONTACT_CENTER:
        return 'Контактний центр';

      default:
        return 'Увага!';
    }
  }, [type]);

  const content = useMemo<string | JSX.Element>(() => {
    switch (type) {
      case ServiceInformerType.PAYMENT_DETAILS:
        return (
          <>
            Послуга є платною. Ви можете оплатити її онлайн в процесі замовлення, або додати документ про її сплату на
            рахунок відповідного Головного управління Національної поліції.
          </>
        );

      case ServiceInformerType.CONTACT_CENTER:
        return serviceCode === ServiceCodes.MISSING_PERSON ? (
          <>
            Зверніться до нашого контактного центру, за номером телефону{' '}
            <a href={`tel:${config.support.phoneNumber}`}>{config.support.phoneNumber}</a>. Наші спеціалісти з радістю
            відповідатимуть на ваші запитання та нададуть допомогу в усіх питаннях, пов&apos;язаних з заповненням
            анкети.
          </>
        ) : (
          <>
            Зверніться до нашого контактного центру Єдиного реєстру зброї, за номером телефону{' '}
            <a href={`tel:${config.support.phoneNumber}`}>{config.support.phoneNumber}</a>. Наші спеціалісти з радістю
            відповідатимуть на ваші запитання та нададуть допомогу в усіх питаннях, пов&apos;язаних із реєстрацією
            зброї.
          </>
        );

      case ServiceInformerType.PERSONS_STATUS:
        return `Статус особи заповнюється з долученням відповідної підтверджуючої
          довідку з місця служби (роботи) про наявність спеціального статусу для
          отримання послуги щодо придбання, зберігання, носіння або продовження
          такого права на пристрої вітчизняного виробництва для відстрілу патронів,
          споряджених гумовими чи аналогічними за своїми властивостями метальними
          снарядами несмертельної дії.`;

      case ServiceInformerType.WEAPON_DATA:
        return `Поліцейські, працівники інших державних правоохоронних органів,
          державних органів спеціального призначення з правоохоронними функціями,
          військовослужбовці Збройних Сил України, інших військових формувань,
          крім військовослужбовців, які проходять строкову військову службу подають
          довідку про вивчення матеріальної частини зброї або копії відповідного
          документа про закріплення вогнепальної зброї`;

      case ServiceInformerType.INSURANCE_DATA:
        return `Поліцейські, працівники інших державних правоохоронних органів,
          державних органів спеціального призначення з правоохоронними функціями,
          військовослужбовці Збройних Сил України, інших військових формувань,
          крім військовослужбовців, які проходять строкову військову службу
          подають довідку з місця служби (роботи).`;

      case ServiceInformerType.TECHNICAL_CONDITION_DOCUMENT_DATA:
        return (
          <>
            Документом є:
            <ul>
              <li>Довідка про огляд технічного стану і відстріл нарізної зброї, її основних частин, пристрою;</li>
              <li>
                Довідка про огляд технічного стану мисливської гладкоствольної зброї, її основних частин, холодної,
                охолощеної зброї, газових пістолетів (револьверів).
              </li>
            </ul>
          </>
        );

      case ServiceInformerType.TECHNICAL_CONDITION_DETAILS:
        return `Ви можете отримати послугу з огляду технічного стану та
          відстрілу нарізної (комбінованої) зброї або пристроїв, призначених
          для відстрілу патронів, споряджених гумовими кулями в будь-якій
          установі Експертноі служби МВС на території України в будь-який
          робочий день з 9-00 до 18-00.`;

      case ServiceInformerType.VALIDITY_PERIOD_VIOLATION:
        return `У разі порушення строків реєстрації, перереєстрації
          (продовженні строку дії дозволу на зберігання, носіння) зброї,
          основних частин зброї, пристроїв фізична особа особисто звертається
          до органу (підрозділу) поліції за місцем проживання.`;

      case ServiceInformerType.PLACE_OF_RESIDENCE:
        return `Отримати витяг з реєстру територіальних громад про
          задеклароване / зареєстроване місце проживання (перебування)
          можливо за посиланням`;

      case ServiceInformerType.MISSING_PERSON_DETAILS:
        return `Для отримання витягу із Єдиного реєстру осіб,
          зниклих безвісти за особливих обставин необхідно звернутись до
          Департаменту інформатизації МВС України.`;

      default:
        break;
    }
  }, [type]);

  const downloadLink = useMemo<string>(() => {
    if (!links) {
      return '';
    }

    switch (type) {
      case ServiceInformerType.CLUE:
        return links.policeAdresses;

      case ServiceInformerType.TECHNICAL_CONDITION_DETAILS:
        return links.policeDutyUnits;

      case ServiceInformerType.PAYMENT_DETAILS: {
        switch (serviceCode) {
          case ServiceCodes.PURCHASE:
          case ServiceCodes.COLD_STEEL_TRANSPORTATION:
          case ServiceCodes.TRANSPORT_ACROSS_BORDER:
          case ServiceCodes.STORAGE_CARRYING:
          case ServiceCodes.COLD_STEEL_STORAGE_FOR_COLLECTION:
            return links.paymentDetails;

          case ServiceCodes.VALIDITY_PERIOD_PROLONGATION:
            return links.free;

          default:
            return '';
        }
      }

      case ServiceInformerType.PLACE_OF_RESIDENCE:
        return links.vityagTeritorialnoyiGromadi;

      case ServiceInformerType.MISSING_PERSON_DETAILS:
        return links.missingPersonDetails;

      default:
        return '';
    }
  }, [serviceCode, type, links]);

  const documentLink = useMemo<JSX.Element>(() => {
    let downloadLinkContent = '';

    switch (type) {
      case ServiceInformerType.PAYMENT_DETAILS:
        downloadLinkContent = 'Інформація про реквізити платежів';
        break;

      case ServiceInformerType.CLUE:
        downloadLinkContent = 'Місце розташування органів (підзрозділів) поліції';
        break;

      case ServiceInformerType.TECHNICAL_CONDITION_DETAILS:
        downloadLinkContent = 'Чергові частини НДЕКЦ МВС';
        break;

      case ServiceInformerType.PLACE_OF_RESIDENCE:
        downloadLinkContent = 'Інструкція з отримання витягу';
        break;

      case ServiceInformerType.MISSING_PERSON_DETAILS:
        downloadLinkContent = 'Детальна інформація';
        break;

      case ServiceInformerType.CONTACT_CENTER:
      case ServiceInformerType.PERSONS_STATUS:
      case ServiceInformerType.WEAPON_DATA:
      case ServiceInformerType.INSURANCE_DATA:
      case ServiceInformerType.VALIDITY_PERIOD_VIOLATION:
      case ServiceInformerType.TECHNICAL_CONDITION_DOCUMENT_DATA:
        break;
    }

    return !downloadLinkContent ? (
      <React.Fragment />
    ) : (
      <>
        <ExternalLink className={classNames('document-link', { 'shown-full': isContentSizeFull })} href={downloadLink}>
          {downloadLinkContent}
        </ExternalLink>
      </>
    );
  }, [downloadLink, isContentSizeFull, type]);

  return (
    <div className={classNames('service-informer', borderColor)} data-service-informer-type={type}>
      <h3 className="service-informer__title">
        <span>
          <img src={AttentionIconBlack} alt="attention icon" />
        </span>{' '}
        {title}
      </h3>

      <div
        className={classNames('service-informer__content', {
          active: isContentSizeFull,
        })}
      >
        {!!content && <div>{content}</div>}
        {documentLink}
      </div>

      <button className="service-informer__toggler" type="button" onClick={(): void => toggleContentSize()}>
        {isContentSizeFull ? 'Менше' : 'Більше'}
        <span className="service-informer__toggler__icon-container">
          <img
            className={classNames({
              opened: isContentSizeFull,
              closed: !isContentSizeFull,
            })}
            src={ArrowToTheRight}
            alt="toggle informer content size"
          />
        </span>
      </button>
    </div>
  );
});
