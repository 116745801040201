import React from 'react';

import { ActionButton, Props } from './ActionButton';
import SaveIcon from '../../../assets/images/save_icon_blue.svg';
import { ArtifactType } from '../../../components/Api/types';
import { ArtifactTypeNameMapping } from './uk.l10n';

type ExtendedProps = Omit<Props, 'icon' | 'children'> & {
  type: ArtifactType;
};
export const DownloadButton: React.FC<ExtendedProps> = ({ type, ...props }) => (
  <ActionButton {...props} icon={SaveIcon}>
    {ArtifactTypeNameMapping[type]}
  </ActionButton>
);
