import React, { useState, useEffect, useContext } from 'react';
import InputMask from 'react-input-mask';
import InputTextMask from 'react-text-mask';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';

import InitialSelect, { SelectOption } from '../Select';
import FileUpload from '../FileUpload';
import { getCountrySelect } from '../../Api/apiService';
import { DocumentTypeCode } from '../../Api/types';
import calendarIcon from '../../../assets/images/calendar.svg';
import AuthContext from '../../../contexts/Auth/AuthContext';
import { getERZDocumentsSelect, getERZDocumentTypeEntites } from '../../../modules/erz/helpers';
import { useAsyncDataLoader } from '../../../hooks/useAsyncDataLoader';
import { FormConstants, IdConstants, genders } from '../constants';
import { MultiplePhoneField } from '../Inputs/PhoneField';
import { EmailField } from '../Inputs/EmailField';
import { NullValueGuard } from '../../../guards/NullValueGuard';
import { parseFormDataValue } from '../../../helpers/parseFormDataValue';
import { formatDate } from '../../../helpers/dates';
import { PreviewFilesList } from '../PreviewFilesList';
import { AccordionData } from '../../Accordion/Accordion';
import { StepsNavigationName } from '../../../config/services';
import { isLatinLetterRegExp, isUkrainianLetterRegExp } from '../Validation';

export enum PersonalAndPassportDataFieldName {
  lastName = 'lastName',
  Name = 'Name',
  Patronymic = 'Patronymic',
  birthday = 'birthday',
  gender = 'gender',
  phones = 'phones',
  email = 'email',
  personPassportType = 'personPassportType',
  seriaDocPesron = 'seriaDocPesron',
  authDepDocPerson = 'authDepDocPerson',
  issued = 'issued',
  citizenship = 'citizenship',
  rnokpp = 'rnokpp',
  birthPlace = 'birthPlace',
  activeIssuedDate = 'activeIssuedDate',
  numberDocPesron = 'numberDocPesron',
  expired = 'expired',
  authDepCode = 'authDepCode',
  unzr = 'unzr',
  personPassportDocType = 'personPassportDocType',
  rnokppDocType = 'rnokppDocType',
  personPassportDocuments = 'personPassportDocuments',
  photoCardDocType = 'photoCardDocType',
  photoCard = 'photoCard',
  rnokppDoc = 'rnokppDoc',
}

export interface PersonalAndPassportFormDataProps {
  [PersonalAndPassportDataFieldName.lastName]?: string;
  [PersonalAndPassportDataFieldName.Name]?: string;
  [PersonalAndPassportDataFieldName.Patronymic]?: string;
  [PersonalAndPassportDataFieldName.birthday]?: string;
  [PersonalAndPassportDataFieldName.gender]?: string;
  [PersonalAndPassportDataFieldName.phones]?: string[];
  [PersonalAndPassportDataFieldName.email]?: string;
  [PersonalAndPassportDataFieldName.personPassportType]?: string;
  [PersonalAndPassportDataFieldName.seriaDocPesron]?: string;
  [PersonalAndPassportDataFieldName.authDepDocPerson]?: string;
  [PersonalAndPassportDataFieldName.issued]?: string;
  [PersonalAndPassportDataFieldName.citizenship]?: string;
  [PersonalAndPassportDataFieldName.rnokpp]?: number;
  [PersonalAndPassportDataFieldName.birthPlace]?: string;
  [PersonalAndPassportDataFieldName.activeIssuedDate]?: string;
  [PersonalAndPassportDataFieldName.numberDocPesron]?: string;
  [PersonalAndPassportDataFieldName.expired]?: string;
  [PersonalAndPassportDataFieldName.authDepCode]?: string;
  [PersonalAndPassportDataFieldName.unzr]?: string;
  [PersonalAndPassportDataFieldName.personPassportDocType]?: string;
  [PersonalAndPassportDataFieldName.rnokppDocType]?: string;
  [PersonalAndPassportDataFieldName.photoCardDocType]?: string;
  [PersonalAndPassportDataFieldName.photoCard]?: File[];
  [PersonalAndPassportDataFieldName.personPassportDocuments]?: File[];
  [PersonalAndPassportDataFieldName.rnokppDoc]?: File[];
}
interface Props {
  formData: PersonalAndPassportFormDataProps;
  handleInputChange: (name: string, value: unknown) => void;
  handleClearInput: (name: string) => void;
}

interface PreviewProps {
  formData: PersonalAndPassportFormDataProps;
}

const allowedToChoosePassportDocumentTypeCodes: DocumentTypeCode[] = [
  DocumentTypeCode.PASSPORT,
  DocumentTypeCode.ID_CARD,
  DocumentTypeCode.INTERNATIONAL_PASSPORT,
  DocumentTypeCode.RESIDENCE,
  DocumentTypeCode.OLD_RESIDENCE,
];

export const PersonalAndPassportData: React.FC<Props> = ({ formData, handleInputChange, handleClearInput }) => {
  const [optionsCountry, setOptionsCountry] = useState<SelectOption[]>([]);

  const { userData, documentData, idGovData } = useContext(AuthContext);

  const [lastName, setLastName] = useState(formData.lastName || '');
  const [lastNameDisabled, setLastNameDisabled] = useState(false);

  const [Name, setName] = useState(formData.Name || '');
  const [nameDisabled, setNameDisabled] = useState(false);

  const [Patronymic, setPatronymic] = useState(formData.Patronymic || '');
  const [PatronymicDisabled, setPatronymicDisabled] = useState(false);

  const [birthday, setBirthday] = useState(formData.birthday ? new Date(formData.birthday) : undefined);
  const [activeIssuedDate, setActiveIssuedDate] = useState(
    formData.activeIssuedDate ? new Date(formData.activeIssuedDate) : undefined,
  );
  const [gender, setGender] = useState(formData.gender ? JSON.parse(formData.gender) : null);

  const [personPassportType, setPersonPassportType] = useState(
    formData.personPassportType ? JSON.parse(formData.personPassportType) : undefined,
  );
  const [seriaDocPesron, setSeriaDocPesron] = useState(formData.seriaDocPesron || '');
  const [authDepDocPerson, setAuthDepDocPerson] = useState(formData.authDepDocPerson || '');
  const [issued, setIssued] = useState(formData.issued ? new Date(formData.issued) : undefined);
  const [citizenship, setCitizenship] = useState(formData.citizenship ? JSON.parse(formData.citizenship) : undefined);
  const [rnokpp, setRnokpp] = useState(formData.rnokpp || '');
  const [birthPlace, setBirthPlace] = useState(formData.birthPlace || '');
  const [numberDocPesron, setNumberDocPesron] = useState(formData.numberDocPesron || '');
  const [expired, setExpired] = useState(formData.expired ? new Date(formData.expired) : undefined);
  const [authDepCode, setAuthDepCode] = useState(formData.authDepCode || '');
  const [unzr, setUnzr] = useState(formData.unzr || '');
  const [ukraineDictionaryValue, setUkraineDictionaryValue] = useState<SelectOption>(null);
  const [isCitizenshipSelectDisabled, setCitizenshipSelectDisabled] = useState<boolean>(false);

  const DateDocs = new Date().setFullYear(new Date().getFullYear() - 18);

  const [personPassportDocType, setPersonPassportDocType] = useState(
    formData.personPassportDocType ? JSON.parse(formData.personPassportDocType) : undefined,
  );
  const [rnokppDocType, setRnokppDocType] = useState(
    formData.rnokppDocType ? JSON.parse(formData.rnokppDocType) : undefined,
  );
  const [photoCardDocType, setPhotoCard] = useState(
    formData.photoCardDocType ? JSON.parse(formData.photoCardDocType) : undefined,
  );

  const autocompleteDocumentData = (): void => {
    if (documentData?.type) {
      const passportType = documentTypeEntities?.find(({ code }) => code === documentData.type);

      setPersonPassportType(passportType);
      handleInputChange('personPassportType', passportType);
    }
    if (documentData.type === DocumentTypeCode.PASSPORT) {
      const dateIssue = new Date(documentData.fields.dateIssue);

      setIssued(dateIssue);
      handleInputChange('issued', dateIssue);

      setAuthDepDocPerson(documentData.fields.issue);
      handleInputChange('authDepDocPerson', documentData.fields.issue);
    } else if (documentData.type === DocumentTypeCode.OLD_RESIDENCE) {
      const dateIssue = new Date(documentData.fields.dateIssue);

      setActiveIssuedDate(dateIssue);
      handleInputChange('activeIssuedDate', dateIssue);

      setAuthDepDocPerson(documentData.fields.issue);
      handleInputChange('authDepDocPerson', documentData.fields.issue);
    } else {
      const dateIssue = new Date(documentData.fields.dateIssue);

      setActiveIssuedDate(dateIssue);
      handleInputChange('activeIssuedDate', dateIssue);

      setAuthDepCode(documentData.fields.issue);
      handleInputChange('authDepCode', documentData.fields.issue);
    }

    if (
      documentData.type === DocumentTypeCode.PASSPORT ||
      documentData.type === DocumentTypeCode.INTERNATIONAL_PASSPORT ||
      documentData.type === DocumentTypeCode.OLD_RESIDENCE
    ) {
      const seriaAndNumber = documentData.fields.series + documentData.fields.number;

      setSeriaDocPesron(seriaAndNumber);
      handleInputChange('seriaDocPesron', seriaAndNumber);
    } else {
      setSeriaDocPesron(documentData.fields.series);
      handleInputChange('seriaDocPesron', documentData.fields.series);

      setNumberDocPesron(documentData.fields.number);
      handleInputChange('numberDocPesron', documentData.fields.number);
    }

    const expiredDate = new Date(documentData.fields.expiredDate);

    setExpired(expiredDate);
    handleInputChange('expired', expiredDate);
  };

  const { data: documentTypeEntities = [], load } = useAsyncDataLoader(() =>
    getERZDocumentTypeEntites(allowedToChoosePassportDocumentTypeCodes),
  );

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      personPassportType?.code === DocumentTypeCode.RESIDENCE ||
      personPassportType?.code === DocumentTypeCode.OLD_RESIDENCE
    ) {
      setCitizenshipSelectDisabled(false);
    } else {
      setCitizenshipSelectDisabled(true);
      handleInputChange('citizenship', ukraineDictionaryValue);
      setCitizenship(ukraineDictionaryValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personPassportType, ukraineDictionaryValue]);

  useEffect(() => {
    if (!formData.personPassportType && documentData?.type) {
      autocompleteDocumentData();
    }

    if (!formData.rnokpp && idGovData?.drfo_code) {
      setRnokpp(idGovData.drfo_code);
      handleInputChange('rnokpp', idGovData.drfo_code);
    }

    if (userData.name_last) {
      setLastNameDisabled(true);
      setLastName(userData.name_last);
      handleInputChange('lastName', userData.name_last);
    }

    if (userData.name_first) {
      setNameDisabled(true);
      setName(userData.name_first);
      handleInputChange('Name', userData.name_first);
    }

    if (userData.name_middle) {
      setPatronymicDisabled(true);
      setPatronymic(userData.name_middle);
      handleInputChange('Patronymic', userData.name_middle);
    }

    if (!formData.birthday && userData.date_of_birth) {
      setBirthday(new Date(userData.date_of_birth));
      handleInputChange('birthday', new Date(userData.date_of_birth));
    }

    if (!formData.gender && userData.gender) {
      setGender(userData.gender ? genders.find((option) => option.id === userData.gender) : genders[0]);

      handleInputChange('gender', userData.gender ? genders.find((option) => option.id === userData.gender) : null);
    }

    if (!formData.gender && !userData.gender) {
      setGender(genders[0]);
    }

    if (!formData.email && userData.contact_email) {
      handleInputChange('email', userData.contact_email);
    }

    const getUkraineDictionaryValue = async (): Promise<void> => {
      const countries = await getCountrySelect();
      setUkraineDictionaryValue(countries.find((country) => country.id === IdConstants.UKRAINE));
    };

    getUkraineDictionaryValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypeEntities]);

  const handlePersonPassportTypeChange = (newValue: object, name: string): void => {
    setPersonPassportType(newValue);
    handleInputChange(name, newValue);
    handleInputChange(PersonalAndPassportDataFieldName.personPassportDocType, newValue);
    handleInputChange('personPassportDocuments', []);

    if ((newValue as (typeof documentTypeEntities)[0]).code === documentData.type) {
      autocompleteDocumentData();
    } else {
      setActiveIssuedDate(undefined);
      handleClearInput('activeIssuedDate');

      setSeriaDocPesron('');
      handleClearInput('seriaDocPesron');

      setAuthDepDocPerson('');
      handleClearInput('authDepDocPerson');

      setIssued(undefined);
      handleClearInput('issued');

      setNumberDocPesron('');
      handleClearInput('numberDocPesron');

      setExpired(undefined);
      handleClearInput('expired');

      setAuthDepCode('');
      handleClearInput('authDepCode');
    }
  };

  const handleChange = (newValue: object, name: string): void => {
    const setValue = {
      gender: setGender,
      citizenship: setCitizenship,
    }[name];
    setValue(newValue);
    handleInputChange(name, newValue);
  };

  const handleInputsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    const setValue = {
      lastName: setLastName,
      Name: setName,
      Patronymic: setPatronymic,
      seriaDocPesron: setSeriaDocPesron,
      authDepDocPerson: setAuthDepDocPerson,
      rnokpp: setRnokpp,
      birthPlace: setBirthPlace,
      numberDocPesron: setNumberDocPesron,
      authDepCode: setAuthDepCode,
      unzr: setUnzr,
    }[name];
    setValue(value);
    handleInputChange(name, value);
  };

  const passportDataFields: Record<string, (required?: boolean) => JSX.Element> = {
    seriaDocPesron: (required) => (
      <div className={classNames('form-input', 'form-input_margin-bottom-none', { required })}>
        <div className="form-input__label">Серія та номер</div>
        <InputTextMask
          mask={
            personPassportType?.code === DocumentTypeCode.PASSPORT ||
            personPassportType?.code === DocumentTypeCode.OLD_RESIDENCE
              ? [isUkrainianLetterRegExp, isUkrainianLetterRegExp, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
              : [isLatinLetterRegExp, isLatinLetterRegExp, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
          }
          className="form-input__input"
          placeholder="Не вказано"
          data-mask="6"
          type="text"
          name="seriaDocPesron"
          value={seriaDocPesron.toUpperCase()}
          onChange={handleInputsChange}
          onBlur={(e): void => {
            const valueWithoutMask = e.target.value.replace(/_/g, '');
            if (valueWithoutMask.length < parseInt(e.target.dataset.mask)) {
              e.target.value = '';
              handleInputsChange(e);
            }
          }}
        />
      </div>
    ),
    authDepDocPerson: (required) => (
      <div className={classNames('form-input', 'form-input_margin-bottom-none', { required })}>
        <div className="form-input__label">Орган видачі</div>
        <input
          className="form-input__input"
          placeholder="Не вказано"
          type="text"
          name="authDepDocPerson"
          value={authDepDocPerson}
          onChange={handleInputsChange}
          maxLength={FormConstants.INPUT_MAX_LENGTH}
        />
      </div>
    ),
    issued: (required) => (
      <div className={classNames('form-input', 'form-input_margin-bottom-none', { required })}>
        <div className="form-input__label">Дата видачі</div>
        <div className="form-input__date-box">
          <DatePicker
            locale="uk"
            name="issued"
            id="issued"
            placeholderText="дд.мм.рррр"
            selected={issued}
            className="form-input__input  form-control"
            dateFormat="dd.MM.yyyy"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={new Date()}
            customInput={
              <InputMask mask="99.99.9999" name="issued" value={issued ? issued.toLocaleDateString('uk-UA') : ''} />
            }
            onChange={(date): void => {
              setIssued(date);
              handleInputChange('issued', date);
            }}
          />
          <label htmlFor="issued">
            <img className="has-datetimepicker form-input__date-icon js-date-picker" src={calendarIcon} alt="icon" />
          </label>
        </div>
      </div>
    ),
    activeIssuedDate: (required) => (
      <div className={classNames('form-input', 'form-input_margin-bottom-none', { required })}>
        <div className="form-input__label">Дата видачі</div>
        <div className="form-input__date-box">
          <DatePicker
            locale="uk"
            name="activeIssuedDate"
            id="activeIssuedDate"
            selected={activeIssuedDate}
            placeholderText="дд.мм.рррр"
            className="form-input__input  form-control"
            dateFormat="dd.MM.yyyy"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={new Date()}
            customInput={
              <InputMask
                mask="99.99.9999"
                name="activeIssuedDate"
                value={activeIssuedDate ? activeIssuedDate.toLocaleDateString('uk-UA') : ''}
              />
            }
            onChange={(date): void => {
              setActiveIssuedDate(date);
              handleInputChange('activeIssuedDate', date);
            }}
          />
          <label htmlFor="activeIssuedDate">
            <img className="has-datetimepicker form-input__date-icon js-date-picker" src={calendarIcon} alt="icon" />
          </label>
        </div>
      </div>
    ),
    numberDocPesron: (required) => (
      <div className={classNames('form-input', 'form-input_margin-bottom-none', { required })}>
        <div className="form-input__label">Номер</div>
        <InputMask
          mask="999999999"
          data-mask="9"
          className="form-input__input"
          placeholder="Не вказано"
          type="text"
          name="numberDocPesron"
          value={numberDocPesron}
          onChange={handleInputsChange}
          onBlur={(e): void => {
            const valueWithoutMask = e.target.value.replace(/_/g, '');
            if (valueWithoutMask.length < parseInt(e.target.dataset.mask)) {
              e.target.value = '';
              handleInputsChange(e);
            }
          }}
        />
      </div>
    ),
    expired: (required) => (
      <div className={classNames('form-input', 'form-input_margin-bottom-none', { required })}>
        <div className="form-input__label">Термін дії</div>
        <div className="form-input__date-box">
          <DatePicker
            locale="uk"
            name="expired"
            id="expired"
            placeholderText="дд.мм.рррр"
            selected={expired}
            className="form-input__input  form-control"
            dateFormat="dd.MM.yyyy"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            minDate={new Date()}
            customInput={
              <InputMask mask="99.99.9999" name="expired" value={expired ? expired.toLocaleDateString('uk-UA') : ''} />
            }
            onChange={(date): void => {
              setExpired(date);
              handleInputChange('expired', date);
            }}
          />
          <label htmlFor="expired">
            <img className="has-datetimepicker form-input__date-icon js-date-picker" src={calendarIcon} alt="icon" />
          </label>
        </div>
      </div>
    ),
    authDepCode: (required) => (
      <div className={classNames('form-input', 'form-input_margin-bottom-none', { required })}>
        <div className="form-input__label">Уповноважений суб’єкт, що видав документ</div>
        <InputMask
          mask="9999"
          className="form-input__input"
          placeholder="Не вказано"
          type="text"
          data-mask="4"
          name="authDepCode"
          value={authDepCode}
          onChange={handleInputsChange}
          onBlur={(e): void => {
            const valueWithoutMask = e.target.value.replace(/_/g, '');
            if (valueWithoutMask.length < parseInt(e.target.dataset.mask)) {
              e.target.value = '';
              handleInputsChange(e);
            }
          }}
        />
      </div>
    ),
    unzr: (required) => (
      <div className={classNames('form-input', 'form-input_margin-bottom-none', { required })}>
        <div className="form-input__label">УНЗР заявника</div>
        <InputMask
          mask="99999999-99999"
          className="form-input__input"
          placeholder="Не вказано"
          type="text"
          data-mask="14"
          name="unzr"
          value={unzr}
          onChange={handleInputsChange}
          onBlur={(e): void => {
            const valueWithoutMask = e.target.value.replace(/_/g, '');
            if (valueWithoutMask.length < parseInt(e.target.dataset.mask)) {
              e.target.value = '';
              handleInputsChange(e);
            }
          }}
        />
      </div>
    ),
  };

  return (
    <>
      <div
        id="step-description"
        className="block-title block-title_lg block-title_padding-bottom-md-extra block-title_bg-light"
      >
        {StepsNavigationName.personalAndPassport}
      </div>
      <div className="form__content">
        <div className="form__title">Особисті дані заявника</div>

        {/* LAST NAME */}
        <div className="form-input form-input_margin-bottom-none required">
          <div className="form-input__label">Прізвище</div>
          <div className="input-select input-select_max-width">
            <input
              name="lastName"
              className="form-input__input"
              placeholder="Прізвище"
              type="text"
              value={lastName}
              disabled={lastNameDisabled}
              onChange={handleInputsChange}
            />
          </div>
        </div>

        {/* NAME */}
        <div className="form-input form-input_margin-bottom-none required">
          <div className="form-input__label">Ім’я</div>
          <div className="input-select input-select_max-width">
            <input
              name="Name"
              className="form-input__input"
              placeholder="Ім’я"
              type="text"
              value={Name}
              disabled={nameDisabled}
              onChange={handleInputsChange}
            />
          </div>
        </div>

        {/* PATROMIC */}
        <div className="form-input form-input_margin-bottom-none">
          <div className="form-input__label">По батькові</div>
          <div className="input-select input-select_max-width">
            <input
              name="Patronymic"
              className="form-input__input"
              placeholder="По батькові"
              type="text"
              value={Patronymic}
              disabled={PatronymicDisabled}
              onChange={handleInputsChange}
            />
          </div>
        </div>

        {/* DATA BIRTHDAY / GENDER */}
        <div className="form__row">
          <div className="form-input form-input_margin-bottom-none required">
            <div className="form-input__label">Дата народження</div>
            <div className="form-input__date-box">
              <DatePicker
                locale="uk"
                id="birthday"
                name="birthday"
                selected={birthday}
                placeholderText="дд.мм.рррр"
                className="form-input__input  form-control"
                dateFormat="dd.MM.yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={new Date(DateDocs)}
                customInput={
                  <InputMask
                    mask="99.99.9999"
                    name="birthday"
                    type="text"
                    value={birthday ? birthday.toLocaleDateString('uk-UA') : ''}
                  />
                }
                onChange={(date): void => {
                  setBirthday(date);
                  handleInputChange('birthday', date);
                }}
              />
              <label htmlFor="birthday">
                <img
                  className="has-datetimepicker form-input__date-icon js-date-picker"
                  src={calendarIcon}
                  alt="icon"
                />
              </label>
            </div>
          </div>
          <div className="form-input form-input_margin-bottom-none required">
            <div className="form-input__label">Стать</div>
            <div className="input-select input-select_max-width">
              <InitialSelect
                name="gender"
                options={genders}
                placeholder="Не вибрано"
                defaultValue={gender}
                onChange={handleChange}
                onMenuOpen={(): void => {
                  const indexToRemove = genders.findIndex((option) => option.id === 'U');
                  indexToRemove !== -1 && genders.splice(indexToRemove, 1);
                }}
              />
            </div>
          </div>
        </div>

        {/* PHONE USER */}

        <MultiplePhoneField
          required
          defaultPhoneValues={formData.phones || [userData.contact_phone]}
          onChange={(phones): void => handleInputChange('phones', phones)}
        />

        {/* EMAIL */}

        <EmailField required value={formData.email} change={handleInputChange} />

        {/* FILE */}

        <div className="form-input form-input_margin-bottom-none d-none no-group">
          <div className="input-select input-select_max-width">
            <input name="photoCardDocType" value={photoCardDocType} />
          </div>
        </div>

        <div className="form-input form-input_margin-bottom-none required no-group">
          <div className="form-input__label">Фото (3.5x4.5)</div>
          <FileUpload
            inputName="photoCard"
            inputTitle="Додати фото"
            value={formData.photoCard}
            handleInputChange={async (name, value): Promise<void> => {
              handleInputChange(name, value);

              if (!formData.photoCardDocType) {
                const [document] = await getERZDocumentsSelect({
                  allowedDocumentTypeCodes: [DocumentTypeCode.PHOTO_CARD],
                });
                setPhotoCard(document);
                handleInputChange('photoCardDocType', document);
              }
            }}
            handleClearInput={handleClearInput}
          />
        </div>

        <div className="form__title">Паспортні дані</div>

        {/* Type of document that confirms identity */}
        <div className="form-input form-input_margin-bottom-none required">
          <div className="form-input__label">Тип документа, який підтверджує особу</div>
          <div className="input-select input-select_max-width">
            <InitialSelect
              name="personPassportType"
              options={documentTypeEntities}
              placeholder="Не вибрано"
              defaultValue={personPassportType}
              onChange={handlePersonPassportTypeChange}
            />
          </div>
        </div>

        {personPassportType?.code === DocumentTypeCode.PASSPORT && (
          <>
            {passportDataFields.seriaDocPesron(true)}
            {passportDataFields.authDepDocPerson(true)}
            {passportDataFields.issued(true)}
          </>
        )}

        {personPassportType?.code === DocumentTypeCode.INTERNATIONAL_PASSPORT && (
          <>
            {passportDataFields.seriaDocPesron(true)}

            <div className="form__row">
              {passportDataFields.activeIssuedDate(true)}
              {passportDataFields.expired(true)}
            </div>

            {passportDataFields.authDepCode(true)}
          </>
        )}

        {personPassportType?.code === DocumentTypeCode.ID_CARD && (
          <>
            {passportDataFields.numberDocPesron(true)}

            <div className="form__row">
              {passportDataFields.activeIssuedDate(true)}
              {passportDataFields.expired(true)}
            </div>

            {passportDataFields.authDepCode(true)}
            {passportDataFields.unzr(true)}
          </>
        )}

        {personPassportType?.code === DocumentTypeCode.RESIDENCE && (
          <>
            {passportDataFields.numberDocPesron(true)}

            <div className="form__row">
              {passportDataFields.activeIssuedDate(true)}
              {passportDataFields.expired(true)}
            </div>

            {passportDataFields.authDepCode(true)}
            {passportDataFields.unzr(true)}
          </>
        )}

        {personPassportType?.code === DocumentTypeCode.OLD_RESIDENCE && (
          <>
            {passportDataFields.seriaDocPesron(true)}
            {passportDataFields.authDepDocPerson(true)}

            <div className="form__row">
              {passportDataFields.activeIssuedDate(true)}
              {passportDataFields.expired()}
            </div>
          </>
        )}

        {/* Citizenship */}
        <div className="form-input form-input_margin-bottom-none required">
          <div className="form-input__label">Громадянство</div>
          <div className="input-select input-select_max-width">
            <InitialSelect
              name="citizenship"
              options={optionsCountry}
              placeholder="Не вибрано"
              defaultValue={citizenship}
              onChange={handleChange}
              onMenuOpen={(): void => {
                if (!optionsCountry.length) {
                  getCountrySelect(setOptionsCountry);
                }
              }}
              disabled={isCitizenshipSelectDisabled}
            />
          </div>
        </div>

        <div className="form-input form-input_margin-bottom-none d-none no-group">
          <div className="input-select input-select_max-width">
            <InitialSelect
              name="personPassportDocType"
              options={documentTypeEntities}
              placeholder="Не вибрано"
              defaultValue={personPassportDocType}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-input form-input_margin-bottom-none required">
          <div className="form-input__label">
            Сканкопія паспорта або посвідки (сторінки з інформацією про власника та реквізитами документа), а також за
            наявності сторінки з місцем реєстрації
          </div>
          <FileUpload
            inputName="personPassportDocuments"
            inputTitle="Додати cканкопію документа"
            value={formData.personPassportDocuments}
            quantityFiles={personPassportType?.code === DocumentTypeCode.PASSPORT ? 6 : 3}
            handleInputChange={async (name, value): Promise<void> => {
              handleInputChange(name, value);
              const [document] = await getERZDocumentsSelect({
                allowedDocumentTypeCodes: [personPassportType.code],
              });
              setPersonPassportDocType(document);
              handleInputChange('personPassportDocType', document);
            }}
            handleClearInput={handleClearInput}
          />
        </div>

        {/* rnokpp */}

        <div className="form-input form-input_margin-bottom-none required">
          <div className="form-input__label">РНОКПП</div>
          <div className="form-input__row gap-md">
            <InputMask
              mask="9999999999"
              className="form-input__input"
              placeholder="Не вказано"
              type="text"
              data-mask="10"
              name="rnokpp"
              value={rnokpp}
              onChange={handleInputsChange}
              onBlur={(e): void => {
                const valueWithoutMask = e.target.value.replace(/_/g, '');

                if (valueWithoutMask.length < parseInt(e.target.dataset.mask)) {
                  e.target.value = '';
                  handleInputsChange(e);
                }
              }}
              disabled
            />
          </div>
        </div>

        <div className="form-input form-input_margin-bottom-none d-none no-group">
          <div className="input-select input-select_max-width">
            <input name="rnokppDocType" value={rnokppDocType} />
          </div>
        </div>

        <div className="form-input form-input_margin-bottom-none required">
          <div className="form-input__label">Сканкопія РНОКПП</div>
          <FileUpload
            inputName="rnokppDoc"
            inputTitle="Додати cканкопію документа"
            value={formData.rnokppDoc}
            handleInputChange={async (name, value): Promise<void> => {
              handleInputChange(name, value);
              if (!formData.rnokppDocType) {
                const [document] = await getERZDocumentsSelect({
                  allowedDocumentTypeCodes: [DocumentTypeCode.RNOKPP],
                });
                setRnokppDocType(document);
                handleInputChange('rnokppDocType', document);
              }
            }}
            handleClearInput={handleClearInput}
          />
        </div>

        {/* Place of birth */}
        <div className="form-input form-input_margin-bottom-none required">
          <div className="form-input__label">Місце народження</div>
          <input
            className="form-input__input"
            placeholder="Не вказано"
            type="text"
            name="birthPlace"
            value={birthPlace}
            onChange={handleInputsChange}
            maxLength={FormConstants.INPUT_MAX_LENGTH}
          />
        </div>
      </div>
    </>
  );
};

export const previewPersonalAndPassportData = ({ formData }: PreviewProps): AccordionData => {
  return {
    title: StepsNavigationName.personalAndPassport,
    content: (
      <>
        <div className="accordion__content-title">Особисті дані заявника</div>
        <div className="custom-columns custom-columns_grey-bg custom-columns_default-gap">
          <div className="custom-columns__row custom-columns__row_padding-tiny">
            <div className="custom-columns__col">
              <div className="custom-columns__title">Прізвище</div>
              <div className="custom-columns__info">
                <NullValueGuard>{formData[PersonalAndPassportDataFieldName.lastName]}</NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">Ім’я</div>
              <div className="custom-columns__info">
                <NullValueGuard>{formData[PersonalAndPassportDataFieldName.Name]}</NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">По батькові</div>
              <div className="custom-columns__info">
                <NullValueGuard>{formData[PersonalAndPassportDataFieldName.Patronymic]}</NullValueGuard>
              </div>
            </div>
          </div>
          <div className="custom-columns__row custom-columns__row_padding-tiny">
            <div className="custom-columns__col">
              <div className="custom-columns__title">Дата народження</div>
              <div className="custom-columns__info">
                <NullValueGuard>{formatDate(formData[PersonalAndPassportDataFieldName.birthday])}</NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">Стать</div>
              <div className="custom-columns__info">
                <NullValueGuard>
                  {parseFormDataValue(formData[PersonalAndPassportDataFieldName.gender])?.label}
                </NullValueGuard>
              </div>
            </div>
          </div>
          <div className="custom-columns__row custom-columns__row_padding-tiny">
            <div className="custom-columns__col">
              <div className="custom-columns__title">Контактний телефон</div>
              <div className="custom-columns__info">
                <NullValueGuard>{formData[PersonalAndPassportDataFieldName.phones]?.join(', ')}</NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">Електронна адреса</div>
              <div className="custom-columns__info">
                <NullValueGuard>{formData[PersonalAndPassportDataFieldName.email]}</NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">Фото (3.5x4.5)</div>
              <div className="custom-columns__info">
                <NullValueGuard>
                  {formData[PersonalAndPassportDataFieldName.photoCard] ? (
                    <PreviewFilesList files={formData[PersonalAndPassportDataFieldName.photoCard]} />
                  ) : null}
                </NullValueGuard>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion__content-title">Паспортні дані</div>
        <div className="custom-columns custom-columns_grey-bg custom-columns_default-gap">
          <div className="custom-columns__row custom-columns__row_padding-tiny">
            <div className="custom-columns__col">
              <div className="custom-columns__title">Тип документа, який підтверджує особу</div>
              <div className="custom-columns__info">
                <NullValueGuard>
                  {parseFormDataValue(formData[PersonalAndPassportDataFieldName.personPassportType])?.label}
                </NullValueGuard>
              </div>
            </div>

            {formData[PersonalAndPassportDataFieldName.seriaDocPesron] && (
              <div className="custom-columns__col">
                <div className="custom-columns__title">Серія та номер</div>
                <div className="custom-columns__info">{formData[PersonalAndPassportDataFieldName.seriaDocPesron]}</div>
              </div>
            )}

            {formData[PersonalAndPassportDataFieldName.numberDocPesron] && (
              <div className="custom-columns__col">
                <div className="custom-columns__title">Номер</div>
                <div className="custom-columns__info">{formData[PersonalAndPassportDataFieldName.numberDocPesron]}</div>
              </div>
            )}

            {formData[PersonalAndPassportDataFieldName.authDepDocPerson] && (
              <div className="custom-columns__col">
                <div className="custom-columns__title">Орган видачі</div>
                <div className="custom-columns__info">
                  {formData[PersonalAndPassportDataFieldName.authDepDocPerson]}
                </div>
              </div>
            )}

            {formData[PersonalAndPassportDataFieldName.authDepCode] && (
              <div className="custom-columns__col">
                <div className="custom-columns__title">Уповноважений суб’єкт, що видав документ</div>
                <div className="custom-columns__info">{formData[PersonalAndPassportDataFieldName.authDepCode]}</div>
              </div>
            )}
          </div>
          <div className="custom-columns__row custom-columns__row_padding-tiny">
            {formData[PersonalAndPassportDataFieldName.unzr] && (
              <div className="custom-columns__col">
                <div className="custom-columns__title">УНЗР заявника</div>
                <div className="custom-columns__info">{formData[PersonalAndPassportDataFieldName.unzr]}</div>
              </div>
            )}

            {formData[PersonalAndPassportDataFieldName.citizenship] && (
              <div className="custom-columns__col">
                <div className="custom-columns__title">Громадянство</div>
                <div className="custom-columns__info">
                  <NullValueGuard>
                    {parseFormDataValue(formData[PersonalAndPassportDataFieldName.citizenship])?.label}
                  </NullValueGuard>
                </div>
              </div>
            )}

            {formData[PersonalAndPassportDataFieldName.rnokpp] && (
              <div className="custom-columns__col">
                <div className="custom-columns__title">РНОКПП</div>
                <div className="custom-columns__info">{formData[PersonalAndPassportDataFieldName.rnokpp]}</div>
              </div>
            )}

            {formData[PersonalAndPassportDataFieldName.birthPlace] && (
              <div className="custom-columns__col">
                <div className="custom-columns__title">Місце народження</div>
                <div className="custom-columns__info">{formData[PersonalAndPassportDataFieldName.birthPlace]}</div>
              </div>
            )}
          </div>
          <div className="custom-columns__row custom-columns__row_padding-tiny">
            {[
              formData[PersonalAndPassportDataFieldName.issued],
              formData[PersonalAndPassportDataFieldName.activeIssuedDate],
            ].map(
              (date, index) =>
                date && (
                  <div className="custom-columns__col" key={`${+date + index}`}>
                    <div className="custom-columns__title">Дата видачі</div>
                    <div className="custom-columns__info">
                      <NullValueGuard>{formatDate(date)}</NullValueGuard>
                    </div>
                  </div>
                ),
            )}
            {formData[PersonalAndPassportDataFieldName.expired] && (
              <div className="custom-columns__col">
                <div className="custom-columns__title">Термін дії</div>
                <div className="custom-columns__info">
                  <NullValueGuard>{formatDate(formData[PersonalAndPassportDataFieldName.expired])}</NullValueGuard>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    ),
  };
};
