import { DependencyList, EffectCallback, useEffect } from 'react';

type Callback = () => Promise<ReturnType<EffectCallback>>;

export const useAsyncEffect = (effect: Callback, deps?: DependencyList): void =>
  useEffect(() => {
    (async (): Promise<void> => {
      await effect();
    })();
  }, deps);
