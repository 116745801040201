/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-key */
import React, { useState, useEffect, useMemo } from 'react';

import './StepsNavigation.scss';

interface Props {
  navigationData: Array<{
    step: number;
    name: string;
    active: boolean;
    completed: boolean;
  }>;
}

const StepsNavigation = ({ navigationData }: Props): JSX.Element => {
  const [updatedObjects, setUpdatedObjects] = useState(navigationData);
  const currentStep = useMemo(() => updatedObjects.find((step) => step.active), [updatedObjects]);

  useEffect(() => {
    setUpdatedObjects(navigationData);
  }, [navigationData]);

  return (
    <nav className="stepper-aside">
      <div className="stepper">
        {updatedObjects.map((item, i) => (
          <div
            className={`stepper__item ${item.active ? 'active' : ''}  ${item.completed ? 'completed' : ''}`}
            key={item.step}
          >
            <span className="stepper__item-icon">{i + 1}</span>
            <span className="stepper__item-text">{item.name}</span>
          </div>
        ))}
      </div>

      <div className="stepper-mob">
        <span className="stepper-mob__step-number">{currentStep?.step + '/' + navigationData.length}</span>
        <span className="stepper-mob__step-name">{currentStep?.name}</span>
      </div>
    </nav>
  );
};

export default StepsNavigation;
