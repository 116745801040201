import React, { useEffect, useMemo } from 'react';
import './Statistics.scss';

import { useAsyncDataLoader } from '../../hooks/useAsyncDataLoader';
import api from '../Api/api';
import { StatisticsData } from '../Api/types';

const StatisticsKeysMapping: Record<keyof StatisticsData, string> = {
  user_total: 'Користувачів за весь час',
  user_last_week: 'Користувачів зареєстровано за останній тиждень',
  user_last_month: 'Користувачів зареєстровано за останній місяць',
  services: 'Послуг для користувача на сайті',
  orders: 'Послуг замовлено за весь час',
};

export const Statistics: React.FC = () => {
  const {
    isLoading,
    data: statistics,
    load,
  } = useAsyncDataLoader(async () => (await api.getStatistics()).data, { defaultDataValue: {} });

  const filtratedStatistics = useMemo<StatisticsData>(() => {
    const { user_last_week, user_last_month, user_total, ...otherStatistics } = statistics;
    return user_last_week
      ? { user_total, user_last_week, ...otherStatistics }
      : { user_total, user_last_month, ...otherStatistics };
  }, [statistics]);

  useEffect(() => {
    load();
  }, []);

  return (
    <div className="statistic">
      <div className="statistic__container container">
        {!isLoading &&
          statistics &&
          Object.entries(filtratedStatistics).map(([statisticKey, statisticValue]) => (
            <StatisticItem
              key={statisticKey}
              label={StatisticsKeysMapping[statisticKey as keyof StatisticsData]}
              value={statisticValue}
            />
          ))}
      </div>
    </div>
  );
};

const StatisticItem: React.FC<{ label: string; value: string }> = ({ label, value }) => {
  return (
    <div className="statistic-item">
      <div className="statistic-item__value">{value?.toLocaleString()}</div>
      <div className="statistic-item__label">{label}</div>
    </div>
  );
};
