import { isEmpty } from 'lodash';

import { DocumentTypeEntity, reformatDocumentTypeToEntity } from '../../../helpers/reformatDocumentTypeToEntity';
import { api } from '../../../components/Api';
import { DocumentType, IntegrationSystem } from '../../../components/Api/types';

export const getERZDocumentTypeEntites = async (
  allowedDocumentTypeCodes: Array<DocumentType<IntegrationSystem.ERZ>['code']> = [],
  disallowedDocumentTypeKinds: Array<DocumentType<IntegrationSystem.ERZ>['kind']> = [],
): Promise<Array<DocumentTypeEntity<IntegrationSystem.ERZ>>> => {
  let documentTypes = (await api.dictionary.getDocumentTypes.erz()).data;

  if (!!allowedDocumentTypeCodes?.length) {
    documentTypes = allowedDocumentTypeCodes.reduce((accumulator, documentTypeCode) => {
      const wantedDocumentType = documentTypes.find(
        (documentType) => documentType.code !== null && documentType.code === documentTypeCode,
      );

      return isEmpty(wantedDocumentType) ? accumulator : [...accumulator, wantedDocumentType];
    }, []);
  }

  if (!!disallowedDocumentTypeKinds?.length) {
    documentTypes = documentTypes.filter((documentType) => !disallowedDocumentTypeKinds.includes(documentType.kind));
  }

  const entities = documentTypes.map(reformatDocumentTypeToEntity);

  return entities;
};
