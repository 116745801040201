import { IntegrationSystem, UserRequestStatus } from '../../../components/Api/types';
import { StatusColor } from '../../../components/Status';
import { generateMappedStatuses } from '../../../helpers/generateMappedStatuses';

export const RMP_statuses = generateMappedStatuses(IntegrationSystem.RMP, [
  {
    status: UserRequestStatus.sent,
    label: 'Відправлено до уповноваженого з питань зниклих безвісти',
    color: StatusColor.GREEN,
  },
]);
