import { ArtifactType } from '../../../components/Api/types';

export enum IACUserRequestStatus {
  processing = 'processing',
  refusal = 'refusal',
  done = 'done',
}

export enum IACUserRequestChannel {
  CIC = 'CIC',
  DII = 'DII',
  SCE = 'SCE',
  MVS = 'MVС',
}

export interface IACUserRequest {
  id: string;
  status: IACUserRequestStatus;
  esn: string;
  created_at: string;
  completed_at: string;
  purpose_id: string;
  purpose_name: string;
  channel: IACUserRequestChannel;
  artifacts: ArtifactType[];
}
