import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { ROUTES, ROUTES_BY_SERVICE_CODE } from '../router/config';
import { ServiceCodes } from '../components/Api/types';

export const GercRedirectMiddleware = (): JSX.Element => {
  const queryParams = useSearchParams()[0];
  const requestId = queryParams.get('requestId');
  const serviceCode = queryParams.get('serviceCode') as ServiceCodes;

  return (
    <Navigate
      to={ROUTES_BY_SERVICE_CODE[serviceCode] || ROUTES.HOME}
      state={{ continueGercPayment: true, request_id: +requestId }}
    />
  );
};
