import React from 'react';

import './ServiceMenuList.scss';
import { InternalServiceTile } from './InternalServiceTile';
import { ServiceType, UserServiceExternal, UserService } from '../Api/types';
import { ExternalServiceTile } from './ExternalServiceTile';

interface ServiceProps {
  servicesType: ServiceType.INTERNAL;
  list: UserService[];
}

interface ExternalServiceProps {
  servicesType: ServiceType.EXTERNAL;
  list: UserServiceExternal[];
}

type Props = ServiceProps | ExternalServiceProps;

export const FlatList = React.memo<Props>(({ list, servicesType }) => {
  const classes = 'main-nav__content_active';

  return (
    <div className={classes}>
      <div className="services__list">
        {servicesType === ServiceType.EXTERNAL
          ? list?.map((item) => <ExternalServiceTile service={item} key={item.name} />)
          : list?.map((item) => <InternalServiceTile service={item} key={item.id} withAuthBanner />)}
      </div>
    </div>
  );
});
