import React, { useState } from 'react';

import MainTemplate from '../../../templates/MainTemplate/MainTemplate';
import ModalBox from '../../../components/Modals/modalBox';
import ServiceMenuListMockup from '../../../components/Home/ServiceMenuListMockup';
import { api } from '../../../components/Api';
import { useAsyncDataLoader } from '../../../hooks/useAsyncDataLoader';
import { useAsyncEffect } from '../../../hooks/useAsyncEffect';
import { FlatList } from '../../../components/Home/FlatList';
import './ExternalServicesPage.scss';
import { ServiceType } from '../../../components/Api/types';

const ExternaServicesPage: React.FC = () => {
  const [isModalOpen, setIsPanelOpen] = useState<boolean>(false);
  const {
    isLoading,
    load,
    data: externalServices = [],
  } = useAsyncDataLoader(async () => (await api.services.getExternal()).data);

  useAsyncEffect(() => load(), []);

  const toggleModalAddService = (): void => {
    setIsPanelOpen(!isModalOpen);
  };

  return (
    <MainTemplate>
      <section id="external-services" className="section-block section-light external-services-page">
        <div className="container">
          <div className="external-services-page__container">
            <div className="external-services-page__header">Інші державні послуги</div>
            {isLoading && <ServiceMenuListMockup classType="loading" />}

            {!isLoading && <FlatList servicesType={ServiceType.EXTERNAL} list={externalServices} />}
          </div>
        </div>
      </section>
      <ModalBox isModalOpen={isModalOpen} clickOnAddServiceButton={{ toggleModalAddService }} />
    </MainTemplate>
  );
};

export default ExternaServicesPage;
