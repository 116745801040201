import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'antd';

import MainTemplate from '../../templates/MainTemplate/MainTemplate';
import AuthContext from '../../contexts/Auth/AuthContext';
import './LoginPage.scss';
import { IdGovProvider } from './IdGovProvider';
import { BasicProvider } from './BasicProvider';
import { config } from '../../config';
import { usePageAfterAuth } from './usePageAfterAuth';
import { VideoInstructionLink } from '../../components/VideoInstructionLink';
import { useAsyncDataLoader } from '../../hooks/useAsyncDataLoader';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import { ROUTES } from '../../router/config';

const LoginPage: React.FC = (): JSX.Element => {
  const { isLoggedIn, storedLinks } = useContext(AuthContext);
  const navigate = useNavigate();
  const { pageToOpenAfterAuth } = usePageAfterAuth();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(ROUTES.HOME);
    }
  }, [isLoggedIn, navigate]);

  const handleSingIn = (): void => {
    navigate(pageToOpenAfterAuth);
  };

  const { data = {} as any, load } = useAsyncDataLoader(
    async () => (await fetch('/warnings.json')).json() as Promise<{ auth: boolean }>,
  );

  useAsyncEffect(load, []);

  return (
    <MainTemplate>
      <section className="login-page section-block section-grey">
        <div className="container container__content-center">
          <div className="login-page__login-form custom-card custom-card__max-width">
            <div className="custom-card__top" />
            <div className="custom-card__body">
              {data?.auth && (
                <Alert
                  showIcon
                  className="login-alert"
                  type="warning"
                  message="На жаль, зараз Ви не можете увійти до свого кабінету. Ми знаємо про цю проблему і працюємо над її вирішенням."
                />
              )}

              <div className="block-title block-title_text-center custom-card__title">
                Авторизуйтесь за допомогою ID.GOV
              </div>
              {config.authProviders.basic && <BasicProvider handleSignIn={handleSingIn} />}
              {config.authProviders.idGov && (
                <IdGovProvider expectedPath={pageToOpenAfterAuth} handleSignIn={handleSingIn} />
              )}
            </div>

            <VideoInstructionLink
              link={storedLinks.videoInstruction?.authorization}
              className="login-page__video-instruction"
              asButton
            />
          </div>
        </div>
      </section>
    </MainTemplate>
  );
};

export default LoginPage;
