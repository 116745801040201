/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import InputTextMask from 'react-text-mask';
import DatePicker from 'react-datepicker';
import { IMaskInput } from 'react-imask';

import calendarIcon from '../../../assets/images/calendar.svg';
import { api } from '../../../components/Api';
import InitialSelect, { SelectOption } from '../../../components/Forms/Select';
import {
  validateField,
  isRussianLetters,
  isLatinLetters,
  isNotDigit,
  validateEmail,
  validatePhone,
  ValidateInputValueResult,
  isLatinLetterRegExp,
  isUkrainianLetterRegExp,
} from '../../../components/Forms/Validation';
import CabinetTemplate from '../../../templates/CabinetTemplate/CabinetTemplate';
import './Profile.scss';
import { getFormattingPhone } from '../../../helpers/phoneHelpers';
import AuthContext from '../../../contexts/Auth/AuthContext';
import { DocumentTypeCode } from '../../../components/Api/types';
import { validateInputCharacterHelper } from '../../../helpers/validateInputCharacterHelper';
import {
  getCitySelect,
  getCountrySelect,
  getDistrictSelect,
  getRegionSelect,
} from '../../../components/Api/apiService';
import { FormConstants, IdConstants, genders } from '../../../components/Forms/constants';
import { areDatesDifferent, formatDateStringToISO, getDateWithoutTime } from '../../../helpers/dates';
import { getERZDocumentTypeEntites } from '../../../modules/erz/helpers';
import { useAsyncDataLoader } from '../../../hooks/useAsyncDataLoader';
import { VideoInstructionLink } from '../../../components/VideoInstructionLink';
import { Button } from '../../../components/Button';

const maxYears = new Date();
maxYears.setFullYear(maxYears.getFullYear() - 14);

const todaysDate = getDateWithoutTime(new Date());
const minDate = getDateWithoutTime(new Date('1923-01-01'));

const errorMessages = {
  russianAlphabet: 'Не передбачено використання символів російського алфавіту',
  latinAlphabet: 'Не передбачено використання символів латинського алфавіту',
  issuingAuthority: 'Орган видачі документа повинен містити не менше 4 символів',
  fieldRequired: 'Необхідно заповнити поле',
  digitsOnly: 'Лише цифри',
  cyrillicOnly: 'Лише кирилиця',
  latinOnly: 'Лише латиниця',
  passportNumber: 'Номер паспорта має містити 6 цифр',
  papassportNumberRange: 'має містити від 2 до 9 цифр',
  passportSeries: 'Серія має містити 2 літери',
  passportNumberLength: 'має містити 9 цифр',
  ageUnder14: 'Вам має бути більше 14 років',
};

const allowedToChoosePassportDocumentTypeCodes: DocumentTypeCode[] = [
  DocumentTypeCode.PASSPORT,
  DocumentTypeCode.ID_CARD,
  DocumentTypeCode.INTERNATIONAL_PASSPORT,
  DocumentTypeCode.RESIDENCE,
  DocumentTypeCode.OLD_RESIDENCE,
];

const DATE_FORMAT = 'dd.MM.yyyy';

const Profile: React.FC = () => {
  const { storedLinks } = useContext(AuthContext);
  const dateBirtPickerRef = useRef(null);
  const dateIssuePickerRef = useRef(null);
  const dateExparedPickerRef = useRef(null);

  const [editProfile, setEditProfile] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(false);

  const [optionsCountry, setOptionsCountry] = useState<SelectOption[]>([]);
  const [optionsRegion, setOptionsRegion] = useState<SelectOption[]>([]);

  const [optionsDistrictBirth, setOptionsDistrictBirth] = useState<SelectOption[]>([]);
  const [optionsCityBirth, setOptionsCityBirth] = useState<SelectOption[]>([]);

  const [optionsDistrictReg, setOptionsDistrictReg] = useState<SelectOption[]>([]);
  const [optionsCityReg, setOptionsCityReg] = useState<SelectOption[]>([]);

  const [birthDatePicker, setBirthDatePicker] = useState(false);

  const [dateIssuePicker, setDateIssuePicker] = useState(false);
  const [expiredDatePicker, setExparedDatePicker] = useState(false);

  const { idGovData, userData, documentData, updateUserData, socialProtect } = useContext(AuthContext);

  const fullName = useMemo(() => {
    return [userData?.name_first, userData?.name_middle, userData?.name_last].join(' ');
  }, [userData]);

  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState(null);
  const [birthday, setBirthday] = useState(null);
  const [email, setEmail] = useState('');
  const [citizenship, setCitizenship] = useState(null);

  const [rank, setRank] = useState(null);
  const [serveSign, setServeSign] = useState(null);
  const [tag, setTag] = useState(null);
  const [servicePlace, setServicePlace] = useState(null);

  const [сountryOfBirth, setCountryOfBirth] = useState(null);
  const [regionOfBirth, setRegionOfBirth] = useState(null);
  const [districtOfBirth, setDistrictOfBirth] = useState(null);
  const [cityOfBirth, setCityOfBirth] = useState(null);

  const [сountryOfReg, setCountryOfReg] = useState(null);
  const [regionOfReg, setRegionOfReg] = useState(null);
  const [districtOfReg, setDistrictOfReg] = useState(null);
  const [cityOfReg, setCityOfReg] = useState(null);

  const [addressState, setAddressState] = useState(null);
  const [addressCity, setAddressCity] = useState('');
  const [addressAddress, setAddressAddress] = useState(null);

  const [typeDoc, setTypeDoc] = useState(null);
  const [seriesDoc, setSeriesDoc] = useState(null);
  const [numberDoc, setNumberDoc] = useState(null);
  const [issueDoc, setIssueDoc] = useState('');
  const [dateIssue, setDateIssue] = useState(null);
  const [issueCountry, setIssueCountry] = useState(null);
  const [drfoCode, setDrfoCode] = useState(null);
  const [expiredDate, setExparedDate] = useState(null);

  const [inputEmailError, setInputEmailError] = useState<string>('');
  const [inputPhoneError, setInputPhoneError] = useState<string>('');
  const [inputGenderError, setInputGenderError] = useState<string>('');
  const [inputСitizenshipError, setInputCitizenshipError] = useState<string>('');
  const [inputBirthdayError, setInputBirthdayError] = useState<string>('');
  const [inputTypeDocError, setInputTypeDocError] = useState<string>('');
  const [inputSeriesDocError, setInputSeriesDocError] = useState<string>('');
  const [inputNumberDocError, setInputNumberDocError] = useState<string>('');
  const [inputIssueDocError, setInputIssueDocError] = useState<string>('');
  const [inputDateIssueError, setInputDateIssueError] = useState<string>('');
  const [inputExpiredDateError, setInputExpiredDateError] = useState<string>('');
  const [inputIssueCountryError, setInputIssueCountryError] = useState<string>('');

  const { data: documentTypeEntities = [], load } = useAsyncDataLoader(() =>
    getERZDocumentTypeEntites(allowedToChoosePassportDocumentTypeCodes),
  );

  const updateDataUser = (data: any): void => {
    setBirthday(data?.date_of_birth ? new Date(data.date_of_birth) : null);
    setGender(data?.gender ? genders.find((option) => option.id === data.gender) || null : null);

    setPhone(data?.contact_phone || data?.phone || '');
    setEmail(data?.contact_email || data?.email || '');
    setCitizenship(data?.citizenship.label ? data.citizenship : null);
    setCountryOfBirth(data?.country_birth.label ? data.country_birth : null);
    setRegionOfBirth(data?.region_birth.label ? data.region_birth : null);
    setDistrictOfBirth(data?.district_birth.label ? data.district_birth : null);
    setCityOfBirth(data?.city_birth.label ? data.city_birth : null);
    setCountryOfReg(data?.country_reg.label ? data.country_reg : null);
    setRegionOfReg(data?.region_reg.label ? data.region_reg : null);
    setDistrictOfReg(data?.district_reg.label ? data.district_reg : null);
    setCityOfReg(data?.city_reg.label ? data.city_reg : null);
    setAddressState(data?.address_state ? data.address_state : null);
    setAddressCity(data?.address_city ? data.address_city : null);
    setAddressAddress(data?.address_address ? data.address_address : null);
    setTypeDoc(data?.type ? data.type : null);

    setSeriesDoc(data.fields?.series ? data.fields.series : null);
    setNumberDoc(data.fields?.number ? data.fields.number : null);
    setIssueDoc(data.fields?.issue ? data.fields.issue : '');
    setIssueCountry(data.fields?.issueCountryIso2 ? data.fields.issueCountryIso2 : 'УКРАЇНА');
    setDateIssue(data.fields?.dateIssue ? new Date(data.fields.dateIssue) : null);
    setExparedDate(data.fields?.expiredDate ? new Date(data.fields.expiredDate) : null);
    setDrfoCode(data?.drfo_code ? data.drfo_code : null);

    setRank(data?.rank ? data.rank : null);
    setServeSign(data?.serve_sign ? data.serve_sign : null);
    setTag(data?.tag ? data.tag : null);
    setServicePlace(data?.service_place ? data.service_place : null);

    setInputEmailError('');
    setInputPhoneError('');
    setInputGenderError('');
    setInputCitizenshipError('');
    setInputBirthdayError('');
    setInputTypeDocError('');
    setInputSeriesDocError('');
    setInputNumberDocError('');
    setInputIssueDocError('');
    setInputDateIssueError('');
    setInputExpiredDateError('');
    setInputIssueCountryError('');

    if (!optionsRegion.length) {
      getRegionSelect(setOptionsRegion);
    }
  };

  useEffect(() => {
    load().then(() => {
      updateDataUser({ ...userData, ...idGovData, ...documentData, ...socialProtect });
      getCountrySelect(setOptionsCountry);
    });
  }, [documentData, idGovData, userData]);

  const handleUpdateProfileClick = async (): Promise<void> => {
    let requestData: {
      [key: string]: string | any;
    } = {};

    const updatedData: {
      [key: string]: string | undefined;
    } = {};

    const updateDocuments: {
      type?: string;
      document: {
        fields: {
          [key: string]: string | undefined;
        };
      };
    } = {
      document: {
        fields: {},
      },
    };

    const updateIDGOV: {
      [key: string]: string | undefined;
    } = {};

    const fieldMappings = {
      email: email,
      phone: getFormattingPhone(phone),
      contact_email: email,
      contact_phone: getFormattingPhone(phone),
      gender: gender.id,
      date_of_birth: birthday.toISOString(),
      citizenship,
      country_birth: сountryOfBirth,
      region_birth: regionOfBirth,
      district_birth: districtOfBirth,
      city_birth: cityOfBirth,
      country_reg: сountryOfReg,
      region_reg: regionOfReg,
      district_reg: districtOfReg,
      city_reg: cityOfReg,
    };

    const fieldMappingsDocuments = {
      document: {
        type: typeDoc,
        fields: {
          type: typeDoc,
          series: seriesDoc,
          number: numberDoc,
          issue: issueDoc,
          typeName: documentTypeEntities?.find(({ code }) => {
            return code === typeDoc;
          }).code,
          dateIssue: dateIssue.toISOString(),
          expiredDate: expiredDate ? expiredDate.toISOString() : null,
          issueCountryIso2: issueCountry,
        },
      },
    };

    const fieldMappingsIDGOV = {
      address_address: addressAddress,
      address_city: addressCity,
      address_state: addressState,
      drfo_code: drfoCode,
    };

    const update = (mappings: any, newData: any, oldData: any): void => {
      Object.entries(mappings).forEach(([key, value]) => {
        if ((oldData as any)[key] !== value) {
          if (key === 'phone' || key === 'email') {
            newData['contact_' + key] = value;
          } else if (key === 'type') {
            if (newData === updateDocuments) {
              newData.document.type = documentTypeEntities.find(({ code }) => code === value)?.code;
            } else {
              newData[key] = value;
              if (value === null) {
                delete newData[key];
              }
            }
          } else {
            if (newData === updateIDGOV) {
              newData[key] = value;
            } else {
              if (newData.document) {
                newData.document.fields[key] = value;
                if (value === null) {
                  delete newData.document.fields[key];
                }
              } else {
                newData[key] = value;
                if (value === null) {
                  delete newData[key];
                }
              }
            }
          }
        }
      });
    };

    update(fieldMappings, updatedData, userData);
    update(fieldMappingsIDGOV, updateIDGOV, idGovData);

    requestData = {
      ...updatedData,
      ...fieldMappingsDocuments,
      ...updateIDGOV,
    };

    if (Object.keys(requestData).length > 0) {
      const data = await api.updateUserProfile({
        ...requestData,
      });

      updateUserData(data);
    }
  };

  const inputErrorFunctions: Record<string, (errors: React.SetStateAction<string>) => void> = {
    citizenship: setInputCitizenshipError,
    gender: setInputGenderError,
    birthday: setInputBirthdayError,
    typeDoc: setInputTypeDocError,
    dateIssue: setInputDateIssueError,
    seriesDoc: setInputSeriesDocError,
    expiredDate: setInputExpiredDateError,
    issueDoc: setInputIssueDocError,
    numberDoc: setInputNumberDocError,
    email: setInputEmailError,
    phone: setInputPhoneError,
    issueCountry: setInputIssueCountryError,
  };

  const fieldValues: Record<string, any> = {
    citizenship,
    gender,
    birthday,
    typeDoc,
    dateIssue,
    seriesDoc,
    expiredDate,
    issueDoc,
    numberDoc,
    email,
    phone,
    issueCountry,
  };

  const validateAndSubmit = (): void => {
    const fields = Object.keys(fieldValues).map((fieldName) => {
      return {
        name: fieldName,
        validate: (): ValidateInputValueResult => {
          if (fieldName === 'email') return validateEmail(fieldValues.email);
          if (fieldName === 'phone') return validatePhone(fieldValues.phone);
          if (fieldName === 'expiredDate' && typeDoc === DocumentTypeCode.OLD_RESIDENCE)
            return {
              error: '',
              isValid: true,
            };

          return validateField(fieldValues[fieldName]);
        },
        value: fieldValues[fieldName],
        input: document.querySelector(`input[name='${fieldName}']`),
      };
    });

    let firstErrorElement: HTMLElement = null;

    function validateFields(): void {
      const errors = [];
      for (const { name, validate, value, input } of fields) {
        const error = validate().error;
        if (name === 'birthday') {
          if (!value) {
            errors.push({ name, errors: error, errors_input: input });
          } else {
            const maxYearsDate = maxYears;
            const userBirthday = value;

            if (userBirthday >= maxYearsDate) {
              errors.push({
                name,
                errors: errorMessages.ageUnder14,
                errors_input: input,
              });
            }
          }
        }

        if (!!error) {
          errors.push({ name, errors: error, errors_input: input });
        }
      }

      if (errors.length) {
        for (const error of errors) {
          const { name, errors: fieldErrors, errors_input } = error;

          setInputErrors(name, fieldErrors);

          if (!firstErrorElement) {
            firstErrorElement = errors_input as HTMLInputElement;
          }
        }
      } else {
        const error_input = document.querySelector('.error-input') as HTMLInputElement;
        if (error_input) {
          firstErrorElement = error_input;
        }
      }

      if (firstErrorElement) {
        setTimeout(() => {
          firstErrorElement.closest('.edit-block').scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }, 100);
      }
    }

    function setInputErrors(fieldName: string, errors: React.SetStateAction<string>): void {
      const setInputErrorFunction = inputErrorFunctions[fieldName];
      if (setInputErrorFunction) {
        setInputErrorFunction(errors);
      }
    }

    validateFields();
    if (!firstErrorElement) {
      setEditProfile(false);
      setUpdateProfile(false);
      handleUpdateProfileClick();
    }
  };

  return (
    <CabinetTemplate>
      <section className="profile container-with-aside__section">
        <div className="profile__title block-title">Профіль користувача</div>

        <div className="profile__tools">
          <div className="profile__edit-buttons">
            {editProfile ? (
              <>
                <Button
                  className="profile__edit-button"
                  outline
                  onClick={(): void => {
                    setEditProfile(false);
                    updateDataUser({
                      ...userData,
                      ...documentData,
                      ...idGovData,
                    });
                  }}
                >
                  Скасувати
                </Button>
                <Button className="profile__edit-button" disabled={updateProfile} onClick={validateAndSubmit}>
                  Зберегти зміни
                </Button>
              </>
            ) : (
              <Button
                className="profile__edit-button"
                onClick={(event): void => {
                  event.preventDefault();
                  setEditProfile(!editProfile);
                  setUpdateProfile(true);
                }}
              >
                Редагувати дані
              </Button>
            )}
          </div>

          <VideoInstructionLink
            className="profile__video-instruction"
            link={storedLinks.videoInstruction.profile}
            asButton
          />
        </div>

        <div className="block-with-img">
          <div className="block-title_md block-title_padding-bottom-sm-xl block-with-img__top-info">Загальні дані</div>
          <div className="block-with-img__top-info">
            <div className="text-with-icon">
              <div className="text-with-icon__title text-with-icon__title_md text-with-icon__title_attention-primary">
                Актуальний номер телефону та адреса електронної пошти необхідна для оперативного повідомлення по
                виконанню послуг
              </div>
            </div>
          </div>

          <div className="custom-columns_padding-sm">
            <div>
              <div className="custom-columns custom-columns__grid_gap-35">
                {/* Name / Birthday */}
                <div className="custom-columns__row custom-columns__row_two-col">
                  <div className="custom-columns__col">
                    <div className="custom-columns__title">ПІБ</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <>
                          <div className="edit-block">
                            <div className="form-input form-input_with-help-btn">
                              <input
                                id="fullName"
                                type="text"
                                className="form-input__input  form-control"
                                name="fullName "
                                value={fullName}
                                disabled={true}
                                placeholder="Не вказано"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="form-input form-input_with-help-btn">{fullName ? fullName : 'Не вказано'}</div>
                      )}
                    </div>
                  </div>
                  <div className="custom-columns__col">
                    <div className="custom-columns__title custom-columns__title_required">Дата народження</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <>
                          <div className="edit-block">
                            <div className="form-input form-input_with-help-btn">
                              <DatePicker
                                selected={birthday || ''}
                                id="birthday"
                                className={classNames('form-input__input form-control', {
                                  'error-input': inputBirthdayError.length,
                                })}
                                name="birthday"
                                showMonthDropdown
                                showYearDropdown
                                open={birthDatePicker}
                                dateFormat={DATE_FORMAT}
                                locale="uk"
                                autoComplete="off"
                                placeholderText="Не визначена"
                                dropdownMode="select"
                                minDate={minDate}
                                maxDate={todaysDate}
                                ref={dateBirtPickerRef}
                                onChange={(date): void => {
                                  console.log(date);
                                  if (date) {
                                    setBirthday(date);
                                    setBirthDatePicker(false);
                                    setInputBirthdayError('');
                                    setUpdateProfile(false);
                                  } else {
                                    setBirthday(null);
                                    setUpdateProfile(false);
                                  }
                                }}
                                onBlur={(): void => {
                                  setBirthDatePicker(false);
                                }}
                                onSelect={(): void => {
                                  setExparedDatePicker(false);
                                }}
                              />
                              <button
                                onClick={(): void => {
                                  setBirthDatePicker(true);
                                  // dateBirtPickerRef.current.input.element.focus();
                                }}
                              >
                                <img
                                  className="has-datetimepicker form-input__date-icon js-date-picker"
                                  src={calendarIcon}
                                  alt="icon"
                                />
                              </button>
                            </div>
                          </div>
                          {inputBirthdayError && (
                            <div className="error-msg" key={inputBirthdayError}>
                              {inputBirthdayError}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="form-input form-input_with-help-btn">
                          {birthday ? birthday.toLocaleDateString('uk-UA') : 'Не визначена'}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Gender / Citizenship */}
                <div className="custom-columns__row custom-columns__row_two-col custom-columns__row_border-bottom">
                  <div className="custom-columns__col">
                    <div className="custom-columns__title custom-columns__title_required">Стать</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <>
                          <div className="edit-block">
                            <div className="form-input form-input_with-help-btn">
                              <InitialSelect
                                name="gender"
                                options={genders.filter(({ id }) => id !== genders[0].id)}
                                placeholder="Не вибрано"
                                className={classNames({
                                  'error-input': inputGenderError.length,
                                })}
                                defaultValue={gender}
                                onChange={(newValue): void => {
                                  setGender(newValue);
                                  setInputGenderError('');
                                  setUpdateProfile(false);
                                }}
                              />
                            </div>
                          </div>
                          {inputGenderError && (
                            <div className="error-msg" key={inputGenderError}>
                              {inputGenderError}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="form-input form-input_with-help-btn">
                          {gender ? gender.label : 'Не визначена'}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="custom-columns__col">
                    <div className="custom-columns__title custom-columns__title_required">Громадянство</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <>
                          <div className="edit-block">
                            <div className="form-input form-input_with-help-btn">
                              <InitialSelect
                                name="citizenship"
                                options={optionsCountry.filter(({ id }) => id === IdConstants.UKRAINE)}
                                className={classNames({
                                  'error-input': inputСitizenshipError.length,
                                })}
                                placeholder="Не вибрано"
                                defaultValue={citizenship}
                                onChange={(newValue): void => {
                                  setCitizenship(newValue);
                                  setInputCitizenshipError('');
                                  setUpdateProfile(false);
                                }}
                                onMenuOpen={getCountrySelect}
                              />
                            </div>
                          </div>
                          {inputСitizenshipError && (
                            <div className="error-msg" key={inputСitizenshipError}>
                              {inputСitizenshipError}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="form-input form-input_with-help-btn ">
                          {citizenship ? citizenship.label : 'Не визначена'}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Address of birth */}
                <div className="custom-columns__row custom-columns__row_two-col">
                  <div className="custom-columns__col">
                    <div className="custom-columns__title">Країна народження</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <div className="edit-block">
                          <div className="form-input form-input_with-help-btn">
                            <InitialSelect
                              name="сountryOfBirth"
                              options={optionsCountry}
                              placeholder="Не вибрано"
                              defaultValue={сountryOfBirth}
                              onChange={(newValue): void => {
                                setCountryOfBirth(newValue);
                                setRegionOfBirth('');
                                setDistrictOfBirth('');
                                setCityOfBirth('');
                                setOptionsRegion([]);
                                setOptionsDistrictBirth([]);
                                setOptionsCityBirth([]);
                                setUpdateProfile(false);
                              }}
                              onMenuOpen={getCountrySelect}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="form-input form-input_with-help-btn">
                          {сountryOfBirth ? сountryOfBirth.label : 'Не визначена'}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="custom-columns__col">
                    <div className="custom-columns__title">Район народження (за наявності)</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <div className="edit-block">
                          <div className="form-input form-input_with-help-btn">
                            <InitialSelect
                              name="districtOfBirth"
                              options={optionsDistrictBirth}
                              placeholder="Не вибрано"
                              defaultValue={districtOfBirth}
                              disabled={
                                !regionOfBirth ||
                                regionOfBirth.id === IdConstants.KYIV ||
                                regionOfBirth.id === IdConstants.SEVASTOPOL
                              }
                              onChange={(newValue): void => {
                                setDistrictOfBirth(newValue);
                                setCityOfBirth('');
                                setOptionsCityBirth([]);
                                setUpdateProfile(false);
                              }}
                              onMenuOpen={(): void => {
                                if (!optionsDistrictBirth.length) {
                                  getDistrictSelect(regionOfBirth.id, setOptionsDistrictBirth);
                                }
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="form-input form-input_with-help-btn">
                          {districtOfBirth ? districtOfBirth.label : 'Не визначена'}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="custom-columns__row custom-columns__row_two-col custom-columns__row_border-bottom">
                  <div className="custom-columns__col">
                    <div className="custom-columns__title">Область народження</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <div className="edit-block">
                          <div className="form-input form-input_with-help-btn">
                            <InitialSelect
                              name="regionOfBirth"
                              options={optionsRegion}
                              disabled={!сountryOfBirth || сountryOfBirth.id !== IdConstants.UKRAINE}
                              placeholder="Не вибрано"
                              defaultValue={regionOfBirth}
                              onChange={(newValue): void => {
                                const newObj = newValue as (typeof genders)[0];
                                setRegionOfBirth(newObj);
                                setDistrictOfBirth('');
                                setCityOfBirth('');
                                setOptionsDistrictBirth([]);
                                setOptionsCityBirth([]);
                                setUpdateProfile(false);
                                if (newObj.id === IdConstants.KYIV || newObj.id === IdConstants.SEVASTOPOL) {
                                  setDistrictOfBirth('');
                                  setCityOfBirth(newObj);
                                }
                              }}
                              onMenuOpen={(): void => {
                                if (!optionsRegion.length) {
                                  getRegionSelect(setOptionsRegion);
                                }
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="form-input form-input_with-help-btn">
                          {regionOfBirth ? regionOfBirth.label : 'Не визначена'}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="custom-columns__col">
                    <div className="custom-columns__title">Населений пункт народження</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <div className="edit-block">
                          <div className="form-input form-input_with-help-btn">
                            {сountryOfBirth?.id === IdConstants.UKRAINE ? (
                              <InitialSelect
                                name="cityOfBirth"
                                options={optionsCityBirth}
                                disabled={
                                  !districtOfBirth ||
                                  regionOfBirth.id === IdConstants.KYIV ||
                                  regionOfBirth.id === IdConstants.SEVASTOPOL
                                }
                                placeholder="Не вибрано"
                                defaultValue={cityOfBirth}
                                onChange={(newValue): void => {
                                  setCityOfBirth(newValue);
                                  setUpdateProfile(false);
                                }}
                                onMenuOpen={(): void => {
                                  if (!optionsCityBirth.length) {
                                    getCitySelect(districtOfBirth.id, setOptionsCityBirth);
                                  }
                                }}
                              />
                            ) : (
                              <>
                                <input
                                  type="text"
                                  name="cityOfBirth"
                                  value={cityOfBirth ? cityOfBirth.label : ''}
                                  className="form-input__input form-control"
                                  placeholder="Не вибрано"
                                  onKeyDown={validateInputCharacterHelper}
                                  onChange={(event): void => {
                                    setCityOfBirth({
                                      id: null,
                                      label: event.target.value,
                                    });
                                    setUpdateProfile(false);
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="form-input form-input_with-help-btn">
                          {cityOfBirth ? cityOfBirth.label : 'Не визначена'}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Address of reg */}
                <div className="custom-columns__row custom-columns__row_two-col">
                  <div className="custom-columns__col">
                    <div className="custom-columns__title">Країна реєстрації</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <div className="edit-block">
                          <div className="form-input form-input_with-help-btn">
                            <InitialSelect
                              name="сountryOfReg"
                              options={optionsCountry}
                              placeholder="Не вибрано"
                              defaultValue={сountryOfReg}
                              onChange={(newValue): void => {
                                setCountryOfReg(newValue);
                                setRegionOfReg('');
                                setDistrictOfReg('');
                                setCityOfReg('');
                                setOptionsRegion([]);
                                setOptionsDistrictReg([]);
                                setOptionsCityReg([]);
                                setUpdateProfile(false);
                              }}
                              onMenuOpen={getCountrySelect}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="form-input form-input_with-help-btn">
                          {сountryOfReg ? сountryOfReg.label : 'Не визначена'}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="custom-columns__col">
                    <div className="custom-columns__title">Район реєстрації (за наявності)</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <div className="edit-block">
                          <div className="form-input form-input_with-help-btn">
                            <InitialSelect
                              name="districtOfReg"
                              options={optionsDistrictReg}
                              placeholder="Не вибрано"
                              disabled={
                                !regionOfReg ||
                                regionOfReg.id === IdConstants.KYIV ||
                                regionOfReg.id === IdConstants.SEVASTOPOL
                              }
                              defaultValue={districtOfReg}
                              onChange={(newValue): void => {
                                setDistrictOfReg(newValue);
                                setCityOfReg('');
                                setOptionsCityReg([]);
                                setUpdateProfile(false);
                              }}
                              onMenuOpen={(): void => {
                                if (!optionsDistrictReg.length) {
                                  getDistrictSelect(regionOfReg.id, setOptionsDistrictReg);
                                }
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="form-input form-input_with-help-btn">
                          {districtOfReg ? districtOfReg.label : 'Не визначена'}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="custom-columns__row custom-columns__row_two-col custom-columns__row_border-bottom">
                  <div className="custom-columns__col">
                    <div className="custom-columns__title">Область реєстрації</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <div className="edit-block">
                          <div className="form-input form-input_with-help-btn">
                            <InitialSelect
                              name="regionOfReg"
                              options={optionsRegion}
                              placeholder="Не вибрано"
                              defaultValue={regionOfReg}
                              disabled={!сountryOfReg || сountryOfReg.id !== IdConstants.UKRAINE}
                              onChange={(newValue): void => {
                                const newObj = newValue as (typeof genders)[0];
                                setRegionOfReg(newObj);
                                setDistrictOfReg('');
                                setCityOfReg('');
                                setOptionsDistrictReg([]);
                                setOptionsCityReg([]);
                                setUpdateProfile(false);
                                if (newObj.id === IdConstants.KYIV || newObj.id === IdConstants.SEVASTOPOL) {
                                  setDistrictOfReg('');
                                  setCityOfReg(newObj);
                                }
                              }}
                              onMenuOpen={(): void => {
                                if (!optionsRegion.length) {
                                  getRegionSelect(setOptionsRegion);
                                }
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="form-input form-input_with-help-btn">
                          {regionOfReg ? regionOfReg.label : 'Не визначена'}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="custom-columns__col">
                    <div className="custom-columns__title">Населений пункт реєстрації</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <div className="edit-block">
                          <div className="form-input form-input_with-help-btn">
                            {сountryOfReg?.id === IdConstants.UKRAINE ? (
                              <>
                                <InitialSelect
                                  name="cityOfReg"
                                  options={optionsCityReg}
                                  placeholder="Не вибрано"
                                  disabled={
                                    !districtOfReg ||
                                    regionOfReg.id === IdConstants.KYIV ||
                                    regionOfReg.id === IdConstants.SEVASTOPOL
                                  }
                                  defaultValue={cityOfReg}
                                  onChange={(newValue): void => {
                                    setCityOfReg(newValue);
                                    setUpdateProfile(false);
                                  }}
                                  onMenuOpen={(): void => {
                                    if (!optionsCityReg.length) {
                                      getCitySelect(districtOfReg.id, setOptionsCityReg);
                                    }
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <input
                                  type="text"
                                  name="cityOfReg"
                                  value={cityOfReg ? cityOfReg.label : ''}
                                  className="form-input__input form-control"
                                  placeholder="Не вибрано"
                                  onKeyDown={validateInputCharacterHelper}
                                  onChange={(event): void => {
                                    setCityOfReg({
                                      id: null,
                                      label: event.target.value,
                                    });
                                    setUpdateProfile(false);
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="form-input form-input_with-help-btn">
                          {cityOfReg ? cityOfReg.label : 'Не визначена'}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Phone / Email  */}
                <div className="custom-columns__row custom-columns__row_two-col custom-columns__row_border-bottom">
                  <div className="custom-columns__col">
                    <div className="custom-columns__title custom-columns__title_required">Номер телефону</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <>
                          <div className="edit-block">
                            <div className="form-input form-input_with-help-btn">
                              <InputMask
                                mask="+380 (99) 999-99-99"
                                placeholder="+380 (__) ___-__-__"
                                className={classNames('form-input__input form-control', {
                                  'error-input': inputPhoneError.length,
                                })}
                                value={phone}
                                onChange={(event): void => {
                                  const errorPhone = validatePhone(event.currentTarget.value).error;
                                  setUpdateProfile(!!errorPhone);
                                  setInputPhoneError(errorPhone);
                                  setPhone(event.currentTarget.value);
                                }}
                                type="tel"
                                id="phoneUser"
                                name="phone"
                              />
                            </div>
                          </div>
                          {inputPhoneError && (
                            <div className="error-msg" key={inputPhoneError}>
                              {inputPhoneError}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="form-input form-input_with-help-btn">{phone ? phone : 'Не визначена'}</div>
                      )}
                    </div>
                  </div>
                  <div className="custom-columns__col">
                    <div className="custom-columns__title custom-columns__title_required">Електронна пошта</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <>
                          <div className="edit-block">
                            <div className="form-input form-input_with-help-btn">
                              <input
                                id="email"
                                type="text"
                                className={classNames('form-input__input form-control', {
                                  'error-input': inputEmailError.length,
                                })}
                                name="email"
                                value={email}
                                onChange={(event): void => {
                                  const errorEmail = validateEmail(event.currentTarget.value).error;
                                  setUpdateProfile(!!errorEmail);
                                  setInputEmailError(errorEmail);
                                  setEmail(event.currentTarget.value);
                                }}
                                placeholder="Не вказано"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          {inputEmailError && (
                            <div className="error-msg" key={inputEmailError}>
                              {inputEmailError}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="form-input form-input_with-help-btn">{email ? email : 'Не визначена'}</div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Social protect data  */}
                {(rank || serveSign || tag || servicePlace) && (
                  <div className="custom-columns__row custom-columns__row_two-col custom-columns__row_border-bottom">
                    {rank && (
                      <div className="custom-columns__col">
                        <div className="custom-columns__title">Звання</div>
                        <div className="custom-columns__info">
                          {editProfile ? (
                            <div className="edit-block">
                              <div className="form-input form-input_with-help-btn">
                                <input
                                  id="rank"
                                  type="text"
                                  className="form-input__input form-control"
                                  name="rank"
                                  value={rank}
                                  placeholder="Не вказане"
                                  autoComplete="off"
                                  disabled
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="form-input form-input_with-help-btn">{rank}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {serveSign && (
                      <div className="custom-columns__col">
                        <div className="custom-columns__title">Ознака військової служби</div>
                        <div className="custom-columns__info">
                          {editProfile ? (
                            <div className="edit-block">
                              <div className="form-input form-input_with-help-btn">
                                <input
                                  id="serveSign"
                                  type="text"
                                  className="form-input__input form-control"
                                  name="serveSign"
                                  value={serveSign}
                                  placeholder="Не вказана"
                                  autoComplete="off"
                                  disabled
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="form-input form-input_with-help-btn">{serveSign}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {tag && (
                      <div className="custom-columns__col">
                        <div className="custom-columns__title">Номер жетону</div>
                        <div className="custom-columns__info">
                          {editProfile ? (
                            <div className="edit-block">
                              <div className="form-input form-input_with-help-btn">
                                <input
                                  id="tag"
                                  type="text"
                                  className="form-input__input form-control"
                                  name="tag"
                                  value={tag}
                                  placeholder="Не вказаний"
                                  autoComplete="off"
                                  disabled
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="form-input form-input_with-help-btn">{tag}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {servicePlace && (
                      <div className="custom-columns__col">
                        <div className="custom-columns__title">Місце проходження служби</div>
                        <div className="custom-columns__info">
                          {editProfile ? (
                            <div className="edit-block">
                              <div className="form-input form-input_with-help-btn">
                                <input
                                  id="servicePlace"
                                  type="text"
                                  className="form-input__input form-control"
                                  name="servicePlace"
                                  value={servicePlace}
                                  placeholder="Не вказане"
                                  autoComplete="off"
                                  disabled
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="form-input form-input_with-help-btn">{servicePlace}</div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Passport data */}
          <div className="block-title_lg block-title_padding-bottom-sm-xl block-with-img__top-info">Паспортні дані</div>

          <div className="custom-columns_padding-sm">
            <div className="custom-columns custom-columns__grid_gap-24">
              <div className="custom-columns__row custom-columns__row_two-col custom-columns__row_border-bottom">
                <div className="custom-columns__col">
                  <div className="custom-columns__title">Область</div>
                  <div className="custom-columns__info">
                    {editProfile ? (
                      <>
                        <div className="edit-block">
                          <div className="form-input form-input_with-help-btn">
                            <InitialSelect
                              name="addressState"
                              options={optionsRegion}
                              placeholder="Не вибрано"
                              defaultValue={optionsRegion.find((o) => o.label === addressState || null)}
                              onChange={(newValue): void => {
                                const newObj = newValue as (typeof genders)[0];
                                setAddressState(newObj.label);
                                setUpdateProfile(false);
                              }}
                              onMenuOpen={(): void => {
                                if (!optionsRegion.length) {
                                  getRegionSelect(setOptionsRegion);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="form-input form-input_with-help-btn">
                        {addressState ? addressState : 'Не вказано'}
                      </div>
                    )}
                  </div>
                </div>
                <div className="custom-columns__col">
                  <div className="custom-columns__title">Місто</div>
                  <div className="custom-columns__info">
                    {editProfile ? (
                      <>
                        <div className="edit-block">
                          <div className="form-input form-input_with-help-btn">
                            <input
                              id="addressCity"
                              type="text"
                              className="form-input__input  form-control"
                              name="addressCity"
                              onKeyDown={validateInputCharacterHelper}
                              value={addressCity}
                              onChange={(event): void => {
                                setAddressCity(event.currentTarget.value);
                                setUpdateProfile(false);
                              }}
                              placeholder="Не вказано"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="form-input form-input_with-help-btn">
                        {addressCity ? addressCity : 'Не вказано'}
                      </div>
                    )}
                  </div>
                </div>
                <div className="custom-columns__col">
                  <div className="custom-columns__title">Адреса</div>
                  <div className="custom-columns__info">
                    {editProfile ? (
                      <>
                        <div className="edit-block">
                          <div className="form-input form-input_with-help-btn">
                            <input
                              id="addressAddress"
                              type="text"
                              className="form-input__input form-control"
                              name="addressAddress"
                              value={addressAddress}
                              onKeyDown={validateInputCharacterHelper}
                              data-number={true}
                              onChange={(event): void => {
                                setAddressAddress(event.currentTarget.value);
                                setUpdateProfile(false);
                              }}
                              placeholder="Не вказано"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="form-input form-input_with-help-btn">
                        {addressAddress ? addressAddress : 'Не вказано'}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="custom-columns__row custom-columns__row_two-col">
                <div className="custom-columns__col">
                  <div className="custom-columns__title custom-columns__title_required">Документ</div>
                  <div className="custom-columns__info">
                    {editProfile ? (
                      <>
                        <div className="edit-block">
                          <div className="form-input form-input_with-help-btn">
                            <InitialSelect
                              name="typeDoc"
                              options={documentTypeEntities}
                              className={classNames('form-input__input form-control', {
                                'error-input': inputTypeDocError.length,
                              })}
                              placeholder="Не вибрано"
                              defaultValue={documentTypeEntities?.find(({ code }) => code === typeDoc)}
                              onChange={(newValue): void => {
                                const newObj = newValue as (typeof documentTypeEntities)[0];
                                setTypeDoc(newObj.code);
                                setUpdateProfile(false);
                                setSeriesDoc('');
                                setNumberDoc('');
                                setIssueDoc('');
                                setExparedDate(null);
                                setDateIssue(null);
                                setInputSeriesDocError('');
                                setInputNumberDocError('');
                                setInputTypeDocError('');
                                setInputIssueDocError('');
                                setInputExpiredDateError('');
                                setInputDateIssueError('');
                              }}
                            />
                          </div>
                        </div>
                        {inputTypeDocError && (
                          <div className="error-msg" key={inputTypeDocError}>
                            {inputTypeDocError}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="form-input form-input_with-help-btn">
                        {typeDoc
                          ? documentTypeEntities?.find(({ code }) => code === typeDoc)?.label || 'Не вказано'
                          : 'Не вказано'}
                      </div>
                    )}
                  </div>
                </div>

                {(typeDoc === DocumentTypeCode.PASSPORT ||
                  typeDoc === DocumentTypeCode.INTERNATIONAL_PASSPORT ||
                  typeDoc === DocumentTypeCode.OLD_RESIDENCE) && (
                  <div className="custom-columns__col">
                    <div className="custom-columns__title custom-columns__title_required">Серія документа</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <>
                          <div className="edit-block">
                            <div className="form-input form-input_with-help-btn">
                              <InputTextMask
                                mask={
                                  typeDoc === DocumentTypeCode.PASSPORT || typeDoc === DocumentTypeCode.OLD_RESIDENCE
                                    ? [isUkrainianLetterRegExp, isUkrainianLetterRegExp]
                                    : [isLatinLetterRegExp, isLatinLetterRegExp]
                                }
                                className={classNames('form-input__input form-control', {
                                  'error-input': inputSeriesDocError.length,
                                })}
                                guide={false}
                                placeholder="Не вказано"
                                type="text"
                                name="seriesDoc"
                                value={seriesDoc}
                                onChange={(event): void => {
                                  const value = event.target.value.toUpperCase();
                                  if (!value) {
                                    if (
                                      typeDoc === DocumentTypeCode.PASSPORT ||
                                      typeDoc === DocumentTypeCode.OLD_RESIDENCE
                                    ) {
                                      setInputSeriesDocError(errorMessages.cyrillicOnly);
                                      setSeriesDoc(value);
                                    } else {
                                      setInputSeriesDocError(errorMessages.latinOnly);
                                      setSeriesDoc(value);
                                    }
                                  } else {
                                    setSeriesDoc(value);
                                    setUpdateProfile(false);
                                    setInputSeriesDocError('');
                                  }
                                }}
                                onBlur={(event): void => {
                                  const regex = /[ЭЪЫЁэъыё]/;
                                  const value = event.target.value.toUpperCase();
                                  if (!value) {
                                    setInputSeriesDocError(errorMessages.fieldRequired);
                                    setSeriesDoc(value);
                                  } else if (regex.test(value)) {
                                    setInputSeriesDocError(errorMessages.russianAlphabet);
                                    setSeriesDoc(value);
                                  } else if (value.length < 2) {
                                    setInputSeriesDocError(errorMessages.passportSeries);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          {inputSeriesDocError && (
                            <div className="error-msg" key={inputSeriesDocError}>
                              {inputSeriesDocError}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="form-input form-input_with-help-btn">
                          {seriesDoc ? seriesDoc : 'Не вказано'}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {(typeDoc === DocumentTypeCode.PASSPORT ||
                  typeDoc === DocumentTypeCode.ID_CARD ||
                  typeDoc === DocumentTypeCode.INTERNATIONAL_PASSPORT ||
                  typeDoc === DocumentTypeCode.RESIDENCE ||
                  typeDoc === DocumentTypeCode.OLD_RESIDENCE) && (
                  <div className="custom-columns__col">
                    <div className="custom-columns__title custom-columns__title_required">Номер документа</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <>
                          <div className="edit-block">
                            <div className="form-input form-input_with-help-btn">
                              <InputTextMask
                                mask={
                                  typeDoc === DocumentTypeCode.PASSPORT ||
                                  typeDoc == DocumentTypeCode.INTERNATIONAL_PASSPORT ||
                                  typeDoc === DocumentTypeCode.OLD_RESIDENCE
                                    ? [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
                                    : [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
                                }
                                className={classNames('form-input__input form-control', {
                                  'error-input': inputNumberDocError.length,
                                })}
                                guide={false}
                                placeholder="Не вказано"
                                type="text"
                                name="numberDoc"
                                value={numberDoc.toUpperCase()}
                                onChange={(event): void => {
                                  setNumberDoc(event.currentTarget.value);
                                  setUpdateProfile(false);
                                  setInputNumberDocError('');
                                }}
                                onBlur={(event): void => {
                                  if (!event.target.value) {
                                    setNumberDoc(event.currentTarget.value);
                                    setInputNumberDocError(errorMessages.fieldRequired);
                                  } else if (
                                    (typeDoc === DocumentTypeCode.ID_CARD || typeDoc === DocumentTypeCode.RESIDENCE) &&
                                    event.target.value.match(/\d/g)?.length < 9
                                  ) {
                                    setNumberDoc(event.currentTarget.value);
                                    setInputNumberDocError(
                                      `Номер ${typeDoc === DocumentTypeCode.ID_CARD ? 'id картки' : 'посвідки'} ${
                                        errorMessages.passportNumberLength
                                      }`,
                                    );
                                  } else if (
                                    typeDoc === DocumentTypeCode.OLD_RESIDENCE &&
                                    event.target.value.match(/\d/g)?.length < 6
                                  ) {
                                    setNumberDoc(event.currentTarget.value);
                                    setInputNumberDocError('Номер посвідки має містити 6 цифр');
                                  } else if (
                                    typeDoc !== DocumentTypeCode.RESIDENCE &&
                                    event.target.value.match(/\d/g)?.length < 6
                                  ) {
                                    setNumberDoc(event.currentTarget.value);
                                    setInputNumberDocError(errorMessages.passportNumber);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          {inputNumberDocError && (
                            <div className="error-msg" key={inputNumberDocError}>
                              {inputNumberDocError}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="form-input form-input_with-help-btn">
                          {numberDoc ? numberDoc : 'Не вказано'}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {(typeDoc === DocumentTypeCode.PASSPORT ||
                  typeDoc === DocumentTypeCode.ID_CARD ||
                  typeDoc === DocumentTypeCode.INTERNATIONAL_PASSPORT ||
                  typeDoc === DocumentTypeCode.RESIDENCE ||
                  typeDoc === DocumentTypeCode.OLD_RESIDENCE) && (
                  <div className="custom-columns__col">
                    <div className="custom-columns__title custom-columns__title_required">Яким органом видано</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <>
                          <div className="edit-block">
                            <div className="form-input form-input_with-help-btn">
                              {typeDoc === DocumentTypeCode.PASSPORT || typeDoc === DocumentTypeCode.OLD_RESIDENCE ? (
                                <input
                                  id="issueDoc"
                                  type="text"
                                  className={classNames('form-input__input form-control', {
                                    'error-input': inputIssueDocError.length,
                                  })}
                                  name="issueDoc"
                                  value={issueDoc}
                                  onChange={(event): void => {
                                    const value = event.currentTarget.value.toUpperCase();
                                    if (!value) {
                                      setIssueDoc(value);
                                      setInputIssueDocError(errorMessages.fieldRequired);
                                    } else if (isRussianLetters(value)) {
                                      setIssueDoc(value);
                                      setInputIssueDocError(errorMessages.russianAlphabet);
                                    } else if (isLatinLetters(value)) {
                                      setIssueDoc(value);
                                      setInputIssueDocError(errorMessages.latinAlphabet);
                                    } else {
                                      setIssueDoc(value);
                                      setUpdateProfile(false);
                                      setInputIssueDocError('');
                                    }
                                  }}
                                  placeholder="Не вказано"
                                  autoComplete="off"
                                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                                />
                              ) : (
                                <InputTextMask
                                  mask={[/\d/, /\d/, /\d/, /\d/]}
                                  className={classNames('form-input__input form-control', {
                                    'error-input': inputIssueDocError.length,
                                  })}
                                  placeholder="Не вказано"
                                  type="text"
                                  guide={false}
                                  name="issueDoc"
                                  value={issueDoc.toUpperCase()}
                                  onChange={(event): void => {
                                    const value = event.currentTarget.value.toUpperCase();
                                    if (!value) {
                                      setIssueDoc(value);
                                      setInputIssueDocError(errorMessages.digitsOnly);
                                    } else {
                                      setIssueDoc(value);
                                      setUpdateProfile(false);
                                      setInputIssueDocError('');
                                    }
                                  }}
                                  onBlur={(event): void => {
                                    const value = event.currentTarget.value.toUpperCase();
                                    if (!value) {
                                      setIssueDoc(value);
                                      setInputIssueDocError(errorMessages.fieldRequired);
                                    } else if (value.match(/\d/g)?.length < 4) {
                                      setIssueDoc(value);
                                      setInputIssueDocError(errorMessages.issuingAuthority);
                                    }
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          {inputIssueDocError && (
                            <div className="error-msg" key={inputIssueDocError}>
                              {inputIssueDocError}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="form-input form-input_with-help-btn">{issueDoc ? issueDoc : 'Не вказано'}</div>
                      )}
                    </div>
                  </div>
                )}

                {(typeDoc === DocumentTypeCode.PASSPORT ||
                  typeDoc === DocumentTypeCode.ID_CARD ||
                  typeDoc === DocumentTypeCode.INTERNATIONAL_PASSPORT ||
                  typeDoc === DocumentTypeCode.RESIDENCE ||
                  typeDoc === DocumentTypeCode.OLD_RESIDENCE) && (
                  <div className="custom-columns__col">
                    <div className="custom-columns__title custom-columns__title_required">Дата видачі</div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <>
                          <div className="edit-block">
                            <div className="form-input form-input_with-help-btn">
                              <DatePicker
                                selected={dateIssue || ''}
                                id="dateIssue"
                                name="dateIssue"
                                className={classNames('form-input__input form-control', {
                                  'error-input': inputDateIssueError.length,
                                })}
                                showMonthDropdown
                                showYearDropdown
                                open={dateIssuePicker}
                                dateFormat={DATE_FORMAT}
                                locale="uk"
                                placeholderText="Не визначена"
                                dropdownMode="select"
                                minDate={minDate}
                                maxDate={todaysDate}
                                ref={dateIssuePickerRef}
                                onChange={(date): void => {
                                  if (date) {
                                    setDateIssue(date);
                                    setDateIssuePicker(false);
                                    setUpdateProfile(false);
                                    setInputDateIssueError('');
                                  } else {
                                    setDateIssue(null);
                                    setUpdateProfile(false);
                                  }
                                }}
                                onBlur={(): void => {
                                  setDateIssuePicker(false);
                                }}
                                onSelect={(): void => {
                                  setExparedDatePicker(false);
                                }}
                                customInput={
                                  <IMaskInput
                                    mask={Date}
                                    max={todaysDate}
                                    min={minDate}
                                    value={dateIssue ? dateIssue.toLocaleDateString('uk-UA') : ''}
                                    lazy={false}
                                    onAccept={(_, mask): void => {
                                      if (isNotDigit(mask.unmaskedValue)) {
                                        setDateIssue(null);
                                        dateIssuePickerRef.current.state.preSelection = null;
                                        dateIssuePickerRef.current.clear();
                                      }
                                    }}
                                    onComplete={(value): void => {
                                      const newDateIssue = new Date(formatDateStringToISO(value as string));

                                      setDateIssue(newDateIssue);
                                      setDateIssuePicker(false);
                                      setInputDateIssueError('');
                                      areDatesDifferent([newDateIssue, dateIssue]) && setUpdateProfile(false);
                                    }}
                                  />
                                }
                              />
                              <button
                                onClick={(): void => {
                                  setDateIssuePicker(true);
                                  // dateIssuePickerRef.current.input.element.focus();
                                }}
                              >
                                <img
                                  className="has-datetimepicker form-input__date-icon js-date-picker"
                                  src={calendarIcon}
                                  alt="icon"
                                />
                              </button>
                            </div>
                          </div>
                          {inputDateIssueError && (
                            <div className="error-msg" key={inputDateIssueError}>
                              {inputDateIssueError}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="form-input form-input_with-help-btn">
                          {dateIssue ? dateIssue.toLocaleDateString('uk-UA') : 'Не визначена'}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {(typeDoc === DocumentTypeCode.ID_CARD ||
                  typeDoc === DocumentTypeCode.INTERNATIONAL_PASSPORT ||
                  typeDoc === DocumentTypeCode.RESIDENCE ||
                  typeDoc === DocumentTypeCode.OLD_RESIDENCE) && (
                  <div className="custom-columns__col">
                    <div
                      className={classNames('custom-columns__title', {
                        'custom-columns__title_required': typeDoc !== DocumentTypeCode.OLD_RESIDENCE,
                      })}
                    >
                      Термін дії
                    </div>
                    <div className="custom-columns__info">
                      {editProfile ? (
                        <>
                          <div className="edit-block">
                            <div className="form-input form-input_with-help-btn">
                              <DatePicker
                                selected={expiredDate || ''}
                                id="expiredDate"
                                name="expiredDate"
                                className={classNames('form-input__input form-control', {
                                  'error-input': inputExpiredDateError.length,
                                })}
                                showMonthDropdown
                                showYearDropdown
                                open={expiredDatePicker}
                                dateFormat={DATE_FORMAT}
                                locale="uk"
                                placeholderText="Не визначена"
                                dropdownMode="select"
                                minDate={todaysDate}
                                ref={dateExparedPickerRef}
                                onChange={(date): void => {
                                  if (date) {
                                    setExparedDate(date);
                                    setExparedDatePicker(false);
                                    setUpdateProfile(false);
                                    setInputExpiredDateError('');
                                  } else {
                                    setExparedDate(null);
                                    setUpdateProfile(false);
                                  }
                                }}
                                onBlur={(): void => {
                                  setExparedDatePicker(false);
                                }}
                                onSelect={(): void => {
                                  setExparedDatePicker(false);
                                }}
                                customInput={
                                  <IMaskInput
                                    mask={Date}
                                    min={todaysDate}
                                    value={expiredDate ? expiredDate.toLocaleDateString('uk-UA') : ''}
                                    lazy={false}
                                    onAccept={(_, mask): void => {
                                      if (isNotDigit(mask.unmaskedValue)) {
                                        setExparedDate(null);
                                        dateExparedPickerRef.current.state.preSelection = null;
                                        dateExparedPickerRef.current.clear();
                                      }
                                    }}
                                    onComplete={(value): void => {
                                      const newExparedDate = new Date(formatDateStringToISO(value as string));

                                      setExparedDate(newExparedDate);
                                      setExparedDatePicker(false);
                                      setInputExpiredDateError('');
                                      areDatesDifferent([newExparedDate, expiredDate]) && setUpdateProfile(false);
                                    }}
                                  />
                                }
                              />
                              <button
                                onClick={(): void => {
                                  setExparedDatePicker(true);
                                  // dateExparedPickerRef.current.input.element.focus();
                                }}
                              >
                                <img
                                  className="has-datetimepicker form-input__date-icon js-date-picker"
                                  src={calendarIcon}
                                  alt="icon"
                                />
                              </button>
                            </div>
                          </div>
                          {inputExpiredDateError && (
                            <div className="error-msg" key={inputExpiredDateError}>
                              {inputExpiredDateError}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="form-input form-input_with-help-btn">
                          {expiredDate ? expiredDate.toLocaleDateString('uk-UA') : 'Не визначена'}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="custom-columns__col">
                  <div className="custom-columns__title custom-columns__title_required">Країна видачі</div>
                  <div className="custom-columns__info">
                    {editProfile ? (
                      <>
                        <div className="edit-block">
                          <div className="form-input form-input_with-help-btn">
                            <InitialSelect
                              name="issueCountry"
                              options={optionsCountry.filter(
                                ({ id }) =>
                                  id === IdConstants.UKRAINE ||
                                  typeDoc === DocumentTypeCode.RESIDENCE ||
                                  typeDoc === DocumentTypeCode.OLD_RESIDENCE,
                              )}
                              className={classNames({
                                'error-input': inputIssueCountryError.length,
                              })}
                              placeholder="Не вибрано"
                              defaultValue={
                                optionsCountry.find((x) => {
                                  return x.label === issueCountry;
                                }) || null
                              }
                              onChange={(newValue): void => {
                                const newObj = newValue as (typeof genders)[0];
                                setIssueCountry(newObj.label);
                                setInputIssueCountryError('');
                                setUpdateProfile(false);
                              }}
                            />
                          </div>
                        </div>
                        {inputIssueCountryError && (
                          <div className="error-msg" key={inputIssueCountryError}>
                            {inputIssueCountryError}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="form-input form-input_with-help-btn">
                        {issueCountry === 'UA' ? 'Україна' : !issueCountry ? 'Не визначено' : issueCountry}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="custom-columns__row custom-columns__row_two-col">
                <div className="custom-columns__col">
                  <div className="custom-columns__title">РНОКПП</div>
                  <div className="custom-columns__info">
                    {editProfile ? (
                      <>
                        <div className="edit-block">
                          <div className="form-input form-input_with-help-btn">
                            <input
                              id="drfoCode"
                              type="text"
                              className="form-input__input  form-control"
                              name="drfoCode"
                              disabled={true}
                              value={drfoCode}
                              placeholder="Не вказано"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="form-input form-input_with-help-btn">{drfoCode ? drfoCode : 'Не вказано'}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </CabinetTemplate>
  );
};

export default Profile;
