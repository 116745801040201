import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { Button } from '../../../Button';
import { ROUTES } from '../../../../router/config';
import './OrderServiceLink.scss';

interface Props {
  className?: string;
}

export const OrderServiceLink: React.FC<Props> = ({ className = '' }) => {
  const navigate = useNavigate();

  return (
    <Button
      bgColor="accent"
      className={classNames('order-service-link', className)}
      onClick={(): void => navigate(ROUTES.INTERNAL_SERVICES)}
    >
      Замовити послугу
    </Button>
  );
};
