/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { HTMLAttributeAnchorTarget, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './InfoBlock.scss';

import AuthContext from '../../../contexts/Auth/AuthContext';
import { getPublicRouteUrl } from '../../../helpers/route';
import { Slide, slides } from '../../../config/slides';

type SlideLink = Slide & {
  target: HTMLAttributeAnchorTarget;
};

const InfoBlock = (): JSX.Element => {
  const { isLoggedIn } = useContext(AuthContext);

  const slideList: SlideLink[] = useMemo(
    () =>
      slides.map((slide) => ({
        ...slide,
        link: slide.type && slide.type === 'service' ? getPublicRouteUrl(slide.link, isLoggedIn) : slide.link,
        target: slide.type && slide.type === 'external' ? '_blank' : '_self',
      })),
    [isLoggedIn],
  );

  return (
    <section>
      <div className="">
        <div className="infoBlock">
          <Swiper
            slidesPerView={1}
            className="swiper"
            navigation={false}
            pagination={{ enabled: false }}
            autoplay={{
              delay: 15000,
              disableOnInteraction: false,
            }}
            loop={true}
            speed={1200}
            effect="fade"
            modules={[Navigation, Pagination, Autoplay]}
            allowTouchMove
          >
            {slideList.map((slide) => (
              <SwiperSlide key={slide.title}>
                <div className="infoBlock__item">
                  <div className="container" style={{ height: '100%' }}>
                    <div className="infoBlock__item__content">
                      <h2 className="block-title infoBlock__item__content__title">{slide.title}</h2>
                      {slide.link && (
                        <span>
                          <Link
                            className="infoBlock__item__content__link"
                            to={slide.link}
                            target={slide.target}
                            rel="noopener, noreferrer, nofollow"
                          >
                            Детальніше
                          </Link>
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="infoBlock__item__blur" />

                  <div className="infoBlock__item__image">
                    <img src={slide.img} alt={slide.title} />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default InfoBlock;
