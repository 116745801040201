import React, { useEffect, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';

import calendarIcon from '../../../assets/images/calendar.svg';
import InitialSelect from '../Select';
import FileUpload from '../FileUpload';
import { FormConstants, TechnicalConditionDataFieldName } from '../constants';
import { DocumentTypeEntity } from '../../../helpers/reformatDocumentTypeToEntity';
import { getERZDocumentsSelect } from '../../../modules/erz/helpers';
import { DocumentTypeCode } from '../../Api/types';
import { parseFormDataValue } from '../../../helpers/parseFormDataValue';
import { NullValueGuard } from '../../../guards/NullValueGuard';
import { formatDate } from '../../../helpers/dates';
import { PreviewFilesList } from '../PreviewFilesList';
import { AccordionData } from '../../Accordion/Accordion';
import { StepsNavigationName } from '../../../config/services';

export interface TechnicalConditionFormDataProps {
  [TechnicalConditionDataFieldName.confirmTechnicalConditionDoc]?: boolean;
  [TechnicalConditionDataFieldName.confirmTechnicalConditionDocType]?: string;
  [TechnicalConditionDataFieldName.confirmTechnicalConditionDocSerialNumber]?: string;
  [TechnicalConditionDataFieldName.confirmTechnicalConditionDocIssuingAuthority]?: string;
  [TechnicalConditionDataFieldName.confirmTechnicalConditionDocIssueDate]?: string;
  [TechnicalConditionDataFieldName.confirmTechnicalConditionDocument]?: File[];
}

interface Props {
  formData: TechnicalConditionFormDataProps;
  isStepMandatory?: boolean;
  handleInputChange: (name: string, value: unknown) => void;
  handleClearInput: (name: string) => void;
}

interface PreviewProps {
  formData: TechnicalConditionFormDataProps;
  hiddenTechnicalCondition?: boolean;
}

export const TechnicalConditionData: React.FC<Props> = ({
  formData,
  isStepMandatory = false,
  handleClearInput,
  handleInputChange,
}) => {
  const [optionsPersonDoc, setOptionsPersonDoc] = useState<DocumentTypeEntity[]>([]);

  const confirmTechnicalConditionDoc = useMemo(
    () => formData.confirmTechnicalConditionDoc || isStepMandatory,
    [formData.confirmTechnicalConditionDoc, isStepMandatory],
  );
  const confirmTechnicalConditionDocType = useMemo(
    () => JSON.parse(formData.confirmTechnicalConditionDocType || null),
    [formData.confirmTechnicalConditionDocType],
  );
  const confirmTechnicalConditionDocIssueDate = useMemo(
    () =>
      formData.confirmTechnicalConditionDocIssueDate ? new Date(formData.confirmTechnicalConditionDocIssueDate) : null,
    [formData.confirmTechnicalConditionDocIssueDate],
  );

  useEffect(() => {
    if (confirmTechnicalConditionDoc) {
      (async () => {
        if (!confirmTechnicalConditionDocType) {
          const [document] = await getERZDocumentsSelect({
            allowedDocumentTypeCodes: [DocumentTypeCode.TECHNICAL_CONDITION],
            setOptions: setOptionsPersonDoc,
          });

          handleInputChange(TechnicalConditionDataFieldName.confirmTechnicalConditionDocType, document);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmTechnicalConditionDoc, confirmTechnicalConditionDocType]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    const booleanValue = value.toLowerCase() === 'true';

    if (!booleanValue) {
      [
        TechnicalConditionDataFieldName.confirmTechnicalConditionDocType,
        TechnicalConditionDataFieldName.confirmTechnicalConditionDocSerialNumber,
        TechnicalConditionDataFieldName.confirmTechnicalConditionDocIssuingAuthority,
        TechnicalConditionDataFieldName.confirmTechnicalConditionDocIssueDate,
        TechnicalConditionDataFieldName.confirmTechnicalConditionDocument,
      ].forEach(handleClearInput);
    }

    handleInputChange(name, booleanValue);
  };

  return (
    <>
      <div
        id="step-description"
        className="block-title block-title_lg block-title_padding-bottom-md-extra block-title_bg-light"
      >
        {StepsNavigationName.technicalCondition}
      </div>

      <div className="form__content">
        {!isStepMandatory && (
          <div className="form__row">
            <div className="form-input form-input_margin-bottom-none">
              <div className="form-input__label form-input__label_margin-bottom-md">
                Ви хочете додати дані документа?
              </div>
              <fieldset
                id={TechnicalConditionDataFieldName.confirmTechnicalConditionDoc}
                className="form-input__radiobuttons"
              >
                <label className="radio" htmlFor="confirmTechnicalConditionDoc_yes">
                  <input
                    className="radio__input"
                    type="radio"
                    id="confirmTechnicalConditionDoc_yes"
                    name={TechnicalConditionDataFieldName.confirmTechnicalConditionDoc}
                    value="true"
                    checked={confirmTechnicalConditionDoc}
                    onChange={handleRadioChange}
                  />
                  <span className="radio__checkmark"></span>
                  <span className="radio__label">Так</span>
                </label>

                <label className="radio" htmlFor="confirmTechnicalConditionDoc_no">
                  <input
                    className="radio__input"
                    type="radio"
                    id="confirmTechnicalConditionDoc_no"
                    name={TechnicalConditionDataFieldName.confirmTechnicalConditionDoc}
                    value="false"
                    checked={!confirmTechnicalConditionDoc}
                    onChange={handleRadioChange}
                  />
                  <span className="radio__checkmark"></span>
                  <span className="radio__label">Ні</span>
                </label>
              </fieldset>
            </div>
          </div>
        )}

        {(isStepMandatory || confirmTechnicalConditionDoc) && (
          <>
            <div className="form-input form-input_margin-bottom-none required no-group">
              <div className="form-input__label">
                Тип документа, який підтверджує відповідність технічного стану та відстрілу
              </div>
              <div className="input-select js-input-select">
                <InitialSelect
                  name={TechnicalConditionDataFieldName.confirmTechnicalConditionDocType}
                  options={optionsPersonDoc}
                  placeholder="Не вибрано"
                  defaultValue={confirmTechnicalConditionDocType}
                  onChange={(newValue, name) => handleInputChange(name, newValue)}
                  onMenuOpen={() => {
                    if (!optionsPersonDoc.length) {
                      getERZDocumentsSelect({
                        allowedDocumentTypeCodes: [DocumentTypeCode.TECHNICAL_CONDITION],
                        setOptions: setOptionsPersonDoc,
                      });
                    }
                  }}
                />
              </div>
            </div>

            <div className="form__row">
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Орган видачі</div>
                <input
                  className="form-input__input"
                  placeholder="Не вказано"
                  type="text"
                  name={TechnicalConditionDataFieldName.confirmTechnicalConditionDocIssuingAuthority}
                  value={formData.confirmTechnicalConditionDocIssuingAuthority || ''}
                  onChange={({ target: { name, value } }) => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>

              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Номер</div>
                <input
                  className="form-input__input"
                  placeholder="Не вказано"
                  type="text"
                  name={TechnicalConditionDataFieldName.confirmTechnicalConditionDocSerialNumber}
                  value={formData.confirmTechnicalConditionDocSerialNumber || ''}
                  onChange={({ target: { name, value } }) => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
            </div>

            <div className="form-input input-select_max-width form-input_margin-bottom-none required">
              <div className="form-input__label">Дата видачі</div>
              <div className="form-input__date-box">
                <DatePicker
                  locale="uk"
                  name={TechnicalConditionDataFieldName.confirmTechnicalConditionDocIssueDate}
                  placeholderText="дд.мм.рррр"
                  id={TechnicalConditionDataFieldName.confirmTechnicalConditionDocIssueDate}
                  selected={confirmTechnicalConditionDocIssueDate}
                  className="form-input__input  form-control"
                  dateFormat="dd.MM.yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={new Date()}
                  customInput={
                    <InputMask
                      mask="99.99.9999"
                      name={TechnicalConditionDataFieldName.confirmTechnicalConditionDocIssueDate}
                      value={
                        confirmTechnicalConditionDocIssueDate
                          ? confirmTechnicalConditionDocIssueDate.toLocaleDateString('uk-UA')
                          : ''
                      }
                    />
                  }
                  onChange={(date) => {
                    handleInputChange(TechnicalConditionDataFieldName.confirmTechnicalConditionDocIssueDate, date);
                  }}
                />
                <label htmlFor={TechnicalConditionDataFieldName.confirmTechnicalConditionDocIssueDate}>
                  <img
                    className="has-datetimepicker form-input__date-icon js-date-picker"
                    src={calendarIcon}
                    alt="icon"
                  />
                </label>
              </div>
            </div>

            <div className="form-input form-input_margin-bottom-none required">
              <div className="form-input__label">
                Документ, який підтверджує відповідність технічного стану та відстрілу
              </div>
              <FileUpload
                inputName={TechnicalConditionDataFieldName.confirmTechnicalConditionDocument}
                inputTitle="Додати сканкопію документа"
                value={formData.confirmTechnicalConditionDocument}
                handleInputChange={handleInputChange}
                handleClearInput={handleClearInput}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const previewTechnicalCondition = ({
  formData,
  hiddenTechnicalCondition = false,
}: PreviewProps): AccordionData => {
  return {
    title: StepsNavigationName.technicalCondition,
    content: (
      <div className="custom-columns custom-columns_grey-bg custom-columns_default-gap">
        <div className="custom-columns__row custom-columns__row_padding-tiny custom-columns__row_one-col">
          <div className="custom-columns__col">
            <div className="custom-columns__title">
              Тип документа, який підтверджує відповідність технічного стану та відстрілу
            </div>
            <div className="custom-columns__info">
              <NullValueGuard>
                {parseFormDataValue(formData[TechnicalConditionDataFieldName.confirmTechnicalConditionDocType])?.label}
              </NullValueGuard>
            </div>
          </div>
        </div>
        <div className="custom-columns__row custom-columns__row_padding-tiny">
          <div className="custom-columns__col">
            <div className="custom-columns__title">Орган видачі</div>
            <div className="custom-columns__info">
              <NullValueGuard>
                {formData[TechnicalConditionDataFieldName.confirmTechnicalConditionDocIssuingAuthority]}
              </NullValueGuard>
            </div>
          </div>
          <div className="custom-columns__col">
            <div className="custom-columns__title">Номер</div>
            <div className="custom-columns__info">
              <NullValueGuard>
                {formData[TechnicalConditionDataFieldName.confirmTechnicalConditionDocSerialNumber]}
              </NullValueGuard>
            </div>
          </div>
          <div className="custom-columns__col">
            <div className="custom-columns__title">Дата видачі</div>
            <div className="custom-columns__info">
              <NullValueGuard>
                {formatDate(formData[TechnicalConditionDataFieldName.confirmTechnicalConditionDocIssueDate])}
              </NullValueGuard>
            </div>
          </div>
          <div className="custom-columns__col">
            <div className="custom-columns__title">
              Документ, який підтверджує відповідність технічного стану та відстрілу
            </div>
            <div className="custom-columns__info">
              <NullValueGuard>
                {formData[TechnicalConditionDataFieldName.confirmTechnicalConditionDocument] ? (
                  <PreviewFilesList
                    files={formData[TechnicalConditionDataFieldName.confirmTechnicalConditionDocument]}
                  />
                ) : null}
              </NullValueGuard>
            </div>
          </div>
        </div>
      </div>
    ),
    hidden: hiddenTechnicalCondition,
  };
};
