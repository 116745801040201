import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import classNames from 'classnames';

import { validateEmail } from '../Validation';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
  label?: string;
  change: (name: string, value: unknown) => void;
}

export const EmailField: React.FC<Props> = React.memo(function EmailField({
  name = 'email',
  value = '',
  placeholder = 'example@example.com',
  required = false,
  label = 'Електронна адреса',
  change,
}) {
  const [inputEmailError, setInputEmailError] = useState<string>('');

  useEffect(() => {
    if (value) {
      const { isValid, error } = validateEmail(value);
      setInputEmailError(isValid ? '' : error);
    }
  }, []);

  return (
    <>
      <div
        className={classNames('form-input form-input_margin-bottom-none', {
          'error-div': inputEmailError.length,
          required,
        })}
      >
        <div className="form-input__label">{label}</div>
        <div className="input-select input-select_max-width">
          <input
            className="form-input__input form-control"
            placeholder={placeholder}
            type="text"
            name={name}
            value={value}
            onChange={({ target }) => {
              change(target.name, target.value.trim());
            }}
            onBlur={(event) => {
              const { isValid, error } = validateEmail(event.target.value);
              setInputEmailError(isValid ? '' : error);
            }}
          />
        </div>
        {inputEmailError && (
          <div className="error-msg" key={inputEmailError}>
            {inputEmailError}
          </div>
        )}
      </div>
    </>
  );
});
