import React from 'react';

import Accordion from '../../../../../../components/Accordion/Accordion';
import { getAccordionsText } from '../../../../../../components/Api/apiService';
import { useAsyncDataLoader } from '../../../../../../hooks/useAsyncDataLoader';
import { useAsyncEffect } from '../../../../../../hooks/useAsyncEffect';
import Loader from '../../../../../../components/Loader';
import { StepsNavigationName } from '../../../../../../config/services';
import { ServiceCodes } from '../../../../../../components/Api/types';

const Step1: React.FC = () => {
  const { isLoading: isTextLoading, data: accordionsText, load: loadText } = useAsyncDataLoader(getAccordionsText);

  useAsyncEffect(loadText, []);

  if (isTextLoading) {
    return <Loader centered />;
  }

  return (
    <>
      <div
        id="step-description"
        className="block-title block-title_lg block-title_padding-bottom-md-extra block-title_bg-light"
      >
        {StepsNavigationName.serviceDescription}
      </div>
      <Accordion
        accordionData={
          (accordionsText &&
            accordionsText[ServiceCodes.VALIDITY_PERIOD_PROLONGATION].map(
              (value) =>
                ({
                  title: <div dangerouslySetInnerHTML={{ __html: value.title }}></div>,
                  content: <div dangerouslySetInnerHTML={{ __html: value.content }}></div>,
                }) as any,
            )) ||
          []
        }
      />
    </>
  );
};

export default Step1;
