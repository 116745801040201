import { FormDataType } from '../components/Api/types';

export const markEmptyInputFieldWithAnError = (invalidField: HTMLInputElement | HTMLTextAreaElement): void => {
  const parentDiv = invalidField.type === 'file' ? invalidField.parentNode.parentNode : invalidField.parentNode;

  if (parentDiv) {
    if (!invalidField.closest('.error-div')) {
      const errorDiv = document.createElement('div');
      errorDiv.className = 'error-msg empty-field';
      errorDiv.textContent = invalidField.type !== 'file' ? `Необхідно заповнити поле` : `Необхідно додати вкладення`;
      if (invalidField.type === 'file') {
        const parent = invalidField.closest('.required');
        const parentFileDiv = parent.querySelector('.file-input__description');
        parentFileDiv.insertBefore(errorDiv, parentFileDiv.firstChild);
      } else {
        parentDiv.appendChild(errorDiv);
      }
    }

    if (parentDiv instanceof Element) {
      parentDiv.classList.add('error-div');
    }
  }
};

export const removeErrorMarkFromInputField = (inputName: string): void => {
  const input = document.querySelector(`[name='${inputName}']`);
  const parentDiv = input?.parentNode;
  const errorDiv = parentDiv?.querySelector('.empty-field');

  if (input instanceof HTMLInputElement && input.type === 'file') {
    const parent = input.closest('.required');
    if (parent) {
      const error = parent.querySelector('.error-div');

      if (error) {
        error.classList.remove('error-div');
      }
      const errorMsg = parent.querySelector('.empty-field');
      if (errorMsg) {
        errorMsg.remove();
      }
    }
  }
  if (errorDiv && parentDiv instanceof Element && parentDiv.contains(errorDiv)) {
    parentDiv.classList.remove('error-div');
    parentDiv.removeChild(errorDiv);
  }
};

export const validateRequiredFieldsToBeFilled = (
  formData: FormDataType,
): {
  hasBlankFields: boolean;
  blankInputFields: Array<HTMLInputElement | HTMLTextAreaElement>;
} => {
  const requiredRadioFields = [...document.querySelectorAll('.form-input__radiobuttons.required')];
  const requiredInputs = [...document.querySelectorAll('.required input:not([disabled])')] as HTMLInputElement[];
  const requiredTextareas = [
    ...document.querySelectorAll('.required textarea:not([disabled])'),
  ] as HTMLTextAreaElement[];

  const uncheckedRadioFields = [...requiredRadioFields].filter((fieldset) => {
    return [...fieldset.childNodes].every((labelElement) => {
      const radioInput = [...labelElement.childNodes].find(
        (inputElement) => inputElement instanceof HTMLInputElement && inputElement.type === 'radio',
      ) as HTMLInputElement;

      return !radioInput.checked;
    });
  });

  const blankInputFields = [...requiredInputs, ...requiredTextareas].filter((input) => {
    if (!(input instanceof HTMLInputElement || input instanceof HTMLTextAreaElement)) return false;
    if (!input.name || input.type === 'checkbox' || input.type === 'radio') return false;
    if (!input.value && !formData[input.name]) {
      return true;
    }

    return false;
  });

  const invalidFields = [...blankInputFields, ...uncheckedRadioFields];
  const hasBlankFields = !!invalidFields.length || !!document.querySelectorAll('.error-msg').length;

  if (hasBlankFields) {
    const firstInvalidField = invalidFields.length
      ? invalidFields[0].closest('.required')
      : document.querySelector('.error-msg');

    firstInvalidField.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }

  return {
    hasBlankFields,
    blankInputFields,
  };
};
