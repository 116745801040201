import React from 'react';
import './FloatActionButtons.scss';

import { DeafSupport } from '../DeafSupport';

export const FloatActionButtons: React.FC = () => {
  return (
    <div className="fab">
      <div className="fab__list">
        <DeafSupport className="fab__button" />
      </div>
    </div>
  );
};
