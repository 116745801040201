import React, { useContext, useMemo } from 'react';

import CrissCrossIcon from '../../../assets/images/criss-cross_gray.svg';
import { FeatureName, ServiceCodes } from '../../Api/types';
import { FeatureContext } from '../../../contexts/Features/FeatureContext';
import { Reason } from '../../../contexts/ServiceAvailability/ServiceAvailabilityContext';
import { LinkAsButton } from '../../Button';
import { CommonMsg, ReasonedMsg, TRRMsg, TRRCancelationMsg } from './Messages';
import { ROUTES, ROUTES_BY_SERVICE_CODE } from '../../../router/config';
import { ERZ_ROUTES } from '../../../modules/erz/routes/config';
import { decorateERZRoute } from '../../../modules/erz/helpers/decorateERZRoute';
import './ServiceUnAvailable.scss';

const buttons = {
  home: (
    <LinkAsButton to={ROUTES.HOME} className="form__button form__button--right-side">
      На головну
    </LinkAsButton>
  ),
  weaponRegistered: (
    <LinkAsButton to={decorateERZRoute(ERZ_ROUTES.USER.WEAPON_REGISTERED)} outline className="form__button">
      До моєї зброї
    </LinkAsButton>
  ),
};

interface Props {
  causedBy: FeatureName | Reason;
  customMsg: JSX.Element;
}

export const ServiceUnAvailable: React.FC<Props> = ({ causedBy, customMsg = null }) => {
  const { getFeatureDetails } = useContext(FeatureContext);

  const content = useMemo<JSX.Element>(() => {
    switch (causedBy) {
      case Reason.NOT_ENOUGH_DATA:
        return customMsg || <CommonMsg />;

      case Reason.DUPLICATED_APPLICATION:
        return customMsg;

      case FeatureName.erzServices:
        return <ReasonedMsg reason={getFeatureDetails(causedBy)} />;

      case FeatureName.temporaryResidenceRelocation:
        return <TRRMsg reason={getFeatureDetails(causedBy)} />;

      case FeatureName.cancellationTemporaryResidenceRelocation:
        return <TRRCancelationMsg reason={getFeatureDetails(causedBy)} />;

      default:
        break;
    }
  }, [causedBy]);

  const actionButtons = useMemo<JSX.Element>(() => {
    switch (causedBy) {
      case Reason.NOT_ENOUGH_DATA:
      case Reason.DUPLICATED_APPLICATION:
        return buttons.home;

      case FeatureName.temporaryResidenceRelocation:
        const reason = getFeatureDetails(causedBy);
        return reason === Reason.DUPLICATED_APPLICATION ? (
          buttons.home
        ) : (
          <LinkAsButton
            to={ROUTES_BY_SERVICE_CODE[ServiceCodes.CANCELLATION_TEMPORARY_RESIDENCE_RELOCATION]}
            className="form__button form__button--centered"
          >
            Скасувати
          </LinkAsButton>
        );

      case FeatureName.erzServices:
        return (
          <>
            {buttons.weaponRegistered}
            {buttons.home}
          </>
        );

      default:
        return <React.Fragment />;
    }
  }, [causedBy]);

  return (
    <>
      <div className="service-unavailable">
        <div>
          <img src={CrissCrossIcon} alt="Criss Cross" width={40} height={40} />
        </div>
        <div className="service-unavailable__content">{content}</div>
      </div>
      <div className="form__bottom">{actionButtons}</div>
    </>
  );
};
