import React, { useContext } from 'react';

import AccessibilityContext from '../../../contexts/AccessibilityContext';

import './AccessibilityPanelButton.scss';
import classNames from 'classnames';

interface Props {
  color?: 'black' | 'white';
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const AccessibilityPanelButton: React.FC<Props> = ({ onClick, color = 'white' }) => {
  const { turnOnAccessibilityPanel } = useContext(AccessibilityContext);

  return (
    <button
      onClick={(e): void => {
        turnOnAccessibilityPanel(e);
        onClick && onClick(e);
      }}
      className="media-title js-accessibility-btn accessibility-button accessibility-button__button-right"
    >
      <img
        className="media-title__media"
        src={color === 'white' ? '/images/visibility_off.svg' : '/images/eye_blue.svg'}
        alt="eye"
        width="24"
        height="24"
      />
      <span
        className={classNames('media-title__title', {
          'accessibility-button--black': color === 'black',
        })}
      >
        Доступність
      </span>
    </button>
  );
};
