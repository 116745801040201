import React, { useMemo } from 'react';
import classNames from 'classnames';
import './Status.scss';

import { IntegrationSystem } from '../Api/types';

export enum StatusColor {
  BLUE = 'blue',
  YELLOW = 'yellow',
  RED = 'red',
  GREEN = 'green',
  GREY = 'grey',

  EMPTY = 'empty',
}

const statusColorMapping = {
  [StatusColor.BLUE]: '#1E4DBA',
  [StatusColor.YELLOW]: '#BAA31E',
  [StatusColor.RED]: '#BA1E1E',
  [StatusColor.GREEN]: '#1EBA23',
  [StatusColor.GREY]: '#6F6F6F',
  [StatusColor.EMPTY]: '',
};

export interface StatusMapping<T extends string> {
  status: T | 'all';
  value: `${T}/${IntegrationSystem | 'all'}`; // not sure about IntegrationSystem | 'all'
  label: string;
  color: StatusColor;
  integrationSystem: IntegrationSystem | 'all';
}

interface Props<T extends string> {
  className?: string;
  originStatus: T;
  findIn: Array<StatusMapping<T>>;
}

export function Status<T extends string>({ className, originStatus, findIn }: Props<T>): React.JSX.Element {
  const status = useMemo(
    () =>
      findIn.find((mappedStatus) => mappedStatus.status === originStatus) || {
        value: 'unknown',
        label: 'Статус не знайдено',
        color: StatusColor.GREY,
      },
    [originStatus, findIn],
  );
  const color = useMemo(() => statusColorMapping[status.color], [status]);

  return (
    <div
      className={classNames('status', { className })}
      style={{
        backgroundColor: color,
        color: color ? '#fff' : null,
      }}
    >
      {status.label}
    </div>
  );
}
