import React from 'react';

type Props = {
  text: string;
  icon?: string;
};

export const Informer: React.FC<Props> = ({ text, icon }) => (
  <div className="information">
    {icon && <img src={icon} alt="no data icon" />}
    <div className="block-title block-title_sm block-title_supportive">{text}</div>
  </div>
);
