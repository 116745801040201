import React from 'react';

import { ValidateInputValueResult, validatePhone } from '../Validation';
import { getFormattingPhone } from '../../../helpers/phoneHelpers';
import { MultipleInputField } from './MultipleInputField';
import { InputField, InputFieldProps } from './InputField';

type PhoneFieldProps = InputFieldProps & {
  ignorePhoneCodeValidation?: boolean;
};

export const PhoneField: React.FC<PhoneFieldProps> = React.memo(function PhoneField({
  ignorePhoneCodeValidation = false,
  ...props
}) {
  return (
    <InputField
      label="Контактний телефон"
      placeholder="+380 (__) ___-__-__"
      validator={(phoneValue: string): ValidateInputValueResult => validatePhone(phoneValue, ignorePhoneCodeValidation)}
      {...props}
      withMask
      mask="+380 (99) 999-99-99"
      inputMaskType="tel"
      change={(name, value): void => {
        const formattedValue = getFormattingPhone(value as string);

        props.change(name, formattedValue);
      }}
    />
  );
});

interface MultiplePhoneFieldProps {
  required?: boolean;
  label?: string;
  placeholder?: string;
  maxPhoneNumbersQuantity?: number;
  ignorePhoneCodeValidation?: boolean;
  defaultPhoneValues: string[];
  onChange: (value: string[]) => void;
}

export const MultiplePhoneField: React.FC<MultiplePhoneFieldProps> = React.memo(function PhoneField({
  required = false,
  label = 'Контактний телефон',
  placeholder = '+380 (__) ___-__-__',
  maxPhoneNumbersQuantity = 2,
  ignorePhoneCodeValidation = false,
  defaultPhoneValues = [],
  onChange,
}) {
  return (
    <MultipleInputField
      label={label}
      withMask
      mask="+380 (99) 999-99-99"
      inputMaskType="tel"
      inputPlaceholder={placeholder}
      addInputButtonText="Додати ще один телефон"
      maxInputsQuantity={maxPhoneNumbersQuantity}
      defaultValues={defaultPhoneValues}
      formatValue={getFormattingPhone}
      required={required}
      validateUniqueness
      uniquenessErrorMessage="Цей номер телефону вже введено"
      onChange={onChange}
      validator={(phoneValue: string): ValidateInputValueResult => validatePhone(phoneValue, ignorePhoneCodeValidation)}
    />
  );
});
