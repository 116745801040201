import { useAsyncDataLoader } from '../../hooks/useAsyncDataLoader';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';

export const useTipUserList = (): string[] => {
  const { data = [], load } = useAsyncDataLoader<string[]>(async () => {
    if (!process.env.REACT_APP_USERS_FROM) return [];

    const res = await fetch(process.env.REACT_APP_USERS_FROM);
    return res.json();
  });

  useAsyncEffect(() => load(), []);

  return data;
};
