import { getERZDocumentTypeEntites } from './getERZDocumentTypeEntites';
import { DocumentType, IntegrationSystem } from '../../../components/Api/types';
import { DocumentTypeEntity } from '../../../helpers/reformatDocumentTypeToEntity';

export interface GetERZDocumentsSelectOptions {
  allowedDocumentTypeCodes?: [DocumentType<IntegrationSystem.ERZ>['code']];
  disallowedDocumentTypeKinds?: Array<DocumentType<IntegrationSystem.ERZ>['kind']>;
  setOptions?: (newOptions: Array<DocumentTypeEntity<IntegrationSystem.ERZ>>) => void;
}

export const getERZDocumentsSelect = async ({
  allowedDocumentTypeCodes,
  disallowedDocumentTypeKinds = [],
  setOptions,
}: GetERZDocumentsSelectOptions = {}): Promise<Array<DocumentTypeEntity<IntegrationSystem.ERZ>>> => {
  const entities = await getERZDocumentTypeEntites(allowedDocumentTypeCodes, disallowedDocumentTypeKinds);
  setOptions && setOptions(entities);

  return entities;
};
