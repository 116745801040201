import { ServiceCodes } from '../../Api/types';

export enum ServiceInformerBorderColor {
  YELLOW = 'service-informer--yellow',
  BLUE = 'service-informer--blue',
  RED = 'service-informer--red',
}

export enum ServiceInformerType {
  PAYMENT_DETAILS = 'paymentDetails',
  CLUE = 'clue',
  CONTACT_CENTER = 'contactCenter',
  PERSONS_STATUS = 'personsStatus',
  WEAPON_DATA = 'weaponData',
  INSURANCE_DATA = 'insuranceData',
  TECHNICAL_CONDITION_DOCUMENT_DATA = 'technicalConditionDocumentData',
  TECHNICAL_CONDITION_DETAILS = 'technicalConditionDetails',
  VALIDITY_PERIOD_VIOLATION = 'validityPeriodViolation',
  PLACE_OF_RESIDENCE = 'placeOfResidence',
  MISSING_PERSON_DETAILS = 'missingPersonDetails',
}

interface PaymentDetailsProps {
  type: ServiceInformerType.PAYMENT_DETAILS;
  serviceCode:
    | ServiceCodes.PURCHASE
    | ServiceCodes.COLD_STEEL_TRANSPORTATION
    | ServiceCodes.TRANSPORT_ACROSS_BORDER
    | ServiceCodes.STORAGE_CARRYING
    | ServiceCodes.COLD_STEEL_STORAGE_FOR_COLLECTION
    | ServiceCodes.VALIDITY_PERIOD_PROLONGATION;
}

interface ClueProps {
  type: ServiceInformerType.CLUE;
  serviceCode?: never;
}

interface ContactCenterProps {
  type: ServiceInformerType.CONTACT_CENTER;
  serviceCode?: ServiceCodes.MISSING_PERSON | never;
}

interface PersonsStatusProps {
  type: ServiceInformerType.PERSONS_STATUS;
  serviceCode?: never;
}

interface WeaponDataProps {
  type: ServiceInformerType.WEAPON_DATA;
  serviceCode?: never;
}

interface InsuranceDataProps {
  type: ServiceInformerType.INSURANCE_DATA;
  serviceCode?: never;
}

interface TechnicalConditionDocumentDataProps {
  type: ServiceInformerType.TECHNICAL_CONDITION_DOCUMENT_DATA;
  serviceCode?: never;
}

interface TechnicalConditionDataProps {
  type: ServiceInformerType.TECHNICAL_CONDITION_DETAILS;
  serviceCode?: never;
}

interface ValidityPeriodViolationProps {
  type: ServiceInformerType.VALIDITY_PERIOD_VIOLATION;
  serviceCode?: never;
}

interface PlaceOfResidenceProps {
  type: ServiceInformerType.PLACE_OF_RESIDENCE;
  serviceCode?: never;
}

interface MissingPersonDetailsProps {
  type: ServiceInformerType.MISSING_PERSON_DETAILS;
  serviceCode?: never;
}

export type Props =
  | PaymentDetailsProps
  | ClueProps
  | ContactCenterProps
  | PersonsStatusProps
  | WeaponDataProps
  | InsuranceDataProps
  | TechnicalConditionDocumentDataProps
  | TechnicalConditionDataProps
  | ValidityPeriodViolationProps
  | PlaceOfResidenceProps
  | MissingPersonDetailsProps;
