import React, { useEffect, useState } from 'react';

import './LayoutAlertMessage.scss';

const localStorageAlertKeyName = 'showAlert';

function getShowAlertLocaleStorageState() {
  return JSON.parse(window.localStorage.getItem(localStorageAlertKeyName));
}

export function handleAlertMessage(action: 'show' | 'hide'): void {
  window.localStorage.setItem(localStorageAlertKeyName, JSON.stringify(action === 'show'));
}

const LayoutAlertMessage = (): JSX.Element => {
  const [showAlert, setAlertState] = useState<boolean>(false);
  // const isCloseAlert = alertState ? '' : 'alert-message_closed'

  useEffect(() => {
    if (window.localStorage.getItem(localStorageAlertKeyName) === null) {
      handleAlertMessage('show');
      setAlertState(true);
    } else {
      setAlertState(getShowAlertLocaleStorageState());
    }
  }, []);

  const closeAlertHandler = () => {
    handleAlertMessage('hide');
    setAlertState(false);
  };

  if (!showAlert) {
    return <React.Fragment />;
  }

  return (
    <div className="alert-message alert-message_warning js-index-alert">
      <div className="container alert-message__container">
        <div className="alert-message__title alert-message__title_mvs">
          <div className="alert-message__title-name">Cервіс перебуває в режимі тестової експлуатації</div>
        </div>
        <button onClick={closeAlertHandler} className="alert-message__close-button">
          Сховати повідомлення
        </button>
      </div>
    </div>
  );
};

export default LayoutAlertMessage;
