import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, CheckboxProps, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

import MainTemplate from '../../../templates/MainTemplate/MainTemplate';
import ServiceMenuListMockup from '../../../components/Home/ServiceMenuListMockup';
import { api } from '../../../components/Api';
import { useAsyncDataLoader } from '../../../hooks/useAsyncDataLoader';
import { FlatList } from '../../../components/Home/FlatList';
import { Button } from '../../../components/Button';
import SearchWhiteIcon from '../../../assets/images/search-white.svg';
import { ServiceType, UserService } from '../../../components/Api/types';
import SadSmileIcon from '../../../assets/images/sad_smile.svg';
import './InternalServicesPage.scss';
import { HeaderWithGoBackButton } from '../../../components/Forms/HeaderWithGoBackButton';
import { ROUTES } from '../../../router/config';

const CheckboxGroup = Checkbox.Group;
const MIN_LETTERS_TO_FILTER = 3;

const InternalServicesPage: React.FC = () => {
  const navigate = useNavigate();

  const {
    isLoading: isServicesLoading,
    load: loadServices,
    data: services,
  } = useAsyncDataLoader(async () => (await api.services.getAll()).data);
  const {
    isLoading: isCategoriesLoading,
    data: categories,
    load: loadCategories,
  } = useAsyncDataLoader(async () => (await api.services.categories()).data);

  const [filteredServices, setFilteredServices] = useState<UserService[]>([]);
  const [filterText, setFilterText] = useState<string>('');
  const [checkedFilters, setCheckedFilters] = useState<string[]>([]);

  const defaultCategories = useMemo<string[]>(
    () =>
      (categories &&
        categories.reduce((acc, category) => {
          acc.push(category.name);
          return acc;
        }, [] as string[])) ||
      [],
    [categories],
  );
  const checkAll = useMemo<boolean>(
    () => defaultCategories && defaultCategories.length === checkedFilters.length,
    [defaultCategories, checkedFilters],
  );
  const indeterminate = useMemo<boolean>(
    () => checkedFilters && checkedFilters.length > 0 && checkedFilters.length < defaultCategories.length,
    [defaultCategories, checkedFilters],
  );

  const filterServicesByText = useCallback<(servicesToFilter: UserService[]) => UserService[]>(
    (servicesToFilter: UserService[]) =>
      filterText.length < MIN_LETTERS_TO_FILTER
        ? servicesToFilter
        : servicesToFilter.filter((service) => service.name.toLowerCase().includes(filterText.toLowerCase())),
    [filterText],
  );
  const filterServicesByCategory = useCallback<(servicesToFilter: UserService[]) => UserService[]>(
    (servicesToFilter) =>
      categories
        ? servicesToFilter.filter((service) =>
            checkedFilters.includes(categories.find((category) => category.id === service.category_id).name),
          )
        : [],
    [categories, checkedFilters],
  );
  const onCheckAllChange = useCallback<CheckboxProps['onChange']>(
    (e) => {
      setCheckedFilters(e.target.checked ? defaultCategories : []);
    },
    [defaultCategories],
  );

  useEffect(() => {
    loadServices();
    loadCategories();
  }, []);

  useEffect(() => {
    setCheckedFilters(defaultCategories);
  }, [defaultCategories]);

  useEffect(() => {
    if (services) {
      setFilteredServices(filterServicesByText(filterServicesByCategory(services)));
    }
  }, [services, categories, checkedFilters]);

  return (
    <MainTemplate>
      <section id="services" className="section-block section-light internal-services-page">
        <div className="container">
          <div className="internal-services-page__header">Послуги</div>
          <div className="internal-services-page__header--mobile">
            <HeaderWithGoBackButton
              header="На головну"
              hideTitleOverFlow
              goBackText="На головну"
              onGoBack={(): void => navigate(ROUTES.HOME)}
            />
          </div>
        </div>
        <div className="container">
          <div className="internal-services-page__container">
            {(isServicesLoading || isCategoriesLoading) && <ServiceMenuListMockup classType="loading" />}

            {!(isServicesLoading || isCategoriesLoading) && (
              <>
                <div className="internal-services-page__search-block">
                  <div className="internal-services-page__search-block__header-container">
                    <div className="internal-services-page__search-block__header-container__header">Пошук</div>
                  </div>
                  <div className="internal-services-page__search-block__input-container">
                    <Input
                      allowClear={{
                        clearIcon: (
                          <Button
                            type="reset"
                            outline
                            withoutBorder
                            onClick={(): void => {
                              setFilterText('');
                              setFilteredServices(filterServicesByCategory(services));
                            }}
                            className="internal-services-page__search-block__input-container__clear-button"
                          >
                            <img src="/images/close_grey.svg" alt="clear" />
                          </Button>
                        ),
                      }}
                      onChange={(e): void => setFilterText(e.currentTarget.value)}
                      name="filter"
                      value={filterText}
                      placeholder={`Введіть від ${MIN_LETTERS_TO_FILTER} літер`}
                      onPressEnter={(): void => setFilteredServices(filterServicesByText(filteredServices))}
                    />
                    <Button
                      className="internal-services-page__search-block__input-container__search-button"
                      disabled={filterText.length < MIN_LETTERS_TO_FILTER}
                      onClick={(): void => setFilteredServices(filterServicesByText(filteredServices))}
                    >
                      <img src={SearchWhiteIcon} alt="search" />
                    </Button>
                  </div>
                  <div className="internal-services-page__search-block__checkbox-container">
                    <div className="internal-services-page__search-block__checkbox-container__header">Категорії</div>
                    <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                      Всі
                    </Checkbox>
                    <CheckboxGroup
                      options={defaultCategories}
                      value={checkedFilters}
                      onChange={setCheckedFilters}
                      className="internal-services-page__search-block__checkbox-container__checkbox-group"
                    />
                  </div>
                </div>
                <div className="internal-services-page__services-block">
                  {filteredServices.length ? (
                    <FlatList servicesType={ServiceType.INTERNAL} list={filteredServices} />
                  ) : (
                    EmptyResultHTML
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </MainTemplate>
  );
};

export const EmptyResultHTML = (
  <div className="internal-services-page__services-block__empty-filter-result">
    <div>
      <img src={SadSmileIcon} alt="sad smile" />
    </div>
    <div>
      <div>Не знайдено жодної інформації</div>
    </div>
  </div>
);

export default InternalServicesPage;
