import React, { useMemo, useState } from 'react';

import InitialSelect, { SelectOption } from '../Select';
import { getOrgNpuSelect } from '../../Api/apiService';
import Accordion, { AccordionData } from '../../../components/Accordion/Accordion';
import { StepsNavigationName } from '../../../config/services';
import { PayloadGroupMockup } from '../constants';
import { fieldNamesForServerFactory } from '../../../helpers/fieldNamesForServerFactory';

export enum ConfirmationOfApplicationFieldName {
  orgNpu = 'orgNpu',
  allowToDataProcessing = 'allowToDataProcessing',
}

const payloadGroupName = 'org_npu';

export const confirmationOfApplicationDataPayloadGroupMockup: PayloadGroupMockup<typeof payloadGroupName> = {
  [payloadGroupName]: {
    id: null,
    name: null,
  },
};

export const confirmationOfApplicationDataFieldNamesForServer = fieldNamesForServerFactory({
  groupName: payloadGroupName,
  fields: [
    {
      localFieldName: ConfirmationOfApplicationFieldName.orgNpu,
      serverFieldName: 'orgNpu',
    },
  ],
});
export interface ConfirmationOfApplicationFormDataProps {
  [ConfirmationOfApplicationFieldName.orgNpu]?: string;
  [ConfirmationOfApplicationFieldName.allowToDataProcessing]?: boolean;
}

interface Props {
  formData: ConfirmationOfApplicationFormDataProps;
  accordionData: AccordionData[];
  withOrgNpu?: boolean;
  handleInputChange: (name: string, value: unknown) => void;
}

export const ConfirmationOfApplication: React.FC<Props> = ({
  formData,
  withOrgNpu = true,
  handleInputChange,
  accordionData,
}) => {
  const [optionsOrgNpu, setOptionsOrgNpu] = useState<SelectOption[]>([]);

  const orgNpu = useMemo(() => JSON.parse(formData.orgNpu || null), [formData.orgNpu]);

  const allowToDataProcessing = useMemo(
    () => formData.allowToDataProcessing || false,
    [formData.allowToDataProcessing],
  );

  return (
    <>
      <div
        id="step-description"
        className="block-title block-title_lg block-title_padding-bottom-md-extra block-title_bg-light"
      >
        {StepsNavigationName.confirmationOfApplication}
      </div>

      <Accordion accordionData={accordionData} accordionClass="accordion-confirmation" withMargin />

      <div className="form__content form__content_padding-md">
        {withOrgNpu && (
          <div className="form__row">
            <div className="form-input form-input_margin-bottom-none required">
              <div className="form-input__label">Орган НПУ, до якого надсилається заява</div>
              <div className="input-select js-input-select">
                <InitialSelect
                  name={ConfirmationOfApplicationFieldName.orgNpu}
                  options={optionsOrgNpu}
                  placeholder="Не вибрано"
                  defaultValue={orgNpu}
                  onChange={(newValue, name) => handleInputChange(name, newValue)}
                  onMenuOpen={() => {
                    if (!optionsOrgNpu.length) {
                      getOrgNpuSelect(setOptionsOrgNpu);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className="form__row">
          <div className="form-input-checkbox mb-none">
            <input
              id={ConfirmationOfApplicationFieldName.allowToDataProcessing}
              name={ConfirmationOfApplicationFieldName.allowToDataProcessing}
              className="form-input-checkbox__input"
              type="checkbox"
              checked={allowToDataProcessing}
              onChange={({ target: { name, checked } }) => handleInputChange(name, checked)}
            />
            <label
              className="form-input-checkbox__label"
              htmlFor={ConfirmationOfApplicationFieldName.allowToDataProcessing}
            >
              Я підтверджую правильність даних та даю згоду на обробку персональних даних
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
