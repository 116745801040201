import './ListItem.scss';

import { IACUserRequest } from '../../../../types';
import { IACUserRequestChannels, IACUserRequestStatuses } from '../../../../resources/uk.l10n';
import { Status } from '../../../../../../components/Status';
import { ChannelTag } from '../../../../../../components/ChannelTag';
import { api } from '../../../../../../components/Api';
import { useArtifactLoader } from '../../../../../../hooks/useArtifactLoader';
import { NullValueGuard } from '../../../../../../guards/NullValueGuard';
import { ServiceCodes } from '../../../../../../components/Api/types';
import { ServiceNames } from '../../../../../../config/services';
import { DownloadButton } from '../../../../../../pages/UserArea/UserRequests/DownloadButton';

interface Props {
  item: IACUserRequest;
}

export const ListItem: React.FC<Props> = ({ item }) => {
  const { isArtifactLoading, downloadArtifact } = useArtifactLoader((artifactType) =>
    api.iac.downloadUserRequestArtifact(item.id, artifactType),
  );

  return (
    <div className="user-request">
      <div className="row">
        <div className="column">
          <div className="column__name">Статус</div>
          <Status originStatus={item.status} findIn={IACUserRequestStatuses} />
        </div>
        <ChannelTag channel={item.channel} channels={IACUserRequestChannels} />
      </div>
      <div className="row">
        <div className="column">
          <div className="column__name">Назва</div>
          <div className="column__value">{ServiceNames[ServiceCodes.CRIMINAL_RECORD_EXTRACT]}</div>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <div className="column__name">Мета запиту</div>
          <div className="column__value">
            <NullValueGuard>{item.purpose_name}</NullValueGuard>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <div className="column__name">Дата запиту</div>
          <div className="column__value">
            <NullValueGuard>{item.created_at}</NullValueGuard>
          </div>
        </div>

        <div className="column">
          <div className="column__name">Дата витягу</div>
          <div className="column__value">
            <NullValueGuard>{item.completed_at}</NullValueGuard>
          </div>
        </div>

        <div className="column">
          <div className="column__name">№</div>
          <div className="column__value">
            <NullValueGuard>{item.esn}</NullValueGuard>
          </div>
        </div>
      </div>

      {!!item.artifacts?.length && (
        <div className="row artifacts">
          {item.artifacts.map((artifactType) => (
            <div className="column" key={artifactType}>
              <DownloadButton
                type={artifactType}
                disabled={isArtifactLoading}
                onClick={(): Promise<void> => downloadArtifact(artifactType)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
