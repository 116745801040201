import { Collapse, CollapseProps } from 'antd';
import React, { useEffect, useState } from 'react';
import './Accordion.scss';

export interface AccordionData {
  title: string | React.ReactNode;
  content: React.ReactNode;
  hidden?: boolean;
  showArrow?: boolean;
  collapsible?: CollapseProps['collapsible'];
}

interface AccordionProps {
  accordionData: AccordionData[];
  accordionClass?: string;
  expandIconPosition?: CollapseProps['expandIconPosition'];
  bordered?: CollapseProps['bordered'];
  withMargin?: boolean;
  accordion?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  accordionData,
  accordionClass,
  expandIconPosition = 'end',
  bordered,
  accordion = true,
  withMargin = true,
}) => {
  const [accordionItems, setAccordionItems] = useState<CollapseProps['items']>([]);

  useEffect(() => {
    setAccordionItems(
      accordionData.reduce(
        (acc, accordionDataItem, index) => {
          if (!accordionDataItem.hidden) {
            acc.push({
              key: index,
              label: accordionDataItem.title,
              children: accordionDataItem.content,
              showArrow: accordionDataItem.showArrow,
              collapsible: accordionDataItem.collapsible || 'header',
            });
          }
          return acc;
        },
        [] as CollapseProps['items'],
      ),
    );
  }, [accordionData]);

  return (
    <Collapse
      accordion={accordion}
      items={accordionItems}
      expandIconPosition={expandIconPosition}
      className={`accordion ${accordionClass}`}
      bordered={bordered}
      style={{
        marginBottom: withMargin ? '20px' : '0px',
      }}
    />
  );
};

export default Accordion;
