import { useEffect, useState } from 'react';

import { api } from '../components/Api';
import { InvoiceStatus } from '../components/Api/types';
import { useShortPolling } from './useShortPolling';

const DELAY_TIME = 1000 * 15; // 15sec
const TOTAL_AWAIT_TIME = 1000 * 120; // 2min

interface HookReturn extends Omit<ReturnType<typeof useShortPolling>, 'startShortPolling'> {
  startPaymentShortPolling: (requestId: number) => void;
}

export const usePaymentShortPolling = (
  saveUserRequest: (userRequest: Awaited<ReturnType<typeof api.services.draft.getDetails>>['data']) => void,
): HookReturn => {
  const [requestId, setRequestId] = useState<number>(null);

  useEffect(() => {
    if (requestId) startShortPolling();
  }, [requestId]);

  const { isShortPollingFailed, isShortPollingInProgress, isShortPollingSuccessed, startShortPolling } =
    useShortPolling({
      totalAwaitTime: TOTAL_AWAIT_TIME,
      delayTime: DELAY_TIME,
      fetchFunction: async () => {
        const { data: userRequest } = await api.services.draft.getDetails(requestId);

        saveUserRequest(userRequest);

        return userRequest;
      },
      isResponseAppropriate: (draft) => {
        const { invoice } = draft;

        return invoice.status === InvoiceStatus.FAILED || invoice.status === InvoiceStatus.SUCCESS;
      },
    });

  return {
    startPaymentShortPolling: setRequestId,
    isShortPollingFailed,
    isShortPollingInProgress,
    isShortPollingSuccessed,
  };
};
