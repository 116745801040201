import { ServiceCodes } from '../../../components/Api/types';
import { decorateMSPRoute } from '../helpers/decorateMSPRoute';

export const MSP_ROUTES = {
  USER: {
    SOCIAL_PROTECT_NEEDS: '/user/social-protect/needs',
    SOCIAL_PROTECT_MENTOR: '/user/social-protect/mentor',
    SOCIAL_PROTECT_SERVICE: '/user/social-protect/service',
    SOCIAL_PROTECT_TREATMENT: '/user/social-protect/treatment',
    SOCIAL_PROTECT_SUPPORT: '/user/social-protect/support',
  },
  SERVICES: {
    SOCIAL_PROTECT: '/services/social-protect',
  },
} as const;

export const MSP_ROUTES_BY_SERVICE_CODE = {
  [ServiceCodes.SOCIAL_PROTECT]: decorateMSPRoute(MSP_ROUTES.SERVICES.SOCIAL_PROTECT),
} as const;
