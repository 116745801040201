import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';

import FileUpload from '../FileUpload';
import InitialSelect, { SelectOption } from '../Select';
import { DocumentRegisteredDocDataFieldName, FormConstants, IdConstants, PayloadGroupMockup } from '../constants';
import { DocumentTypeCode } from '../../Api/types';
import { DocumentTypeEntity } from '../../../helpers/reformatDocumentTypeToEntity';
import { getERZDocumentsSelect } from '../../../modules/erz/helpers';
import { formatDate, getDateWithoutTime } from '../../../helpers/dates';
import {
  getCitySelect,
  getCountrySelect,
  getDistrictSelect,
  getRegionSelect,
  getStreetSelect,
} from '../../Api/apiService';
import { DeclaredDocumentsFormDataProps, DeclaredDocumentsCreate } from '../../Documents/DeclaredDocumentsCreate';
import calendarIcon from '../../../assets/images/calendar.svg';
import { DeclaredDocumentsPreview } from '../../Documents/DeclaredDocumentsPreview';
import { NullValueGuard } from '../../../guards/NullValueGuard';
import { parseFormDataValue } from '../../../helpers/parseFormDataValue';
import { PreviewFilesList } from '../PreviewFilesList';
import { AccordionData } from '../../Accordion/Accordion';
import { StepsNavigationName } from '../../../config/services';
import { HouseField } from '../Inputs/HouseField';
import { CorpsField } from '../Inputs/CorpsField';
import { FlatField } from '../Inputs/FlatField';
import { fieldNamesForServerFactory } from '../../../helpers/fieldNamesForServerFactory';

export enum PlaceOfResidenceDataFieldName {
  registeredCountry = 'registeredCountry',
  registeredRegion = 'registeredRegion',
  registeredDistrict = 'registeredDistrict',
  registeredCity = 'registeredCity',
  registeredStreet = 'registeredStreet',
  registeredHouse = 'registeredHouse',
  registeredCorps = 'registeredCorps',
  registeredFlat = 'registeredFlat',
  registeredDoc = 'registeredDoc',

  declaredCountry = 'declaredCountry',
  declaredRegion = 'declaredRegion',
  declaredDistrict = 'declaredDistrict',
  declaredCity = 'declaredCity',
  declaredStreet = 'declaredStreet',
  declaredHouse = 'declaredHouse',
  declaredCorps = 'declaredCorps',
  declaredFlat = 'declaredFlat',
}

export interface PlaceOfResidenceFormDataProps {
  [PlaceOfResidenceDataFieldName.registeredCountry]?: string;
  [PlaceOfResidenceDataFieldName.registeredRegion]?: string;
  [PlaceOfResidenceDataFieldName.registeredDistrict]?: string;
  [PlaceOfResidenceDataFieldName.registeredCity]?: string;
  [PlaceOfResidenceDataFieldName.registeredStreet]?: string;
  [PlaceOfResidenceDataFieldName.registeredHouse]?: string;
  [PlaceOfResidenceDataFieldName.registeredCorps]?: string;
  [PlaceOfResidenceDataFieldName.registeredFlat]?: string;
  [PlaceOfResidenceDataFieldName.registeredDoc]?: boolean;

  [PlaceOfResidenceDataFieldName.declaredCountry]?: string;
  [PlaceOfResidenceDataFieldName.declaredRegion]?: string;
  [PlaceOfResidenceDataFieldName.declaredDistrict]?: string;
  [PlaceOfResidenceDataFieldName.declaredCity]?: string;
  [PlaceOfResidenceDataFieldName.declaredStreet]?: string;
  [PlaceOfResidenceDataFieldName.declaredHouse]?: string;
  [PlaceOfResidenceDataFieldName.declaredCorps]?: string;
  [PlaceOfResidenceDataFieldName.declaredFlat]?: string;

  [DocumentRegisteredDocDataFieldName.registeredDocType]?: string;
  [DocumentRegisteredDocDataFieldName.registeredLocalCommunityNumber]?: string;
  [DocumentRegisteredDocDataFieldName.registeredLocalCommunityDate]?: string;
  [DocumentRegisteredDocDataFieldName.registeredLocalCommunityAuthDep]?: string;
  [DocumentRegisteredDocDataFieldName.registeredDocumentLocalCommunityDoc]?: File[];
}

export const payloadRegisteredGroupName = 'registered_address';

export const placeOfResidenceRegisteredDataPayloadGroupMockup: PayloadGroupMockup<typeof payloadRegisteredGroupName> = {
  [payloadRegisteredGroupName]: {
    country_id: null,
    country_name: null,
    region_id: null,
    region_name: null,
    city_id: null,
    city_name: null,
    street_id: null,
    street_name: null,
    house: null,
    corps: null,
    flat: null,
    district_id: null,
    district_name: null,
  },
};

export const placeOfResidenceRegisteredDataFieldNamesForServer = fieldNamesForServerFactory({
  groupName: payloadRegisteredGroupName,
  fields: [
    {
      localFieldName: PlaceOfResidenceDataFieldName.registeredCountry,
      serverFieldName: 'country',
    },
    {
      localFieldName: PlaceOfResidenceDataFieldName.registeredRegion,
      serverFieldName: 'region',
    },
    {
      localFieldName: PlaceOfResidenceDataFieldName.registeredCity,
      serverFieldName: 'city',
    },
    {
      localFieldName: PlaceOfResidenceDataFieldName.registeredDistrict,
      serverFieldName: 'district',
    },
    {
      localFieldName: PlaceOfResidenceDataFieldName.registeredStreet,
      serverFieldName: 'street',
    },
    {
      localFieldName: PlaceOfResidenceDataFieldName.registeredHouse,
      serverFieldName: 'house',
    },
    {
      localFieldName: PlaceOfResidenceDataFieldName.registeredCorps,
      serverFieldName: 'corps',
    },
    {
      localFieldName: PlaceOfResidenceDataFieldName.registeredFlat,
      serverFieldName: 'flat',
    },
  ],
});

export const payloadDeclaredGroupName = 'declared_address';

export const placeOfResidenceDeclaredDataPayloadGroupMockup: PayloadGroupMockup<typeof payloadDeclaredGroupName> = {
  [payloadDeclaredGroupName]: {
    house: null,
    corps: null,
    flat: null,
    country_id: null,
    country_name: null,
    region_id: null,
    region_name: null,
    city_id: null,
    city_name: null,
    street_id: null,
    street_name: null,
    district_id: null,
    district_name: null,
  },
};

export const placeOfResidenceDeclaredDataFieldNamesForServer = fieldNamesForServerFactory({
  groupName: payloadDeclaredGroupName,
  fields: [
    {
      localFieldName: PlaceOfResidenceDataFieldName.declaredCountry,
      serverFieldName: 'country',
    },
    {
      localFieldName: PlaceOfResidenceDataFieldName.declaredRegion,
      serverFieldName: 'region',
    },
    {
      localFieldName: PlaceOfResidenceDataFieldName.declaredCity,
      serverFieldName: 'city',
    },
    {
      localFieldName: PlaceOfResidenceDataFieldName.declaredDistrict,
      serverFieldName: 'district',
    },
    {
      localFieldName: PlaceOfResidenceDataFieldName.declaredStreet,
      serverFieldName: 'street',
    },
    {
      localFieldName: PlaceOfResidenceDataFieldName.declaredHouse,
      serverFieldName: 'house',
    },
    {
      localFieldName: PlaceOfResidenceDataFieldName.declaredCorps,
      serverFieldName: 'corps',
    },
    {
      localFieldName: PlaceOfResidenceDataFieldName.declaredFlat,
      serverFieldName: 'flat',
    },
  ],
});

interface Props {
  formData: PlaceOfResidenceFormDataProps & DeclaredDocumentsFormDataProps;
  handleInputChange: (name: string, value: unknown) => void;
  handleClearInput: (name: string) => void;
}

interface PreviewProps {
  formData: PlaceOfResidenceFormDataProps & DeclaredDocumentsFormDataProps;
}

const todaysDate = getDateWithoutTime(new Date());

export const PlaceOfResidence: React.FC<Props> = ({ formData, handleInputChange, handleClearInput }) => {
  const [optionsPersonDoc, setOptionsPersonDoc] = useState<DocumentTypeEntity[]>([]);
  const [optionsCountry, setOptionsCountry] = useState<SelectOption[]>([]);
  const [optionsRegion, setOptionsRegion] = useState<SelectOption[]>([]);
  const [optionsRegisteredDistrict, setOptionsRegisteredDistrict] = useState<SelectOption[]>([]);
  const [optionsRegisteredCity, setOptionsRegisteredCity] = useState<SelectOption[]>([]);
  const [optionsRegisteredStreet, setOptionsRegisteredStreet] = useState<SelectOption[]>([]);

  const [optionsDeclaredDistrict, setOptionsDeclaredDistrict] = useState<SelectOption[]>([]);
  const [optionsDeclaredCity, setOptionsDeclaredCity] = useState<SelectOption[]>([]);
  const [optionsDeclaredStreet, setOptionsDeclaredStreet] = useState<SelectOption[]>([]);

  const registeredCountryValue = useMemo(
    () => JSON.parse(formData.registeredCountry || null)?.id,
    [formData.registeredCountry],
  );

  const declaredCountryValue = useMemo(
    () => JSON.parse(formData.declaredCountry || null)?.id,
    [formData.declaredCountry],
  );

  const isRegisteredCountryActive = useMemo(
    () => registeredCountryValue === IdConstants.UKRAINE,
    [registeredCountryValue],
  );

  const isDeclaredCountryActive = useMemo(() => declaredCountryValue === IdConstants.UKRAINE, [declaredCountryValue]);

  const registeredCountry = useMemo(() => JSON.parse(formData.registeredCountry || null), [formData.registeredCountry]);

  const registeredRegion = useMemo(() => JSON.parse(formData.registeredRegion || null), [formData.registeredRegion]);

  const registeredDistrict = useMemo(
    () => JSON.parse(formData.registeredDistrict || null),
    [formData.registeredDistrict],
  );

  const registeredCity = useMemo(() => {
    try {
      return JSON.parse(formData.registeredCity);
    } catch (error) {
      return formData.registeredCity || null;
    }
  }, [formData.registeredCity]);

  const registeredStreet = useMemo(() => {
    try {
      return JSON.parse(formData.registeredStreet);
    } catch (error) {
      return formData.registeredStreet || null;
    }
  }, [formData.registeredStreet]);

  const registeredDocType = useMemo(() => JSON.parse(formData.registeredDocType || null), [formData.registeredDocType]);

  const registeredLocalCommunityDate = useMemo(
    () => (formData.registeredLocalCommunityDate ? new Date(formData.registeredLocalCommunityDate) : null),
    [formData.registeredLocalCommunityDate],
  );

  const declaredCountry = useMemo(() => JSON.parse(formData.declaredCountry || null), [formData.declaredCountry]);

  const declaredRegion = useMemo(() => JSON.parse(formData.declaredRegion || null), [formData.declaredRegion]);

  const declaredDistrict = useMemo(() => JSON.parse(formData.declaredDistrict || null), [formData.declaredDistrict]);

  const declaredCity = useMemo(() => {
    try {
      return JSON.parse(formData.declaredCity);
    } catch (error) {
      return formData.declaredCity || null;
    }
  }, [formData.declaredCity]);

  const declaredStreet = useMemo(() => {
    try {
      return JSON.parse(formData.declaredStreet);
    } catch (error) {
      return formData.declaredStreet || null;
    }
  }, [formData.declaredStreet]);

  const registeredDoc = useMemo(() => formData.registeredDoc || false, [formData.registeredDoc]);

  const clearRegisteredAddress = (
    name:
      | PlaceOfResidenceDataFieldName.registeredCountry
      | PlaceOfResidenceDataFieldName.registeredRegion
      | PlaceOfResidenceDataFieldName.registeredDistrict
      | PlaceOfResidenceDataFieldName.registeredCity,
  ) => {
    switch (name) {
      case PlaceOfResidenceDataFieldName.registeredCountry:
        [
          PlaceOfResidenceDataFieldName.registeredRegion,
          PlaceOfResidenceDataFieldName.registeredDistrict,
          PlaceOfResidenceDataFieldName.registeredCity,
          PlaceOfResidenceDataFieldName.registeredStreet,
        ].forEach(handleClearInput);
        setOptionsRegisteredDistrict([]);
        setOptionsRegisteredCity([]);
        setOptionsRegisteredStreet([]);
        break;

      case PlaceOfResidenceDataFieldName.registeredRegion:
        [
          PlaceOfResidenceDataFieldName.registeredDistrict,
          PlaceOfResidenceDataFieldName.registeredCity,
          PlaceOfResidenceDataFieldName.registeredStreet,
        ].forEach(handleClearInput);
        setOptionsRegisteredDistrict([]);
        setOptionsRegisteredCity([]);
        setOptionsRegisteredStreet([]);
        break;

      case PlaceOfResidenceDataFieldName.registeredDistrict:
        [PlaceOfResidenceDataFieldName.registeredCity, PlaceOfResidenceDataFieldName.registeredStreet].forEach(
          handleClearInput,
        );
        setOptionsRegisteredCity([]);
        setOptionsRegisteredStreet([]);
        break;

      case PlaceOfResidenceDataFieldName.registeredCity:
        [PlaceOfResidenceDataFieldName.registeredStreet].forEach(handleClearInput);
        setOptionsRegisteredStreet([]);
        break;

      default:
        return;
    }
  };

  const clearDeclaredAddress = (
    name:
      | PlaceOfResidenceDataFieldName.declaredCountry
      | PlaceOfResidenceDataFieldName.declaredRegion
      | PlaceOfResidenceDataFieldName.declaredDistrict
      | PlaceOfResidenceDataFieldName.declaredCity,
  ) => {
    switch (name) {
      case PlaceOfResidenceDataFieldName.declaredCountry:
        [
          PlaceOfResidenceDataFieldName.declaredRegion,
          PlaceOfResidenceDataFieldName.declaredDistrict,
          PlaceOfResidenceDataFieldName.declaredCity,
          PlaceOfResidenceDataFieldName.declaredStreet,
        ].forEach(handleClearInput);
        setOptionsDeclaredDistrict([]);
        setOptionsDeclaredCity([]);
        setOptionsDeclaredStreet([]);
        break;

      case PlaceOfResidenceDataFieldName.declaredRegion:
        [
          PlaceOfResidenceDataFieldName.declaredDistrict,
          PlaceOfResidenceDataFieldName.declaredCity,
          PlaceOfResidenceDataFieldName.declaredStreet,
        ].forEach(handleClearInput);
        setOptionsDeclaredDistrict([]);
        setOptionsDeclaredCity([]);
        setOptionsDeclaredStreet([]);
        break;

      case PlaceOfResidenceDataFieldName.declaredDistrict:
        [PlaceOfResidenceDataFieldName.declaredCity, PlaceOfResidenceDataFieldName.declaredStreet].forEach(
          handleClearInput,
        );
        setOptionsDeclaredCity([]);
        setOptionsDeclaredStreet([]);
        break;

      case PlaceOfResidenceDataFieldName.declaredCity:
        [PlaceOfResidenceDataFieldName.declaredStreet].forEach(handleClearInput);
        setOptionsDeclaredStreet([]);
        break;

      default:
        return;
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    const booleanValue = value.toLowerCase() === 'true';

    if (!booleanValue) {
      [
        PlaceOfResidenceDataFieldName.declaredCountry,
        PlaceOfResidenceDataFieldName.declaredRegion,
        PlaceOfResidenceDataFieldName.declaredDistrict,
        PlaceOfResidenceDataFieldName.declaredCity,
        PlaceOfResidenceDataFieldName.declaredStreet,
        PlaceOfResidenceDataFieldName.declaredHouse,
        PlaceOfResidenceDataFieldName.declaredCorps,
        PlaceOfResidenceDataFieldName.declaredFlat,
      ].forEach(handleClearInput);
    }
    handleInputChange(name, booleanValue);
  };

  useEffect(() => {
    const initialData = async () => {
      if (!formData.registeredCountry) {
        const countries = await getCountrySelect();
        const UKRAINE = countries.find((country) => country.id === IdConstants.UKRAINE);

        handleInputChange(PlaceOfResidenceDataFieldName.registeredCountry, UKRAINE);
      }

      if (!formData.registeredDocType) {
        const [document] = await getERZDocumentsSelect({
          allowedDocumentTypeCodes: [DocumentTypeCode.VITYAG_TERITORIALNOYI_GROMADI],
          setOptions: setOptionsPersonDoc,
        });
        handleInputChange(DocumentRegisteredDocDataFieldName.registeredDocType, document);
      }
    };

    initialData();
  }, []);

  return (
    <>
      <div
        id="step-description"
        className="block-title block-title_lg block-title_padding-bottom-md-extra block-title_bg-light"
      >
        {StepsNavigationName.currentPlaceOfResidence}
      </div>

      <div className="form__content">
        <div className="form__title">Зареєстроване місце проживання (зберігання зброї)</div>

        <div className="form__row">
          <div className="form-input form-input_margin-bottom-none required">
            <div className="form-input__label">Країна</div>
            <div className="input-select js-input-select">
              <InitialSelect
                name={PlaceOfResidenceDataFieldName.registeredCountry}
                options={optionsCountry}
                placeholder="Не вибрано"
                defaultValue={registeredCountry}
                onChange={(newValue, name) => {
                  handleInputChange(name, newValue);
                  clearRegisteredAddress(PlaceOfResidenceDataFieldName.registeredCountry);
                }}
                onMenuOpen={() => {
                  if (!optionsCountry.length) {
                    getCountrySelect(setOptionsCountry);
                  }
                }}
              />
            </div>
          </div>

          <div
            className={classNames('form-input form-input_margin-bottom-none required', {
              'disabled-div': !registeredCountry || registeredCountry?.id !== IdConstants.UKRAINE,
            })}
          >
            <div className="form-input__label">Область</div>
            <div className="input-select js-input-select">
              <InitialSelect
                name={PlaceOfResidenceDataFieldName.registeredRegion}
                options={optionsRegion}
                placeholder="Не вибрано"
                defaultValue={registeredRegion}
                disabled={!registeredCountry || registeredCountry?.id !== IdConstants.UKRAINE}
                onChange={(newValue, name) => {
                  handleInputChange(name, newValue);
                  clearRegisteredAddress(PlaceOfResidenceDataFieldName.registeredRegion);

                  if (newValue.id === IdConstants.KYIV || newValue.id === IdConstants.SEVASTOPOL) {
                    setOptionsRegisteredCity([]);
                    handleInputChange(PlaceOfResidenceDataFieldName.registeredCity, newValue);
                  }
                }}
                onMenuOpen={() => {
                  if (!optionsRegion.length) {
                    getRegionSelect(setOptionsRegion);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="form__row">
          <div
            className={classNames('form-input form-input_margin-bottom-none required', {
              'disabled-div':
                !registeredRegion ||
                registeredRegion?.id === IdConstants.KYIV ||
                registeredRegion?.id === IdConstants.SEVASTOPOL,
            })}
          >
            <div className="form-input__label">Район</div>
            <div className="input-select js-input-select">
              <InitialSelect
                name={PlaceOfResidenceDataFieldName.registeredDistrict}
                options={optionsRegisteredDistrict}
                placeholder="Не вибрано"
                defaultValue={registeredDistrict}
                disabled={
                  !registeredRegion ||
                  registeredRegion?.id === IdConstants.KYIV ||
                  registeredRegion?.id === IdConstants.SEVASTOPOL
                }
                onChange={(newValue, name) => {
                  handleInputChange(name, newValue);
                  clearRegisteredAddress(PlaceOfResidenceDataFieldName.registeredDistrict);
                }}
                onMenuOpen={() => {
                  if (!optionsRegisteredDistrict.length) {
                    getDistrictSelect(registeredRegion.id, setOptionsRegisteredDistrict);
                  }
                }}
              />
            </div>
          </div>

          <div
            className={classNames('form-input form-input_margin-bottom-none required', {
              'disabled-div':
                registeredRegion?.id !== IdConstants.KYIV &&
                registeredRegion?.id !== IdConstants.SEVASTOPOL &&
                isRegisteredCountryActive &&
                !registeredDistrict,
            })}
            key={registeredCountry?.id}
          >
            <div className="form-input__label">Назва населеного пункту</div>

            {isRegisteredCountryActive && (
              <div className="input-select js-input-select">
                <InitialSelect
                  name={PlaceOfResidenceDataFieldName.registeredCity}
                  options={optionsRegisteredCity}
                  placeholder="Не вибрано"
                  defaultValue={registeredCity}
                  disabled={
                    registeredRegion?.id === IdConstants.KYIV ||
                    registeredRegion?.id === IdConstants.SEVASTOPOL ||
                    !registeredDistrict
                  }
                  onChange={(newValue, name) => {
                    handleInputChange(name, newValue);
                    clearRegisteredAddress(PlaceOfResidenceDataFieldName.registeredCity);
                  }}
                  onMenuOpen={() => {
                    if (!optionsRegisteredCity.length) {
                      getCitySelect(registeredDistrict.id, setOptionsRegisteredCity);
                    }
                  }}
                />
              </div>
            )}

            {!isRegisteredCountryActive && (
              <div className="input-select js-input-select">
                <input
                  className="form-input__input"
                  placeholder="Не вказано"
                  type="text"
                  name={PlaceOfResidenceDataFieldName.registeredCity}
                  value={registeredCity}
                  onChange={({ target: { name, value } }) => {
                    handleInputChange(name, value);
                    clearRegisteredAddress(PlaceOfResidenceDataFieldName.registeredCity);
                  }}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
            )}
          </div>
        </div>

        <div
          className={classNames('form-input form-input_margin-bottom-none required', {
            'disabled-div': isRegisteredCountryActive && !registeredCity,
          })}
          key={registeredCountry?.id}
        >
          <div className="form-input__label">Назва вулиці</div>

          {isRegisteredCountryActive && (
            <div className="input-select js-input-select">
              <InitialSelect
                name={PlaceOfResidenceDataFieldName.registeredStreet}
                options={optionsRegisteredStreet}
                placeholder="Не вибрано"
                defaultValue={registeredStreet}
                disabled={isRegisteredCountryActive && !registeredCity}
                onChange={(newValue, name) => handleInputChange(name, newValue)}
                onMenuOpen={() => {
                  if (!optionsRegisteredStreet.length) {
                    getStreetSelect(registeredCity.id, setOptionsRegisteredStreet);
                  }
                }}
              />
            </div>
          )}

          {!isRegisteredCountryActive && (
            <div className="input-select js-input-select">
              <input
                className="form-input__input"
                placeholder="Не вказано"
                type="text"
                name={PlaceOfResidenceDataFieldName.registeredStreet}
                value={registeredStreet}
                onChange={({ target: { name, value } }) => handleInputChange(name, value)}
                maxLength={FormConstants.INPUT_MAX_LENGTH}
              />
            </div>
          )}
        </div>

        <div className="form__row">
          <HouseField
            name={PlaceOfResidenceDataFieldName.registeredHouse}
            value={formData[PlaceOfResidenceDataFieldName.registeredHouse]}
            change={handleInputChange}
            required
          />
          <CorpsField
            name={PlaceOfResidenceDataFieldName.registeredCorps}
            value={formData[PlaceOfResidenceDataFieldName.registeredCorps]}
            change={handleInputChange}
          />
          <FlatField
            name={PlaceOfResidenceDataFieldName.registeredFlat}
            value={formData[PlaceOfResidenceDataFieldName.registeredFlat]}
            change={handleInputChange}
          />
        </div>

        <div className="form__title">
          Дані документу, який підтверджує зареєстроване місце проживання (зберігання зброї)
        </div>

        <div className="form-input form-input_margin-bottom-none required no-group">
          <div className="form-input__label">
            Тип документу, який підтверджує зареєстроване місце проживання (зберігання зброї)
          </div>
          <div className="input-select js-input-select">
            <InitialSelect
              name={DocumentRegisteredDocDataFieldName.registeredDocType}
              options={optionsPersonDoc}
              placeholder="Не вибрано"
              defaultValue={registeredDocType}
              onChange={(newValue, name) => handleInputChange(name, newValue)}
              onMenuOpen={() => {
                if (!optionsPersonDoc.length) {
                  getERZDocumentsSelect({
                    allowedDocumentTypeCodes: [DocumentTypeCode.VITYAG_TERITORIALNOYI_GROMADI],
                    setOptions: setOptionsPersonDoc,
                  });
                }
              }}
            />
          </div>
        </div>

        {registeredDocType?.code === DocumentTypeCode.VITYAG_TERITORIALNOYI_GROMADI && (
          <>
            <div className="form__row form__container">
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Номер</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentRegisteredDocDataFieldName.registeredLocalCommunityNumber}
                  value={formData.registeredLocalCommunityNumber}
                  onChange={({ target: { name, value } }) => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Дата формування</div>
                <div className="form-input__date-box">
                  <DatePicker
                    locale="uk"
                    name={DocumentRegisteredDocDataFieldName.registeredLocalCommunityDate}
                    id={DocumentRegisteredDocDataFieldName.registeredLocalCommunityDate}
                    selected={registeredLocalCommunityDate}
                    placeholderText="дд.мм.рррр"
                    className="form-input__input  form-control"
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={todaysDate}
                    customInput={
                      <InputMask
                        mask="99.99.9999"
                        name={DocumentRegisteredDocDataFieldName.registeredLocalCommunityDate}
                        value={
                          registeredLocalCommunityDate ? registeredLocalCommunityDate.toLocaleDateString('uk-UA') : ''
                        }
                      />
                    }
                    onChange={(date) => {
                      handleInputChange(DocumentRegisteredDocDataFieldName.registeredLocalCommunityDate, date);
                    }}
                  />
                  <label htmlFor={DocumentRegisteredDocDataFieldName.registeredLocalCommunityDate}>
                    <img
                      className="has-datetimepicker form-input__date-icon js-date-picker"
                      src={calendarIcon}
                      alt="icon"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="form__row form__container">
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Орган видачі</div>
                <input
                  className="form-input__input"
                  type="text"
                  placeholder="Не вказано"
                  name={DocumentRegisteredDocDataFieldName.registeredLocalCommunityAuthDep}
                  value={formData.registeredLocalCommunityAuthDep}
                  onChange={({ target: { name, value } }) => handleInputChange(name, value)}
                  maxLength={FormConstants.INPUT_MAX_LENGTH}
                />
              </div>
            </div>

            <div className="form-input form-input_margin-bottom-none required">
              <div className="form-input__label">
                Документ, який підтверджує зареєстроване місце проживання (зберігання зброї)
              </div>

              <FileUpload
                inputName={DocumentRegisteredDocDataFieldName.registeredDocumentLocalCommunityDoc}
                inputTitle="Додати сканкопію документа"
                value={formData.registeredDocumentLocalCommunityDoc}
                handleInputChange={handleInputChange}
                handleClearInput={handleClearInput}
              />
            </div>
          </>
        )}

        <div className="form__row">
          <div className="form-input form-input_margin-bottom-none required">
            <div className="form-input__label form-input__label_margin-bottom-md">
              Адреса реєстрації співпадає з задекларованим місцем проживання (зберігання зброї)
            </div>
            <fieldset id={PlaceOfResidenceDataFieldName.registeredDoc} className="form-input__radiobuttons">
              <label className="radio" htmlFor={PlaceOfResidenceDataFieldName.registeredDoc + '_no'}>
                <input
                  className="radio__input"
                  type="radio"
                  id={PlaceOfResidenceDataFieldName.registeredDoc + '_no'}
                  name={PlaceOfResidenceDataFieldName.registeredDoc}
                  value="false"
                  checked={!registeredDoc}
                  onChange={handleRadioChange}
                />
                <span className="radio__checkmark"></span>
                <span className="radio__label">Так</span>
              </label>

              <label className="radio" htmlFor={PlaceOfResidenceDataFieldName.registeredDoc + '_yes'}>
                <input
                  className="radio__input"
                  type="radio"
                  id={PlaceOfResidenceDataFieldName.registeredDoc + '_yes'}
                  name={PlaceOfResidenceDataFieldName.registeredDoc}
                  value="true"
                  checked={registeredDoc}
                  onChange={handleRadioChange}
                />
                <span className="radio__checkmark"></span>
                <span className="radio__label">Ні</span>
              </label>
            </fieldset>
          </div>
        </div>

        {registeredDoc && (
          <>
            <div className="form__title">Задеклароване місце проживання (зберігання зброї)</div>
            <div className="form__row">
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Країна</div>
                <div className="input-select js-input-select">
                  <InitialSelect
                    name={PlaceOfResidenceDataFieldName.declaredCountry}
                    options={optionsCountry}
                    placeholder="Не вибрано"
                    defaultValue={declaredCountry}
                    onChange={(newValue, name) => {
                      handleInputChange(name, newValue);
                      clearDeclaredAddress(PlaceOfResidenceDataFieldName.declaredCountry);
                    }}
                    onMenuOpen={() => {
                      if (!optionsCountry.length) {
                        getCountrySelect(setOptionsCountry);
                      }
                    }}
                  />
                </div>
              </div>

              <div
                className={classNames('form-input form-input_margin-bottom-none required', {
                  'disabled-div': !declaredCountry || declaredCountry?.id !== IdConstants.UKRAINE,
                })}
              >
                <div className="form-input__label">Область</div>
                <div className="input-select js-input-select">
                  <InitialSelect
                    name={PlaceOfResidenceDataFieldName.declaredRegion}
                    options={optionsRegion}
                    placeholder="Не вибрано"
                    defaultValue={declaredRegion}
                    disabled={!declaredCountry || declaredCountry?.id !== IdConstants.UKRAINE}
                    onChange={(newValue, name) => {
                      handleInputChange(name, newValue);
                      clearDeclaredAddress(PlaceOfResidenceDataFieldName.declaredRegion);

                      if (newValue.id === IdConstants.KYIV || newValue.id === IdConstants.SEVASTOPOL) {
                        setOptionsDeclaredCity([]);
                        handleInputChange(PlaceOfResidenceDataFieldName.declaredCity, newValue);
                      }
                    }}
                    onMenuOpen={() => {
                      if (!optionsRegion.length) {
                        getRegionSelect(setOptionsRegion);
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="form__row">
              <div
                className={classNames('form-input form-input_margin-bottom-none required', {
                  'disabled-div':
                    !declaredRegion ||
                    declaredRegion?.id === IdConstants.KYIV ||
                    declaredRegion?.id === IdConstants.SEVASTOPOL,
                })}
              >
                <div className="form-input__label">Район</div>
                <div className="input-select js-input-select">
                  <InitialSelect
                    name={PlaceOfResidenceDataFieldName.declaredDistrict}
                    options={optionsDeclaredDistrict}
                    placeholder="Не вибрано"
                    defaultValue={declaredDistrict}
                    disabled={
                      !declaredRegion ||
                      declaredRegion?.id === IdConstants.KYIV ||
                      declaredRegion?.id === IdConstants.SEVASTOPOL
                    }
                    onChange={(newValue, name) => {
                      handleInputChange(name, newValue);
                      clearDeclaredAddress(PlaceOfResidenceDataFieldName.declaredDistrict);
                    }}
                    onMenuOpen={() => {
                      if (!optionsDeclaredDistrict.length) {
                        getDistrictSelect(declaredRegion.id, setOptionsDeclaredDistrict);
                      }
                    }}
                  />
                </div>
              </div>

              <div
                className={classNames('form-input form-input_margin-bottom-none required', {
                  'disabled-div':
                    declaredRegion?.id !== IdConstants.KYIV &&
                    declaredRegion?.id !== IdConstants.SEVASTOPOL &&
                    isDeclaredCountryActive &&
                    !declaredDistrict,
                })}
                key={declaredCountry?.id}
              >
                <div className="form-input__label">Назва населеного пункту</div>

                {isDeclaredCountryActive && (
                  <div className="input-select js-input-select">
                    <InitialSelect
                      name={PlaceOfResidenceDataFieldName.declaredCity}
                      options={optionsDeclaredCity}
                      placeholder="Не вибрано"
                      defaultValue={declaredCity}
                      disabled={
                        declaredRegion?.id === IdConstants.KYIV ||
                        declaredRegion?.id === IdConstants.SEVASTOPOL ||
                        !declaredDistrict
                      }
                      onChange={(newValue, name) => {
                        handleInputChange(name, newValue);
                        clearDeclaredAddress(PlaceOfResidenceDataFieldName.declaredCity);
                      }}
                      onMenuOpen={() => {
                        if (!optionsDeclaredCity.length) {
                          getCitySelect(declaredDistrict.id, setOptionsDeclaredCity);
                        }
                      }}
                    />
                  </div>
                )}

                {!isDeclaredCountryActive && (
                  <div className="input-select js-input-select">
                    <input
                      className="form-input__input"
                      placeholder="Не вказано"
                      type="text"
                      name={PlaceOfResidenceDataFieldName.declaredCity}
                      value={declaredCity}
                      onChange={({ target: { name, value } }) => {
                        handleInputChange(name, value);
                        clearDeclaredAddress(PlaceOfResidenceDataFieldName.declaredCity);
                      }}
                      maxLength={FormConstants.INPUT_MAX_LENGTH}
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              className={classNames('form-input form-input_margin-bottom-none required', {
                'disabled-div': isDeclaredCountryActive && !declaredCity,
              })}
              key={declaredCountry?.id}
            >
              <div className="form-input__label">Назва вулиці</div>

              {isDeclaredCountryActive && (
                <div className="input-select js-input-select">
                  <InitialSelect
                    name={PlaceOfResidenceDataFieldName.declaredStreet}
                    options={optionsDeclaredStreet}
                    placeholder="Не вибрано"
                    defaultValue={declaredStreet}
                    disabled={isDeclaredCountryActive && !declaredCity}
                    onChange={(newValue, name) => handleInputChange(name, newValue)}
                    onMenuOpen={() => {
                      if (!optionsDeclaredStreet.length) {
                        getStreetSelect(declaredCity.id, setOptionsDeclaredStreet);
                      }
                    }}
                  />
                </div>
              )}

              {!isDeclaredCountryActive && (
                <div className="input-select js-input-select">
                  <input
                    className="form-input__input"
                    placeholder="Не вказано"
                    type="text"
                    name={PlaceOfResidenceDataFieldName.declaredStreet}
                    value={declaredStreet}
                    onChange={({ target: { name, value } }) => handleInputChange(name, value)}
                    maxLength={FormConstants.INPUT_MAX_LENGTH}
                  />
                </div>
              )}
            </div>

            <div className="form__row">
              <HouseField
                name={PlaceOfResidenceDataFieldName.declaredHouse}
                value={formData[PlaceOfResidenceDataFieldName.declaredHouse]}
                change={handleInputChange}
                required
              />
              <CorpsField
                name={PlaceOfResidenceDataFieldName.declaredCorps}
                value={formData[PlaceOfResidenceDataFieldName.declaredCorps]}
                change={handleInputChange}
              />
              <FlatField
                name={PlaceOfResidenceDataFieldName.declaredFlat}
                value={formData[PlaceOfResidenceDataFieldName.declaredFlat]}
                change={handleInputChange}
              />
            </div>

            <div className="form__title">
              Дані документа, який підтверджує задеклароване місце проживання (зберігання зброї)
            </div>
          </>
        )}

        <DeclaredDocumentsCreate
          required
          formData={formData}
          handleInputChange={handleInputChange}
          handleClearInput={handleClearInput}
          showDocuments={registeredDoc}
        />
      </div>
    </>
  );
};

export const previewPlaceOfResidence = ({ formData }: PreviewProps): AccordionData => {
  return {
    title: 'Місце реєстрації та проживання (перебування)',
    content: (
      <>
        <div className="accordion__content-title">Адреса реєстрації</div>

        <div className="custom-columns custom-columns_grey-bg custom-columns_default-gap">
          <div className="custom-columns__row custom-columns__row_padding-tiny">
            <div className="custom-columns__col">
              <div className="custom-columns__title">Країна</div>
              <div className="custom-columns__info">
                <NullValueGuard>
                  {parseFormDataValue(formData[PlaceOfResidenceDataFieldName.registeredCountry])?.label}
                </NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">Область</div>
              <div className="custom-columns__info">
                <NullValueGuard>
                  {parseFormDataValue(formData[PlaceOfResidenceDataFieldName.registeredRegion])?.label}
                </NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">Район</div>
              <div className="custom-columns__info">
                <NullValueGuard>
                  {parseFormDataValue(formData[PlaceOfResidenceDataFieldName.registeredDistrict])?.label}
                </NullValueGuard>
              </div>
            </div>
          </div>

          <div className="custom-columns__row custom-columns__row_padding-tiny">
            <div className="custom-columns__col">
              <div className="custom-columns__title">Назва населеного пункту</div>
              <div className="custom-columns__info">
                <NullValueGuard>
                  {parseFormDataValue(formData[PlaceOfResidenceDataFieldName.registeredCity])?.label ||
                    formData[PlaceOfResidenceDataFieldName.registeredCity]}
                </NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">Назва вулиці</div>
              <div className="custom-columns__info">
                <NullValueGuard>
                  {parseFormDataValue(formData[PlaceOfResidenceDataFieldName.registeredStreet])?.label ||
                    formData[PlaceOfResidenceDataFieldName.registeredStreet]}
                </NullValueGuard>
              </div>
            </div>
          </div>

          <div className="custom-columns__row custom-columns__row_padding-tiny">
            <div className="custom-columns__col">
              <div className="custom-columns__title">Номер будинку</div>
              <div className="custom-columns__info">
                <NullValueGuard>{formData[PlaceOfResidenceDataFieldName.registeredHouse]}</NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">Номер корпусу</div>
              <div className="custom-columns__info">
                <NullValueGuard>{formData[PlaceOfResidenceDataFieldName.registeredCorps]}</NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">Номер квартири</div>
              <div className="custom-columns__info">
                <NullValueGuard>{formData[PlaceOfResidenceDataFieldName.registeredFlat]}</NullValueGuard>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion__content-title">Дані документа, який підтверджує місце реєстрації</div>
        <div className="custom-columns custom-columns_grey-bg custom-columns_default-gap">
          <div className="custom-columns__row custom-columns__row_padding-tiny">
            <div className="custom-columns__col">
              <div className="custom-columns__title">Тип документа, який підтверджує місце реєстрації</div>
              <div className="custom-columns__info">
                <NullValueGuard>
                  {parseFormDataValue(formData[DocumentRegisteredDocDataFieldName.registeredDocType])?.label}
                </NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">Орган видачі</div>
              <div className="custom-columns__info">
                <NullValueGuard>
                  {formData[DocumentRegisteredDocDataFieldName.registeredLocalCommunityAuthDep]}
                </NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">Номер</div>
              <div className="custom-columns__info">
                <NullValueGuard>
                  {formData[DocumentRegisteredDocDataFieldName.registeredLocalCommunityNumber]}
                </NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">Дата формування</div>
              <div className="custom-columns__info">
                <NullValueGuard>
                  {formatDate(formData[DocumentRegisteredDocDataFieldName.registeredLocalCommunityDate])}
                </NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">Документ, який підтверджує місце реєстрації</div>
              <div className="custom-columns__info">
                <NullValueGuard>
                  {formData[DocumentRegisteredDocDataFieldName.registeredDocumentLocalCommunityDoc] ? (
                    <PreviewFilesList
                      files={formData[DocumentRegisteredDocDataFieldName.registeredDocumentLocalCommunityDoc]}
                    />
                  ) : null}
                </NullValueGuard>
              </div>
            </div>
          </div>
        </div>
        <div className="custom-columns custom-columns_grey-bg">
          <div className="custom-columns__row custom-columns__row_padding-tiny custom-columns__row_one-col">
            <div className="custom-columns__title">
              Адреса реєстрації співпадає з задекларованим місцем проживання (перебування)
            </div>
            <div className="custom-columns__info">
              {!formData[PlaceOfResidenceDataFieldName.registeredDoc] ? 'Так' : 'Ні'}
            </div>
          </div>
        </div>
        {formData[PlaceOfResidenceDataFieldName.registeredDoc] && (
          <>
            <div className="accordion__content-title">Задеклароване місце проживання (перебування)</div>
            <div className="custom-columns custom-columns_grey-bg custom-columns_default-gap">
              <div className="custom-columns__row custom-columns__row_padding-tiny">
                <div className="custom-columns__col">
                  <div className="custom-columns__title">Країна</div>
                  <div className="custom-columns__info">
                    <NullValueGuard>
                      {parseFormDataValue(formData[PlaceOfResidenceDataFieldName.declaredCountry])?.label}
                    </NullValueGuard>
                  </div>
                </div>
                <div className="custom-columns__col">
                  <div className="custom-columns__title">Область</div>
                  <div className="custom-columns__info">
                    <NullValueGuard>
                      {parseFormDataValue(formData[PlaceOfResidenceDataFieldName.declaredRegion])?.label}
                    </NullValueGuard>
                  </div>
                </div>
                <div className="custom-columns__col">
                  <div className="custom-columns__title">Район</div>
                  <div className="custom-columns__info">
                    <NullValueGuard>
                      {parseFormDataValue(formData[PlaceOfResidenceDataFieldName.declaredDistrict])?.label}
                    </NullValueGuard>
                  </div>
                </div>
              </div>

              <div className="custom-columns__row custom-columns__row_padding-tiny">
                <div className="custom-columns__col">
                  <div className="custom-columns__title">Назва населеного пункту</div>
                  <div className="custom-columns__info">
                    <NullValueGuard>
                      {parseFormDataValue(formData[PlaceOfResidenceDataFieldName.declaredCity])?.label ||
                        formData[PlaceOfResidenceDataFieldName.declaredCity]}
                    </NullValueGuard>
                  </div>
                </div>
                <div className="custom-columns__col">
                  <div className="custom-columns__title">Назва вулиці</div>
                  <div className="custom-columns__info">
                    <NullValueGuard>
                      {parseFormDataValue(formData[PlaceOfResidenceDataFieldName.declaredStreet])?.label ||
                        formData[PlaceOfResidenceDataFieldName.declaredStreet]}
                    </NullValueGuard>
                  </div>
                </div>
              </div>

              <div className="custom-columns__row custom-columns__row_padding-tiny">
                <div className="custom-columns__col">
                  <div className="custom-columns__title">Номер будинку</div>
                  <div className="custom-columns__info">
                    <NullValueGuard>{formData[PlaceOfResidenceDataFieldName.declaredHouse]}</NullValueGuard>
                  </div>
                </div>
                <div className="custom-columns__col">
                  <div className="custom-columns__title">Номер корпусу</div>
                  <div className="custom-columns__info">
                    <NullValueGuard>{formData[PlaceOfResidenceDataFieldName.declaredCorps]}</NullValueGuard>
                  </div>
                </div>
                <div className="custom-columns__col">
                  <div className="custom-columns__title">Номер квартири</div>
                  <div className="custom-columns__info">
                    <NullValueGuard>{formData[PlaceOfResidenceDataFieldName.declaredFlat]}</NullValueGuard>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion__content-title">
              Дані документа, який підтверджує задеклароване місце проживання (перебування)
            </div>
            <DeclaredDocumentsPreview formData={formData} />
          </>
        )}
      </>
    ),
  };
};
