import React, { useEffect, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';

import FileUpload from '../../../../../../components/Forms/FileUpload';
import InitialSelect, { SelectOption } from '../../../../../../components/Forms/Select';
import calendarIcon from '../../../../../../assets/images/calendar.svg';
import { DocumentTypeEntity } from '../../../../../../helpers/reformatDocumentTypeToEntity';
import { getERZDocumentsSelect } from '../../../../helpers/getERZDocumentsSelect';
import { DocumentTypeCode } from '../../../../../../components/Api/types';
import { FormConstants, PayloadGroupMockup, PersonStatusFieldName } from '../../../../../../components/Forms/constants';
import { getIndividualStatusSelect } from '../../../../../../components/Api/apiService';
import { todaysDate } from '../PermissionRenewal';
import { NullValueGuard } from '../../../../../../guards/NullValueGuard';
import { formatDate } from '../../../../../../helpers/dates';
import { PreviewFilesList } from '../../../../../../components/Forms/PreviewFilesList';
import { parseFormDataValue } from '../../../../../../helpers/parseFormDataValue';
import { AccordionData } from '../../../../../../components/Accordion/Accordion';
import { StepsNavigationName } from '../../../../../../config/services';
import { fieldNamesForServerFactory } from '../../../../../../helpers/fieldNamesForServerFactory';

export interface PersonStatusFormDataProps {
  [PersonStatusFieldName.personAddDeclaredDoc]?: boolean;
  [PersonStatusFieldName.personStatusName]?: string;
  [PersonStatusFieldName.personStatusDocType]?: string;
  [PersonStatusFieldName.personStatusOrg]?: string;
  [PersonStatusFieldName.personStatusDocSerialNumber]?: string;
  [PersonStatusFieldName.personStatusDocIssuingAuthority]?: string;
  [PersonStatusFieldName.personStatusDocIssueDate]?: string;
  [PersonStatusFieldName.personStatusDocExpireDate]?: string;
  [PersonStatusFieldName.personStatusDocument]?: File[];
}

const payloadGroupName = 'personal_status';

export const personStatusDataPayloadGroupMockup: PayloadGroupMockup<typeof payloadGroupName> = {
  [payloadGroupName]: {
    id: null,
    name: null,
  },
};

export const personStatusDataDataFieldNamesForServer = fieldNamesForServerFactory({
  groupName: payloadGroupName,
  fields: [
    {
      localFieldName: PersonStatusFieldName.personStatusName,
      serverFieldName: 'personalStatus',
    },
  ],
});

interface Props {
  formData: PersonStatusFormDataProps;
  handleInputChange: (name: string, value: unknown) => void;
  handleClearInput: (name: string) => void;
}

interface PreviewProps {
  formData: PersonStatusFormDataProps;
}

const Step5: React.FC<Props> = ({ formData, handleInputChange, handleClearInput }) => {
  const [optionsPersonStatus, setOptionsPersonStatus] = useState<SelectOption[]>([]);
  const [optionsPersonDoc, setOptionsPersonDoc] = useState<DocumentTypeEntity[]>([]);

  const personAddDeclaredDoc = useMemo(() => formData.personAddDeclaredDoc || false, [formData.personAddDeclaredDoc]);
  const personStatusName = useMemo(() => JSON.parse(formData.personStatusName || null), [formData.personStatusName]);
  const personStatusDocType = useMemo(
    () => JSON.parse(formData.personStatusDocType || null),
    [formData.personStatusDocType],
  );
  const personStatusDocIssueDate = useMemo(
    () => (formData.personStatusDocIssueDate ? new Date(formData.personStatusDocIssueDate) : null),
    [formData.personStatusDocIssueDate],
  );
  const personStatusDocExpireDate = useMemo(
    () => (formData.personStatusDocExpireDate ? new Date(formData.personStatusDocExpireDate) : null),
    [formData.personStatusDocExpireDate],
  );

  const handleInputsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    handleInputChange(name, value);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    const booleanValue = value.toLowerCase() === 'true';

    if (!booleanValue) {
      [
        PersonStatusFieldName.personStatusDocType,
        PersonStatusFieldName.personStatusName,
        PersonStatusFieldName.personStatusOrg,
        PersonStatusFieldName.personStatusDocSerialNumber,
        PersonStatusFieldName.personStatusDocIssuingAuthority,
        PersonStatusFieldName.personStatusDocIssueDate,
        PersonStatusFieldName.personStatusDocExpireDate,
        PersonStatusFieldName.personStatusDocument,
      ].forEach(handleClearInput);
    }

    handleInputChange(name, booleanValue);
  };

  useEffect(() => {
    if (personAddDeclaredDoc) {
      (async (): Promise<void> => {
        if (!formData.personStatusDocType) {
          const [document] = await getERZDocumentsSelect({
            allowedDocumentTypeCodes: [DocumentTypeCode.CERTIFICATION],
            setOptions: setOptionsPersonDoc,
          });
          handleInputChange(PersonStatusFieldName.personStatusDocType, document);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personAddDeclaredDoc, personStatusDocType]);

  return (
    <>
      <div
        id="step-description"
        className="block-title block-title_lg block-title_padding-bottom-md-extra block-title_bg-light"
      >
        {StepsNavigationName.personStatus}
      </div>

      <div className="form__content">
        <div className="form__row">
          <div className="form-input form-input_margin-bottom-none">
            <div className="form-input__label form-input__label_margin-bottom-md">Ви хочете додати дані документа?</div>
            <fieldset id={PersonStatusFieldName.personAddDeclaredDoc} className="form-input__radiobuttons">
              <label className="radio" htmlFor={PersonStatusFieldName.personAddDeclaredDoc + '_yes'}>
                <input
                  className="radio__input"
                  type="radio"
                  id={PersonStatusFieldName.personAddDeclaredDoc + '_yes'}
                  name={PersonStatusFieldName.personAddDeclaredDoc}
                  value="true"
                  checked={personAddDeclaredDoc}
                  onChange={handleRadioChange}
                />
                <span className="radio__checkmark"></span>
                <span className="radio__label">Так</span>
              </label>

              <label className="radio" htmlFor={PersonStatusFieldName.personAddDeclaredDoc + '_no'}>
                <input
                  className="radio__input"
                  type="radio"
                  id={PersonStatusFieldName.personAddDeclaredDoc + '_no'}
                  name={PersonStatusFieldName.personAddDeclaredDoc}
                  value="false"
                  checked={!personAddDeclaredDoc}
                  onChange={handleRadioChange}
                />
                <span className="radio__checkmark"></span>
                <span className="radio__label">Ні</span>
              </label>
            </fieldset>
          </div>
        </div>

        {personAddDeclaredDoc && (
          <>
            <div className="form-input form-input_margin-bottom-none required">
              <div className="form-input__label">Назва статусу особи</div>
              <div className="input-select js-input-select">
                <InitialSelect
                  name={PersonStatusFieldName.personStatusName}
                  options={optionsPersonStatus}
                  placeholder="Не вибрано"
                  defaultValue={personStatusName}
                  onChange={(newValue, name): void => handleInputChange(name, newValue)}
                  onMenuOpen={(): void => {
                    if (!optionsPersonStatus.length) {
                      getIndividualStatusSelect(setOptionsPersonStatus);
                    }
                  }}
                />
              </div>
            </div>

            <div className="form__title">Дані документа, який підтверджує статус особи</div>

            <div className="form-input form-input_margin-bottom-none required no-group">
              <div className="form-input__label">Тип документа, який підтверджує статус особи</div>
              <div className="input-select js-input-select">
                <InitialSelect
                  name={PersonStatusFieldName.personStatusDocType}
                  options={optionsPersonDoc}
                  placeholder="Не вибрано"
                  defaultValue={personStatusDocType}
                  onChange={(newValue, name): void => handleInputChange(name, newValue)}
                  onMenuOpen={(): void => {
                    if (!optionsPersonDoc.length) {
                      getERZDocumentsSelect({
                        allowedDocumentTypeCodes: [DocumentTypeCode.CERTIFICATION],
                        setOptions: setOptionsPersonDoc,
                      });
                    }
                  }}
                />
              </div>
            </div>

            <div className="form-input form-input_margin-bottom-none required">
              <div className="form-input__label">Назва організації</div>
              <input
                className="form-input__input"
                placeholder="Не вказано"
                type="text"
                name={PersonStatusFieldName.personStatusOrg}
                value={formData.personStatusOrg || ''}
                onChange={handleInputsChange}
                maxLength={FormConstants.INPUT_MAX_LENGTH}
              />
            </div>

            <div className="form-input form-input_margin-bottom-none required">
              <div className="form-input__label">Серія та номер</div>
              <input
                className="form-input__input"
                placeholder="Не вказано"
                type="text"
                name={PersonStatusFieldName.personStatusDocSerialNumber}
                value={formData.personStatusDocSerialNumber || ''}
                onChange={handleInputsChange}
                maxLength={FormConstants.INPUT_MAX_LENGTH}
              />
            </div>

            <div className="form-input form-input_margin-bottom-none required">
              <div className="form-input__label">Орган видачі</div>
              <input
                className="form-input__input"
                placeholder="Не вказано"
                type="text"
                name={PersonStatusFieldName.personStatusDocIssuingAuthority}
                value={formData.personStatusDocIssuingAuthority || ''}
                onChange={handleInputsChange}
                maxLength={FormConstants.INPUT_MAX_LENGTH}
              />
            </div>

            <div className="form__row">
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Дата видачі</div>
                <div className="form-input__date-box">
                  <DatePicker
                    locale="uk"
                    name={PersonStatusFieldName.personStatusDocIssueDate}
                    placeholderText="дд.мм.рррр"
                    id={PersonStatusFieldName.personStatusDocIssueDate}
                    selected={personStatusDocIssueDate}
                    className="form-input__input  form-control"
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={todaysDate}
                    customInput={
                      <InputMask
                        mask="99.99.9999"
                        name={PersonStatusFieldName.personStatusDocIssueDate}
                        value={personStatusDocIssueDate ? personStatusDocIssueDate.toLocaleDateString('uk-UA') : ''}
                      />
                    }
                    onChange={(date): void => handleInputChange(PersonStatusFieldName.personStatusDocIssueDate, date)}
                  />
                  <label htmlFor={PersonStatusFieldName.personStatusDocIssueDate}>
                    <img
                      className="has-datetimepicker form-input__date-icon js-date-picker"
                      src={calendarIcon}
                      alt="icon"
                    />
                  </label>
                </div>
              </div>

              <div className="form-input form-input_margin-bottom-none">
                <div className="form-input__label">Термін дії</div>
                <div className="form-input__date-box">
                  <DatePicker
                    locale="uk"
                    name={PersonStatusFieldName.personStatusDocExpireDate}
                    id={PersonStatusFieldName.personStatusDocExpireDate}
                    placeholderText="дд.мм.рррр"
                    selected={personStatusDocExpireDate}
                    className="form-input__input  form-control"
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={todaysDate}
                    customInput={
                      <InputMask
                        mask="99.99.9999"
                        name={PersonStatusFieldName.personStatusDocExpireDate}
                        value={personStatusDocExpireDate ? personStatusDocExpireDate.toLocaleDateString('uk-UA') : ''}
                      />
                    }
                    onChange={(date): void => handleInputChange(PersonStatusFieldName.personStatusDocExpireDate, date)}
                  />
                  <label htmlFor={PersonStatusFieldName.personStatusDocExpireDate}>
                    <img
                      className="has-datetimepicker form-input__date-icon js-date-picker"
                      src={calendarIcon}
                      alt="icon"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="form-input form-input_margin-bottom-none required">
              <div className="form-input__label">Документ, який підтверджує статус особи</div>
              <FileUpload
                inputName={PersonStatusFieldName.personStatusDocument}
                inputTitle="Додати сканкопію документа"
                value={formData.personStatusDocument}
                handleInputChange={handleInputChange}
                handleClearInput={handleClearInput}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const previewPersonStatus = ({ formData }: PreviewProps): AccordionData => {
  return {
    title: StepsNavigationName.personStatus,
    content: (
      <>
        <div className="custom-columns custom-columns_grey-bg">
          <div className="custom-columns__row custom-columns__row_padding-tiny">
            <div className="custom-columns__col">
              <div className="custom-columns__title">Назва статусу особи</div>
              <div className="custom-columns__info">
                <NullValueGuard>
                  {parseFormDataValue(formData[PersonStatusFieldName.personStatusName])?.label}
                </NullValueGuard>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion__content-title">Дані документа, який підтверджує статус особи</div>
        <div className="custom-columns custom-columns_grey-bg custom-columns_default-gap">
          <div className="custom-columns__row custom-columns__row_padding-tiny">
            <div className="custom-columns__col">
              <div className="custom-columns__title">Тип документа, який підтверджує статус особи</div>
              <div className="custom-columns__info">
                <NullValueGuard>
                  {parseFormDataValue(formData[PersonStatusFieldName.personStatusDocType])?.label}
                </NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">Серія та номер</div>
              <div className="custom-columns__info">
                <NullValueGuard>{formData[PersonStatusFieldName.personStatusDocSerialNumber]}</NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">Назва організації</div>
              <div className="custom-columns__info">
                <NullValueGuard>{formData[PersonStatusFieldName.personStatusOrg]}</NullValueGuard>
              </div>
            </div>
          </div>
          <div className="custom-columns__row custom-columns__row_padding-tiny">
            <div className="custom-columns__col">
              <div className="custom-columns__title">Орган видачі</div>
              <div className="custom-columns__info">
                <NullValueGuard>{formData[PersonStatusFieldName.personStatusDocIssuingAuthority]}</NullValueGuard>
              </div>
            </div>
            <div className="custom-columns__col">
              <div className="custom-columns__title">Дата видачі</div>
              <div className="custom-columns__info">
                <NullValueGuard>{formatDate(formData[PersonStatusFieldName.personStatusDocIssueDate])}</NullValueGuard>
              </div>
            </div>
          </div>
        </div>
        <div className="custom-columns__row custom-columns__row_padding-tiny">
          <div className="custom-columns__col">
            <div className="custom-columns__title">Документ, який підтверджує статус особи</div>
            <div className="custom-columns__info">
              <NullValueGuard>
                {formData[PersonStatusFieldName.personStatusDocument] ? (
                  <PreviewFilesList files={formData[PersonStatusFieldName.personStatusDocument]} />
                ) : null}
              </NullValueGuard>
            </div>
          </div>
        </div>
      </>
    ),
  };
};

export default Step5;
