import { Routes, Route } from 'react-router-dom';
import { lazy, useContext } from 'react';

import { MSP_ROUTES } from './config';
import { SuspenseWithLoader } from '../../../components/SuspenseWithLoader';
import { PrivateRouteWrapper } from '../../../components/PrivateRouteWrapper';
import AuthContext from '../../../contexts/Auth/AuthContext';
import ErrorPage from '../../../pages/ErrorPage/ErrorPage';

const SocialProtect = lazy(() => import('../pages/services/SocialProtect'));
const SocialProtectDetails = lazy(() => import('../pages/SocialProtectDetails'));

export const MSPRouter: React.FC = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/user" element={<PrivateRouteWrapper isLoggedIn={isLoggedIn} />}>
        <Route
          path={MSP_ROUTES.USER.SOCIAL_PROTECT_NEEDS}
          element={
            <SuspenseWithLoader>
              <SocialProtectDetails needs />
            </SuspenseWithLoader>
          }
        />
        <Route
          path={MSP_ROUTES.USER.SOCIAL_PROTECT_MENTOR}
          element={
            <SuspenseWithLoader>
              <SocialProtectDetails mentor />
            </SuspenseWithLoader>
          }
        />
        <Route
          path={MSP_ROUTES.USER.SOCIAL_PROTECT_SERVICE}
          element={
            <SuspenseWithLoader>
              <SocialProtectDetails service />
            </SuspenseWithLoader>
          }
        />
        <Route
          path={MSP_ROUTES.USER.SOCIAL_PROTECT_TREATMENT}
          element={
            <SuspenseWithLoader>
              <SocialProtectDetails treatment />
            </SuspenseWithLoader>
          }
        />
        <Route
          path={MSP_ROUTES.USER.SOCIAL_PROTECT_SUPPORT}
          element={
            <SuspenseWithLoader>
              <SocialProtectDetails support />
            </SuspenseWithLoader>
          }
        />
      </Route>

      <Route path="/services" element={<PrivateRouteWrapper isLoggedIn={isLoggedIn} />}>
        <Route
          path={MSP_ROUTES.SERVICES.SOCIAL_PROTECT}
          element={
            <SuspenseWithLoader>
              <SocialProtect />
            </SuspenseWithLoader>
          }
        >
          <Route
            path=":id"
            element={
              <SuspenseWithLoader>
                <SocialProtect />
              </SuspenseWithLoader>
            }
          />
        </Route>
      </Route>

      <Route path="*" element={<ErrorPage errorType="404" />} />
    </Routes>
  );
};
