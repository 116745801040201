import React, { useContext, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import { GercRedirectMiddleware } from '../guards/GercRedirectMiddleware';
import { IntegrationSystem } from '../components/Api/types';
import { ERZRouter } from '../modules/erz/routes';
import { IACRouter } from '../modules/iac/routes';
import { MSPRouter } from '../modules/msp/routes';
import { RMPRouter } from '../modules/rmp/routes';
import { ROUTES, ROUTES_LEGACY } from './config';
import HomePage from '../pages/Services/HomePage';
import LoginPage from '../pages/LoginPage';
import PersonalDataPage from '../pages/PersonalDataPage';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import Profile from '../pages/UserArea/Profile';
import UserRequests from '../pages/UserArea/UserRequests';
import { PrivateRouteWrapper } from '../components/PrivateRouteWrapper';
import AuthContext from '../contexts/Auth/AuthContext';
import InternalServicesPage from '../pages/Services/InternalServicesPage';
import ExternaServicesPage from '../pages/Services/ExternalServicesPage';

const RouterContainer = (): JSX.Element => {
  const { isLoggedIn } = useContext(AuthContext);
  const { pathname, hash, key, search } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <Routes>
      <Route path={ROUTES.HOME}>
        <Route index element={<HomePage />} />
        <Route path={ROUTES.INTERNAL_SERVICES} element={<InternalServicesPage />} />
        <Route path={ROUTES.EXTERNAL_SERVICES} element={<ExternaServicesPage />} />

        <Route path={ROUTES.LOGIN} element={<LoginPage />} />
        <Route path={ROUTES.PERSONAL_DATA} element={<PersonalDataPage />} />

        <Route path="/user" element={<PrivateRouteWrapper isLoggedIn={isLoggedIn} />}>
          <Route path={ROUTES.USER.PROFILE} element={<Profile />} />
          <Route path="/user/requests">
            <Route index element={<Navigate to={ROUTES.USER.REQUESTS.ACTIVE_REQUESTS} />} />
            <Route path={ROUTES.USER.REQUESTS.ACTIVE_REQUESTS} element={<UserRequests tab="active" />} />
            <Route path={ROUTES.USER.REQUESTS.ARCHIVED_REQUESTS} element={<UserRequests tab="archived" />} />
            <Route path={ROUTES.USER.REQUESTS.DRAFT_REQUESTS} element={<UserRequests tab="draft" />} />
          </Route>
        </Route>

        <Route path={`/${IntegrationSystem.ERZ}/*`} element={<ERZRouter />} />
        <Route path={`/${IntegrationSystem.IAC}/*`} element={<IACRouter />} />
        <Route path={`/${IntegrationSystem.MSP}/*`} element={<MSPRouter />} />
        <Route path={`/${IntegrationSystem.RMP}/*`} element={<RMPRouter />} />

        <Route path="/gerc_redirect" element={<GercRedirectMiddleware />} />

        {ROUTES_LEGACY.map(({ LEGACY_PATH, RELEVANT_PATH }) => (
          <Route
            key={LEGACY_PATH + RELEVANT_PATH}
            path={LEGACY_PATH}
            element={<Navigate to={RELEVANT_PATH} state={{ search }} />}
          />
        ))}

        <Route path="*" element={<ErrorPage errorType="404" />} />
      </Route>
    </Routes>
  );
};

export default RouterContainer;
