import React, { useMemo } from 'react';
import classNames from 'classnames';
import './VideoInstructionLink.scss';

import { ExternalLink } from './Forms/ExternalLink/ExternalLink';

interface Props {
  link?: string;
  className?: string;
  asButton?: boolean;
}

export const VideoInstructionLink: React.FC<Props> = React.memo(function C({
  link = '',
  className = '',
  asButton = false,
}) {
  const classList = useMemo<string>(
    () =>
      classNames('video-instruction', {
        [className]: className,
        'video-instruction--as-button': asButton,
      }),
    [className, asButton],
  );

  if (!link) {
    return <React.Fragment />;
  }

  return (
    <ExternalLink href={link} className={classList}>
      <span>
        <img src="/images/video_resume_blue.svg" alt="Відео інструкція" />
      </span>
      <span>Відео інструкція</span>
    </ExternalLink>
  );
});
