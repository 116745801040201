/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import Step1 from './Step1';
import Step2, { permitDataFieldNamesForServer, permitDataPayloadGroupMockup } from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5, { personStatusDataDataFieldNamesForServer, personStatusDataPayloadGroupMockup } from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';
import Step9 from './Step9';
import Step10 from './Step10';
import Step11 from './Step11';
import Step12 from './Step12';
import Step13 from './Step13';
import { api } from '../../../../../../components/Api';
import AuthContext from '../../../../../../contexts/Auth/AuthContext';
import Loader from '../../../../../../components/Loader';
import ModalContext from '../../../../../../contexts/Modal/ModalContext';
import { ModalName } from '../../../../../../contexts/Modal/modalTypes';
import {
  currentServiceCode,
  serviceName,
  stepNumberToDownloadPdf,
  stepNumberToPay,
  stepNumberToSubmitForm,
  totalSteps,
} from '../PermissionRenewal';
import { signDataHelpers } from '../../../../../../helpers/signDataHelpers';
import { saveFileFromBlob } from '../../../../../../helpers/fetch/saveFileFromBlob';
import { base64ToPdfBlob, fileToBase64 } from '../../../../../../helpers/fileHelper';
import { useFormDraft } from '../../../../../../hooks/useFormDraft';
import { ServiceAvailabilityProvider } from '../../../../../../contexts/ServiceAvailability/ServiceAvailabilityContext';
import {
  AdditionalDocumentFieldName,
  PaymentDataFieldName,
  attachmentMapping,
  getServiceData,
} from '../../../../../../components/Forms/constants';
import { formatDate } from '../../../../../../helpers/dates';
import {
  ArtifactType,
  FormDataType,
  InvoiceStatus,
  UserRequest,
  UserRequestStatus,
  UserServicePaymentType,
} from '../../../../../../components/Api/types';
import { useAsyncDataLoader } from '../../../../../../hooks/useAsyncDataLoader';
import {
  ApproveAndDownloadButton,
  DownloadButton,
  GoToPaymentStepButton,
  HomeButton,
  NextStepButton,
  PayButton,
  PayLaterButton,
  PreviousStepButton,
  SignButton,
} from '../../../../../../components/Forms/Buttons';
import {
  markEmptyInputFieldWithAnError,
  removeErrorMarkFromInputField,
  validateRequiredFieldsToBeFilled,
} from '../../../../../../helpers/invalidFields';
import {
  payloadRegisteredGroupName as placeOfResidencePayloadRegisteredGroupName,
  payloadDeclaredGroupName as placeOfResidencePayloadDeclaredGroupName,
  placeOfResidenceDeclaredDataFieldNamesForServer,
  placeOfResidenceDeclaredDataPayloadGroupMockup,
  placeOfResidenceRegisteredDataFieldNamesForServer,
  placeOfResidenceRegisteredDataPayloadGroupMockup,
  PlaceOfResidenceDataFieldName,
} from '../../../../../../components/Forms/Steps/PlaceOfResidence';
import {
  confirmationOfApplicationDataFieldNamesForServer,
  confirmationOfApplicationDataPayloadGroupMockup,
} from '../../../../../../components/Forms/Steps/ConfirmationOfApplication';
import { PaymentType } from '../../../../../../components/Forms/Steps/Payment';
import { usePaymentShortPolling } from '../../../../../../hooks/usePaymentShortPolling';
import { StepNavigation } from '../../../../../../helpers/stepsNavigationDataFactory';
import { clearLocationState } from '../../../../../../helpers/clearLocationState';
import { useArtifactLoader } from '../../../../../../hooks/useArtifactLoader';
import { ArtifactTypeNameMapping } from '../../../../../../pages/UserArea/UserRequests/uk.l10n';
import { isInsuranceDataStepMandatory } from '../../../../../../components/Forms/Steps/InsuranceData';
import { FormDocument } from '../../../../../../components/Forms/DocumentsData';

interface FormStepsProps {
  handleHiddenStep?: (step: string) => void;
  steps: StepNavigation[];
}

interface groups {
  name: string;
}

interface singData {
  sign: {
    signData: string;
    signingTime: string;
  };
}

export interface StepProps {
  formData: FormDataType;
  handleInputChange: (name: string, value: unknown) => void;
}

enum ServerFieldName {
  permitType = 'permit',
  personPassportType = 'doc_type',
  gender = 'sex',
  registeredCity = 'city',
  registeredStreet = 'street',
}

const fieldNameForServer = {
  // PERMIT
  ...permitDataFieldNamesForServer,

  // OWNER
  Name: {
    name: 'first_name',
    group: 'owner',
  },
  lastName: {
    name: 'last_name',
    group: 'owner',
  },
  Patronymic: {
    name: 'patronymic',
    group: 'owner',
  },
  birthday: {
    name: 'birthday',
    group: 'owner',
  },
  gender: {
    name: ServerFieldName.gender,
    group: 'owner',
  },
  phones: {
    name: 'phones',
    group: 'owner',
  },
  email: {
    name: 'email',
    group: 'owner',
  },
  personPassportType: {
    name: ServerFieldName.personPassportType,
    group: 'owner',
  },
  seriaDocPesron: {
    name: 'serial',
    group: 'owner',
  },
  numberDocPesron: {
    name: 'serial',
    group: 'owner',
  },
  authDepDocPerson: {
    name: 'auth_dep',
    group: 'owner',
  },
  citizenship: {
    name: 'citizenship',
    group: 'owner',
  },
  issued: {
    name: 'issued',
    group: 'owner',
  },
  activeIssuedDate: {
    name: 'issued',
    group: 'owner',
  },
  expired: {
    name: 'expired',
    group: 'owner',
  },
  authDepCode: {
    name: 'auth_dep',
    group: 'owner',
  },
  unzr: {
    name: 'unzr',
    group: 'owner',
  },
  rnokpp: {
    name: 'rnokpp',
    group: 'owner',
  },
  birthPlace: {
    name: 'birth_place',
    group: 'owner',
  },

  // REGISTERED
  ...placeOfResidenceRegisteredDataFieldNamesForServer,

  // DECLARED
  ...placeOfResidenceDeclaredDataFieldNamesForServer,

  // PERSON_STATUS
  ...personStatusDataDataFieldNamesForServer,

  // ORG_NPU
  ...confirmationOfApplicationDataFieldNamesForServer,
};

const noGroupInput: groups[] = [];
let PDF_Base64: unknown[] = [];
let RESPONSE: string | null = null;

const FormData: {
  [key: string]: {
    [key: string]: unknown;
  };
} = {
  ...permitDataPayloadGroupMockup,
  owner: {
    last_name: null,
    first_name: null,
    patronymic: null,
    birthday: null,
    phones: null,
    email: null,
    doc_type: null,
    doc_name: null,
    serial: null,
    issued: null,
    expired: null,
    auth_dep: null,
    unzr: null,
    rnokpp: null,
    birth_place: null,
    sex: null,
    citizenship_id: null,
    citizenship_name: null,
  },
  ...placeOfResidenceRegisteredDataPayloadGroupMockup,
  ...confirmationOfApplicationDataPayloadGroupMockup,
  ...placeOfResidenceDeclaredDataPayloadGroupMockup,
  ...personStatusDataPayloadGroupMockup,
  service: {
    id: null,
    name: null,
    code: null,
  },
};

const FormDataAttachments: {
  attachments?: unknown[];
} = {
  attachments: [
    {
      id: null,
      typeDoc: null,
      fileName: null,
      code: null,
      flag: 'default',
      info: {},
      encoded_contents: [],
    },
  ],
};

const signData: singData = {
  sign: {
    signData: signDataHelpers ? null : 'Test',
    signingTime: signDataHelpers ? null : new Date().toISOString(),
  },
};

const FormSteps: React.FC<FormStepsProps> = ({ handleHiddenStep, steps }) => {
  const { documentData } = useContext(AuthContext);
  const { renderSpecificModal } = useContext(ModalContext);
  const { state: locationState } = useLocation();
  const { createDraft, saveDraft, loadDraft, deleteDraft, prefillFormData } = useFormDraft();
  const {
    data: serviceDraft,
    load: loadServiceDraft,
    isLoading: isServiceDraftLoading,
  } = useAsyncDataLoader(() => loadDraft(currentServiceCode));
  const {
    data: services,
    load: loadServices,
    isLoading: isServicesLoading,
  } = useAsyncDataLoader(async () => (await api.services.getAll()).data);
  const {
    data: permitTypesList,
    load: loadPermitTypesList,
    isLoading: isPermitTypesListLoading,
  } = useAsyncDataLoader(async () => (await api.dictionary.getPermitTypes()).data);

  const availableSteps = useMemo<StepNavigation[]>(() => steps.filter((step) => !step.hidden), [steps]);
  const hiddenStep = useMemo<StepNavigation>(() => steps.find((step) => step.hidden), [steps]);
  const [currentUserRequest, setCurrentUserRequest] = useState<UserRequest>(null);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [currentRequestId, setCurrentRequestId] = useState<number>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(signDataHelpers);
  const [data, setData] = useState<FormDataType>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [willBePayedLater, setWillBePayedLater] = useState<boolean>(false);
  const [allowedPaymentType, setAllowedPaymentType] = useState<UserServicePaymentType>();
  const isOfflinePayment = useMemo<boolean>(
    () => data[PaymentDataFieldName.paymentType] === PaymentType.OFFLINE,
    [data],
  );

  const { isArtifactLoading, downloadArtifact } = useArtifactLoader((artifactType) =>
    api.downloadUserRequestArtifact(currentRequestId, artifactType),
  );
  const { isShortPollingSuccessed, isShortPollingInProgress, isShortPollingFailed, startPaymentShortPolling } =
    usePaymentShortPolling(setCurrentUserRequest);

  const scrollTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    // Redirect from GERC
    if (!!locationState?.continueGercPayment && !!locationState?.request_id) {
      setCurrentStep(stepNumberToPay);
      setCurrentRequestId(locationState.request_id);
      startPaymentShortPolling(locationState.request_id);
    }
    // Redirect from UserRequests.active by pressing button "Оплатити"
    if (!!locationState?.continuePayment && !!locationState?.request_id) {
      setCurrentStep(stepNumberToPay);
      setCurrentRequestId(locationState.request_id);
    }
    // Redirect from UserRequests.draft by pressing button "Продовжити"
    if (!!locationState?.continueDraft && !!locationState?.request_id) {
      setIsLoading(true);
      setCurrentRequestId(locationState.request_id);

      prefillFormData({
        draftId: locationState.request_id,
        handleInputChange: handleInputChange,
      })
        .then(({ meta, parsedStateForm }) => {
          setData(parsedStateForm);
          scrollTop();
          setCurrentStep(meta.current_step);
          return loadServiceDraft();
        })
        .finally(() => setIsLoading(false));
    }

    clearLocationState();
  }, []);

  useEffect(() => {
    if (currentStep === stepNumberToPay && isEmpty(currentUserRequest)) {
      (async (): Promise<void> => {
        setIsLoading(true);

        const { data: userRequest } = await api.services.draft.getDetails(currentRequestId);

        setIsLoading(false);
        setCurrentUserRequest(userRequest);
      })();
    }
  }, [currentStep, currentUserRequest, currentRequestId]);

  useEffect(() => {
    if (isShortPollingSuccessed || isShortPollingFailed) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  }, [isShortPollingSuccessed, isShortPollingFailed]);

  useEffect(() => {
    scrollTop();

    if (!FormData.service.id) {
      getServiceData(currentServiceCode)
        .then(({ service, applicationAttachment }) => {
          FormData.service = service;
          FormDataAttachments.attachments[0] = applicationAttachment;
        })
        .catch((error) => {
          if (error.status === 403 || error.status === 401) {
            getServiceData(currentServiceCode);
          }
        });
    }
  }, [scrollTop]);

  useEffect(() => {
    if (data?.permitType) {
      const permit = JSON.parse(data?.permitType.toString());
      handleHiddenStep(permit.permitType);
    }
  }, [data, handleHiddenStep]);

  useEffect(() => {
    loadServices();
    loadPermitTypesList();
  }, []);

  useEffect(() => {
    setAllowedPaymentType(services?.find((service) => service.code === currentServiceCode).paymentType);
  }, [services]);

  const handleSignUp = useCallback((sign: string) => {
    signData.sign.signData = sign;
    signData.sign.signingTime = new Date().toISOString();
    setIsDisabled(false);
  }, []);

  const handlePayButtonClick = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const { redirect_uri } = await api.services.draft.createInvoice(currentRequestId);
      window.location.href = redirect_uri;
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleClearField = useCallback((name: string) => {
    setData((prevFormData) => ({
      ...prevFormData,
      [name]: null,
    }));
  }, []);

  const handleInputChange = (name: string, value: unknown): void => {
    const isFieldInGroup = Object.keys(fieldNameForServer).includes(name);

    if (!isFieldInGroup) {
      if (
        !noGroupInput.some((x) => {
          return x.name === name;
        })
      ) {
        noGroupInput.push({
          name: name,
        });
      }
    }

    if (value === null || (value instanceof Date && isNaN(value as any))) {
      setData((prevFormData) => ({
        ...prevFormData,
        [name]: null,
      }));
    } else if (typeof value === 'string' || typeof value === 'boolean') {
      setData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else if (typeof value === 'object') {
      if (value instanceof Array) {
        setData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      } else {
        const stringValue = JSON.stringify(value).replace(/^"(.*)"$/, '$1');
        setData((prevFormData) => ({
          ...prevFormData,
          [name]: stringValue,
        }));
      }
    }

    removeErrorMarkFromInputField(name);
  };

  const handleNextStep = async (): Promise<void> => {
    if (isLoading) return;

    if (currentStep === 1) {
      if (isEmpty(documentData)) {
        renderSpecificModal({
          modalName: ModalName.DocumentMissingAlert,
          modalProps: {
            serviceName,
          },
        });

        return;
      }

      try {
        setIsLoading(true);

        const draftId = await createDraft({
          id: FormData.service.id as string,
          name: FormData.service.name as string,
          code: currentServiceCode,
          totalSteps,
        });

        setCurrentRequestId(draftId);

        await loadServiceDraft();
      } catch (error) {
        const parsedError = JSON.parse(error as string);
        const { requestId, reason } = JSON.parse(parsedError.message) as {
          requestId: number;
          reason: UserRequestStatus.draft | UserRequestStatus.payment;
        };

        switch (reason) {
          case UserRequestStatus.draft:
            renderSpecificModal({
              modalName: ModalName.ServiceDraftExistingAlert,
              modalProps: {
                startNewDraft: () => {
                  setIsLoading(true);

                  deleteDraft(requestId)
                    .then(async () => {
                      setData({});
                      await handleNextStep(); // createDraft logic again
                    })
                    .finally(() => setIsLoading(false));
                },
                continueDraft: () => {
                  setIsLoading(true);

                  prefillFormData({
                    draftId: requestId,
                    handleInputChange: handleInputChange,
                  })
                    .then(({ meta, parsedStateForm }) => {
                      setData(parsedStateForm);
                      scrollTop();
                      setCurrentRequestId(requestId);
                      setCurrentStep(meta.current_step);
                      return loadServiceDraft();
                    })
                    .finally(() => setIsLoading(false));
                },
              },
            });
            return;

          case UserRequestStatus.payment: {
            renderSpecificModal({
              modalName: ModalName.PayOrLeaveAlert,
              modalProps: {
                payButtonAction: () => {
                  scrollTop();
                  setCurrentRequestId(requestId);
                  setCurrentStep(stepNumberToPay);
                },
                // TODO: make function to delete request in payment status after back implements endpoint
                onDeleteCallback: () => {
                  setIsLoading(true);

                  deleteDraft(requestId)
                    .then(async () => {
                      setData({});
                      await handleNextStep(); // createDraft logic again
                    })
                    .finally(() => setIsLoading(false));
                },
              },
            });
            return;
          }

          default:
            console.error(error);
            break;
        }
      } finally {
        setIsLoading(false);
      }

      scrollTop();
      setCurrentStep((prevStep) => prevStep + 1);
      return;
    }

    const { hasBlankFields, blankInputFields } = validateRequiredFieldsToBeFilled(data);

    hasBlankFields && blankInputFields.forEach(markEmptyInputFieldWithAnError);

    if (!hasBlankFields) {
      if (currentStep < stepNumberToDownloadPdf) {
        setIsLoading(true);

        try {
          const activeStep = steps.find((x) => {
            return x.step > currentStep && !x.hidden;
          });

          await saveDraft({
            draftId: serviceDraft.id,
            draftCurrentStep: serviceDraft?.meta?.current_step || 0,
            meta: {
              current_step: currentStep > hiddenStep?.step ? currentStep - 1 : currentStep,
              total_steps: availableSteps.length,
            },
            stateForm: data,
          });

          await loadServiceDraft();

          setCurrentStep(activeStep.step);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        } finally {
          setIsLoading(false);
          scrollTop();
        }
      }
      if (currentStep === stepNumberToPay && isOfflinePayment) {
        setIsLoading(true);

        try {
          const [receiptBase64] = await fileToBase64(data[PaymentDataFieldName.paymentDocument] as [File]);

          await api.services.attachReceipt(currentRequestId, receiptBase64);
          await loadServiceDraft();

          setWillBePayedLater(false);
          setCurrentStep((prevStep) => prevStep + 1);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const handlePrevStep = (): void => {
    if (currentStep > 1) {
      scrollTop();
      const activeStep = steps.find((x) => {
        return x.step === currentStep - 1;
      });
      setCurrentStep(activeStep.hidden ? activeStep.step - 1 : activeStep.step);
    }
  };

  const StepButtons = function (): JSX.Element {
    const orgNpu = data['orgNpu'];
    const checkbox = data['allowToDataProcessing'];

    let containerBtn = null;

    if (currentStep === 1) {
      containerBtn = <NextStepButton handleNextStep={handleNextStep} />;
    } else if (currentStep > 1 && currentStep < stepNumberToDownloadPdf) {
      containerBtn = (
        <>
          <PreviousStepButton handlePrevStep={handlePrevStep} />
          <NextStepButton handleNextStep={handleNextStep} />
        </>
      );
    } else if (currentStep === stepNumberToDownloadPdf) {
      containerBtn = (
        <>
          <PreviousStepButton handlePrevStep={handlePrevStep} />
          <ApproveAndDownloadButton
            disabled={!orgNpu || !checkbox}
            onClick={(): void => {
              setIsLoading(true);
              create_peyload();
            }}
          />
        </>
      );
    } else if (currentStep === stepNumberToSubmitForm) {
      containerBtn = (
        <SignButton
          disabled={isDisabled || isLoading}
          onClick={(): void => {
            setIsLoading(true);
            create_peyload();
          }}
        />
      );
    } else if (currentStep === stepNumberToPay) {
      if (!isShortPollingInProgress) {
        containerBtn = (
          <>
            <PayLaterButton
              onClick={(): void => {
                setWillBePayedLater(true);
                setCurrentStep((prevStep) => prevStep + 1);
              }}
            />
            {data[PaymentDataFieldName.paymentType] === PaymentType.ONLINE ? (
              <PayButton onClick={handlePayButtonClick} />
            ) : (
              <NextStepButton disabled={!data[PaymentDataFieldName.paymentDocument]} handleNextStep={handleNextStep} />
            )}
          </>
        );
      }
    } else {
      switch (true) {
        case allowedPaymentType !== UserServicePaymentType.WITHOUT_PAYMENT &&
          !isOfflinePayment &&
          currentUserRequest.invoice?.status === InvoiceStatus.SUCCESS:
          containerBtn = (
            <>
              <DownloadButton
                downloading={isArtifactLoading}
                text={ArtifactTypeNameMapping[ArtifactType.RECEIPT]}
                onClick={(): Promise<void> => downloadArtifact(ArtifactType.RECEIPT)}
                outline
                place="left-side"
              />
              <HomeButton />
            </>
          );
          break;

        case allowedPaymentType !== UserServicePaymentType.WITHOUT_PAYMENT &&
          !isOfflinePayment &&
          currentUserRequest.invoice?.status === InvoiceStatus.IN_PROGRESS:
        case allowedPaymentType !== UserServicePaymentType.WITHOUT_PAYMENT &&
          !isOfflinePayment &&
          currentUserRequest.invoice?.status === InvoiceStatus.FAILED:
        case willBePayedLater:
          containerBtn = (
            <>
              <GoToPaymentStepButton
                disabled={!willBePayedLater && isShortPollingFailed}
                onClick={(): void => {
                  setWillBePayedLater(false);
                  setCurrentStep((prevStep) => prevStep - 1);
                }}
              />
              <HomeButton />
            </>
          );
          break;

        default:
          containerBtn = <HomeButton />;
          break;
      }
    }

    return !!containerBtn && <div className="form__bottom">{containerBtn}</div>;
  };

  const renderFormStep = (step: number): JSX.Element => {
    switch (step) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 formData={data} handleInputChange={handleInputChange} permitTypesList={permitTypesList} />;
      case 3:
        return <Step3 handleClearInput={handleClearField} formData={data} handleInputChange={handleInputChange} />;
      case 4:
        return <Step4 formData={data} handleClearInput={handleClearField} handleInputChange={handleInputChange} />;
      case 5:
        return <Step5 formData={data} handleClearInput={handleClearField} handleInputChange={handleInputChange} />;

      case 6:
        return <Step6 formData={data} handleClearInput={handleClearField} handleInputChange={handleInputChange} />;
      case 7:
        return (
          <Step7
            formData={data}
            isStepMandatory={isInsuranceDataStepMandatory(currentServiceCode)}
            handleClearInput={handleClearField}
            handleInputChange={handleInputChange}
          />
        );
      case 8:
        return <Step8 formData={data} handleClearInput={handleClearField} handleInputChange={handleInputChange} />;
      case 9:
        return <Step9 formData={data} handleClearInput={handleClearField} handleInputChange={handleInputChange} />;

      case 10:
        return <Step10 formData={data} handleInputChange={handleInputChange} permitTypesList={permitTypesList} />;

      case 11:
        return <Step11 signUpPayload={FormData.service} rnokpp={data.rnokpp as string} handleSignUp={handleSignUp} />;

      case 12:
        return (
          <Step12
            formData={data}
            isPaymentInProgress={isShortPollingInProgress}
            handleClearInput={handleClearField}
            handleInputChange={handleInputChange}
            currentRequestId={currentRequestId}
            allowedPaymentType={allowedPaymentType}
          />
        );

      case 13:
        return (
          <Step13
            willBePayedLater={willBePayedLater}
            invoiceStatus={isOfflinePayment ? null : currentUserRequest?.invoice?.status}
            serviceCode={currentServiceCode}
          />
        );

      default:
        return null;
    }
  };

  function create_peyload(): void {
    FormDataAttachments.attachments = [FormDataAttachments.attachments[0]];

    async function createFormDate(): Promise<void> {
      async function createFileBase64(files: File[]): Promise<string[]> {
        const arrFiles: string[] = [];
        if (currentStep !== stepNumberToDownloadPdf) {
          for (let f = 0; f < files.length; f++) {
            const reader = new FileReader();
            const filePromise = new Promise<string>((resolve) => {
              reader.onload = function (): void {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const base64String = btoa(reader.result as string);
                resolve(base64String.toString());
              };
            });
            reader.readAsBinaryString(files[f]);
            const base64String = await filePromise;
            arrFiles.push(base64String);
          }
        }
        return arrFiles;
      }

      function createInfo(
        info: Record<string, string>,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        data: Record<string, unknown>,
      ): Record<string, unknown> {
        const obj: Record<string, unknown> = {};

        Object.keys(info).forEach((key) => {
          const infoKey = info[key];
          if (data[infoKey]) {
            obj[key] = formatDate(data[infoKey] as string, '.', true);
          }
        });
        return obj;
      }

      for (const i in data) {
        if (Object.hasOwnProperty.call(data, i)) {
          const serverName = (
            fieldNameForServer as {
              [key: string]: { name: string; group: string };
            }
          )[i];

          if (serverName && data[i]) {
            const formDataValue = data[i];
            const jsonString = typeof formDataValue === 'string' ? formDataValue : JSON.stringify(formDataValue);
            const group = serverName.group;
            const name = serverName.name;
            const id_key = name + '_id';
            const name_key = name + '_name';

            if (!group || !name) {
              return;
            }

            let obj;
            try {
              obj = JSON.parse(jsonString);
            } catch (e) {}

            if (obj && typeof obj === 'object') {
              if (obj instanceof Object) {
                if (name === ServerFieldName.permitType) {
                  FormData[group].id = obj.id;
                  FormData[group].type = obj.permitType;
                } else if (name === ServerFieldName.personPassportType) {
                  FormData[group].doc_type = obj.code;
                  FormData[group].doc_name = obj.label;
                } else if (name === ServerFieldName.gender) {
                  FormData[group].sex = obj.id;
                } else {
                  if (FormData[group][id_key] === undefined) {
                    FormData[group].id = obj.id;
                    FormData[group].name = obj.label;
                  } else {
                    FormData[group][id_key] = obj.id;
                    FormData[group][name_key] = obj.label;
                  }
                }
              }
              if (obj instanceof Array) {
                FormData[group][name] = obj;
              }
            } else {
              if (name === ServerFieldName.registeredCity || name === ServerFieldName.registeredStreet) {
                FormData[group][id_key] = null;
                FormData[group][name_key] = data[i];
              } else {
                FormData[group][name] = formatDate(data[i]?.toString(), '.', true);
              }
            }
          } else {
            const statusGroup = noGroupInput.find((x) => {
              return x.name === i;
            });
            if (statusGroup) {
              let obj;
              try {
                obj = JSON.parse(data[i]?.toString());
              } catch (e) {}

              if (i === AdditionalDocumentFieldName.additionalDocuments) {
                for (const additionalAttachment of data[i] as unknown as FormDocument[]) {
                  const { type, attachments } = additionalAttachment;

                  FormDataAttachments.attachments.push({
                    id: type.id,
                    typeDoc: type.label,
                    fileName: type.label,
                    code: type.code,
                    info: {},
                    flag: 'additional',
                    encoded_contents: Array.isArray(attachments) ? await createFileBase64(attachments) : [],
                  });
                }
              }

              if (attachmentMapping[i] && obj) {
                const infoAttach = attachmentMapping[i].info;
                const infoContents = attachmentMapping[i].encoded_contents as string;

                FormDataAttachments.attachments.push({
                  id: obj.id,
                  typeDoc: obj.label,
                  fileName: obj.label,
                  code: obj.code,
                  flag: 'default',
                  info: createInfo(infoAttach, data),
                  encoded_contents: Array.isArray(data[infoContents])
                    ? await createFileBase64(data[infoContents] as File[])
                    : [],
                });
              }
            }
          }
        }
      }

      if (!data[PlaceOfResidenceDataFieldName.registeredDoc] === true) {
        FormData[placeOfResidencePayloadDeclaredGroupName] = {
          ...FormData[placeOfResidencePayloadRegisteredGroupName],
        };
      }
    }

    async function someFunction(): Promise<void> {
      await createFormDate();
      await requestFormData();
    }

    someFunction();
    scrollTop();
  }

  async function requestFormData(): Promise<void> {
    if (currentStep === stepNumberToDownloadPdf) {
      const FormDataAttachmentsWithoutApplication = {
        attachments: FormDataAttachments.attachments.slice(1),
      };

      api.services
        .generatePdf(currentServiceCode, {
          requestId: currentRequestId,
          ...FormData,
          ...FormDataAttachmentsWithoutApplication,
        })
        .then((response) => {
          RESPONSE = response;
          PDF_Base64 = [response];
          setCurrentStep((prevStep) => prevStep + 1);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        })
        .finally(() => setIsLoading(false));
    }

    if (currentStep === stepNumberToSubmitForm) {
      (FormDataAttachments.attachments[0] as any).encoded_contents = PDF_Base64;

      api.services
        .submit(currentServiceCode, {
          requestId: currentRequestId,
          ...FormData,
          ...FormDataAttachments,
          ...signData,
        })
        .then(() => {
          saveFileFromBlob('Заява.pdf', base64ToPdfBlob(RESPONSE));
          setCurrentStep((prevStep) =>
            allowedPaymentType === UserServicePaymentType.WITHOUT_PAYMENT ? prevStep + 2 : prevStep + 1,
          );
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        })
        .finally(() => setIsLoading(false));
    }
  }

  return (
    <form name="weaponPurchasePermit" className="form form_max-width-md form_bg-default">
      <div className="step-container" data-step={currentStep}>
        <ServiceAvailabilityProvider serviceCode={currentServiceCode} skipAvailabilityValidation={currentStep === 1}>
          {isLoading || isServiceDraftLoading || isServicesLoading || isPermitTypesListLoading ? (
            <Loader centered />
          ) : (
            <>
              {renderFormStep(currentStep)}
              {StepButtons()}
            </>
          )}
        </ServiceAvailabilityProvider>
      </div>
    </form>
  );
};

export default FormSteps;
