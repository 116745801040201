import React from 'react';

import './PersonalDataPage.scss';
import MainTemplate from '../../templates/MainTemplate/MainTemplate';

const PersonalDataPage: React.FC = () => {
  return (
    <MainTemplate>
      <div className="personal-data">
        <div className="container personal-data__content">
          <h1>
            Повідомлення про обробку персональних даних сервісом центральної підсистеми єдиної інформаційної системи
            Міністерства внутрішніх справ «Єдине вікно для громадян»
          </h1>

          <p>
            На виконання ч. 2 ст. 12 Закону України «Про захист персональних даних» цим повідомленням інформуємо
            суб’єктів персональних даних про: склад та зміст зібраних персональних даних, володільця персональних даних,
            мету збору персональних даних, права суб’єкта персональних даних та відомості про осіб, яким передаються
            персональні дані.
          </p>
          <p>
            Сервіс центральної підсистеми єдиної інформаційної системи Міністерства внутрішніх справ (далі – ЦПЄІС МВС)
            «Єдине вікно для громадян» розроблено Державним підприємством «ІНФТОЕХ», реалізуючи свої повноваження
            адміністратора ЄІС МВС, відповідно до Положення про єдину інформаційну систему Міністерства внутрішніх
            справ, затвердженої постановою Кабінету Міністрів України від 14.11.2018 № 1024.
          </p>
          <p>
            Визначення, що використовуються у цьому повідомленні, вживаються у значенні, що надані їм Законом України
            «Про захист персональних даних».
          </p>
          <ol>
            <li>
              <h3>1. Загальні умови</h3>
              <ol>
                <li>
                  1.1. Персональні дані обробляються з застосуванням автоматизованих засобів обробки, в тому числі
                  шляхом електронної інформаційної взаємодії з електронними державними реєстрами, інформаційними
                  системами та/або центрами обробки даних.
                </li>
              </ol>
            </li>

            <li>
              <h3>2. Персональні дані, що збираються за допомогою «Єдиного вікна для громадян»</h3>
              <ol>
                2.1. Відомості про профіль користувача (суб’єкта персональних даних):
                <ol>
                  <li>2.1.1. прізвище, ім’я та по батькові;</li>
                  <li>2.1.2. дата народження;</li>
                  <li>2.1.3. стать;</li>
                  <li>2.1.4. громадянство;</li>
                  <li>2.1.5. країна народження / реєстрації;</li>
                  <li>2.1.6. район проживання / реєстрації;</li>
                  <li>2.1.7. область народження / реєстрації;</li>
                  <li>2.1.8. населений пункт народження / реєстрації;</li>
                  <li>2.1.9. номер телефону;</li>
                  <li>2.1.10. електронна пошта;</li>
                  <li>
                    2.1.11. дані документа, що посвідчує особу (тип документа, номер, ким видано, дата видачі, термін
                    дії, країна видачі, область, місто, адреса);
                  </li>
                  <li>2.1.12. РНОКПП.</li>
                </ol>
              </ol>
            </li>

            <li>
              <h3>3. Відомості про володільця та розпорядника персональних даних</h3>
              <ol>
                <li>
                  3.1. Особи, які здійснюють обробку персональних даних за допомогою «Єдиного вікна для громадян»:
                  <ol>
                    <li>
                      3.1.1. володілець персональних даних – Міністерство внутрішніх справ України (вул. Академіка
                      Богомольця 10, м. Київ, 01601);
                    </li>
                    <li>
                      3.1.2. розпорядник персональних даних – Державне підприємство «ІНФОТЕХ» (вул. Дегтярівська 15 Б,
                      м. Київ, 04050).
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <h3>4. Мета обробки персональних даних:</h3>
              <ol>
                <ol>
                  <li>
                    4.1. Персональні дані обробляються з метою:
                    <ol>
                      <li>4.1.1. створення та забезпечення функціонування «Єдиного вікна для громадян»;</li>
                      <li>
                        4.1.2. забезпечення доступу суб’єкта персональних даних до інформації про себе, що обробляється
                        в ЄІС МВС;
                      </li>
                      <li>
                        4.1.3. забезпечення можливості сплати суб’єктом персональних даних коштів за надання
                        адміністративних послуг, що доступні для оплати у функціональних підсистемах, центральних
                        підсистемах ЄІС МВС тощо;
                      </li>
                      <li>
                        4.1.4. реалізації прав та обов’язків передбачених Положенням про єдину інформаційну систему
                        Міністерства внутрішніх справ, затвердженої постановою Кабінету Міністрів України від 14.11.2018
                        № 1024 та іншими нормативно-правовими актами.
                      </li>
                    </ol>
                  </li>
                </ol>
              </ol>
            </li>

            <li>
              <h3>5. Накопичення та зберігання персональних даних</h3>
              <ol>
                <li>
                  5.1. Персональні дані Користувача вносяться до бази даних «Єдиного вікна для громадян» та зберігаються
                  у захищеному середовищі, в центрі обробки даних на території України.
                </li>
                <li>5.2. Персональні дані зберігаються протягом строку, необхідного для досягнення мети обробки.</li>
              </ol>
            </li>

            <li>
              <h3>6. Передача персональних даних третім особам</h3>
              <ol>
                <li>
                  6.1. З метою забезпечення функціонування «Єдиного вікна для громадян» персональні дані можуть
                  передаватися технічним платформам та сервісам, що забезпечують роботу або доступність «Єдиного вікна
                  для громадян» та за допомогою яких передаються, зберігаються та структуруються дані. Володілець та/або
                  розпорядник не несе відповідальності за функціонування таких сервісів та процеси їхньої обробки
                  персональних даних, якщо такі сервіси належать третім особам.
                </li>
                <li>6.2. Передача персональних даних за кордон не здійснюється.</li>
              </ol>
            </li>

            <li>
              <h3>7. Права Користувача щодо персональних даних</h3>
              <ol>
                <li>
                  7.1. Користувач, як суб’єкт персональних даних, наділений правами передбаченими ст. 8 Закону України
                  «Про захист персональних даних», зокрема:
                  <ol>
                    <li>
                      7.1.1. знати про джерела збирання, місцезнаходження своїх даних, мету їх обробки, володільця чи
                      розпорядника, а також умови надання доступу до даних;
                    </li>
                    <li>7.1.2. отримувати доступ до власних персональних даних;</li>
                    <li>
                      7.1.3. пред&apos;являти вмотивовану вимогу щодо зміни або знищення власних персональних даних;
                    </li>
                    <li>7.1.4. вмотивовано заперечувати проти обробки власних персональних даних;</li>
                    <li>
                      7.1.5. відкликати згоду на обробку власних персональних даних, якщо дані обробляються на підставі
                      згоди;
                    </li>
                    <li>7.1.6. знати механізм автоматичної обробки персональних даних;</li>
                    <li>7.1.7. на захист від автоматизованого рішення, яке має для користувача правові наслідки;</li>
                    <li>7.1.8. звертатися зі скаргами до Уповноваженого ВРУ з прав людини або до суду тощо.</li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              <h3>8. Інші умови</h3>
              <ol>
                <li>
                  8.1. Запитання з приводу обробки персональних даних можна направити сюди:{' '}
                  <a href="mailto:services@infotech.gov.ua">services@infotech.gov.ua</a>.
                </li>
                <li>
                  8.2. До цього повідомлення можуть вноситися зміни та доповнення в односторонньому порядку, у зв’язку з
                  чим рекомендуємо час від часу переглядати його зміст.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </MainTemplate>
  );
};

export default PersonalDataPage;
