import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// Images
import AttentionIconRed from '../../../assets/images/attention_red.svg';
import InProgressIcon from '../../../assets/images/in_progress.svg';
import ArrowForwardiconBlue from '../../../assets/images/arrow_forward_blue.svg';
import DeleteIcon from '../../../assets/images/delete-icon.svg';
import OkBlueOutlinedIcon from '../../../assets/images/ok_blue_outlined.svg';
// Helpers
import { formatDate } from '../../../helpers/dates';
// Others
import {
  IntegrationSystem,
  InvoiceStatus,
  ServiceCodes,
  UserRequest,
  UserRequestPart,
  UserRequestStatus,
  UserService,
} from '../../../components/Api/types';
import Accordion from '../../../components/Accordion/Accordion';
import { SEDUserRequestChannels, userRequestPartStatuses } from './uk.l10n';
import { NullValueGuard } from '../../../guards/NullValueGuard';
import ModalContext from '../../../contexts/Modal/ModalContext';
import { ModalName } from '../../../contexts/Modal/modalTypes';
import { api } from '../../../components/Api';
import { DownloadButton } from './DownloadButton';
import { Button, LinkAsButton } from '../../../components/Button';
import { useArtifactLoader } from '../../../hooks/useArtifactLoader';
import { ROUTES_BY_SERVICE_CODE } from '../../../router/config';
import { ChannelTag } from '../../../components/ChannelTag';
import { Status, StatusMapping } from '../../../components/Status';
import { ERZ_statuses } from '../../../modules/erz/resources/uk.l10n';
import { IAC_statuses } from '../../../modules/iac/resources/uk.l10n';
import { RMP_statuses } from '../../../modules/rmp/resources/uk.l10n';

type Props = {
  item: UserRequest;
  services: UserService[];
  storeDeletedRequest: (order: UserRequest) => void;
  inAccordion?: boolean;
  title?: boolean;
  content?: boolean;
};

interface UserRequestComposition<T> extends React.FC<T> {
  Part: React.FC<PartProps>;
}

const UserRequestListItem: UserRequestComposition<Props> = ({
  item,
  services,
  storeDeletedRequest,
  inAccordion = false,
}) => {
  const { isArtifactLoading, downloadArtifact } = useArtifactLoader((artifactType) =>
    api.downloadUserRequestArtifact(item.id, artifactType),
  );

  const createdDate = useMemo(() => (item.created_at || '').split(' ')[0], [item]);
  const updatedDate = useMemo(() => (item.updated_at || '').split(' ')[0], [item]);
  const showExternalDecision = useMemo<boolean>(
    () => (item.channel === 'ekg' && !!item.external_decision) || item.status === UserRequestStatus.payment,
    [item],
  );
  const mappedStatusesByIntegrationSystem = useMemo<Array<StatusMapping<UserRequestStatus>>>(() => {
    const sourceSystem = services?.find((service) => service.code === item.service_code)?.source;

    switch (sourceSystem) {
      case IntegrationSystem.ERZ:
        return ERZ_statuses;

      case IntegrationSystem.IAC:
        return IAC_statuses;

      case IntegrationSystem.RMP:
        return RMP_statuses;

      default:
        return ERZ_statuses;
    }
  }, [services, item]);

  return (
    <li className={classNames('list__item', { 'list__item--in-accordion': inAccordion })} data-id={item.id}>
      <div className="list__item__info-block">
        {showExternalDecision && (
          <>
            <div className="list__item__info-block__text">
              <img src={AttentionIconRed} width={24} height={24} alt="attention icon" />
              <span>
                {item.external_decision ||
                  'Вам необхідно оплатити послугу з надання дозвільного документа впродовж 14 днів, після закінчення цього терміну запит буде анульовано.'}
              </span>
            </div>

            <div className="separator" />
          </>
        )}

        <ChannelTag channel={item.channel} channels={SEDUserRequestChannels} />
      </div>

      <div className="list__item__title">
        <div className="list__item__title__content">
          <div className="list__item__column" title={item.title}>
            <span className="list__item__column__name">
              {item.status === UserRequestStatus.sent ? 'Назва' : 'Назва заяви'}
            </span>
            <span className="title">{item.title}</span>
          </div>

          <div className="list__item__column">
            <span className="list__item__column__name">Статус</span>
            <Status originStatus={item.status} findIn={mappedStatusesByIntegrationSystem} />
          </div>
        </div>
      </div>

      <div className="list__item__content">
        <div className="list__item__column">
          <span className="list__item__column__name">Дата створення</span>
          <span>
            <NullValueGuard>{formatDate(createdDate)}</NullValueGuard>
          </span>
        </div>

        {item.status !== UserRequestStatus.sent && (
          <>
            <div className="list__item__column">
              <span className="list__item__column__name">№ заяви</span>
              <span className="grey-text">
                <span>
                  <NullValueGuard>{item.number_application}</NullValueGuard>
                </span>
              </span>
            </div>

            <div className="list__item__column">
              <span className="list__item__column__name">Дата оновлення</span>
              <span>
                <NullValueGuard>{formatDate(updatedDate)}</NullValueGuard>
              </span>
            </div>

            <div className="list__item__column">
              {item.status === UserRequestStatus.payment && item?.invoice?.status !== InvoiceStatus.SUCCESS && (
                <>
                  <LinkAsButton
                    to={ROUTES_BY_SERVICE_CODE[item.service_code]}
                    state={{
                      continuePayment: true,
                      request_id: item.id,
                    }}
                  >
                    <span>Оплатити</span>
                  </LinkAsButton>
                  <Button
                    outline
                    bgColor="error"
                    onClick={(): void => {
                      api.services.draft.delete(item.id);
                      storeDeletedRequest(item);
                    }}
                  >
                    <img src={DeleteIcon} alt="delete" />
                    <span>Видалити</span>
                  </Button>
                </>
              )}

              {!!item.artifacts.length &&
                item.artifacts.map(
                  (type): React.ReactElement => (
                    <DownloadButton
                      key={type}
                      disabled={isArtifactLoading}
                      onClick={(): Promise<void> => downloadArtifact(type)}
                      type={type}
                    />
                  ),
                )}
            </div>
          </>
        )}
      </div>
    </li>
  );
};

type PartProps = {
  part: UserRequestPart;
};

const UserRequestPartBlock: React.FC<PartProps> = ({ part }) => {
  const { isArtifactLoading, downloadArtifact } = useArtifactLoader((artifactType) =>
    api.downloadUserRequestPartArtifact(part.id, artifactType),
  );

  return (
    <li className="list__item list__item--in-accordion list__item--part" key={part.id}>
      <div className="list__item__title">
        <div className="list__item__title__content">
          <div className="list__item__column">
            <span className="list__item__column__name">Модель</span>
            <span className="title">{part.modelName}</span>
          </div>

          <div className="list__item__column">
            <span className="list__item__column__name">Статус</span>
            <Status originStatus={part.status} findIn={userRequestPartStatuses} />
          </div>
        </div>
      </div>

      <div className="list__item__content">
        <div className="list__item__column">
          <span className="list__item__column__name">Тип</span>
          <span>
            <NullValueGuard>{part.type}</NullValueGuard>
          </span>
        </div>

        {!!part.artifacts.length && (
          <div className="list__item__column">
            {part.artifacts.map(
              (type): React.ReactElement => (
                <DownloadButton
                  key={type}
                  disabled={isArtifactLoading}
                  onClick={(): Promise<void> => downloadArtifact(type)}
                  type={type}
                />
              ),
            )}
          </div>
        )}
      </div>
    </li>
  );
};

UserRequestListItem.Part = UserRequestPartBlock;

export const ListItem: React.FC<Props> = ({ item, services, storeDeletedRequest }) => {
  if (item.parts?.length) {
    return (
      <Accordion
        accordionClass="white-bg header-link-min-width"
        accordionData={[
          {
            title: (
              <UserRequestListItem
                item={item}
                services={services}
                storeDeletedRequest={storeDeletedRequest}
                inAccordion
              />
            ),
            content: item.parts.map((part) => <UserRequestListItem.Part key={part.id} part={part} />),
          },
        ]}
        withMargin={false}
      />
    );
  }

  return <UserRequestListItem item={item} services={services} storeDeletedRequest={storeDeletedRequest} />;
};

interface DraftListItemProps {
  item: UserRequest;
  storeDeletedDraft: (draft: UserRequest) => void;
}

export const DraftListItem: React.FC<DraftListItemProps> = ({ item, storeDeletedDraft }) => {
  const { renderSpecificModal } = useContext(ModalContext);

  const createdDate = (item.created_at || '').split(' ')[0];
  const updateDate = (item.updated_at || '').split(' ')[0];

  const isDraftCompleted = useMemo(() => item.meta.last_step_filled === item.meta.total_steps, [item]);

  return (
    <>
      <div className="list__item__title">
        <div className="list__item__title__content">
          <div
            className={classNames('list__item__column', 'list__item__column--steps-info', {
              completed: isDraftCompleted,
            })}
          >
            <span>
              <img src={isDraftCompleted ? OkBlueOutlinedIcon : InProgressIcon} alt="In progress" />
            </span>
            <span>
              Заповнено кроків: {item.meta.last_step_filled - 1} з {item.meta.total_steps}
            </span>
          </div>

          <div className="list__item__column" title={item.title}>
            <span className="list__item__column__name">
              {item.service_code === ServiceCodes.MISSING_PERSON ? 'Назва' : 'Назва заяви'}
            </span>
            <span className="title">{item.title}</span>
          </div>
        </div>
      </div>

      <div className="list__item__content">
        <div className="list__item__column">
          <span className="list__item__column__name">Дата створення</span>
          <span>
            <NullValueGuard>{formatDate(new Date(createdDate))}</NullValueGuard>
          </span>
        </div>

        <div className="list__item__column list__item__column--draft-button_down">
          <Link
            to={ROUTES_BY_SERVICE_CODE[item.service_code]}
            state={{
              continueDraft: true,
              request_id: item.id,
            }}
            className="draft-button draft-button--continue"
          >
            <span>
              <img src={ArrowForwardiconBlue} alt="Continue icon" />
            </span>
            <span>Продовжити</span>
          </Link>
        </div>

        <div className="list__item__column">
          <span className="list__item__column__name">Дата оновлення</span>
          <span>
            <NullValueGuard>{formatDate(new Date(updateDate))}</NullValueGuard>
          </span>
        </div>

        <div className="list__item__column list__item__column--draft-button_up">
          <button
            className="draft-button draft-button--delete"
            onClick={(): void => {
              renderSpecificModal({
                modalName: ModalName.DeleteServiceDraftAlert,
                modalProps: {
                  draftIdToDelete: item.id,
                  onDeleteCallback: () => storeDeletedDraft(item),
                },
              });
            }}
          >
            <span>
              <img src={DeleteIcon} alt="Delete icon" />
            </span>
            <span>Видалити</span>
          </button>
        </div>
      </div>
    </>
  );
};
