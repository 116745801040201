import { ServiceCodes } from '../../../components/Api/types';
import { decorateIACRoute } from '../helpers/decorateIACRoute';

export const IAC_ROUTES = {
  SERVICES: {
    CRIMINAL_RECORD_EXTRACT: '/services/criminal-record-extract',
    CRIMINAL_RECORD_SEARCH: '/services/criminal-record-search',
    CRIMINAL_RECORD_SEARCH_QR: '/services/criminal-record-search/qr', // TODO: route was changed
    CRIMINAL_RECORD_SEARCH_QR_LEGACY: '/services/criminal-record-search/qr-legacy', // TODO: route was changed
    CRIMINAL_RECORD_SEARCH_ALL: '/services/criminal-record-search-all',
  },
} as const;

export const IAC_ROUTES_BY_SERVICE_CODE = {
  [ServiceCodes.CRIMINAL_RECORD_EXTRACT]: decorateIACRoute(IAC_ROUTES.SERVICES.CRIMINAL_RECORD_EXTRACT),
  [ServiceCodes.CRIMINAL_RECORD_SEARCH]: decorateIACRoute(IAC_ROUTES.SERVICES.CRIMINAL_RECORD_SEARCH),
  [ServiceCodes.CRIMINAL_RECORD_SEARCH_ALL]: decorateIACRoute(IAC_ROUTES.SERVICES.CRIMINAL_RECORD_SEARCH_ALL),
} as const;

export const IAC_ROUTES_LEGACY = [
  {
    LEGACY_PATH: '/search/iac',
    RELEVANT_PATH: IAC_ROUTES_BY_SERVICE_CODE[ServiceCodes.CRIMINAL_RECORD_SEARCH],
  },
  {
    LEGACY_PATH: '/search/iacl',
    RELEVANT_PATH: IAC_ROUTES_BY_SERVICE_CODE[ServiceCodes.CRIMINAL_RECORD_SEARCH],
  },
];
