import React, { useEffect, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';

import FileUpload from '../FileUpload';
import InitialSelect from '../Select';
import { DocumentTypeEntity } from '../../../helpers/reformatDocumentTypeToEntity';
import { getERZDocumentsSelect } from '../../../modules/erz/helpers';
import calendarIcon from '../../../assets/images/calendar.svg';
import { DocumentTypeCode, ServiceCodes } from '../../Api/types';
import { FormConstants, InsuranceDataFieldName } from '../constants';
import { formatDate, getDateWithoutTime } from '../../../helpers/dates';
import { parseFormDataValue } from '../../../helpers/parseFormDataValue';
import { NullValueGuard } from '../../../guards/NullValueGuard';
import { PreviewFilesList } from '../PreviewFilesList';
import { AccordionData } from '../../Accordion/Accordion';
import { StepsNavigationName } from '../../../config/services';

const todaysDate = getDateWithoutTime(new Date());

export interface InsuranceFormDataProps {
  [InsuranceDataFieldName.confirmInsuranceDoc]?: boolean;
  [InsuranceDataFieldName.confirmInsuranceDocType]?: string;
  [InsuranceDataFieldName.confirmInsuranceDocSerialNumber]?: string;
  [InsuranceDataFieldName.confirmInsuranceDocIssueDate]?: string;
  [InsuranceDataFieldName.confirmInsuranceDocExpireDate]?: string;
  [InsuranceDataFieldName.confirmInsuranceDocument]?: File[];
}

interface Props {
  formData: InsuranceFormDataProps;
  isStepMandatory?: boolean;
  handleInputChange: (name: string, value: unknown) => void;
  handleClearInput: (name: string) => void;
}
interface PreviewProps {
  formData: InsuranceFormDataProps;
}

export const isInsuranceDataStepMandatory = (serviceCode: ServiceCodes): boolean =>
  serviceCode === ServiceCodes.STORAGE_CARRYING;

export const InsuranceData: React.FC<Props> = ({
  formData,
  isStepMandatory = false,
  handleInputChange,
  handleClearInput,
}) => {
  const [optionsPersonDoc, setOptionsPersonDoc] = useState<DocumentTypeEntity[]>([]);

  const confirmInsuranceDoc = useMemo(
    () => formData.confirmInsuranceDoc || isStepMandatory,
    [formData.confirmInsuranceDoc, isStepMandatory],
  );
  const confirmInsuranceDocType = useMemo(
    () => JSON.parse(formData.confirmInsuranceDocType || null),
    [formData.confirmInsuranceDocType],
  );
  const confirmInsuranceDocIssueDate = useMemo(
    () => (formData.confirmInsuranceDocIssueDate ? new Date(formData.confirmInsuranceDocIssueDate) : null),
    [formData.confirmInsuranceDocIssueDate],
  );
  const confirmInsuranceDocExpireDate = useMemo(
    () => (formData.confirmInsuranceDocExpireDate ? new Date(formData.confirmInsuranceDocExpireDate) : null),
    [formData.confirmInsuranceDocExpireDate],
  );

  useEffect(() => {
    if (confirmInsuranceDoc) {
      (async () => {
        if (!confirmInsuranceDocType) {
          const [document] = await getERZDocumentsSelect({
            allowedDocumentTypeCodes: [DocumentTypeCode.INSURANCE_POLICY],
            setOptions: setOptionsPersonDoc,
          });
          handleInputChange(InsuranceDataFieldName.confirmInsuranceDocType, document);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmInsuranceDocType, confirmInsuranceDoc]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    const booleanValue = value.toLowerCase() === 'true';

    if (!booleanValue) {
      [
        InsuranceDataFieldName.confirmInsuranceDoc,
        InsuranceDataFieldName.confirmInsuranceDocType,
        InsuranceDataFieldName.confirmInsuranceDocSerialNumber,
        InsuranceDataFieldName.confirmInsuranceDocIssueDate,
        InsuranceDataFieldName.confirmInsuranceDocExpireDate,
        InsuranceDataFieldName.confirmInsuranceDocument,
      ].forEach(handleClearInput);
    }

    handleInputChange(name, booleanValue);
  };

  return (
    <>
      <div
        id="step-description"
        className="block-title block-title_lg block-title_padding-bottom-md-extra block-title_bg-light"
      >
        {StepsNavigationName.insurance}
      </div>

      <div className="form__content">
        {!isStepMandatory && (
          <div className="form__row">
            <div className="form-input form-input_margin-bottom-none">
              <div className="form-input__label form-input__label_margin-bottom-md">
                Ви хочете додати дані документа?
              </div>
              <fieldset id={InsuranceDataFieldName.confirmInsuranceDoc} className="form-input__radiobuttons">
                <label className="radio" htmlFor={InsuranceDataFieldName.confirmInsuranceDoc + '_yes'}>
                  <input
                    className="radio__input"
                    type="radio"
                    id={InsuranceDataFieldName.confirmInsuranceDoc + '_yes'}
                    name={InsuranceDataFieldName.confirmInsuranceDoc}
                    value="true"
                    checked={confirmInsuranceDoc}
                    onChange={handleRadioChange}
                  />
                  <span className="radio__checkmark"></span>
                  <span className="radio__label">Так</span>
                </label>

                <label className="radio" htmlFor={InsuranceDataFieldName.confirmInsuranceDoc + '_no'}>
                  <input
                    className="radio__input"
                    type="radio"
                    id={InsuranceDataFieldName.confirmInsuranceDoc + '_no'}
                    name={InsuranceDataFieldName.confirmInsuranceDoc}
                    value="false"
                    checked={!confirmInsuranceDoc}
                    onChange={handleRadioChange}
                  />
                  <span className="radio__checkmark"></span>
                  <span className="radio__label">Ні</span>
                </label>
              </fieldset>
            </div>
          </div>
        )}

        {(isStepMandatory || confirmInsuranceDoc) && (
          <>
            <div className="form-input form-input_margin-bottom-none required no-group">
              <div className="form-input__label">Тип документа, який підтверджує наявність страхування</div>
              <div className="input-select js-input-select">
                <InitialSelect
                  name={InsuranceDataFieldName.confirmInsuranceDocType}
                  options={optionsPersonDoc}
                  placeholder="Не вибрано"
                  defaultValue={confirmInsuranceDocType}
                  onChange={(newValue, name) => handleInputChange(name, newValue)}
                  onMenuOpen={() => {
                    if (!optionsPersonDoc.length) {
                      getERZDocumentsSelect({
                        allowedDocumentTypeCodes: [DocumentTypeCode.INSURANCE_POLICY],
                        setOptions: setOptionsPersonDoc,
                      });
                    }
                  }}
                />
              </div>
            </div>

            <div className="form-input form-input_margin-bottom-none required">
              <div className="form-input__label">Серія та номер</div>
              <input
                className="form-input__input"
                placeholder="Не вказано"
                type="text"
                name={InsuranceDataFieldName.confirmInsuranceDocSerialNumber}
                value={formData.confirmInsuranceDocSerialNumber || ''}
                onChange={({ target: { name, value } }) => handleInputChange(name, value)}
                maxLength={FormConstants.INPUT_MAX_LENGTH}
              />
            </div>

            <div className="form__row">
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Дата видачі</div>
                <div className="form-input__date-box">
                  <DatePicker
                    locale="uk"
                    name={InsuranceDataFieldName.confirmInsuranceDocIssueDate}
                    placeholderText="дд.мм.рррр"
                    id={InsuranceDataFieldName.confirmInsuranceDocIssueDate}
                    selected={confirmInsuranceDocIssueDate}
                    className="form-input__input  form-control"
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={todaysDate}
                    customInput={
                      <InputMask
                        mask="99.99.9999"
                        name={InsuranceDataFieldName.confirmInsuranceDocIssueDate}
                        value={
                          confirmInsuranceDocIssueDate ? confirmInsuranceDocIssueDate.toLocaleDateString('uk-UA') : ''
                        }
                      />
                    }
                    onChange={(date) => {
                      handleInputChange(InsuranceDataFieldName.confirmInsuranceDocIssueDate, date);
                    }}
                  />
                  <label htmlFor={InsuranceDataFieldName.confirmInsuranceDocIssueDate}>
                    <img
                      className="has-datetimepicker form-input__date-icon js-date-picker"
                      src={calendarIcon}
                      alt="icon"
                    />
                  </label>
                </div>
              </div>

              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Термін дії</div>
                <div className="form-input__date-box">
                  <DatePicker
                    locale="uk"
                    name={InsuranceDataFieldName.confirmInsuranceDocExpireDate}
                    id={InsuranceDataFieldName.confirmInsuranceDocExpireDate}
                    placeholderText="дд.мм.рррр"
                    selected={confirmInsuranceDocExpireDate}
                    className="form-input__input  form-control"
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={todaysDate}
                    customInput={
                      <InputMask
                        mask="99.99.9999"
                        name={InsuranceDataFieldName.confirmInsuranceDocExpireDate}
                        value={
                          confirmInsuranceDocExpireDate ? confirmInsuranceDocExpireDate.toLocaleDateString('uk-UA') : ''
                        }
                      />
                    }
                    onChange={(date) => {
                      handleInputChange(InsuranceDataFieldName.confirmInsuranceDocExpireDate, date);
                    }}
                  />
                  <label htmlFor={InsuranceDataFieldName.confirmInsuranceDocExpireDate}>
                    <img
                      className="has-datetimepicker form-input__date-icon js-date-picker"
                      src={calendarIcon}
                      alt="icon"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="form-input form-input_margin-bottom-none required">
              <div className="form-input__label">Документ, який підтверджує наявність страхування</div>
              <FileUpload
                inputName={InsuranceDataFieldName.confirmInsuranceDocument}
                inputTitle="Додати сканкопію документа"
                value={formData.confirmInsuranceDocument}
                handleInputChange={handleInputChange}
                handleClearInput={handleClearInput}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const previewInsuranceData = ({ formData }: PreviewProps): AccordionData => {
  return {
    title: StepsNavigationName.insurance,
    content: (
      <div className="custom-columns custom-columns_grey-bg custom-columns_default-gap">
        <div className="custom-columns__row custom-columns__row_padding-tiny">
          <div className="custom-columns__col">
            <div className="custom-columns__title">Тип документа, який підтверджує наявність страхування</div>
            <div className="custom-columns__info">
              <NullValueGuard>
                {parseFormDataValue(formData[InsuranceDataFieldName.confirmInsuranceDocType])?.label}
              </NullValueGuard>
            </div>
          </div>
          <div className="custom-columns__col">
            <div className="custom-columns__title">Серія та номер</div>
            <div className="custom-columns__info">
              <NullValueGuard>{formData[InsuranceDataFieldName.confirmInsuranceDocSerialNumber]}</NullValueGuard>
            </div>
          </div>
        </div>
        <div className="custom-columns__row custom-columns__row_padding-tiny">
          <div className="custom-columns__col">
            <div className="custom-columns__title">Дата видачі</div>
            <div className="custom-columns__info">
              <NullValueGuard>
                {formatDate(formData[InsuranceDataFieldName.confirmInsuranceDocIssueDate])}
              </NullValueGuard>
            </div>
          </div>
          <div className="custom-columns__col">
            <div className="custom-columns__title">Термін дії</div>
            <div className="custom-columns__info">
              <NullValueGuard>
                {formatDate(formData[InsuranceDataFieldName.confirmInsuranceDocExpireDate])}
              </NullValueGuard>
            </div>
          </div>
          <div className="custom-columns__col">
            <div className="custom-columns__title">Документ, який підтверджує наявність страхування</div>
            <div className="custom-columns__info">
              <NullValueGuard>
                {formData[InsuranceDataFieldName.confirmInsuranceDocument] ? (
                  <PreviewFilesList files={formData[InsuranceDataFieldName.confirmInsuranceDocument]} />
                ) : null}
              </NullValueGuard>
            </div>
          </div>
        </div>
      </div>
    ),
  };
};
