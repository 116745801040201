import React, { useCallback, useState } from 'react';

import Loader from '../../../components/Loader';

export type Props = {
  disabled?: boolean;
  children: string;
  onClick: () => Promise<void>;
  icon: string;
};

export const ActionButton: React.FC<Props> = ({ disabled = false, children, onClick, icon }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const handleClick = useCallback(() => {
    setLoading(true);
    onClick().finally(() => setLoading(false));
  }, [onClick]);

  return (
    <button type="button" className="download-file" disabled={disabled || isLoading} onClick={handleClick}>
      <span>{children}</span>

      <span className="download-file__img-container">
        {isLoading ? <Loader centered /> : <img src={icon} alt="save file" />}
      </span>
    </button>
  );
};
