import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { AccessibilityPanelButton } from '../AccessibilityPanel/AccessibilityPanelButton';
import { ROUTES } from '../../../router/config';
import AuthContext from '../../../contexts/Auth/AuthContext';
import { config } from '../../../config';
import './Footer.scss';
import { ExternalLink } from '../../Forms/ExternalLink/ExternalLink';

const Footer = (): JSX.Element => {
  const { storedLinks } = useContext(AuthContext);

  const handleAccessibilityClick = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className="footer" id="main-footer">
      <div className="container">
        <NavLink to={ROUTES.HOME} className="footer__header">
          <img src="/images/simple_logo.svg" alt="logo" width="72" height="72" />
          <h3 className="header-banner__logo-title">Єдине вікно для громадян</h3>
        </NavLink>
        <div className="footer__links-container">
          <div className="footer__links-container__item-licence">
            <div className="footer__links-container__item-licence__header">
              Якщо не зазначено інше всі матеріали розміщені на умовах ліцензії
            </div>
            <ExternalLink
              className="footer__links-container__item-licence__link"
              href={storedLinks?.externalLink?.licence}
            >
              Creative Commons Attribution 4.0 International license
            </ExternalLink>
          </div>
          <div className="footer__links-container__item-contacts">
            <div className="footer__links-container__item-contacts__link__margin-bottom">
              <div className="footer__links-container__item-contacts__header">Довідка щодо функціонування порталу:</div>
              <ExternalLink
                className="footer__links-container__item-contacts__link"
                href={`mailto:${config.support.email}`}
              >
                {config.support.email}
              </ExternalLink>
            </div>
            <Link
              className="footer__links-container__item-contacts__link"
              to={ROUTES.PERSONAL_DATA}
              target="_blank"
              rel="noreferrer"
            >
              Повідомлення про обробку персональних даних
            </Link>
          </div>
          <div className="footer__links-container__item-links">
            <AccessibilityPanelButton onClick={handleAccessibilityClick} />
            <div className="footer__links-container__item-links__socials">
              <ExternalLink className="footer__social-link" href={storedLinks?.externalLink?.mvsFacebook}>
                <img className="footer__social-icon" src="/images/facebook.svg" alt="facebook" width="24" height="24" />
              </ExternalLink>
              <ExternalLink className="footer__social-link" href={storedLinks?.externalLink?.mvsTwitter}>
                <img className="footer__social-icon" src="/images/twitter.svg" alt="twitter" width="24" height="23" />
              </ExternalLink>
              <ExternalLink className="footer__social-link" href={storedLinks?.externalLink?.mvsYoutube}>
                <img className="footer__social-icon" src="/images/youtube.svg" alt="youtube" width="24" height="20" />
              </ExternalLink>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
