import { CreateSocialProtectAppealAlertProps } from '../../components/Modals/CreateSocialProtectAppealAlert';
import { DeleteServiceDraftAlertProps } from '../../components/Modals/DeleteServiceDraftAlert/DeleteServiceDraftAlert';
import { DocumentDataMissingAlertProps } from '../../components/Modals/DocumentDataMissingAlert';
import { ExternalServiceDetailsAlertProps } from '../../components/Modals/ExternalServiceDetailsAlert';
import { LeavingWhileInProgressAlertProps } from '../../components/Modals/LeavingWhileInProgressAlert.tsx/LeavingWhileInProgressAlert';
import { PayOrLeaveAlertProps } from '../../components/Modals/PayOrLeaveAlert';
import { PaymentDetailsAlertProps } from '../../components/Modals/PaymentDetailsAlert';
import { ServiceDraftExistingAlertProps } from '../../components/Modals/ServiceDraftExistingAlert';

export enum ModalName {
  BirthdayAlert = 'birthday_alert',
  AddressCheckAlert = 'address_alert',
  DocumentMissingAlert = 'document_missing_aler',
  DocumentAddCheckAlert = 'document_add_check_alert',
  ServiceDraftExistingAlert = 'service_draft_existing_alert',
  DeleteServiceDraftAlert = 'delete_service_draft_alert',
  ServerErrorAlert = 'server_error_alert',
  PayOrLeaveAlert = 'pay_or_leave_alert',
  PaymentDetailsAlert = 'payment_details_alert',
  LeavingWhileInProgressAlert = 'leaving_while_in_progress_alert',
  AppealAcceptedAlert = 'appeal_accepted_alert',
  CreateSocialProtectAppealAlert = 'create_appeal_alert',
  ExternalServiceDetailsAlert = 'external_service_details_alert',
}

interface BaseRenderProps {
  modalName: ModalName;
}

export interface BirthdayAlertRender extends BaseRenderProps {
  modalName: ModalName.BirthdayAlert;
  modalProps?: never;
}

export interface AddressCheckAlertRender extends BaseRenderProps {
  modalName: ModalName.AddressCheckAlert;
  modalProps?: never;
}

export interface DocumentMissingAlertRender extends BaseRenderProps {
  modalName: ModalName.DocumentMissingAlert;
  modalProps: DocumentDataMissingAlertProps;
}

export interface DocumentAddCheckAlertRender extends BaseRenderProps {
  modalName: ModalName.DocumentAddCheckAlert;
  modalProps?: never;
}

export interface ServiceDraftExistingAlertRender extends BaseRenderProps {
  modalName: ModalName.ServiceDraftExistingAlert;
  modalProps: ServiceDraftExistingAlertProps;
}

export interface DeleteServiceDraftAlertRender extends BaseRenderProps {
  modalName: ModalName.DeleteServiceDraftAlert;
  modalProps: DeleteServiceDraftAlertProps;
}

export interface ServerErrorAlertRender extends BaseRenderProps {
  modalName: ModalName.ServerErrorAlert;
  modalProps?: never;
}

export interface PayOrLeaveAlertRender extends BaseRenderProps {
  modalName: ModalName.PayOrLeaveAlert;
  modalProps: PayOrLeaveAlertProps;
}

export interface PaymentDetailsAlertRender extends BaseRenderProps {
  modalName: ModalName.PaymentDetailsAlert;
  modalProps: PaymentDetailsAlertProps;
}

export interface LeavingWhileInProgressAlertRender extends BaseRenderProps {
  modalName: ModalName.LeavingWhileInProgressAlert;
  modalProps: LeavingWhileInProgressAlertProps;
}

export interface AppealAcceptedAlertRender extends BaseRenderProps {
  modalName: ModalName.AppealAcceptedAlert;
  modalProps?: never;
}

export interface CreateSocialProtectAppealAlertRender extends BaseRenderProps {
  modalName: ModalName.CreateSocialProtectAppealAlert;
  modalProps: CreateSocialProtectAppealAlertProps;
}

export interface ExternalServiceDetailsAlertRender extends BaseRenderProps {
  modalName: ModalName.ExternalServiceDetailsAlert;
  modalProps: ExternalServiceDetailsAlertProps;
}

export type RenderModalProps =
  | BirthdayAlertRender
  | AddressCheckAlertRender
  | DocumentMissingAlertRender
  | DocumentAddCheckAlertRender
  | ServiceDraftExistingAlertRender
  | DeleteServiceDraftAlertRender
  | ServerErrorAlertRender
  | PayOrLeaveAlertRender
  | PaymentDetailsAlertRender
  | LeavingWhileInProgressAlertRender
  | AppealAcceptedAlertRender
  | CreateSocialProtectAppealAlertRender
  | ExternalServiceDetailsAlertRender;
