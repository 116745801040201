import React from 'react';
import { useNavigate } from 'react-router-dom';

import { HeaderWithGoBackButton, HeaderWithGoBackButtonProps } from './HeaderWithGoBackButton';
import { ROUTES } from '../../router/config';

export const HeaderWithHomeLinkButton = React.memo<Pick<HeaderWithGoBackButtonProps, 'header' | 'className'>>(
  function (props) {
    const navigate = useNavigate();

    return (
      <HeaderWithGoBackButton
        {...props}
        goBackText="Повернутись до списку послуг"
        onGoBack={(): void => navigate(ROUTES.HOME)}
      />
    );
  },
);
