import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import ukUA from 'antd/locale/uk_UA';
import 'dayjs/locale/uk';

import { AuthContextProvider } from './contexts/Auth/AuthContext';
import { ModalContextProvider } from './contexts/Modal/ModalContext';
import RouterContainer from './router';
import ErrorBoundary from './components/ErrorBoundary';
import { FeatureProvider } from './contexts/Features/FeatureProvider';
import { config } from './config';

// eslint-disable-next-line no-console
console.info('VERSION: ', config.VERSION);

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);

registerLocale('uk', uk);
setDefaultLocale('uk');
dayjs.locale('uk');
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

root.render(
  <ConfigProvider locale={ukUA}>
    <BrowserRouter>
      <AuthContextProvider>
        <ModalContextProvider>
          <FeatureProvider>
            <ErrorBoundary>
              <RouterContainer />
            </ErrorBoundary>
          </FeatureProvider>
        </ModalContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </ConfigProvider>,
);
