import React, { memo } from 'react';

import { ModalBorderTopColor, ModalConstructor } from '../ModalConstructor';
import './ExternalServiceDetailsAlert.scss';
import ArrowBlue from '../../../assets/images/arrow_forward_blue.svg';
import { LinkAsButton } from '../../Button';
import { UserServiceExternal } from '../../Api/types';

export interface ExternalServiceDetailsAlertProps {
  service: UserServiceExternal;
}

export const ExternalServiceDetailsAlert = memo<ExternalServiceDetailsAlertProps>(function ExternalServiceDetailsAlert({
  service,
}) {
  return (
    <ModalConstructor modalBorderTopColor={ModalBorderTopColor.red}>
      <div className="external-service-alert">
        <img src={service.icon} alt="service-icon" />
        <div className="external-service-alert__header">{service.name}</div>

        <div className="external-service-alert_body">{service.short_description}</div>

        <div className="external-service-alert__buttons-box">
          <LinkAsButton to={service.link} size="lg" outline withIcon>
            <span>Перейти</span>
            <img src={ArrowBlue} alt="redirect" />
          </LinkAsButton>
        </div>
      </div>
    </ModalConstructor>
  );
});
