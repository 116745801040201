import {
  ArtifactType,
  IntegrationSystem,
  UserRequestChannel,
  UserRequestPartStatus,
  UserRequestStatus,
} from '../../../components/Api/types';
import { StatusColor, StatusMapping } from '../../../components/Status';
import { generateMappedStatuses } from '../../../helpers/generateMappedStatuses';
import { ERZ_statuses } from '../../../modules/erz/resources/uk.l10n';
import { IAC_statuses } from '../../../modules/iac/resources/uk.l10n';
import { RMP_statuses } from '../../../modules/rmp/resources/uk.l10n';

export const defaultSelectOption: StatusMapping<'all'> = {
  status: 'all',
  value: 'all/all',
  label: 'Всі статуси',
  color: StatusColor.EMPTY,
  integrationSystem: 'all',
};

export const ALL_statuses: Array<StatusMapping<UserRequestStatus | 'all'>> = [
  defaultSelectOption,
  ...RMP_statuses,
  ...ERZ_statuses,
  ...IAC_statuses,
];

export const userRequestPartStatuses = generateMappedStatuses(IntegrationSystem.ERZ, [
  {
    status: UserRequestPartStatus.permit,
    label: 'Рішення про надання дозволу',
    color: StatusColor.GREEN,
  },
  {
    status: UserRequestPartStatus.denied,
    label: 'Рішення про відмову у наданні дозволу',
    color: StatusColor.RED,
  },
  {
    status: UserRequestPartStatus.impossible,
    label: 'Прийнято рішення про неможливість надання дозволу',
    color: StatusColor.RED,
  },
  {
    status: UserRequestPartStatus.retained,
    label: 'Рішення про залишення без руху',
    color: StatusColor.GREY,
  },
]);

export const SEDUserRequestChannels: Record<UserRequestChannel, string> = {
  [UserRequestChannel.EKG]: 'Єдине вікно для громадян',
  [UserRequestChannel.NPU]: 'Національна поліція України',
  [UserRequestChannel.SGD]: 'Суб’єкт господарювання',
};

export const ArtifactTypeNameMapping: Record<ArtifactType, string> = {
  [ArtifactType.EXTRACT]: 'Витяг',
  [ArtifactType.RECEIPT]: 'Квитанція',
  [ArtifactType.SIGN]: 'Файл підпису',
  [ArtifactType.MESSAGE]: 'Повідомлення',
  [ArtifactType.APPLICATION]: 'Копія заяви',
};
