import { IntegrationSystem } from '../components/Api/types';
import { StatusMapping } from '../components/Status';

interface InitialStatus<StatusValue extends string> extends Pick<StatusMapping<StatusValue>, 'label' | 'color'> {
  status: StatusValue;
}

export const generateMappedStatuses = <StatusValue extends string>(
  system: IntegrationSystem,
  statuses: Array<InitialStatus<StatusValue>>,
): Array<StatusMapping<StatusValue>> => {
  return statuses.map((status) => ({
    ...status,
    value: `${status.status}/${system}`,
    integrationSystem: system,
  }));
};
