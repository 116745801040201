import React from 'react';

import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import './ModalBox.scss';

interface Props {
  isModalOpen: boolean;
  clickOnAddServiceButton: {
    toggleModalAddService: () => void;
  };
}

const ModalBox = ({ isModalOpen, clickOnAddServiceButton }: Props): JSX.Element => {
  const clickOnAddButton = clickOnAddServiceButton.toggleModalAddService;

  useDisableBodyScroll(isModalOpen);

  if (!isModalOpen) {
    return null;
  }

  return (
    <div className="modal-box">
      <button onClick={clickOnAddButton} className="modal-box__overlay" />
      <div className="modal modal_size">
        <button className="close-button" onClick={clickOnAddButton}>
          <div className="close-button__span" />
          <div className="close-button__span close-button__span_rotate" />
        </button>
        <div className="block-title block-title_margin-bottom-lg">Додати послугу</div>
        <div className="modal__content">
          <div className="modal__row">
            <form className="form" action="">
              <div className="form-input">
                <label htmlFor="email" className="form-input__label">
                  Електронна адреса
                </label>
                <input id="email" className="form-input__input" type="text" placeholder="Введіть електронну адресу" />
              </div>
              <div className="form-input">
                <label htmlFor="service" className="form-input__label">
                  Бажана послуга
                </label>
                <textarea id="service" className="form-input__textarea" placeholder="Введіть опис бажаної послуги" />
              </div>
            </form>
          </div>
        </div>
        <div className="modal__buttons">
          <button onClick={clickOnAddButton} className="button button_border modal__m-right">
            Закрити
          </button>
          <div className="button">Відправити</div>
        </div>
      </div>
    </div>
  );
};

export default ModalBox;
