import { ServiceCodes } from '../components/Api/types';
import { ERZ_ROUTES_BY_SERVICE_CODE, ERZ_ROUTES_LEGACY } from '../modules/erz/routes/config';
import { IAC_ROUTES_BY_SERVICE_CODE, IAC_ROUTES_LEGACY } from '../modules/iac/routes/config';
import { MSP_ROUTES_BY_SERVICE_CODE } from '../modules/msp/routes/config';
import { RMP_ROUTES_BY_SERVICE_CODE } from '../modules/rmp/routes/config';

export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  INTERNAL_SERVICES: '/services',
  EXTERNAL_SERVICES: '/external-services',
  PERSONAL_DATA: '/personal_data',
  USER: {
    PROFILE: '/user/profile',
    REQUESTS: {
      ACTIVE_REQUESTS: '/user/requests/active',
      ARCHIVED_REQUESTS: '/user/requests/archived',
      DRAFT_REQUESTS: '/user/requests/draft',
    },
  },
} as const;

export const ROUTES_LEGACY: Array<Record<'LEGACY_PATH' | 'RELEVANT_PATH', string>> = [
  ...ERZ_ROUTES_LEGACY,
  ...IAC_ROUTES_LEGACY,
];

export const ROUTES_BY_SERVICE_CODE: Record<ServiceCodes, string> = {
  ...ERZ_ROUTES_BY_SERVICE_CODE,
  ...IAC_ROUTES_BY_SERVICE_CODE,
  ...MSP_ROUTES_BY_SERVICE_CODE,
  ...RMP_ROUTES_BY_SERVICE_CODE,
};
