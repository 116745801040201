import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './FileUpload.scss';
import classNames from 'classnames';

import pdf from '../../assets/images/pdf-icon.svg';
import { FileType } from '../../helpers/fileHelper';
import { maxFileSize } from './constants';

const allFileTypes = [FileType.PNG, FileType.JPG, FileType.JPEG, FileType.PDF];

const isPdf = (file?: File): boolean => file?.type === FileType.PDF;

type Props = {
  inputName: string;
  inputTitle?: string;
  value: File[];
  quantityFiles?: number;
  allowedFileTypes?: FileType[];
  handleInputChange: (name: string, value: File[]) => void;
  handleClearInput: (name: string) => void;
};

const FileInput: React.FC<Props> = ({
  inputName,
  inputTitle,
  value,
  quantityFiles = 3,
  allowedFileTypes = allFileTypes,
  handleInputChange,
  handleClearInput,
}) => {
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const allowedFileTypesWithoutPrefix = useMemo<string[]>(
    () => allowedFileTypes.map((type) => type.split('/').at(-1)?.toUpperCase()),
    [allowedFileTypes],
  );
  const [fileMap, setFileMap] = useState<Map<string, boolean>>(new Map(files.map((file: File) => [file.name, true])));

  const filePreviewId = useCallback((id: number) => `${inputName}__preview-${id}`, [inputName]);
  const fileDeleteId = useCallback((id: number) => `${inputName}__delete-${id}`, [inputName]);

  useEffect(() => {
    setFiles(value?.filter((entry): entry is File => entry instanceof File) || []);
    setErrorMessage('');
  }, [value]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files.length) return;

    setErrorMessage('');

    const fileList = event.target.files;

    if (files.length === quantityFiles) {
      setErrorMessage(`Ви завантажили маскимальну кількість файлів - ${quantityFiles} вкладення`);
      return;
    }

    const newFiles = Array.from(fileList).filter((file) => {
      switch (true) {
        case !allowedFileTypes.includes(file.type as FileType):
          setErrorMessage(
            `Файл ${file.name} має недозволений тип - ${
              file.type
            }. Дозволені типи: ${allowedFileTypesWithoutPrefix.join(', ')}.`,
          );
          return false;

        case file.size > maxFileSize:
          setErrorMessage(`Файл ${file.name} завеликий. Максимально допустимий розмір вкладення - 1,6 Мбт`);
          return false;

        default:
          return true;
      }
    });

    if (!newFiles.length) {
      event.target.value = '';
      return;
    }

    newFiles.forEach((file) => {
      const fileName = file.name;

      if (fileMap.get(fileName)) {
        setErrorMessage(`Файл ${fileName} уже додано.`);
      } else {
        setFileMap((prevFileMap) => new Map(prevFileMap.set(fileName, true)));
        setFiles((prevFiles) => [...prevFiles, file]);
      }
    });

    handleInputChange(inputName, [...files, ...newFiles]);

    event.target.value = '';
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const { id } = event.currentTarget;
    const fileSequenceNumber = Number(id.split('-').at(-1));
    const fileIndexToRemove = fileSequenceNumber - 1;
    const fileName = files[fileIndexToRemove].name;
    const newFiles = files.filter((_, index) => index !== fileIndexToRemove);

    setFileMap((prevFileMap) => {
      prevFileMap.delete(fileName);

      return new Map(prevFileMap);
    });
    setFiles(newFiles);
    setErrorMessage('');
    // Is needed to handle empty value in upper component
    handleInputChange(inputName, newFiles);

    if (!newFiles.length) {
      handleClearInput(inputName);
    }
  };

  return (
    <>
      <div
        className={classNames('file-input', {
          'not-empty-description': files.length,
        })}
      >
        <div
          className="file-input__field"
          data-title={inputTitle}
          data-input={inputName}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.code === 'Enter' && fileInputRef.current.click()}
          onClick={() => fileInputRef.current.click()}
        >
          <div className="file-input__attached">
            <div className="file-input__attached-items">
              <>
                {files.map((file, index) => (
                  <div key={filePreviewId(index + 1)} className="file-input__attached-item">
                    <img
                      id={filePreviewId(index + 1)}
                      src={isPdf(file) ? (pdf as any) : URL.createObjectURL(file)}
                      alt={file.name}
                      className="file-input__attached-img"
                    />
                    <button
                      id={fileDeleteId(index + 1)}
                      onClick={handleDeleteClick}
                      className="close-button size-m default-bg outside-position"
                      type="button"
                    >
                      <span className="close-button__span close-button__span_dark"></span>
                      <span className="close-button__span close-button__span_dark close-button__span_rotate"></span>
                    </button>
                  </div>
                ))}
              </>
            </div>
            <div className="file-input__attached-description">
              <svg
                className="file-input__attached-description-img"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M16 6V17.5C16 19.71 14.21 21.5 12 21.5C9.79 21.5 8 19.71 8 17.5V5C8 3.62 9.12 2.5 10.5 2.5C11.88 2.5 13 3.62 13 5V15.5C13 16.05 12.55 16.5 12 16.5C11.45 16.5 11 16.05 11 15.5V6H9.5V15.5C9.5 16.88 10.62 18 12 18C13.38 18 14.5 16.88 14.5 15.5V5C14.5 2.79 12.71 1 10.5 1C8.29 1 6.5 2.79 6.5 5V17.5C6.5 20.54 8.96 23 12 23C15.04 23 17.5 20.54 17.5 17.5V6H16Z"
                  fill="#6F6F6F"
                />
              </svg>
              <div className="file-input__attached-description-text">{inputTitle}</div>
            </div>

            <input
              ref={fileInputRef}
              type="file"
              name={inputName}
              onChange={handleFileChange}
              accept={allowedFileTypes.join(', ')}
              hidden
            />
          </div>
        </div>
      </div>
      <div className="file-input__description">{allowedFileTypesWithoutPrefix.join(', ')}</div>
      {errorMessage && <div className="file-input__error-msg">{errorMessage}</div>}
    </>
  );
};

export default FileInput;
