import React, { useEffect, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';

import FileUpload from '../FileUpload';
import InitialSelect from '../Select';
import { FormConstants, HealthDataFieldName } from '../constants';
import { DocumentTypeEntity } from '../../../helpers/reformatDocumentTypeToEntity';
import { getERZDocumentsSelect } from '../../../modules/erz/helpers';
import calendarIcon from '../../../assets/images/calendar.svg';
import { DocumentTypeCode } from '../../Api/types';
import { formatDate, getDateWithoutTime } from '../../../helpers/dates';
import { NullValueGuard } from '../../../guards/NullValueGuard';
import { parseFormDataValue } from '../../../helpers/parseFormDataValue';
import { PreviewFilesList } from '../PreviewFilesList';
import { AccordionData } from '../../Accordion/Accordion';
import { StepsNavigationName } from '../../../config/services';

const todaysDate = getDateWithoutTime(new Date());

export interface HealthFormDataProps {
  [HealthDataFieldName.healthDataDoc]?: boolean;
  [HealthDataFieldName.healthDataDocType]?: string;
  [HealthDataFieldName.healthDataDocSerialNumber]?: string;
  [HealthDataFieldName.healthDataDocIssuingAuthority]?: string;
  [HealthDataFieldName.healthDataDocIssueDate]?: string;
  [HealthDataFieldName.healthDataDocExpireDate]?: string;
  [HealthDataFieldName.healthDataDocument]?: File[];
}

interface Props {
  formData: HealthFormDataProps;
  isStepMandatory?: boolean;
  handleInputChange: (name: string, value: unknown) => void;
  handleClearInput: (name: string) => void;
}
interface PreviewProps {
  formData: HealthFormDataProps;
}

export const HealthData: React.FC<Props> = ({
  formData,
  isStepMandatory = false,
  handleInputChange,
  handleClearInput,
}) => {
  const [optionsPersonDoc, setOptionsPersonDoc] = useState<DocumentTypeEntity[]>([]);

  const healthDataDoc = useMemo(
    () => formData.healthDataDoc || isStepMandatory,
    [formData.healthDataDoc, isStepMandatory],
  );
  const healthDataDocType = useMemo(() => JSON.parse(formData.healthDataDocType || null), [formData.healthDataDocType]);
  const healthDataDocIssueDate = useMemo(
    () => (formData.healthDataDocIssueDate ? new Date(formData.healthDataDocIssueDate) : null),
    [formData.healthDataDocIssueDate],
  );
  const healthDataDocExpireDate = useMemo(
    () => (formData.healthDataDocExpireDate ? new Date(formData.healthDataDocExpireDate) : null),
    [formData.healthDataDocExpireDate],
  );

  useEffect(() => {
    if (healthDataDoc) {
      (async () => {
        if (!formData.healthDataDocType) {
          const [document] = await getERZDocumentsSelect({
            allowedDocumentTypeCodes: [DocumentTypeCode.MEDICAL_REFERENCE],
            setOptions: setOptionsPersonDoc,
          });
          handleInputChange(HealthDataFieldName.healthDataDocType, document);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthDataDocType, healthDataDoc]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    const booleanValue = value.toLowerCase() === 'true';

    if (!booleanValue) {
      [
        HealthDataFieldName.healthDataDocType,
        HealthDataFieldName.healthDataDocSerialNumber,
        HealthDataFieldName.healthDataDocIssuingAuthority,
        HealthDataFieldName.healthDataDocIssueDate,
        HealthDataFieldName.healthDataDocExpireDate,
        HealthDataFieldName.healthDataDocument,
      ].forEach(handleClearInput);
    }

    handleInputChange(name, booleanValue);
  };

  return (
    <>
      <div
        id="step-description"
        className="block-title block-title_lg block-title_padding-bottom-md-extra block-title_bg-light"
      >
        {StepsNavigationName.health}
      </div>

      <div className="form__content">
        {!isStepMandatory && (
          <div className="form__row">
            <div className="form-input form-input_margin-bottom-none">
              <div className="form-input__label form-input__label_margin-bottom-md">
                Ви хочете додати дані документа?
              </div>
              <fieldset id={HealthDataFieldName.healthDataDoc} className="form-input__radiobuttons">
                <label className="radio" htmlFor={HealthDataFieldName.healthDataDoc + '_yes'}>
                  <input
                    className="radio__input"
                    type="radio"
                    id={HealthDataFieldName.healthDataDoc + '_yes'}
                    name={HealthDataFieldName.healthDataDoc}
                    value="true"
                    checked={healthDataDoc}
                    onChange={handleRadioChange}
                  />
                  <span className="radio__checkmark"></span>
                  <span className="radio__label">Так</span>
                </label>

                <label className="radio" htmlFor={HealthDataFieldName.healthDataDoc + '_no'}>
                  <input
                    className="radio__input"
                    type="radio"
                    id={HealthDataFieldName.healthDataDoc + '_no'}
                    name={HealthDataFieldName.healthDataDoc}
                    value="false"
                    checked={!healthDataDoc}
                    onChange={handleRadioChange}
                  />
                  <span className="radio__checkmark"></span>
                  <span className="radio__label">Ні</span>
                </label>
              </fieldset>
            </div>
          </div>
        )}

        {(isStepMandatory || healthDataDoc) && (
          <>
            <div className="form-input form-input_margin-bottom-none required no-group" data-groupName="healthData">
              <div className="form-input__label">Тип документа, який підтверджує стан здоров’я</div>
              <div className="input-select js-input-select">
                <InitialSelect
                  name={HealthDataFieldName.healthDataDocType}
                  options={optionsPersonDoc}
                  placeholder="Не вибрано"
                  defaultValue={healthDataDocType}
                  onChange={(newValue, name) => handleInputChange(name, newValue)}
                  onMenuOpen={() => {
                    if (!optionsPersonDoc.length) {
                      getERZDocumentsSelect({
                        allowedDocumentTypeCodes: [DocumentTypeCode.MEDICAL_REFERENCE],
                        setOptions: setOptionsPersonDoc,
                      });
                    }
                  }}
                />
              </div>
            </div>

            <div className="form-input form-input_margin-bottom-none required">
              <div className="form-input__label">Серія та номер</div>
              <input
                className="form-input__input"
                placeholder="Не вказано"
                type="text"
                name={HealthDataFieldName.healthDataDocSerialNumber}
                value={formData.healthDataDocSerialNumber || ''}
                onChange={({ target: { name, value } }) => handleInputChange(name, value)}
                maxLength={FormConstants.INPUT_MAX_LENGTH}
              />
            </div>

            <div className="form-input form-input_margin-bottom-none required">
              <div className="form-input__label">Орган видачі</div>
              <input
                className="form-input__input"
                placeholder="Не вказано"
                type="text"
                name={HealthDataFieldName.healthDataDocIssuingAuthority}
                value={formData.healthDataDocIssuingAuthority || ''}
                onChange={({ target: { name, value } }) => handleInputChange(name, value)}
                maxLength={FormConstants.INPUT_MAX_LENGTH}
              />
            </div>

            <div className="form__row">
              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Дата видачі</div>
                <div className="form-input__date-box">
                  <DatePicker
                    locale="uk"
                    name={HealthDataFieldName.healthDataDocIssueDate}
                    placeholderText="дд.мм.рррр"
                    id={HealthDataFieldName.healthDataDocIssueDate}
                    selected={healthDataDocIssueDate}
                    className="form-input__input  form-control"
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={todaysDate}
                    customInput={
                      <InputMask
                        mask="99.99.9999"
                        name={HealthDataFieldName.healthDataDocIssueDate}
                        value={healthDataDocIssueDate ? healthDataDocIssueDate.toLocaleDateString('uk-UA') : ''}
                      />
                    }
                    onChange={(date) => {
                      handleInputChange(HealthDataFieldName.healthDataDocIssueDate, date);
                    }}
                  />
                  <label htmlFor={HealthDataFieldName.healthDataDocIssueDate}>
                    <img
                      className="has-datetimepicker form-input__date-icon js-date-picker"
                      src={calendarIcon}
                      alt="icon"
                    />
                  </label>
                </div>
              </div>

              <div className="form-input form-input_margin-bottom-none required">
                <div className="form-input__label">Термін дії</div>
                <div className="form-input__date-box">
                  <DatePicker
                    locale="uk"
                    name={HealthDataFieldName.healthDataDocExpireDate}
                    placeholderText="дд.мм.рррр"
                    id={HealthDataFieldName.healthDataDocExpireDate}
                    selected={healthDataDocExpireDate}
                    className="form-input__input form-control"
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={todaysDate}
                    customInput={
                      <InputMask
                        mask="99.99.9999"
                        name={HealthDataFieldName.healthDataDocExpireDate}
                        value={healthDataDocExpireDate ? healthDataDocExpireDate.toLocaleDateString('uk-UA') : ''}
                      />
                    }
                    onChange={(date) => {
                      handleInputChange(HealthDataFieldName.healthDataDocExpireDate, date);
                    }}
                  />
                  <label htmlFor={HealthDataFieldName.healthDataDocExpireDate}>
                    <img
                      className="has-datetimepicker form-input__date-icon js-date-picker"
                      src={calendarIcon}
                      alt="icon"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="form-input form-input_margin-bottom-none required">
              <div className="form-input__label">Документ, який підтверджує стан здоров’я</div>
              <FileUpload
                inputName={HealthDataFieldName.healthDataDocument}
                inputTitle="Додати сканкопію документа"
                value={formData.healthDataDocument}
                handleInputChange={handleInputChange}
                handleClearInput={handleClearInput}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const previewHealthData = ({ formData }: PreviewProps): AccordionData => {
  return {
    title: StepsNavigationName.health,
    content: (
      <div className="custom-columns custom-columns_grey-bg custom-columns_default-gap">
        <div className="custom-columns__row custom-columns__row_padding-tiny">
          <div className="custom-columns__col">
            <div className="custom-columns__title">Тип документа, який підтверджує стан здоров’я</div>
            <div className="custom-columns__info">
              <NullValueGuard>
                {parseFormDataValue(formData[HealthDataFieldName.healthDataDocType])?.label}
              </NullValueGuard>
            </div>
          </div>
          <div className="custom-columns__col">
            <div className="custom-columns__title">Серія та номер</div>
            <div className="custom-columns__info">
              <NullValueGuard>{formData[HealthDataFieldName.healthDataDocSerialNumber]}</NullValueGuard>
            </div>
          </div>
          <div className="custom-columns__col">
            <div className="custom-columns__title">Орган видачі</div>
            <div className="custom-columns__info">
              <NullValueGuard>{formData[HealthDataFieldName.healthDataDocIssuingAuthority]}</NullValueGuard>
            </div>
          </div>
        </div>
        <div className="custom-columns__row custom-columns__row_padding-tiny">
          <div className="custom-columns__col">
            <div className="custom-columns__title">Дата видачі</div>
            <div className="custom-columns__info">
              <NullValueGuard>{formatDate(formData[HealthDataFieldName.healthDataDocIssueDate])}</NullValueGuard>
            </div>
          </div>
          <div className="custom-columns__col">
            <div className="custom-columns__title">Термін дії</div>
            <div className="custom-columns__info">
              <NullValueGuard>{formatDate(formData[HealthDataFieldName.healthDataDocExpireDate])}</NullValueGuard>
            </div>
          </div>
          <div className="custom-columns__col">
            <div className="custom-columns__title">Документ, який підтверджує стан здоров’я</div>
            <div className="custom-columns__info">
              <NullValueGuard>
                {formData[HealthDataFieldName.healthDataDocument] ? (
                  <PreviewFilesList files={formData[HealthDataFieldName.healthDataDocument]} />
                ) : null}
              </NullValueGuard>
            </div>
          </div>
        </div>
      </div>
    ),
  };
};
