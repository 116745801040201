import { IACUserRequest } from '../../../../types';
import { ListItem } from './ListItem';
import './Result.scss';

interface Props {
  items: IACUserRequest[];
}

export const Result: React.FC<Props> = ({ items }) => {
  return (
    <div className="result">
      <div className="text__hint">
        <img src="/images/informer_outline_blue.svg" alt="attention icon" width={24} height={24} />
        <p>
          Вам доступні Витяги про несудимість, сформовані протягом попереднього календарного місяця. Якщо Витяги про
          несудимість отримано з метою &quot;Усиновлення, встановлення опіки та піклування, створення прийомної сім`ї
          або дитячого будинку сімейного типу&quot;, то вони доступні за попередній рік.
        </p>
      </div>

      <div className="result__list">
        {items.map((item) => (
          <ListItem item={item} key={item?.id} />
        ))}
      </div>
    </div>
  );
};
