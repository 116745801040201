import React, { memo, useCallback, useState } from 'react';
import { Button as AntButton } from 'antd';
import { ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { ModalBorderTopColor, ModalConstructor } from '../ModalConstructor';
import './CreateSocialProtectAppealAlert.scss';
import Accordion, { AccordionData } from '../../Accordion/Accordion';
import { SocialProtectNeedsList, SocialProtectNeedsListItem } from '../../Api/types';
import { Button } from '../../Button';

export type CreateSocialProtectAppealAlertProps = {
  needs: SocialProtectNeedsList;
  existingChatsOfNeeds: Array<[string, string]>;
  redirectToChatCb: (appealId: string) => void;
  chatCb: (need: SocialProtectNeedsListItem) => void;
  orderCb: () => void;
};

export const CreateSocialProtectAppealAlert = memo(function CreateSocialProtectAppealAlert({
  needs,
  existingChatsOfNeeds,
  redirectToChatCb,
  chatCb,
  orderCb,
}: CreateSocialProtectAppealAlertProps) {
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [currentNeedDetails, setCurrentNeedDetails] = useState<SocialProtectNeedsListItem>();

  const getCurrentNeedChat = useCallback(
    (currentNeed: SocialProtectNeedsListItem) => existingChatsOfNeeds.find(([, needId]) => needId === currentNeed.id),
    [existingChatsOfNeeds],
  );

  return (
    <ModalConstructor modalBorderTopColor={ModalBorderTopColor.blue}>
      <div className="create-social-protect-appeal-alert">
        <div className="create-social-protect-appeal-alert__title">
          <div className="block-title_md">
            {isDetailsOpen ? (
              <>
                <AntButton
                  onClick={(): void => setIsDetailsOpen(false)}
                  className="back-button"
                  icon={<ArrowLeftOutlined />}
                >
                  {currentNeedDetails.name}
                </AntButton>
              </>
            ) : (
              'Виберіть тему для запиту'
            )}
          </div>
        </div>
        {isDetailsOpen ? (
          <div className="detais-container" dangerouslySetInnerHTML={{ __html: currentNeedDetails.description }}></div>
        ) : (
          <div>
            <Accordion
              withMargin={false}
              accordionData={Object.entries(needs).map<AccordionData>(([key, topicNeeds]) => ({
                title: key,
                content: topicNeeds.map((need) => (
                  <AccordionContentItem
                    key={need.id}
                    chatCb={chatCb}
                    need={need}
                    currentNeedChat={getCurrentNeedChat(need)}
                    onDetailsClickCb={(): void => {
                      setIsDetailsOpen(true);
                      setCurrentNeedDetails(need);
                    }}
                    redirectToChatCb={redirectToChatCb}
                  />
                )),
              }))}
            />
            <AntButton block className="create-order-btn" onClick={orderCb}>
              Не знайшли необхідну тему? Написати соціальному працівнику
            </AntButton>
          </div>
        )}
      </div>
    </ModalConstructor>
  );
});

type AccordionContentItemProps = {
  need: SocialProtectNeedsListItem;
  onDetailsClickCb: () => void;
  currentNeedChat: [string, string];
} & Pick<CreateSocialProtectAppealAlertProps, 'chatCb' | 'redirectToChatCb'>;

const AccordionContentItem: React.FC<AccordionContentItemProps> = ({
  need,
  currentNeedChat,
  onDetailsClickCb,
  chatCb,
  redirectToChatCb,
}) => (
  <div key={need.id} className="content-item">
    <div className="content-item-header">{need.name}</div>
    <div className="content-item-buttons-container">
      <AntButton
        type="link"
        icon={<InfoCircleOutlined />}
        onClick={(): void => {
          onDetailsClickCb();
        }}
        size="small"
        className="item-button"
      >
        Детальніше
      </AntButton>
      {currentNeedChat ? (
        <Button
          size="sm"
          className="item-button item-button-chat"
          onClick={(): void => redirectToChatCb(currentNeedChat[0])}
        >
          В чат
        </Button>
      ) : (
        <Button size="sm" className="item-button" onClick={(): void => chatCb(need)}>
          Створити
        </Button>
      )}
    </div>
  </div>
);
