import React, { Component, ErrorInfo, ReactNode } from 'react';

import './ErrorBoundary.scss';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import { EventAntiFloodException, die, on } from '../../eventBus';
import { ROUTES } from '../../router/config';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  errorType: 'typeError' | 'antiFlood';
  timeout: number;
}

class ErrorBoundary extends Component<Props, State> {
  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorType: 'typeError', timeout: 0 };
  }

  public state: State = {
    hasError: false,
    errorType: 'typeError',
    timeout: 0,
  };

  public componentDidMount(): void {
    on('anti_flood_exception', ({ timeout }: EventAntiFloodException) => {
      this.setState({ hasError: true, errorType: 'antiFlood', timeout });
      setTimeout(() => {
        window.location.href = ROUTES.HOME;
      }, timeout * 1000);
    });
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
  }

  public componentWillUnmount(): void {
    die('anti_flood_exception');
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return <ErrorPage errorType={this.state.errorType} timeout={this.state.timeout} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
