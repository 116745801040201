import { ServiceCodes } from '../../../components/Api/types';
import { decorateERZRoute } from '../helpers/decorateERZRoute';

export const ERZ_ROUTES = {
  USER: {
    WEAPON_REGISTERED: '/user/weapon-registered',
  },
  SERVICES: {
    COMMISSION_SALE: '/services/commission-sale',
    COLLECTABLE_WEAPONS_TRANSPORTATION: '/services/collectable-cold-weapons-transportation',
    WEAPON_PURCHASE_PERMIT: '/services/weapon-purchase-permit',
    RESIDENCE_RELOCATION: '/services/residence-relocation',
    TEMPORARY_RESIDENCE_RELOCATION: '/services/temporary-residence-relocation',
    WEAPON_TRANSPORTATION: '/services/weapon-transportation',
    WEAPON_STORAGE_CARRYING: '/services/weapon-storage-carrying',
    PERMISSION_RENEWAL: '/services/permission-renewal',
    COLD_STEEL_STORAGE_FOR_COLLECTION: '/services/cold-steel-storage-for-collection',
    CANCELLATION_TEMPORARY_RESIDENCE_RELOCATION: '/services/cancellation-temporary-residence-relocation',
    ERZ_EXTRACT: '/services/erz-extract',
    ERZ_SEARCH: '/services/erzsearch',
  },
} as const;

export const ERZ_ROUTES_BY_SERVICE_CODE = {
  [ServiceCodes.COMMISSION_SALE]: decorateERZRoute(ERZ_ROUTES.SERVICES.COMMISSION_SALE),
  [ServiceCodes.COLD_STEEL_TRANSPORTATION]: decorateERZRoute(ERZ_ROUTES.SERVICES.COLLECTABLE_WEAPONS_TRANSPORTATION),
  [ServiceCodes.PURCHASE]: decorateERZRoute(ERZ_ROUTES.SERVICES.WEAPON_PURCHASE_PERMIT),
  [ServiceCodes.RESIDENCE_RELOCATION]: decorateERZRoute(ERZ_ROUTES.SERVICES.RESIDENCE_RELOCATION),
  [ServiceCodes.TEMPORARY_RESIDENCE_RELOCATION]: decorateERZRoute(ERZ_ROUTES.SERVICES.TEMPORARY_RESIDENCE_RELOCATION),
  [ServiceCodes.TRANSPORT_ACROSS_BORDER]: decorateERZRoute(ERZ_ROUTES.SERVICES.WEAPON_TRANSPORTATION),
  [ServiceCodes.STORAGE_CARRYING]: decorateERZRoute(ERZ_ROUTES.SERVICES.WEAPON_STORAGE_CARRYING),
  [ServiceCodes.VALIDITY_PERIOD_PROLONGATION]: decorateERZRoute(ERZ_ROUTES.SERVICES.PERMISSION_RENEWAL),
  [ServiceCodes.COLD_STEEL_STORAGE_FOR_COLLECTION]: decorateERZRoute(
    ERZ_ROUTES.SERVICES.COLD_STEEL_STORAGE_FOR_COLLECTION,
  ),
  [ServiceCodes.CANCELLATION_TEMPORARY_RESIDENCE_RELOCATION]: decorateERZRoute(
    ERZ_ROUTES.SERVICES.CANCELLATION_TEMPORARY_RESIDENCE_RELOCATION,
  ),
  [ServiceCodes.ERZ_EXTRACT]: decorateERZRoute(ERZ_ROUTES.SERVICES.ERZ_EXTRACT),
  [ServiceCodes.ERZ_SEARCH]: decorateERZRoute(ERZ_ROUTES.SERVICES.ERZ_SEARCH),
};

export const ERZ_ROUTES_LEGACY = [
  {
    LEGACY_PATH: '/erzsearch',
    RELEVANT_PATH: ERZ_ROUTES_BY_SERVICE_CODE[ServiceCodes.ERZ_SEARCH],
  },
];
