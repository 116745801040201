import def from 'lodash/defaultTo';

import { getDateWithoutTime } from '../helpers/dates';

const parseBool = (v: unknown): boolean => v === 'true';

export const config = {
  VERSION: process.env.REACT_APP_VERSION || 'unknown',
  authProviders: {
    idGov: def(parseBool(process.env.REACT_APP_AUTH_PROVIDER_ID_GOV), true),
    basic: def(parseBool(process.env.REACT_APP_AUTH_PROVIDER_BASIC), false),
  },
  signUp: {
    checkRnokpp: localStorage.hasOwnProperty('ignore-sign')
      ? false
      : def(!parseBool(process.env.REACT_APP_TEST_DATA_SIGN_DEV), true),
    isNewVersion: true,
  },
  cache: {
    validFrom: getDateWithoutTime(def(process.env.CACHE_VALID_FROM_DATE, new Date())),
    validityPeriod: def(Number(process.env.CACHE_VALID_FROM_DATE), 1),
  },
  support: {
    phoneNumber: '+380 (44) 334 40 35',
    email: 'services@infotech.gov.ua',
  },
  captcha: {
    siteKey: process.env.REACT_APP_CAPTCHA_SITEKEY || '6LdfcW8dAAAAAIwmUkjbhajxEg8nLP2RWb4wD04Z',
    enabled: parseBool(process.env.REACT_APP_CAPTCHA_ENABLED),
  },
};
