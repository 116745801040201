import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import './ErrorPage.scss';

import Error404Image from '../../assets/images/404.svg';
import Error500Image from '../../assets/images/500.svg';
import logo from '../../assets/images/Logo_grey.svg';
import { Timer } from '../../components/Timer';
import { ROUTES } from '../../router/config';

interface Props {
  errorType: '404' | '500' | 'typeError' | 'antiFlood';
  timeout?: number;
}

const ErrorPage: React.FC<Props> = ({ errorType, timeout = 0 }): JSX.Element => {
  const errorData = useMemo<{
    img?: string;
    title: string | React.JSX.Element;
    description: string | React.JSX.Element;
  }>(() => {
    switch (errorType) {
      case '404':
        return {
          img: Error404Image,
          title: 'Ой, щось пішло не так...',
          description: 'Такої сторінки не існує.',
        };

      case '500':
        return {
          img: Error500Image,
          title: 'Шановний клієнт!',
          description: (
            <>
              <div>Наразі на сайті проводяться технічні роботи.</div>
              <div>Спробуйте зайти трохи пізніше.</div>
              <div>Дякуємо за розуміння.</div>
            </>
          ),
        };

      case 'typeError':
        if (process.env.NODE_ENV !== 'development' && !window.localStorage.getItem('error_boundary_ignore_redirect')) {
          setTimeout(() => {
            window.location.href = ROUTES.HOME;
          }, 3000);
        }

        return {
          title: 'Ой, щось пішло не так...',
          description: 'Вас буде перенаправлено на головну сторінку автоматично за декілька секунд',
        };

      case 'antiFlood': {
        return {
          title: (
            <div>
              <div className="timer-container">
                <Timer seconds={timeout} />
              </div>
              <h1>Шановний клієнт!</h1>
            </div>
          ),
          description: (
            <>
              <div>На жаль, сталася помилка. Повторити спробу Ви зможете через {timeout} секунд.</div>
              <div>Дякуємо за розуміння.</div>
            </>
          ),
        };
      }
    }
  }, [errorType, timeout]);

  return (
    <div className={`error-page error-page--${errorType}`}>
      <div className="error-page__header">
        <span className="error-page__header__logo">
          <img src={logo} alt="Logo" />
        </span>

        <div className="error-page__header__title">Єдине вікно для громадян</div>
      </div>

      <div className="error-page__body">
        {errorData.img && (
          <span className="error-page__body__img">
            <img src={errorData.img} alt={'Error - ' + errorType} />
          </span>
        )}

        <div className="error-page__body__title">{errorData.title}</div>
        <div className="error-page__body__description">{errorData.description}</div>

        {errorType === '404' && (
          <div>
            <Link className="button" to={ROUTES.HOME}>
              На головну
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
