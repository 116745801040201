/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-inferrable-types */

import React, { useEffect, useState } from 'react';

import '../../../containers/StyleSettings/components/form/euSing.scss';
import * as euLibNew from '../../../assets/signWidget/eusign_new';
import * as euLibOld from '../../../assets/signWidget/eusign_old';
import { config } from '../../../config';
import { FormStepButtonId } from '../Buttons';
import { StepsNavigationName } from '../../../config/services';

const IFRAME_CONTAINER = 'sign-widget-parent';
const WIDGET_ID = 'sign-widget';
const external = true;
const asBase64String = true;
const previousSign: null = null;
const { lib, signAlgo, signType, WIDGET_URI } = {
  old: {
    WIDGET_URI: 'https://id.gov.ua/sign-widget/v20200922/',
    lib: euLibOld,
    signAlgo: euLibOld.EndUser.SignAlgo.DSTU4145WithGOST34311,
    signType: euLibOld.EndUser.SignType.CAdES_X_Long,
  },
  new: {
    WIDGET_URI: 'https://id.gov.ua/sign-widget/v20220527/',
    lib: euLibNew,
    signAlgo: euLibNew.EndUser.SignAlgo.DSTU4145WithGOST34311,
    signType: euLibNew.EndUser.SignType.CAdES_X_Long,
  },
}[process.env.REACT_APP_EUSIGNLIB_MODE || 'old'];

interface Props {
  rnokpp: string;
  signUpPayload: any;
  handleSignUp: (sign: string) => void;
}

export const SignUp = React.memo(function SignUp({ rnokpp, signUpPayload, handleSignUp }: Props) {
  const [errorText, setErrorText] = useState<string>('');

  useEffect(() => {
    const widget = new lib.EndUser(IFRAME_CONTAINER, WIDGET_ID, WIDGET_URI, lib.EndUser.FormType.ReadPKey, null);

    widget.iframe.onload = () => {
      widget.ReadPrivateKey().then(async (signData: any) => {
        const signButton: HTMLButtonElement = document.querySelector(`button#${FormStepButtonId.sign}`);

        if (!config.signUp.checkRnokpp || rnokpp === signData?.[0]?.['infoEx']?.['subjDRFOCode']) {
          signButton.disabled = false;
          setErrorText('');
        } else {
          signButton.disabled = config.signUp.checkRnokpp;
          setErrorText(config.signUp.checkRnokpp ? 'РНОКПП не збігається з вказаним у профілі' : '');
          return;
        }

        const payload = JSON.stringify(signUpPayload);
        const signed = await widget.SignData(payload, external, asBase64String, signAlgo, previousSign, signType);

        handleSignUp(signed);

        signButton.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      });
    };
  }, [handleSignUp, rnokpp, signUpPayload]);

  return (
    <>
      <div
        id="step-description"
        className="block-title block-title_lg block-title_padding-bottom-md-extra block-title_bg-light"
      >
        {StepsNavigationName.signUp}
      </div>

      <div className="form__content form__content_padding-md">
        <div className="sign-up-data">
          <div className="form-box__container flex flex-col gap-y-4 lg:gap-y-6 pt-0">
            <div id="sign-widget-parent" className="row"></div>

            {!!errorText && (
              <div>
                <div className="text-error">Виникла помилка:</div>
                <pre>{errorText}</pre>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
});
